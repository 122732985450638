import axios from "axios"
import{applicationActions} from "../Data/Slices/ApplicationSlice";
import { apiDomain } from "../../Config";

const apiUrl = apiDomain();

const url = "http://qa.edvanz.com"
export const getResources = async (token, group_id, group_owner_id, folder_id, folder_user_id, user_id, type, is_parent,dispatch) => {
    // console.log("Getting Resources", token, group_id, group_owner_id, folder_id, folder_user_id, user_id, type, is_parent);
    try {
        const { data } = await axios.post(`${apiUrl}mobile/user/get-group-documents`, {
            group_id, 
            group_owner_id, 
            user_id, 
            is_parent, 
            type,
            folder_id, 
            folder_user_id
        }, {
            headers: {
                "Content-Type": "application/json",
                TOKEN: token,
            },
        })

        return data
    } catch (e) {
        dispatch(
            applicationActions.setAlertData({
              isAlert: true,
              alertMessage: "Internal Server Error",
              alertType: "danger",
            })
          );
          setTimeout(() => {
            dispatch(
              applicationActions.setAlertData({
                isAlert: false,
                alertMessage: "",
                alertType: "",
              })
            );
          }, 3000);
    }
}