import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import StudentCard from "../../../UI/Analysis/StudentCard";
import AttemptAnalysis from "../../../UI/Analysis/AttemptAnalysis";
import Leaderboard from "../../../UI/Analysis/Leaderboard";

// Data for Subject Card
const subjects = [
  {
    name: "Physics",
    icon: "/analysis3.svg",
    percentage: "34.48%",
    correct: 8,
    incorrect: 12,
  },
  {
    name: "Biology",
    icon: "/analysis4.svg",
    percentage: "34.48%",
    correct: 8,
    incorrect: 12,
  },
  {
    name: "Chemistry",
    icon: "/analysis5.svg",
    percentage: "34.48%",
    correct: 8,
    incorrect: 12,
  },
];

const SubjectCard = ({ name, icon, rank, correct, incorrect }) => {
  return (
    <div className="bg-white rounded-xl p-6 flex flex-col  items-center w-full md:w-[327px] shadow-[2px_4px_60px_0px_rgba(7,50,120,0.1)]">
      {/* Header */}
      <div className="flex justify-between w-full items-center">
        <p className="bg-[#0A3A8E] text-white px-3 py-1 font-normal rounded-full lg:text-lg md:text-base text-lg">
          {name || "NA"}
        </p>
        <img
          src={"./analysis3.svg"}
          alt={name}
          className=" lg:w-12 lg:h-12 md:w-8 md:h-8  w-12 h-12"
        />
      </div>

      {/* Separator */}
      <div className="w-full border-b my-3"></div>

      {/* Score */}
      <p className="text-3xl font-bold">{rank || "-"}</p>

      {/* Correct/Incorrect Stats */}
      <div className="flex justify-between w-full text-gray-600 lg:text-sm md:text-xs  text-sm mt-3">
        <p>Correct : {correct || "-"}</p>
        <p>Incorrect : {incorrect || "-"}</p>
      </div>
    </div>
  );
};

const OverallAnalysis = ({
  pieChartActiveSession,
  setPieChartActiveSession,
  leaderBoardActiveSession,
  setLeaderBoardActiveSession,
  analyticsData,
  quizId,
  attemptStudentId,
}) => {
  // console.log(analyticsData);

  return (
    <div className="flex flex-col relative">
      {/* Student Details */}
      <div className="z-1 mx-5 ">
        <StudentCard analyticsData={analyticsData} />
      </div>

      {/* Star Image */}
      <img
        src="/analysis2.svg"
        alt="Analysis"
        className="lg:w-[70%] w-[100%] h-auto object-cover absolute top-52 left-1/2 transform -translate-x-1/2  "
      />

      {/* Trophy Image */}
      <img
        src="/analysis1.svg"
        alt="AnalysisTrophy"
        className="w-[200px] lg:w-[300px] h-auto object-cover self-center z-1 lg:mt-10 mt-3 "
      />
      <p className="text-center font-bold text-3xl mt-2 z-1 bg-white">
        Great Job, Keep it Up!
      </p>
      <p className="text-center font-extralight text-sm text-textGrey z-1 mt-1 bg-white">
        You’ve scored {analyticsData?.awarded_score} out of{" "}
        {analyticsData?.total_score} marks
      </p>

      {/* Rank Boxes */}
      <div className="mx-5 z-1">
        <div className="flex items-center flex-col lg:flex-row gap-5 lg:gap-0 justify-between bg-white shadow-[2px_4px_60px_0px_rgba(7,50,120,0.1)] rounded-xl px-6 py-4 w-full max-w-5xl xl:mx-auto mt-5 z-1">
          <div className="flex gap-4">
            <div className="bg-[#0743A3] text-white px-6 py-2 rounded-2xl text-center">
              <p className="text-2xl md:text-3xl font-bold">
                {analyticsData?.student_rank}
              </p>
              <p className="text-sm md:text-md">Your Rank</p>
            </div>
            <div className="bg-[#F36421] text-white px-6 py-2 rounded-2xl text-center">
              <p className="text-2xl md:text-3xl font-bold">
                {analyticsData?.ai_rank}
              </p>
              <p className="text-sm md:text-md">AITS Rank</p>
            </div>
            <div className="bg-[#9821F3] text-white px-6 py-2 rounded-2xl text-center justify-center flex flex-col">
              <p className="text-2xl md:text-3xl font-bold">
                {analyticsData?.awarded_score}/{analyticsData?.total_score}
              </p>
              <p className="text-sm md:text-md">Score</p>
            </div>
          </div>

          {/* Percentage & Time */}
          <div className="flex gap-6">
            <div className="text-center">
              <p className="text-3xl font-bold">{analyticsData?.percentage}%</p>
              <p className="text-md text-textGrey">Percentage</p>
            </div>
            <div className="text-center">
              <p className="text-3xl font-bold">
                {analyticsData?.total_time_taken}
              </p>
              <p className="text-md text-textGrey">Time Taken</p>
            </div>
          </div>
        </div>
      </div>

      {/* Subject Card */}
      <div className="flex flex-col md:flex-row justify-center gap-5 mt-6 mx-5">
        {analyticsData?.subjectwiseAttempt?.map((subject, index) => (
          <SubjectCard key={index} {...subject} />
        ))}
      </div>

      {/* Data Div */}
      <div
        className="mt-8 py-5 flex flex-col items-center justify-center"
        style={{
          background:
            "linear-gradient(249.22deg, #001A44 44.08%, #002A6C 101.45%)",
        }}
      >
        {/* Bar Chart & Pie Chart */}
        <div className="xl:w-[80%] w-[100%] ">
          <AttemptAnalysis
            pieChartActiveSession={pieChartActiveSession}
            setPieChartActiveSession={setPieChartActiveSession}
            analyticsData={analyticsData}
            quizId={quizId}
            attemptStudentId={attemptStudentId}
          />
        </div>

        {/* Leaderboard & Topic Wise Analysis */}
        <div className="xl:w-[80%] w-[100%] mt-10">
          <Leaderboard
            leaderBoardActiveSession={leaderBoardActiveSession}
            setLeaderBoardActiveSession={setLeaderBoardActiveSession}
            analyticsData={analyticsData}
          />
        </div>

        {/* Download & Share Button */}
        <div className="flex justify-center mt-10 mb-10 gap-2">
          <button className="bg-[#FEF0E9] text-sm text-white p-2 rounded-full font-medium shadow-lg hover:bg-primaryOrange transition">
            <ShareIcon className="text-lg text-primaryOrange hover:text-white" />
          </button>
          <button className="bg-primaryOrange text-sm text-white px-6 py-2 rounded-full font-medium shadow-lg hover:bg-[#073278] transition hover:bg-transparent border border-primaryOrange hover:text-primaryOrange">
            <DownloadIcon className="text-base" /> Download Results
          </button>
        </div>
      </div>
    </div>
  );
};

export default OverallAnalysis;
