// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login2_container__3-XRw {
  width: 100%;
  height: 100vh;
}

.Login2_leftContainer__nPPGn {
  width: 60%;
  height: 100%;
  background: linear-gradient(249.29deg, #001A44 44.1%, #002A6C 101.3%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login2_rightContainer__ZeaaI {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Login/Login2.0/Login2.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,qEAAqE;EACrE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".container {\n  width: 100%;\n  height: 100vh;\n}\n\n.leftContainer {\n  width: 60%;\n  height: 100%;\n  background: linear-gradient(249.29deg, #001A44 44.1%, #002A6C 101.3%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.rightContainer {\n  width: 40%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Login2_container__3-XRw`,
	"leftContainer": `Login2_leftContainer__nPPGn`,
	"rightContainer": `Login2_rightContainer__ZeaaI`
};
export default ___CSS_LOADER_EXPORT___;
