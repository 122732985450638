import React from "react";
import QuestionMenuBar from "../QuestionMenuBar/QuestionMenuBar";

const Tag = ({ tagName, tagValue, tagColor, tagButtonColor }) => {
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: tagColor,
        height: "fit-content",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          backgroundColor: tagButtonColor,
          alignItems: "center",
          borderRadius: "5px",
          padding: "0px 5px",
        }}
      >
        <p
          style={{
            marginBottom: "0px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {tagName}
        </p>
      </div>
      {tagName !== "Actions" && (
        <p
          style={{
            marginBottom: "0px",
            fontSize: "12px",
            fontWeight: "bold",
            margin: "0px 5px",
          }}
        >
          {tagValue}
        </p>
      )}
      {tagName === "Actions" && <QuestionMenuBar />}
    </div>
  );
};

export default Tag;
