import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import classes from "./SubmiteModal.module.css";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { submitQuiz } from "../../../Store/API/API";
// import { Spinner } from "react-bootstrap";

const SubmiteModal = ({
  show,
  handleClose,
  unAnswered,
  answered,
  markedForReview,
  notVisited,
  online,
  handleTestSubmit,
  quizId,
}) => {
  const questionMendatory = useSelector((state) => state.answerData.mendatory);
  const countMendatory = useSelector(
    (state) => state.answerData.mendatoryCount
  );
  const dispatch = useDispatch();
  const answerState = useSelector((state) => state.answerData.answer);
  const userAttemptId = useSelector((state) => state.answerData.attemptId);
  const userState = useSelector((state) => state.userData.userData);
  const [requiredQuestionBiology, setRequiredQuestionBiology] = useState(true);
  const [requiredQuestionPhysics, setRequiredQuestionPhysics] = useState(true);
  const [requiredQuestionChemistry, setRequiredQuestionChemistry] =
    useState(true);
  const [requiredQuestionMaths, setRequiredQuestionMaths] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [testSubmitted, setTestSubmitted] = useState(false);
  const [error, setError] = useState(false);

  // console.log(answerState, "answerState SubmiteModal");
  // console.log(userAttemptId, "userAttemptId");

  const checkMandatoryQuestion = (questionMendatory, countMendatory) => {
    if (questionMendatory?.Biology) {
      if (
        questionMendatory.Biology?.section_one_mandetory <=
          countMendatory.Biology?.section_one_mandetory &&
        questionMendatory.Biology?.section_two_mandetory <=
          countMendatory.Biology?.section_two_mandetory
      ) {
        setRequiredQuestionBiology(true);
      } else {
        setRequiredQuestionBiology(false);
      }
    }
    if (questionMendatory?.Physics) {
      if (
        questionMendatory.Physics?.section_one_mandetory <=
          countMendatory.Physics?.section_one_mandetory &&
        questionMendatory.Physics?.section_two_mandetory <=
          countMendatory.Physics?.section_two_mandetory
      ) {
        setRequiredQuestionPhysics(true);
      } else {
        setRequiredQuestionPhysics(false);
      }
    }
    if (questionMendatory?.Chemistry) {
      if (
        questionMendatory.Chemistry?.section_one_mandetory <=
          countMendatory.Chemistry?.section_one_mandetory &&
        questionMendatory.Chemistry?.section_two_mandetory <=
          countMendatory.Chemistry?.section_two_mandetory
      ) {
        setRequiredQuestionChemistry(true);
      } else {
        setRequiredQuestionChemistry(false);
      }
    }
    if (questionMendatory?.Maths) {
      if (
        questionMendatory.Maths?.section_one_mandetory <=
          countMendatory.Maths?.section_one_mandetory &&
        questionMendatory.Maths?.section_two_mandetory <=
          countMendatory.Maths?.section_two_mandetory
      ) {
        setRequiredQuestionMaths(true);
      } else {
        setRequiredQuestionMaths(false);
      }
    }
  };

  useEffect(() => {
    if (questionMendatory && countMendatory) {
      checkMandatoryQuestion(questionMendatory, countMendatory);
    }
  }, [questionMendatory, countMendatory]);

  // console.log(questionMendatory, countMendatory, "mendatory");

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage =
        "Are you sure you want to leave? All progress will be lost.";
      event.preventDefault();
      event.returnValue = confirmationMessage;
      // console.log(event);
      // console.log("user Trying to leave");
      // console.log(answerState, "answerState");
      localStorage.setItem("answerState", JSON.stringify(answerState));
      localStorage.setItem("userAttemptId", userAttemptId);
      localStorage.setItem("userState", JSON.stringify(userState));
      localStorage.setItem(
        "questionMendatory",
        JSON.stringify(questionMendatory)
      );
      return confirmationMessage;
    };
    // const handleUnload = (event) => {
    //   event.preventDefault();
    //   console.log(event);
    //   console.log("user is Leaving");
    // };

    window.addEventListener("beforeunload", handleBeforeUnload);
    // window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line
  }, [answerState]);

  const handleSubmit = async () => {
    // console.log(answerState, "submitting");
    setIsSubmitting(true);
    setError(false);
    if (localStorage.getItem("answerState")) {
      localStorage.removeItem("answerState");
      localStorage.removeItem("userAttemptId");
      localStorage.removeItem("userState");
      localStorage.removeItem("questionMendatory");
    }
    if (userAttemptId) {
      const data = await submitQuiz(
        userState,
        userAttemptId,
        answerState,
        questionMendatory,
        quizId || userState.testId,
        dispatch
      );
      // console.log(data, "data");
      if (data === "200") {
        setTestSubmitted(true);
        handleTestSubmit();
        // window.location.href = "https://app.innovartan.com";
      }

      if (data === 400) {
        setError(true);
        setIsSubmitting(false);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <Modal.Body className={classes.modalOuterContainer}>
          <div>
            <h3 className={classes.heading}>Confirmation</h3>
            <p>Submission Details</p>
            <div className={classes.detailsModal}>
              <div
                className={classes.typeBox}
                style={{ backgroundColor: "#f25c5c" }}
              >
                <p style={{ marginBottom: 0 }}>Not Answered</p>
              </div>
              <div
                className={classes.valueBox}
                style={{ backgroundColor: "#98A2FE" }}
              >
                <p style={{ marginBottom: 0 }}>{unAnswered}</p>
              </div>
            </div>
            <div className={classes.detailsModal}>
              <div
                className={classes.typeBox}
                style={{ backgroundColor: "#6eea91" }}
              >
                <p style={{ marginBottom: 0 }}>Answered</p>
              </div>
              <div
                className={classes.valueBox}
                style={{ backgroundColor: "#98A2FE" }}
              >
                <p style={{ marginBottom: 0 }}>{answered}</p>
              </div>
            </div>
            <div className={classes.detailsModal}>
              <div
                className={classes.typeBox}
                style={{ backgroundColor: "#eab14f" }}
              >
                <p style={{ marginBottom: 0 }}>Marked for Review</p>
              </div>
              <div
                className={classes.valueBox}
                style={{ backgroundColor: "#98A2FE" }}
              >
                <p style={{ marginBottom: 0 }}>{markedForReview}</p>
              </div>
            </div>
            <div className={classes.detailsModal}>
              <div
                className={classes.typeBox}
                style={{ backgroundColor: "#D9D9D9" }}
              >
                <p style={{ marginBottom: 0 }}>Not Visited</p>
              </div>
              <div
                className={classes.valueBox}
                style={{ backgroundColor: "#98A2FE" }}
              >
                <p style={{ marginBottom: 0 }}>{notVisited}</p>
              </div>
            </div>
            <h6 style={{ textAlign: "center", margin: "10px 0px" }}>
              You have still time left to answer{" "}
              <span style={{ color: "#f25c5c" }}> {unAnswered} </span> Not
              Answered Question(s),{" "}
              <span style={{ color: "#eab14f" }}>{markedForReview} </span>{" "}
              Marked For Review Question(s),{" "}
              <span style={{ color: "#757ece" }}> {notVisited} </span> Not
              Visited Question(s)
            </h6>
            <p
              style={{
                textAlign: "center",
                margin: "10px 0px",
                fontWeight: 700,
              }}
            >
              Still do You want to Proceed with your submit
            </p>
            {online ? (
              <p
                style={{
                  textAlign: "center",
                  margin: "10px 0px",
                  fontWeight: 700,
                  color: "green",
                }}
              >
                You are online, you can submit your test
              </p>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  margin: "10px 0px",
                  fontWeight: 700,
                  color: "red",
                }}
              >
                You are offline. Please check your internet connection
              </p>
            )}
            {!testSubmitted && !requiredQuestionPhysics ? (
              <p
                style={{
                  textAlign: "center",
                  margin: "10px 0px",
                  fontWeight: 700,
                  color: "red",
                }}
              >
                Please answer all the mandatory questions in Physics
              </p>
            ) : null}
            {!testSubmitted && !requiredQuestionChemistry ? (
              <p
                style={{
                  textAlign: "center",
                  margin: "10px 0px",
                  fontWeight: 700,
                  color: "red",
                }}
              >
                Please answer all the mandatory questions in Chemistry
              </p>
            ) : null}
            {!testSubmitted && !requiredQuestionBiology ? (
              <p
                style={{
                  textAlign: "center",
                  margin: "10px 0px",
                  fontWeight: 700,
                  color: "red",
                }}
              >
                Please answer all the mandatory questions in Biology
              </p>
            ) : null}
            {!testSubmitted && !requiredQuestionMaths ? (
              <p
                style={{
                  textAlign: "center",
                  margin: "10px 0px",
                  fontWeight: 700,
                  color: "red",
                }}
              >
                Please answer all the mandatory questions in Maths
              </p>
            ) : null}
            {testSubmitted && (
              <p
                style={{
                  textAlign: "center",
                  margin: "10px 0px",
                  fontWeight: 700,
                  color: "red",
                }}
              >
                Test Submitted Successfully, You can close this window now
              </p>
            )}

            <div style={{ display: "flex", justifyContent: "center" }}>
              {!testSubmitted && (
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className={classes.modalButton}
                  disabled={isSubmitting}
                >
                  Close
                </Button>
              )}
              {!testSubmitted && !isSubmitting && (
                <Button
                  // disabled={
                  //   !requiredQuestionBiology ||
                  //   !requiredQuestionChemistry ||
                  //   !requiredQuestionPhysics ||
                  //   !requiredQuestionMaths ||
                  //   !online
                  // }
                  variant="primary"
                  className={classes.modalButton}
                  onClick={handleSubmit}
                  disabled={!online}
                >
                  {/* <Link
                  to="/test/retest/submission"
                  style={{ color: "white", textDecoration: "none" }}
                > */}
                  Submit Test
                  {/* </Link> */}
                </Button>
              )}
              {isSubmitting && (
                <Button
                  disabled={true}
                  variant="primary"
                  className={classes.modalButton}
                >
                  Submitting
                </Button>
              )}
              {testSubmitted && (
                <Button
                  disabled={true}
                  variant="danger"
                  className={classes.modalButton}
                >
                  Test Submitted
                </Button>
              )}
            </div>
            {error && (
              <p
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Error while submitting the test, Please try again
              </p>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SubmiteModal;
