import React, { useState } from "react";
import styles from "./MCQMA.module.css";
// import QuestionMenuBar from "../QuestionMenuBar/QuestionMenuBar";
import Tag from "../Tag/Tag";
import EditIcon from "@mui/icons-material/Edit";
import QuestionsBottomTag from "../QuestionsBottomTag/QuestionsBottomTag";
import QuestionTopTag from "../QuestionsTopTag/QuestionTopTag";
import Latex from "react-latex-next";
import QuestionEditBar from "../QuestionEditBar/QuestionEditBar";
import he from "he";

const MCQMA = ({
  correctOption,
  answer,
  question,
  questionOrder,
  location,
  questionId,
  questionBankId,
  questionMarks,
  questionData,
  dropDownValues,
  currentPage,
  handleButtonClick
}) => {
  return (
    <div className={styles.mcqContainer}>
      <QuestionTopTag
        questionId={questionId}
        questionMarks={questionMarks}
        location={location}
        questionBankId={questionBankId}
        handleButtonClick={handleButtonClick}
        currentPage={currentPage}
        dropDownValues={dropDownValues}
      />
      <div className={styles.headerSectionContainer}>
        <div className={styles.mcqQuestion}>
          <div style={{ display: "flex" }}>
            {location !== "QB" ? (
              <input
                type="text"
                className={styles.questionInputBox}
                defaultValue={questionOrder}
              />
            ) : (
              <div className={styles.questionInputBox}>{questionOrder}</div>
            )}
            <div style={{ display: "ruby" }}>
              <Latex>{he.decode(question || "-")}</Latex>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mcqOptions}>
        <div className={styles.mcqOptionContainer}>
          <p className={styles.mcqOptiontag}>A.</p>
          <div
            className={
              answer?.includes("A") ? styles.mcqOptionCorrect : styles.mcqOption
            }
          >
            {/* <div
              dangerouslySetInnerHTML={{
                __html: correctOption[0]?.name || " ",
              }}
            ></div> */}
            <div>
              <Latex>{he.decode(correctOption?.[0]?.name || " ")}</Latex>
            </div>
          </div>
        </div>
        <div className={styles.mcqOptionContainer}>
          <p className={styles.mcqOptiontag}>B.</p>
          <div
            className={
              answer?.includes("B") ? styles.mcqOptionCorrect : styles.mcqOption
            }
          >
            <div>
              <Latex>{he.decode(correctOption?.[1]?.name || " ")}</Latex>
            </div>
          </div>
        </div>
        <div className={styles.mcqOptionContainer}>
          <p className={styles.mcqOptiontag}>C.</p>
          <div
            className={
              answer?.includes("C") ? styles.mcqOptionCorrect : styles.mcqOption
            }
          >
            <div>
              <Latex>{he.decode(correctOption?.[2]?.name || " ")}</Latex>
            </div>
          </div>
        </div>
        <div className={styles.mcqOptionContainer}>
          <p className={styles.mcqOptiontag}>D.</p>
          <div
            className={
              answer?.includes("D") ? styles.mcqOptionCorrect : styles.mcqOption
            }
          >
            <div>
              <Latex>{he.decode(correctOption?.[3]?.name || " ")}</Latex>
            </div>
          </div>
        </div>
      </div>
      {(location === "QB" || location === "QL")  && (
        <div className={styles.QuestionBankContainer}>
          <QuestionsBottomTag questionData={questionData}  />
        </div>
      )}
    </div>
  );
};

export default MCQMA;
