import React, { useRef, useState } from "react";
import {applicationActions} from "../../../Store/Data/Slices/ApplicationSlice";
import { MdOutlineInsertComment } from "react-icons/md";
// import { AiOutlineLike } from "react-icons/ai";
import Input from "../Input/Input";
import {
  deleteComment,
  deletePost,
  handleCommentPost,
  handleLikePost,
} from "../../../Store/API/User";
// import { IoFileTraySharp } from "react-icons/io5";
// import { FaFile } from "react-icons/fa";
// import { Link } from "react-router-dom";
import { BiSolidLike } from "react-icons/bi";
import AttachmentBox from "../Box/AttachmentBox";
import { useDispatch, useSelector } from "react-redux";

const Comment = ({ feed, setFetchAgain }) => {
  const inputRef = useRef();
  const [liked, setLiked] = useState(feed.like_status === 1);
  const [likeCount, setLikeCount] = useState(feed.like_counter);
  const [comments, setComments] = useState(feed.comment);
  const dispatch=useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);

  // console.log(feed)

  // const user_id = 23;
  // const comment_id = 4;
  // const activity_id = 162;
  // const name = null;
  const profile_image =
    "https://d1hwk63oep8mdv.cloudfront.net/4a24423e7382de98949e300536a84340507496747/default_user.png";
  // const type = "comments";
  // const updated_ts = "2024-07-05 13:22:31";
  const created_ts = "3 minutes ago";

  const handleSubmit = async () => {
    // console.log(feed);
    try {
      const token = userState?.token;
      const formData = new FormData();
      formData.append("activity_id", feed.activity_id);
      formData.append("activity_user_id", userState?.userId);
      formData.append("activity_object_type", feed.activity_object_type);
      formData.append("activity_object_user_id", feed.activity_object.user_id);
      formData.append("activity_object_id", feed.activity_object_id);
      formData.append("content", inputRef.current.value);

      const response = await handleCommentPost(token, formData,dispatch);

      if (response && response.type) {
        const newComment = {
          user_id: response.data[0].user_id,
          name: response.data[0].name ?? null,
          profile_image: response.data[0].profile_image ?? profile_image,
          created_ts: response.data[0].created_ts ?? created_ts,
          content: response.data[0].content,
        };

        setComments([...comments, newComment]);
        inputRef.current.value = "";
      }
    } catch (error) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Internal Server Error",
          alertType: "danger",
        })
      );
      setTimeout(() => {
        dispatch(
          applicationActions.setAlertData({
            isAlert: false,
            alertMessage: "",
            alertType: "",
          })
        );
      }, 3000);
    }
  };

  const handleLike = async () => {
    if (liked) return;
    try {
      const token = userState?.token;
      const formData = new FormData();
      formData.append("activity_id", feed.activity_id);
      formData.append("activity_user_id", userState?.userId);
      formData.append("activity_object_type", feed.activity_object_type);
      formData.append("activity_object_user_id", feed.activity_object.user_id);
      formData.append("activity_object_id", feed.activity_object_id);

      const response = await handleLikePost(token, formData,dispatch);

      if (response && response.type) {
        setLiked(!liked);
        setLikeCount((prev) => parseInt(prev) + 1);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleDeleteComment = async (activityId, userId) => {
    const data = await deleteComment(activityId, userId, userState?.token,dispatch);
    // console.log(data);
    if (data && data.type) {
      setComments((prev) =>
        prev.filter((comment) => comment.activity_id !== activityId)
      );
    }
  };

  const handleDeletePost = async () => {
    const data = await deletePost(
      feed.activity_id,
      userState?.userId,
      batchState?.groupId,
      userState?.token,dispatch
    );
    // console.log(data);
    if (data && data.type) {
      setFetchAgain((prev) => !prev);
    }
  };

  let attachments = [];
  if (feed.activity_object.attached_objects) {
    try {
      attachments = JSON.parse(feed.activity_object.attached_objects);
    } catch (e) {
      console.error("Error parsing attached_objects", e);
    }
  }

  return (
    feed && (
      <div className="chat flex w-full rounded-lg py-4 px-4 mb-2 md:p-12 border border-gray-300 bg-white">
        {feed.activity_user_info && (
          <div className="border-2 border-gray-500 rounded-full w-12 h-12 mr-7 mt-2">
            <img
              className="w-full object-contain"
              src={feed?.activity_user_info?.profile_image}
              alt="Profile"
            />
          </div>
        )}
        <div className="info relative flex-1">
          <h5 className=" py-0 my-0">
            {feed?.activity_user_info.first_name}{" "}
            {feed?.activity_user_info.last_name}
          </h5>
          <span className="text-sm py-0 text-gray-400">
            {feed.view_created_ts}
          </span>
          <hr className="w-full border-gray-200 my-2" />
          <div className="info-comment">
            <span
              className="text-xl text-gray-500"
              dangerouslySetInnerHTML={{ __html: feed.activity_object.content }}
            ></span>
          </div>

          {attachments.length > 0 && (
            <div className="attachments mt-3 flex w-[80%] flex-wrap gap-4">
              {attachments.map((attachment, index) => (
                <div
                  key={index}
                  className="cursor-pointer attachment flex flex-col items-center justify-center"
                >
                  <AttachmentBox
                    isImage={attachment.mime.startsWith("image")}
                    attachment={attachment}
                  />
                </div>
              ))}
            </div>
          )}

          <div className="relative my-3">
            <Input ref={inputRef} onSubmit={handleSubmit} />

            <div className="icons absolute flex right-0 md:right-24 -bottom-7">
              <span className="px-2 cursor-pointer">
                <MdOutlineInsertComment color="orange" size={22} />
              </span>
              <span className="px-2 flex cursor-pointer" onClick={handleLike}>
                <BiSolidLike color={"orange"} size={22} />
                <span className="text-slate-500">{likeCount}</span>
              </span>
            </div>
          </div>

          {feed.comment.length !== 0 && (
            <>
              <h5 className="pt-4">Comments</h5>
              <hr className="w-full border-gray-200 my-2" />
            </>
          )}
          {comments.map((comment, idx) => (
            <div key={idx} className="">
              <div className="flex p-3 flex-row justify-between">
                <div className="w-16 h-16 mr-4">
                  <img
                    className="w-full h-full object-contain"
                    src={comment.profile_image}
                    alt="Profile"
                  />
                </div>

                <div className="info flex-1">
                  <h6 className="py-0 my-0">
                    {comment.name ?? comment.user_id}
                  </h6>
                  <span className="text-xs text-gray-400">
                    {comment.created_ts}
                  </span>
                  <div className="info-comment">
                    <h6 className="py-2 text-gray-500">{comment.content}</h6>
                  </div>
                </div>
                {comment.user_id === userState.userId && (
                  <div>
                    <i
                      className="fa-solid fa-trash cursor-pointer"
                      style={{ color: "#717986" }}
                      onClick={() => {
                        handleDeleteComment(
                          comment.activity_id,
                          comment.user_id
                        );
                      }}
                    ></i>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        {feed?.activity_user_info?.user_id == userState.userId && (
          <div>
            <i
              className="fa-regular fa-trash-can cursor-pointer"
              style={{ color: "#717986" }}
              onClick={handleDeletePost}
            ></i>
          </div>
        )}
      </div>
    )
  );
};

export default Comment;
