import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom";
import Spinner from "../../UserInterface/Spinner/Spinner";

const PDFViewer = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();

  const { pdfUrl } = location?.state || "";

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setLoading(false);
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const goToPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const getPDFWidth = () => {
    if (width > 1024) return width - 200; // Full width on desktops
    if (width > 768) return 600; // Medium devices
    if (width > 425) return width - 100; // Tablets
    return width - 40; // Small screens
  };

  useEffect(() => {
    // Update width on window resize
    const updateWidth = () => setWidth(window.innerWidth);
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <div className="mt-[40px] md:mt-[70px] md:pb-12 w-full h-full lg:p-10 md:p-6 p-4 flex flex-col items-center relative">
      {!loading && width > 450 && (
        <div className="flex justify-between gap-6 w-full -mb-11 relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div
            onClick={goToPrevPage}
            disabled={pageNumber === 1}
            className={`bg-[#00122E] text-white font-semibold px-3 py-3 rounded-full shadow-md transition-all duration-300 ease-in-out 
               hover:bg-[#5c83c1] hover:scale-105 active:scale-95 disabled:bg-gray-400 disabled:cursor-not-allowed ${
                 pageNumber === 1 ? "cursor-not-allowed" : "cursor-pointer"
               } `}
          >
            <ArrowBackIcon />
          </div>
          <div
            onClick={goToNextPage}
            disabled={pageNumber === numPages}
            className={`bg-[#F36421] text-white font-semibold px-3 py-3 rounded-full shadow-md transition-all duration-300 ease-in-out 
               hover:bg-[#ff8147] hover:scale-105 active:scale-95 disabled:bg-gray-400 disabled:cursor-not-allowed ${
                 pageNumber === numPages
                   ? "cursor-not-allowed"
                   : "cursor-pointer"
               } `}
          >
            <ArrowForwardIcon />
          </div>
        </div>
      )}

      {!loading && width > 450 && (
        <p>
          Page {pageNumber} of {numPages}
        </p>
      )}

      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} width={getPDFWidth()} />
      </Document>

      {!loading && (
        <p>
          Page {pageNumber} of {numPages}
        </p>
      )}

      {/* Navigation Buttons */}
      {!loading && (
        <div className="flex justify-between gap-6 w-full relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div
            onClick={goToPrevPage}
            disabled={pageNumber === 1}
            className={`bg-[#00122E] text-white font-semibold px-3 py-3 rounded-full shadow-md transition-all duration-300 ease-in-out 
               hover:bg-[#5c83c1] hover:scale-105 active:scale-95 disabled:bg-gray-400 disabled:cursor-not-allowed ${
                 pageNumber === 1 ? "cursor-not-allowed" : "cursor-pointer"
               } `}
          >
            <ArrowBackIcon />
          </div>
          <div
            onClick={goToNextPage}
            disabled={pageNumber === numPages}
            className={`bg-[#F36421] text-white font-semibold px-3 py-3 rounded-full shadow-md transition-all duration-300 ease-in-out 
               hover:bg-[#ff8147] hover:scale-105 active:scale-95 disabled:bg-gray-400 disabled:cursor-not-allowed ${
                 pageNumber === numPages
                   ? "cursor-not-allowed"
                   : "cursor-pointer"
               } `}
          >
            <ArrowForwardIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
