import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import classes from "./video.module.css";
import { getVideoLibrary } from "../../../Store/API/API";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { useNavigate } from "react-router-dom";

const SectionCard = ({ data, isCourse }) => {
  const navigate = useNavigate();
  return (
    <div
      className={classes.sectionCard}
      onClick={() => {
        if (isCourse) {
          navigate("/course-details", {
            state: { course_id: data.course_id },
          });
        }
      }}
      style={{ cursor: isCourse ? "pointer" : "default" }}
    >
      <img
        alt="thumbnail"
        src={data?.cover_photo_url || "/images/sessionLogo.png"}
        className={classes.cardImage}
      />
      <div className={classes.contentContainer}>
        <p className={classes.cardHeading}>{data?.name}</p>
        <p>{data?.subject}</p>
        {/* <p>{data?.course_format}</p> */}
        <p className={classes.cardSubHeading}>By: Innovartan</p>
      </div>
    </div>
  );
};

const VideoLibrary = () => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const [dashboardCourses, setDashboardCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await getVideoLibrary(userState, dispatch);
      if (response && response.status) {
        setDashboardCourses(response.data);
      }
      setIsLoading(false);
    };

    fetchCourses();
  }, [userState, dispatch]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={classes.courseDashboard}>
      <div className={classes.section}>
        <div className={classes.sectionOuter}>
          <div>
            <h2 className="text-[20px] md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl font-bold m-3 mb-0">
              Video Library
            </h2>
          </div>
        </div>
        {dashboardCourses.length > 0 ? (
          <div className=" grid grid-cols-1  px-2 md:p-2 w-full md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxl:grid-cols-5 gap-0 md:gap-2 place-items-center">
            {/* <div className={classes.sectionCardContainer}>  */}
            {dashboardCourses.map((course, index) => (
              <SectionCard
                key={index}
                data={course}
                isCourse={true}
                className="flex justify-center items-center"
              />
            ))}
          </div>
        ) : (
          <p>No courses available.</p>
        )}
      </div>
    </div>
  );
};

export default VideoLibrary;
