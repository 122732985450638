// import React, { useEffect, useState } from "react";
// import styles from "./ViewQuestionBank.module.css";
// import {
//   deleteQuestionBankQuestion,
//   getAttendanceMonth,
//   getQuestionBankDetails,
// } from "../../../../Store/API/API";
// import { useDispatch, useSelector } from "react-redux";
// import SelectInput from "../../../UI/SelectInput/SelectInput";
// import MCQ from "../../../UI/OuestionTypes/MCQ/MCQ";
// import FIB from "../../../UI/OuestionTypes/FIB/FIB";
// import TF from "../../../UI/OuestionTypes/TF/TF";
// import MCQMA from "../../../UI/OuestionTypes/MCQMA/MCQMA";
// import Integer from "../../../UI/OuestionTypes/Integer/Integer";
// import { useLocation, useNavigate } from "react-router-dom";
// import Spinner from "../../../UserInterface/Spinner/Spinner";
// import { applicationActions } from "../../../../Store/Data/Slices/ApplicationSlice";
// import { tab } from "@testing-library/user-event/dist/cjs/convenience/tab.js";

// const TypeCard = ({ number, heading, selected, setSelected, value }) => {
//   return (
//     <div
//       className={`${styles.typeCardContainer}  cursor-pointer`}
//       style={{ backgroundColor: value === selected ? "#ffc107" : "" }}
//       onClick={() => setSelected(value)}
//     >
//       <div className={styles.numberCard}>
//         {number < 10 ? `0${number}` : number || "00"}
//       </div>
//       <p className={styles.typeCardHeading}>{heading}</p>
//     </div>
//   );
// };

// const ViewQuestionBank = () => {
//   const userState = useSelector((state) => state.userData.userData);
//   const batchState = useSelector((state) => state.applicationData.batchData);
//   const dispatch = useDispatch();

//   const location = useLocation();
//   const { questionBankId } = location?.state || "";

//   const navigate = useNavigate();

//   const [questionData, setQuestionData] = useState([]);
//   const [questionBankData, setQuestionBankData] = useState([]);
//   const [tabData, setTabData] = useState([]);
//   const [activeQuestionType, setActiveQuestionType] = useState("fib");
//   const [isLoading, setIsLoading] = useState(true);

//   const fetchQuestionBankDetails = async () => {
//     setIsLoading(true);
//     const data = await getQuestionBankDetails(
//       questionBankId,
//       userState.token,
//       dispatch
//     );
//     console.log(data?.data);
//     if (data) {
//       setQuestionBankData(data?.data?.question_bank_data);
//       setTabData(data?.data?.tab_data);
//       setQuestionData(data?.data?.question_data);
//     }
//     setIsLoading(false);
//   };

//   const handleButtonClick = async (type, questionId) => {
//     setIsLoading(true);
//     if (type === "edit") {
//       return;
//     } else if (type === "delete") {
//       console.log("Delete", questionId);

//       const data = await deleteQuestionBankQuestion(
//         userState.token,
//         questionId,
//         questionBankId,
//         dispatch
//       );

//       if (data?.message === "Question deleted successfully") {
//         const updatedQuestionData = questionData[activeQuestionType].filter(
//           (item) => item.question_id !== questionId
//         );
//         setQuestionData({
//           ...questionData,
//           [activeQuestionType]: updatedQuestionData,
//         });
//         const updatedTabData = tabData[`${activeQuestionType}_count`] - 1;
//         setTabData({
//           ...tabData,
//           [`${activeQuestionType}_count`]: updatedTabData,
//         });
//       }
//     }
//     setIsLoading(false);
//   };

//   const renderQuestion = () => {
//     if (
//       !questionData[activeQuestionType] ||
//       questionData[activeQuestionType].length === 0
//     ) {
//       return (
//         <div className="text-center text-primaryOrange font-semibold">
//           No Questions Available
//         </div>
//       );
//     }

//     switch (activeQuestionType) {
//       case "fib":
//         return questionData?.[activeQuestionType]?.map((question, index) => (
//           <FIB
//             key={index}
//             correctOption={question.solution_array}
//             fibCount={question.fib_count}
//             question={question.question}
//             questionOrder={question.question_order}
//             questionId={question?.question_id}
//             questionMarks={question?.marks}
//             location={"QL"}
//             questionData={question}
//             handleButtonClick={handleButtonClick}
//           />
//         ));

//       case "tf":
//         return questionData?.[activeQuestionType]?.map((question, index) => (
//           <TF
//             key={index}
//             correctOption={question.answer}
//             answer={question.currect_answer}
//             question={question.question}
//             questionOrder={question.question_order}
//             questionId={question?.question_id}
//             questionMarks={question?.marks}
//             location={"QL"}
//             questionData={question}
//             handleButtonClick={handleButtonClick}
//           />
//         ));

//       case "mcq":
//         return questionData?.[activeQuestionType]?.map((question, index) => (
//           <MCQ
//             key={index}
//             correctOption={question?.answer}
//             answer={question?.currect_answer}
//             question={question?.question}
//             questionOrder={question?.question_order}
//             questionId={question?.question_id}
//             questionMarks={question?.marks}
//             location={"QL"}
//             questionData={question}
//             handleButtonClick={handleButtonClick}
//           />
//         ));

//       case "mcqma":
//         return questionData?.[activeQuestionType]?.map((question, index) => (
//           <MCQMA
//             key={index}
//             correctOption={question.answer}
//             answer={question.solution_array}
//             question={question.question}
//             questionOrder={question.question_order}
//             questionId={question?.question_id}
//             questionMarks={question?.marks}
//             location={"QL"}
//             questionData={question}
//             handleButtonClick={handleButtonClick}
//           />
//         ));

//       case "intt":
//         return questionData?.[activeQuestionType]?.map((question, index) => (
//           <Integer
//             key={index}
//             correctOption={question.currect_answer}
//             question={question.question}
//             questionOrder={question.question_order}
//             questionId={question?.question_id}
//             questionMarks={question?.marks}
//             location={"QL"}
//             questionData={question}
//             handleButtonClick={handleButtonClick}
//           />
//         ));

//       default:
//         return <div>No Available</div>;
//     }
//   };

//   useEffect(() => {
//     if (!questionBankId) {
//       dispatch(
//         applicationActions.setAlertData({
//           isAlert: true,
//           alertMessage: "Question Bank Id not found",
//           alertType: "error",
//         })
//       );
//       navigate("/");
//       return;
//     }

//     if (questionBankId) {
//       fetchQuestionBankDetails();
//     }

//     // eslint-disable-next-line
//   }, [questionBankId]);

//   return (
//     <div className={styles.container}>
//       <div className={styles.headerWrapper}>
//         <div className={styles.headerContainer}>
//           <p className={styles.heading}>{`${questionBankData?.title || ""} | ${
//             questionBankData?.curriculum || ""
//           }`}</p>
//         </div>
//         <div className={styles.questionTypeContainer}>
//           <TypeCard
//             number={tabData?.fib_count}
//             heading={"Fill in the Blanks"}
//             value={"fib"}
//             selected={activeQuestionType}
//             setSelected={setActiveQuestionType}
//           />
//           <TypeCard
//             number={tabData?.tf_count}
//             heading={"True/False"}
//             value={"tf"}
//             selected={activeQuestionType}
//             setSelected={setActiveQuestionType}
//           />
//           <TypeCard
//             number={tabData?.mcq_count}
//             heading={"MC - Single Ans"}
//             value={"mcq"}
//             selected={activeQuestionType}
//             setSelected={setActiveQuestionType}
//           />
//           <TypeCard
//             number={tabData?.mcqma_count}
//             heading={"MC - Multiple Ans"}
//             value={"mcqma"}
//             selected={activeQuestionType}
//             setSelected={setActiveQuestionType}
//           />
//           <TypeCard
//             number={tabData?.intt_count}
//             heading={"Integer Type"}
//             value={"intt"}
//             selected={activeQuestionType}
//             setSelected={setActiveQuestionType}
//           />
//         </div>
//       </div>
//       {isLoading === true ? (
//         <Spinner spinnerHeight={"50%"} />
//       ) : (
//         <div className={styles.bodyWrapper}>{renderQuestion()}</div>
//       )}
//     </div>
//   );
// };

// export default ViewQuestionBank;


import React, { useEffect, useState } from "react";
import styles from "./ViewQuestionBank.module.css";
import {
  deleteQuestionBankQuestion,
  getAttendanceMonth,
  getQuestionBankDetails,
} from "../../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../../UI/SelectInput/SelectInput";
import MCQ from "../../../UI/OuestionTypes/MCQ/MCQ";
import FIB from "../../../UI/OuestionTypes/FIB/FIB";
import TF from "../../../UI/OuestionTypes/TF/TF";
import MCQMA from "../../../UI/OuestionTypes/MCQMA/MCQMA";
import Integer from "../../../UI/OuestionTypes/Integer/Integer";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../../UserInterface/Spinner/Spinner";
import { applicationActions } from "../../../../Store/Data/Slices/ApplicationSlice";
import { tab } from "@testing-library/user-event/dist/cjs/convenience/tab.js";

const TypeCard = ({ number, heading, selected, setSelected, value }) => {
  return (
    <div
      className={`${styles.typeCardContainer}  cursor-pointer`}
      style={{ backgroundColor: value === selected ? "#ffc107" : "" }}
      onClick={() => setSelected(value)}
    >
      <div className={styles.numberCard}>
        {number < 10 ? `0${number}` : number || "00"}
      </div>
      <p className={styles.typeCardHeading}>{heading}</p>
    </div>
  );
};

const ViewQuestionBank = () => {
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const dispatch = useDispatch();

  const location = useLocation();
  const { questionBankId } = location?.state || "";
  
  // console.log("location",questionBankId)

  const navigate = useNavigate();

  const [questionData, setQuestionData] = useState([]);
  const [questionBankData, setQuestionBankData] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [activeQuestionType, setActiveQuestionType] = useState("fib");
  const [isLoading, setIsLoading] = useState(true);

  const fetchQuestionBankDetails = async () => {
    setIsLoading(true);
    const data = await getQuestionBankDetails(
      questionBankId,
      userState.token,
      dispatch
    );
    // console.log(data?.data);
    if (data) {
      setQuestionBankData(data?.data?.question_bank_data);
      setTabData(data?.data?.tab_data);
      setQuestionData(data?.data?.question_data);
    }
    setIsLoading(false);
  };

  const handleButtonClick = async (type, questionId) => {
    setIsLoading(true);
    if (type === "edit") {
      return;
    } else if (type === "delete") {
      console.log("Delete", questionId);

      const data = await deleteQuestionBankQuestion(
        userState.token,
        questionId,
        questionBankId,
        dispatch
      );

      if (data?.message === "Question deleted successfully") {
        const updatedQuestionData = questionData[activeQuestionType].filter(
          (item) => item.question_id !== questionId
        );
        setQuestionData({
          ...questionData,
          [activeQuestionType]: updatedQuestionData,
        });
        const updatedTabData = tabData[`${activeQuestionType}_count`] - 1;
        setTabData({
          ...tabData,
          [`${activeQuestionType}_count`]: updatedTabData,
        });
      }
    }
    setIsLoading(false);
  };

  const renderQuestion = () => {
    if (
      !questionData[activeQuestionType] ||
      questionData[activeQuestionType].length === 0
    ) {
      return (
        <div className="text-center text-primaryOrange font-semibold">
          No Questions Available
        </div>
      );
    }

    switch (activeQuestionType) {
      case "fib":
        return questionData?.[activeQuestionType]?.map((question, index) => (
          <FIB
            key={index}
            correctOption={question.solution_array}
            fibCount={question.fib_count}
            question={question.question}
            questionOrder={question.question_order}
            questionId={question?.question_id}
            questionBankId={questionBankId}
            questionMarks={question?.marks}
            location={"QL"}
            questionData={question}
            handleButtonClick={handleButtonClick}
          />
        ));

      case "tf":
        return questionData?.[activeQuestionType]?.map((question, index) => (
          <TF
            key={index}
            correctOption={question.answer}
            answer={question.currect_answer}
            question={question.question}
            questionOrder={question.question_order}
            questionId={question?.question_id}
            questionBankId={questionBankId}
            questionMarks={question?.marks}
            location={"QL"}
            questionData={question}
            handleButtonClick={handleButtonClick}
          />
        ));

      case "mcq":
        return questionData?.[activeQuestionType]?.map((question, index) => (
          <MCQ
            key={index}
            correctOption={question?.answer}
            answer={question?.currect_answer}
            question={question?.question}
            questionOrder={question?.question_order}
            questionId={question?.question_id}
            questionBankId={questionBankId}
            questionMarks={question?.marks}
            location={"QL"}
            questionData={question}
            handleButtonClick={handleButtonClick}
          />
        ));

      case "mcqma":
        return questionData?.[activeQuestionType]?.map((question, index) => (
          <MCQMA
            key={index}
            correctOption={question.answer}
            answer={question.solution_array}
            question={question.question}
            questionOrder={question.question_order}
            questionId={question?.question_id}
            questionBankId={questionBankId}
            questionMarks={question?.marks}
            location={"QL"}
            questionData={question}
            handleButtonClick={handleButtonClick}
          />
        ));

      case "intt":
        return questionData?.[activeQuestionType]?.map((question, index) => (
          <Integer
            key={index}
            correctOption={question.currect_answer}
            question={question.question}
            questionOrder={question.question_order}
            questionId={question?.question_id}
            questionMarks={question?.marks}
            questionBankId={questionBankId}
            location={"QL"}
            questionData={question}
            handleButtonClick={handleButtonClick}
          />
        ));

      default:
        return <div>No Available</div>;
    }
  };

  useEffect(() => {
    if (!questionBankId) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Question Bank Id not found",
          alertType: "error",
        })
      );
      navigate("/");
      return;
    }

    if (questionBankId) {
      fetchQuestionBankDetails();
    }

    // eslint-disable-next-line
  }, [questionBankId]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerContainer}>
          <p className={styles.heading}>{`${questionBankData?.title || ""} | ${
            questionBankData?.curriculum || ""
          }`}</p>
        </div>
        <div className={styles.questionTypeContainer}>
          <TypeCard
            number={tabData?.fib_count}
            heading={"Fill in the Blanks"}
            value={"fib"}
            selected={activeQuestionType}
            setSelected={setActiveQuestionType}
          />
          <TypeCard
            number={tabData?.tf_count}
            heading={"True/False"}
            value={"tf"}
            selected={activeQuestionType}
            setSelected={setActiveQuestionType}
          />
          <TypeCard
            number={tabData?.mcq_count}
            heading={"MC - Single Ans"}
            value={"mcq"}
            selected={activeQuestionType}
            setSelected={setActiveQuestionType}
          />
          <TypeCard
            number={tabData?.mcqma_count}
            heading={"MC - Multiple Ans"}
            value={"mcqma"}
            selected={activeQuestionType}
            setSelected={setActiveQuestionType}
          />
          <TypeCard
            number={tabData?.intt_count}
            heading={"Integer Type"}
            value={"intt"}
            selected={activeQuestionType}
            setSelected={setActiveQuestionType}
          />
        </div>
      </div>
      {isLoading === true ? (
        <Spinner spinnerHeight={"50%"} />
      ) : (
        <div className={styles.bodyWrapper}>{renderQuestion()}</div>
      )}
    </div>
  );
};

export default ViewQuestionBank;
