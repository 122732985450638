import React, { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCourseContent } from "../../../Store/API/API";
import Spinner from "../../UserInterface/Spinner/Spinner";
import TopicAddModalNew from "./TopicAddModalNew";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../CustomModal/CustomModal";

const DPPSection = ({ courseName, activeSection }) => {
  const userState = useSelector((state) => state.userData.userData);
  const [openChapter, setOpenChapter] = useState(0);
  const [courseContent, setCourseContent] = useState([]);
  const [courseId, setCourseId] = useState("");
  // console.log(courseId,"courseId")
  const [isLoading, setIsLoading] = useState(true);
  const [openAddTopicModal, setOpenAddTopicModal] = useState(false);
  const [chapterId, setChapterId] = useState(null);
  const [contentId, setContentId] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchContent = async () => {
    const data = await getCourseContent(
      courseName,
      userState.userId,
      "",
      dispatch
    );
    if (data) {
      // console.log("courseContent", data?.chapter_content);
      // console.log("courseContent", data);
      setCourseId(data?.description?.course_id);

      // processData(data?.chapter_content);
      setCourseContent(data?.chapter_content);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!activeSection === "Syllabus") {
      return;
    }

    fetchContent();

    //eslint-disable-next-line
  }, [userState, activeSection, courseName, courseId]);

  if (isLoading) {
    return <Spinner spinnerHeight={"20%"} />;
  }

  return (
    <div className="w-full lg:w-[70%] md:mb-10 mb-3 md:mt-10 mt-5">
      <h2 className="text-xl font-semibold mb-4">DPP</h2>

      {courseContent.length === 0 && (
        <div className="text-center text-orange-500">No videos available</div>
      )}

      {courseContent.map((chapter, index) => (
        <div key={index} className="mb-4">
          {/* Chapter Header */}
          <div
            className={`flex p-3 rounded-lg border cursor-pointer flex-col`}
            onClick={() => {
              setOpenChapter(openChapter === index ? "" : index);
            }}
          >
            <div className="flex justify-between w-full">
              <div className="flex md:items-center items-start space-x-2 flex-col md:flex-row">
                <div className="flex gap-2">
                  <span className="px-3 py-1 text-sm font-medium bg-[#E7EDF6] text-gray-600 rounded-lg">
                    Chapter {index + 1}
                  </span>
                  <span className="px-2 py-0 border flex items-center rounded-lg text-black text-sm font-light md:hidden">
                    {chapter?.content?.length} DPP
                  </span>
                </div>
                <span className="font-semibold">{chapter?.chapterTitle}</span>
                <span className="px-2 py-0 border rounded-lg text-black text-sm font-light md:block hidden">
                  {chapter?.content?.length} DPP
                </span>
              </div>
              {openChapter === index ? (
                <ExpandLessIcon className="text-3xl" />
              ) : (
                <ExpandMoreIcon className="text-3xl" />
              )}
            </div>
          </div>

          {/* Videos List */}
          {openChapter === index && chapter?.content.length > 0 && (
            <div className="mt-3 space-y-3">
              {chapter?.content.map((test, index) => (
                <div className="rounded-lg overflow-hidden" key={index}>
                  <div
                    className={`flex items-center justify-between p-3 border  
                    border-gray-200 flex-col md:flex-row
                  `}
                  >
                    <div className="flex md:flex-row flex-col items-center mb-3 md:mb-0">
                      {/* Thumbnail */}
                      <img
                        src={
                          "https://innovartan.s3.amazonaws.com/eed41ce53599ed8b76ecc245af61d7da1596812087/e1c924ea8405e75a8f95d40adaccae38.png"
                        }
                        alt="Thumbnail"
                        className="md:w-30 w-30 md:h-16 h-26 rounded-lg object-cover"
                      />
                      <div className="flex-1 md:ml-4 ml-0 md:mt-0 mt-2">
                        <h3 className="font-semibold text-base">
                          {test.title}
                        </h3>
                        {test?.topics?.length > 0 && (
                          <div className="flex flex-row gap-2 flex-wrap">
                            <p className="font-semibold text-xs text-gray-600 mt-1">
                              Topics Covered:
                            </p>
                            {test?.topics?.map((topic, index) => (
                              <p
                                className="font-thin bg-gray-200 px-2 py-1 text-xs rounded-md"
                                key={index}
                              >
                                {topic?.label}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    {userState.userType !== "institute" &&
                      test?.lastAttemptStatus === true && (
                        <button
                          className={`hover:bg-[#0743a3] hover:text-white text-[#0743a3] text-sm px-4 py-2 rounded-lg flex items-center border border-[#0743a3] cursor-pointer  `}
                          onClick={() => {
                            navigate("/ViewTestSubmission", {
                              state: {
                                quizId: test?.test_id || test?.value,
                              },
                            });
                          }}
                        >
                          View Result
                        </button>
                      )}
                    {userState.userType !== "institute" &&
                      test?.lastAttemptStatus !== true && (
                        <button
                          className={` hover:bg-orange-500 hover:text-white text-sm px-4 py-2 rounded-lg flex items-center border border-orange-500 text-orange-500 ${
                            test?.open_dpp === false
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }   `}
                          disabled={test?.open_dpp === false ? true : false}
                          onClick={() => {
                            navigate("/startTest", {
                              state: {
                                quizId: test?.test_id || test?.value,
                              },
                            });
                          }}
                        >
                          Attempt
                        </button>
                      )}
                   <div className="flex gap-2 items-center justify-center">
                   {(userState?.userId == 4 || userState?.instituteId == 4) && userState?.userType !== "student" && (
                           <div
                           className="px-2 py-1 border border-primaryBlue rounded-lg text-xs flex items-center gap-1 text-primaryBlue ml-10 hover:bg-primaryBlue hover:text-textWhite w-20"
                           onClick={() => {
                             setOpenAddTopicModal(true);
                             setContentId(test.content_id);
                             setChapterId(chapter?.dataChapterId);
                           }}
                         >
                           <AddIcon style={{ fontSize: "16px" }} />
                           Topic
                         </div>
                        )}
                   {userState.userType === "institute" && (
                        <button
                          className={`hover:bg-[#0743a3] hover:text-white text-[#0743a3] text-xs px-2 py-1 rounded-lg flex items-center border border-[#0743a3] cursor-pointer`}
                          onClick={() => {
                            navigate("/dpp-submission-view", {
                              state: {
                                quiz_id: test?.test_id,
                                // course_id: courseId,
                              },
                            });
                          }}
                        >
                          Submissions
                        </button>                    
                    )}
                   </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <CustomModal
        isOpen={openAddTopicModal}
        onClose={() => setOpenAddTopicModal(!openAddTopicModal)}
      >
        <TopicAddModalNew
          courseId={courseId}
          chapterId={chapterId}
          contentId={contentId}
          type={"tests"}
          onClose={() => setOpenAddTopicModal(!openAddTopicModal)}
          fetchContent={fetchContent}
          // handleUpdateContent={updateContent}
        />
      </CustomModal>
    </div>
  );
};

export default DPPSection;
