
import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export const Footer2 = ({ isSidebarOpen }) => {
  return (
    <footer className="hidden md:flex bg-backgroundDarkblue px-4 h-10 w-full item-center fixed bottom-0">
      <div
        className={`${
          isSidebarOpen ? "ml-64" : "md:ml-24"
        } flex  w-full justify-between items-center `}
      >
        <p className="text-base text-center text-textLightgrey">
          Copyright © 2025 Innovartan | All Rights Reserved
        </p>
        <div className="flex justify-center items-center mt-3">
          <ul className="flex gap-2">
            {[
              {
                icon: FaFacebookF,
                link: "https://www.facebook.com/innovartan/",
              },
              { icon: FaXTwitter, link: "https://x.com/innovartan" },
              {
                icon: FaLinkedinIn,
                link: "https://www.linkedin.com/company/innovartan",
              },
              {
                icon: FaInstagram,
                link: "https://www.instagram.com/innovartan",
              },
              {
                icon: FaYoutube,
                link: "https://www.youtube.com/@Innovartanlearning",
              },
            ].map(({ icon: Icon, link }, idx) => (
              <li
                key={idx}
                className="bg-secondaryBlue rounded-lg p-2   text-center flex justify-center items-center"
              >
                <button
                  onClick={() =>
                    window.open(link, "_blank", "noopener,noreferrer")
                  }
                  className="text-textWhite text-lg h-4 w-4"
                >
                  <Icon />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};