import React, { useEffect, useState } from "react";
import {
  FaBook,
  FaUser,
  FaRegAddressBook,
} from "react-icons/fa";
import {
  IoIosArrowForward,
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdContacts,
  IoIosArrowBack,
} from "react-icons/io";
import { RxDashboard } from "react-icons/rx";
import {
  MdAssignment,
  MdFeedback,
  MdEvent,
  MdLibraryBooks,
  MdOutlinePostAdd,
  MdOutlineBatchPrediction,
  MdOndemandVideo,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { userLogout } from "../../../Store/API/API";
// import { userDataActions } from "../../../Store/Data/Slices/UserDataSlice";
// import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import { PiGraduationCapFill } from "react-icons/pi";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import { HiMiniBuildingLibrary } from "react-icons/hi2";
import { VscFileSubmodule } from "react-icons/vsc";
import { RiSoundModuleFill } from "@remixicon/react";
import { TbReport } from "react-icons/tb";

const Sidebar2 = ({ isSidebarOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const [isBatchesOpen, setIsBatchesOpen] = useState(false);
  const [isAcademicOpen, setIsAcademicOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("");

  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const schoolState = useSelector((state) => state.applicationData.schoolData);
  const alertState = useSelector((state) => state.applicationData.alertData);
  const dispatch = useDispatch();

  // const handleLogut = async () => {
  //   const data = await userLogout(userState.token, dispatch);
  //   if (data?.status) {
  //     localStorage.removeItem("userData");
  //     localStorage.removeItem("batchData");
  //     localStorage.removeItem("schoolData");
  //     dispatch(userDataActions.setUserData({}));
  //     dispatch(applicationActions.setBatchData({}));
  //     dispatch(applicationActions.setSchoolData({}));
  //     navigate("/");
  //   }
  // };

  useEffect(() => {
    console.log("User State", userState);
    console.log("Batch State", batchState);
    console.log("School State", schoolState);
  }, [userState, batchState, schoolState]);

  const changeFavicon = (iconUrl, title) => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = iconUrl;
    document.getElementsByTagName("head")[0].appendChild(link);

    if (title) {
      document.title = title;
    }
  };

  React.useEffect(() => {
    if (schoolState?.schoolCode === "dpsg")
      changeFavicon("dpsg.png", "GSAT-2025");
    else {
      changeFavicon(schoolState?.favicon, schoolState?.schoolName);
    }
  }, [schoolState?.schoolCode]);

  // React.useEffect(() => {
  //   setFooterHeight(document.getElementById("footer").offsetHeight);
  // }, []);

  const location = useLocation();
  //  const navigate = useNavigate();
  // const networkStatus = useNetwork();

  React.useEffect(() => {
    if (alertState?.isAlert) {
      switch (alertState.alertType) {
        case "success":
          toast.success(alertState.alertMessage, {
            className: "bg-black text-white",
          });
          break;
        case "error":
          toast.error(alertState.alertMessage, {
            className: "bg-black text-white whitespace-normal break-words",
          });
          break;
        case "info":
          toast.info(alertState.alertMessage, {
            className: "bg-black text-white",
          });
          break;
        case "warn":
          toast.warn(alertState.alertMessage, {
            className: "bg-black text-white",
          });
          break;

        default:
          break;
      }
    }
  }, [alertState]);

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good Morning!";
    if (hour < 18) return "Good Afternoon!";
    return "Good Evening!";
  };

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "#f36421",
            color: "white",
            fontSize: "12px",
            padding: "8px 10px",
          },
          arrow: {
            color: "#f36421",
          },
        },
      },
    },
  });
  return (
    <div
      className={`flex-shrink-0 ${isSidebarOpen ? "w-80 md:w-72" : " w-36 md:w-28 "
        } whitespace-nowrap fixed h-[100vh] top-0 left-0 overflow-y-auto ${isSidebarOpen ? "block" : "hidden md:block"
        }`}
      style={{ zIndex: "9999999" }}
    >
      <ToastContainer />
      <div
        className={`bg-backgroundDarkblue min-h-screen ${isSidebarOpen ? " w-72 md:w-64 " : " w-32 md:w-24"
          } transition-all duration-300 p-2 text-textWhite relative flex flex-col items-center`}
      >
        <button
          className={`absolute top-20 right-[-15px] bg-primaryOrange p-3 rounded-full text-textWhite 
                 transition-transform duration-500 ease-out `}
          style={{ zIndex: "99999" }}
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? <IoIosArrowBack /> : <IoIosArrowForward />}
        </button>

        <div className="flex items-center mt-0 mb-4 pt-4 gap-2">
          <div className="flex flex-col">
            <span
              className={`text-sm font-normal text-textWhite ${!isSidebarOpen && "hidden"
                }`}
            >
              {getGreeting()}
            </span>
            <span
              className={`text-base font-medium text-textWhite ${!isSidebarOpen && "hidden"
                }`}
            >
              {`${userState.firstName} ${userState.lastName}`}
            </span>
          </div>
          <img
            src="/images/profile-img.png"
            alt="profile"
            className="w-14 h-14"
          />
        </div>

        <div className="mt-6 space-y-4 w-full overflow-y-auto max-h-[75vh] scrollbar-hide">
          <ThemeProvider theme={theme}>
            {userState?.leftBar?.Dashboard && (
              <Tooltip title={"Dashboard"} placement="right" arrow>
                <div
                  className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                    } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Dashboard"
                      ? "bg-primaryOrange"
                      : "hover:primaryOrangeDisabled"
                    }`}
                  onClick={() => {
                    setActiveItem("Dashboard");
                    navigate("/");
                  }}
                >
                  <RxDashboard className="w-6 h-6" />
                  {isSidebarOpen && (
                    <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                      Dashboard
                    </span>
                  )}
                </div>
              </Tooltip>
            )}
            <hr className="bg-backgroundBlue" />

            <Tooltip title={"Academic"} placement="right" arrow>
              <div
                onClick={() => {
                  setIsAcademicOpen(!isAcademicOpen);
                  setIsBatchesOpen(false);
                }}
              >
                <div
                  className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                    } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Academic"
                      ? "bg-primaryOrange"
                      : "hover:primaryOrangeDisabled"
                    }`}
                  onClick={() => setActiveItem("Academic")}
                >
                  <PiGraduationCapFill className="w-8 h-8" />
                  {isSidebarOpen && (
                    <div className="flex items-center justify-between w-full">
                      <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                        Academic
                      </span>
                      {isAcademicOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </div>
                  )}
                </div>
              </div>
            </Tooltip>
            {isAcademicOpen && (
              <div className="pl-6 space-y-2">
                {userState?.leftBar?.Batches && (
                  <Tooltip title={"Batches"} placement="right" arrow>
                    <div
                      className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                        } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Batches"
                          ? "bg-primaryOrange"
                          : "hover:primaryOrangeDisabled"
                        }`}
                      onClick={() => {
                        setActiveItem("Batches");
                        navigate("/batch");
                      }}
                    >
                      <MdLibraryBooks className="w-6 h-6" />
                      {isSidebarOpen && (
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                          Batches
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}

                {/* {userState?.leftBar?.Courses && (
                <Tooltip title={"Courses"} placement="right" arrow>
                  <div
                    className={`flex items-center ${
                      isSidebarOpen ? "space-x-3" : "justify-center"
                    } p-2 rounded-lg cursor-pointer transition-colors w-full ${
                      activeItem === "Courses"
                        ? "bg-primaryOrange"
                        : "hover:primaryOrangeDisabled"
                    }`}
                    onClick={() => {
                      setActiveItem("Courses");
                      navigate("/courses");
                    }}
                  >
                    <PiBookOpenText className="w-8 h-8" />
                    {isSidebarOpen && (
                      <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                        Courses
                      </span>
                    )}
                  </div>
                </Tooltip>
              )} */}

                {userState?.leftBar?.Timetable && (
                  <Tooltip title={"Time table"} placement="right" arrow>
                    <div
                      className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                        } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Timetable"
                          ? "bg-primaryOrange"
                          : "hover:primaryOrangeDisabled"
                        }`}
                      onClick={() => {
                        setActiveItem("Timetable");
                        navigate("/time-table");
                      }}
                    >
                      <AppRegistrationIcon className="w-6 h-6" />
                      {isSidebarOpen && (
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                          Time Table
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}

                <Tooltip title={"Module Library"} placement="right" arrow>
                  <div
                    className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                      } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "ModuleLibrary"
                        ? "bg-primaryOrange"
                        : "hover:primaryOrangeDisabled"
                      }`}
                    onClick={() => {
                      setActiveItem("ModuleLibrary");
                      navigate("/module-library");
                    }}
                  >
                    <VscFileSubmodule className="w-6 h-6" />
                    {isSidebarOpen && (
                      <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                        Module Library
                      </span>
                    )}
                  </div>
                </Tooltip>


                <Tooltip title={"DPP Module"} placement="right" arrow>
                  <div
                    className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                      } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "DPPModule"
                        ? "bg-primaryOrange"
                        : "hover:primaryOrangeDisabled"
                      }`}
                    onClick={() => {
                      setActiveItem("DPPModule");
                      navigate("/dpp-module");
                    }}
                  >
                    <RiSoundModuleFill className="w-6 h-6" />
                    {isSidebarOpen && (
                      <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                        DPP Module
                      </span>
                    )}
                  </div>
                </Tooltip>

                <Tooltip title={"Video Library"} placement="right" arrow>
                  <div
                    className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                      } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "VideoLibrary"
                        ? "bg-primaryOrange"
                        : "hover:primaryOrangeDisabled"
                      }`}
                    onClick={() => {
                      setActiveItem("VideoLibrary");
                      navigate("/video-library");
                    }}
                  >
                    <MdOndemandVideo className="w-6 h-6" />
                    {isSidebarOpen && (
                      <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                        Video Library
                      </span>
                    )}
                  </div>
                </Tooltip>



                {userState?.userType === "institute" && (
                  <Tooltip title={"Question Bank"} placement="right" arrow>
                    <div
                      className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                        } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Question Bank"
                          ? "bg-primaryOrange"
                          : "hover:primaryOrangeDisabled"
                        }`}
                      onClick={() => {
                        setActiveItem("Question Bank");
                        navigate("/question-bank");
                      }}
                    >
                      <LocalLibraryIcon className="w-6 h-6" />
                      {isSidebarOpen && (
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                          Question Bank
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}

                {userState?.userType === "institute" && (
                  <Tooltip title={"Question Library"} placement="right" arrow>
                    <div
                      className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                        } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Question Library"
                          ? "bg-primaryOrange"
                          : "hover:primaryOrangeDisabled"
                        }`}
                      onClick={() => {
                        setActiveItem("Question Library");
                        navigate("/question-library");
                      }}
                    >
                      <HiMiniBuildingLibrary className="w-6 h-6" />
                      {isSidebarOpen && (
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                          Question Library
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}

                
{userState?.userType === "institute" && (
                  <Tooltip title={"Time Logs"} placement="right" arrow>
                    <div
                      className={`flex items-center ${
                        isSidebarOpen ? "space-x-3" : "justify-center"
                      } p-2 rounded-lg cursor-pointer transition-colors w-full ${
                        activeItem === "Time Logs"
                          ? "bg-primaryOrange"
                          : "hover:primaryOrangeDisabled"
                      }`}
                      onClick={() => {
                        setActiveItem("");
                        navigate("/time-logs");
                      }}
                    >
                      <TbReport  className="w-6 h-6"/>

                      {isSidebarOpen && (
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                       User Activities
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}

              </div>
            )}
            <hr className="bg-backgroundBlue" />

            <div
              onClick={() => {
                setIsBatchesOpen(!isBatchesOpen);
                setIsAcademicOpen(false);
              }}
            >
              {batchState?.groupId && (
                <Tooltip title={"Batch-Section"} placement="right" arrow>
                  <div
                    className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                      } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "BatchesDropdown"
                        ? "bg-primaryOrange"
                        : "hover:primaryOrangeDisabled"
                      }`}
                    onClick={() => setActiveItem("BatchesDropdown")}
                  >
                    <MdOutlineBatchPrediction className="w-8 h-8" />
                    {isSidebarOpen && (
                      <div className="flex items-center justify-between w-full">
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                          Batch Section
                        </span>
                        {isBatchesOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                      </div>
                    )}
                  </div>
                </Tooltip>
              )}
            </div>
            {isBatchesOpen && (
              <div className="pl-6 space-y-2">
                {userState?.leftBar?.Members &&
                  batchState?.batchUserType !== "student" && (
                    <Tooltip title={"Members"} placement="right" arrow>
                      <div
                        className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                          } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Members"
                            ? "bg-primaryOrange"
                            : "hover:primaryOrangeDisabled"
                          }`}
                        onClick={() => {
                          setActiveItem("Members");
                          navigate("/members");
                        }}
                      >
                        <IoMdContacts className="w-6 h-6" />
                        {isSidebarOpen && (
                          <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                            Members
                          </span>
                        )}
                      </div>
                    </Tooltip>
                  )}
                {userState?.leftBar?.Posts && (
                  <Tooltip title={"Posts"} placement="right" arrow>
                    <div
                      className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                        } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Posts"
                          ? "bg-primaryOrange"
                          : "hover:primaryOrangeDisabled"
                        }`}
                      onClick={() => {
                        setActiveItem("Posts");
                        navigate("/post");
                      }}
                    >
                      <MdOutlinePostAdd className="w-6 h-6" />
                      {isSidebarOpen && (
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                          Posts
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}
                {userState?.leftBar?.Assignments && (
                  <Tooltip title={"Assignments"} placement="right" arrow>
                    <div
                      className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                        } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Assignments"
                          ? "bg-primaryOrange"
                          : "hover:primaryOrangeDisabled"
                        }`}
                      onClick={() => {
                        setActiveItem("Assignments");
                        navigate("/assignment");
                      }}
                    >
                      <MdAssignment className="w-6 h-6" />
                      {isSidebarOpen && (
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                          Assignments
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}

                {userState?.leftBar?.Tests && (
                  <>
                    {["/testList", "/testList?dpp=true"].map((path, index) => {
                      const isDpp = path.includes("dpp=true");
                      const isActive =
                        location.pathname + location.search === path;
                      return (
                        <Tooltip
                         key={index}
                          title={isDpp ? "DPP" : "Tests"}
                          placement="right"
                          arrow
                        >
                          <div
                            key={index}
                            className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                              } p-2 rounded-lg cursor-pointer transition-colors w-full ${isActive
                                ? "bg-primaryOrange"
                                : "hover:primaryOrangeDisabled"
                              }`}
                            onClick={() => {
                              setActiveItem(isDpp ? "DPP" : "Tests");
                              navigate(path);
                            }}
                          >
                            {isDpp ? (
                              <FaBook className="w-6 h-6" />
                            ) : (
                              <MdEvent className="w-6 h-6" />
                            )}
                            {isSidebarOpen && (
                              <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                                {isDpp ? "DPP" : "Tests"}
                              </span>
                            )}
                          </div>
                        </Tooltip>
                      );
                    })}
                  </>
                )}

                {userState?.leftBar?.Sessions && (
                  <Tooltip title={"Sessions"} placement="right" arrow>
                    <div
                      className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                        } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Sessions"
                          ? "bg-primaryOrange"
                          : "hover:primaryOrangeDisabled"
                        }`}
                      onClick={() => {
                        setActiveItem("Sessions");
                        navigate("/upcomming-session");
                      }}
                    >
                      <MdLibraryBooks className="w-6 h-6" />
                      {isSidebarOpen && (
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                          Sessions
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}
                {userState?.leftBar?.Resources && (
                  <Tooltip title={"Resources"} placement="right" arrow>
                    <div
                      className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                        } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Resources"
                          ? "bg-primaryOrange"
                          : "hover:primaryOrangeDisabled"
                        }`}
                      onClick={() => {
                        setActiveItem("Resources");
                        navigate("/resources");
                      }}
                    >
                      <FaUser className="w-6 h-6" />
                      {isSidebarOpen && (
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                          Resources
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}

                {userState?.leftBar?.Feedback && (
                  <Tooltip title={"Feedback"} placement="right" arrow>
                    <div
                      className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                        } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Feedback"
                          ? "bg-primaryOrange"
                          : "hover:primaryOrangeDisabled"
                        }`}
                      onClick={() => {
                        setActiveItem("Feedback");
                        navigate("/feedback");
                      }}
                    >
                      <MdFeedback className="w-6 h-6" />
                      {isSidebarOpen && (
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                          Feedback
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}
                {userState?.leftBar?.Attendance && (
                  <Tooltip title={"Attendance"} placement="right" arrow>
                    <div
                      className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                        } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Attendance"
                          ? "bg-primaryOrange"
                          : "hover:primaryOrangeDisabled"
                        }`}
                      onClick={() => {
                        setActiveItem("Attendance");
                        navigate(
                          batchState?.batchUserType === "student"
                            ? "/student-attendance"
                            : "/teacher-attendance"
                        );
                      }}
                    >
                      <FaRegAddressBook className="w-6 h-6" />
                      {isSidebarOpen && (
                        <span className="text-sm md:text-base lg:text-base xl:text-base font-medium">
                          Attendance
                        </span>
                      )}
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
            {/* <hr className="bg-backgroundBlue" /> */}
            {/* <Tooltip title={" Account Setting"} placement="right" arrow>
            <div
              className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "AccountSetting"
                  ? "bg-primaryOrange"
                  : "hover:primaryOrangeDisabled"
                }`}
              onClick={() => {
                setActiveItem("AccountSetting");
                navigate("/profile");
              }}
            >
              <FaCog className="w-6 h-6" />
              {isSidebarOpen && (
                <span
                  // onClick={() => {
                  //   navigate("/profile");
                  // }}
                  className="text-sm md:text-base lg:text-base xl:text-base font-medium"
                >
                  Account Setting
                </span>
              )}
            </div>
          </Tooltip>
          <hr className="bg-backgroundBlue" />

          <Tooltip title={"Logout"} placement="right" arrow>
            <div
              className={`flex items-center ${isSidebarOpen ? "space-x-3" : "justify-center"
                } p-2 rounded-lg cursor-pointer transition-colors w-full ${activeItem === "Logout"
                  ? "bg-primaryOrange"
                  : "hover:primaryOrangeDisabled"
                }`}
              onClick={() => setActiveItem("Logout")}
            >
              <FaSignOutAlt className="w-6 h-6" />
              {isSidebarOpen && (
                <span
                  onClick={() => {
                    handleLogut();
                  }}
                  className="text-sm md:text-base lg:text-base xl:text-base font-medium"
                >
                  Logout
                </span>
              )}
            </div>
          </Tooltip> */}
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};


//hello
export default Sidebar2;
