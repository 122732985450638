// import React, { useEffect, useState } from "react";
// import styles from "./QuestionLibrary.module.css";
// import {
//   getAttendanceMonth,
//   getChapters,
//   getClasses,
//   getQuestionlibraryQuestions,
//   getSubjects,
//   getTopics,
// } from "../../../Store/API/API";
// import { useDispatch, useSelector } from "react-redux";
// import SelectInput from "../../UI/SelectInput/SelectInput";
// import MCQ from "../../UI/OuestionTypes/MCQ/MCQ";
// import FIB from "../../UI/OuestionTypes/FIB/FIB";
// import TF from "../../UI/OuestionTypes/TF/TF";
// import MCQMA from "../../UI/OuestionTypes/MCQMA/MCQMA";
// import Integer from "../../UI/OuestionTypes/Integer/Integer";
// import Pagination from "../../UI/Pagination/Pagination";
// import Spinner from "../../UserInterface/Spinner/Spinner";

// const width = window.innerWidth;

// const SelectInputField = ({
//   placeholder,
//   field,
//   dropDownoptions,
//   handleInputChange,
//   dropDownValues,
// }) => {
//   return (
//     <SelectInput
//       width={width < 600 ? "100%" : "200px"}
//       placeHolder={placeholder}
//       options={dropDownoptions}
//       field={field}
//       handleInputChange={handleInputChange}
//       value={dropDownValues}
//     />
//   );
// };

// const SelectInputField2 = ({
//   heading,
//   placeholder,
//   field,
//   type,
//   dropDownoptions,
//   handleInputChange,
//   dropDownValues,
//   required,
// }) => {
//   return (
//     <div className={styles.selectWrapper}>
//       {required ? (
//         <label className={styles.label}>
//           {heading} <span className={styles.required}>*</span>
//         </label>
//       ) : (
//         <label className={styles.label}>{heading}</label>
//       )}
//       <div className={styles.dropDownWrapper}>
//         <SelectInput
//           width={"100%"}
//           placeHolder={placeholder}
//           options={dropDownoptions}
//           field={field}
//           handleInputChange={handleInputChange}
//           value={dropDownValues}
//           backgroundColor={"#1b3156"}
//           border={"none"}
//           dropdownTextColor={"#FFFFFF"}
//         />
//       </div>
//     </div>
//   );
// };

// const QuestionLibrary = () => {
//   const userState = useSelector((state) => state.userData.userData);
//   const batchState = useSelector((state) => state.applicationData.batchData);
//   const dispatch = useDispatch();

//   const [currentPage, setCurrentPage] = useState(1);
//   const [progressFlag, setProgressFlag] = useState("");
//   const [questionsData, setQuestionsData] = useState([]);
//   const [totalPages, setTotalPages] = useState(1);
//   const [isLoading, setIsLoading] = useState(false);

//   // console.log("currentPage", currentPage);

//   const handlePageChange = (pageNumber) => {
//     // console.log(pageNumber);

//     setCurrentPage(pageNumber);
//     handleView({ pageNumber });
//   };

//   const [dropDownValues, setDropDownValues] = useState({
//     isVerified: "",
//     class: "",
//     subject: "",
//     chapter: "",
//     topics: "",
//   });
//   const [dropDownoptions, setDropDownOptions] = useState({
//     class: [],
//     subject: [],
//     chapter: [],
//     topics: [],
//     isVerified: [
//       { value: "Yes", label: "Yes" },
//       { value: "No", label: "No" },
//     ],
//   });

//   const fetchTopics = async ({ value }) => {
//     const data = await getTopics(
//       dropDownValues.class.value,
//       dropDownValues.subject.value,
//       value,
//       dispatch
//     );

//     // console.log(data);

//     if (data) {
//       setDropDownOptions((prev) => ({
//         ...prev,
//         topics: data,
//       }));
//     }
//   };

//   const fetchChapters = async ({ value }) => {
//     const data = await getChapters(dropDownValues.class.value, value, dispatch);
//     if (data) {
//       setDropDownOptions((prev) => ({
//         ...prev,
//         chapter: data,
//       }));
//     }
//   };

//   const fetchSubjects = async ({ value }) => {
//     const data = await getSubjects(value, dispatch);

//     if (data) {
//       setDropDownOptions((prev) => ({
//         ...prev,
//         subject: data,
//       }));
//     }
//   };

//   const fetchClasses = async () => {
//     const data = await getClasses(dispatch);
//     // console.log(data);

//     if (data) {
//       setDropDownOptions((prev) => ({
//         ...prev,
//         class: data,
//         pastConnectionClass: data,
//       }));
//     }
//   };

//   const handleInputChange = (field, value) => {
//     setDropDownValues((prev) => {
//       const updatedValues = { ...prev, [field]: value };

//       if (field === "class") {
//         updatedValues.subject = null;
//         updatedValues.chapter = null;
//         updatedValues.topics = "";
//         fetchSubjects(value);
//       }

//       if (field === "subject") {
//         updatedValues.chapter = null;
//         updatedValues.topics = "";
//         fetchChapters(value);
//       }

//       if (field === "chapter") {
//         updatedValues.topics = "";
//         fetchTopics(value);
//       }

//       return updatedValues;
//     });
//   };

//   const handleView = async ({ pageNumber = 1 }) => {
//     // console.log(dropDownValues);

//     setQuestionsData([]);

//     if (
//       !dropDownValues.class?.value ||
//       !dropDownValues.subject?.value ||
//       !dropDownValues.chapter?.value
//     ) {
//       setProgressFlag("Please select all required fields");

//       setTimeout(() => {
//         setProgressFlag("");
//       }, 3000);
//       return;
//     }

//     setIsLoading(true);

//     const apiData = {
//       user_id: userState.userId,
//       question_class: dropDownValues?.class?.value,
//       question_subject: dropDownValues?.subject?.value,
//       question_chapter: dropDownValues?.chapter?.value,
//       is_verified: dropDownValues?.isVerified?.value,
//       start_page: pageNumber,
//     };

//     // const libraryData = {
//     //   user_id: "",
//     //   question_class: "",
//     //   question_subject: "",
//     //   question_chapter: "",
//     //   is_verified: "",
//     //   start_page: "",

//     // };

//     const data = await getQuestionlibraryQuestions(
//       apiData,
//       userState?.token,
//       dispatch
//     );

//     if (data?.data) {
//       console.log(data);

//       // console.log(data?.data?.questionsData?.pagination?.page);
//       setQuestionsData(data?.data);
//       setTotalPages((prev) =>
//         data?.data?.pagination?.total_pages > 0
//           ? data?.data?.pagination?.total_pages
//           : 1
//       );
//     }

//     setIsLoading(false);
//     // console.log("data", data);
//   };

//   useEffect(() => {
//     // fetching classes
//     fetchClasses();

//     // eslint-disable-next-line
//   }, []);

//   return (
//     <div className={styles.container}>
//       <div className={styles.headerWrapper}>
//         <div className={styles.headerContainer}>
//           <p className={styles.heading}>Question Library</p>
//         </div>
//         <div className={styles.questionTypeContainer}>
//           <div className="row align-items-end gap-y-2">
//             <div className="col-lg-2 col-md-4 ">
//               <SelectInputField2
//                 heading={"Class"}
//                 field={"class"}
//                 placeholder={"Select Class"}
//                 dropDownValues={dropDownValues}
//                 dropDownoptions={dropDownoptions}
//                 handleInputChange={handleInputChange}
//                 required={true}
//               />
//             </div>
//             <div className="col-lg-2 col-md-4 ">
//               <SelectInputField2
//                 heading={"Subject"}
//                 field={"subject"}
//                 placeholder={"Select Class"}
//                 dropDownValues={dropDownValues}
//                 dropDownoptions={dropDownoptions}
//                 handleInputChange={handleInputChange}
//                 required={true}
//               />
//             </div>
//             <div className="col-lg-2 col-md-4 ">
//               <SelectInputField2
//                 heading={"Chapter"}
//                 field={"chapter"}
//                 placeholder={"Select Class"}
//                 dropDownValues={dropDownValues}
//                 dropDownoptions={dropDownoptions}
//                 handleInputChange={handleInputChange}
//                 required={true}
//               />
//             </div>
//             <div className="col-lg-2 col-md-4 ">
//               <SelectInputField2
//                 heading={"Topic"}
//                 field={"topics"}
//                 placeholder={"Select Class"}
//                 dropDownValues={dropDownValues}
//                 dropDownoptions={dropDownoptions}
//                 handleInputChange={handleInputChange}
//               />
//             </div>
//             <div className="col-lg-2 col-md-4 ">
//               <SelectInputField2
//                 heading={"Is Verified"}
//                 field={"isVerified"}
//                 placeholder={"Select Class"}
//                 dropDownValues={dropDownValues}
//                 dropDownoptions={dropDownoptions}
//                 handleInputChange={handleInputChange}
//               />
//             </div>
//             <div className="col-lg-2 col-md-4 ">
//               <div>
//                 <p
//                   style={{
//                     color: "red",
//                     marginBottom: "5px",
//                     fontSize: "14px",
//                   }}
//                 >
//                   {progressFlag}
//                 </p>
//                 <div
//                   className={styles.markButton}
//                   onClick={() => {
//                     handleView({ pageNumber: 1 });
//                     setCurrentPage(1);
//                   }}
//                 >
//                   <p>View</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className={styles.bodyWrapper}>
//         {isLoading && <Spinner spinnerHeight={"50%"} />}
//         <div className={styles.questionsContainer}>
//           {questionsData?.questions?.map((question, index) => {
//             console.log(question);
//             switch (question?.type) {
//               case "MCQ":
//                 return (
//                   <MCQ
//                     key={index}
//                     correctOption={question?.answer}
//                     answer={question?.currect_answer}
//                     question={question?.question}
//                     questionOrder={question?.question_order}
//                     questionId={question?.question_id}
//                     questionMarks={question?.marks}
//                     location={"QB"}
//                     questionData={question}
//                   />
//                 );
//               case "MCQMA":
//                 return (
//                   <MCQMA
//                     key={index}
//                     correctOption={question.answer}
//                     answer={question.solution_array}
//                     question={question.question}
//                     questionOrder={question.question_order}
//                     questionId={question?.question_id}
//                     questionMarks={question?.marks}
//                     location={"QB"}
//                     questionData={question}
//                   />
//                 );
//               case "TF":
//                 return (
//                   <TF
//                     key={index}
//                     correctOption={question.answer}
//                     answer={question.currect_answer}
//                     question={question.question}
//                     questionOrder={question.question_order}
//                     questionId={question?.question_id}
//                     questionMarks={question?.marks}
//                     location={"QB"}
//                     questionData={question}
//                   />
//                 );
//               case "INTT":
//                 return (
//                   <Integer
//                     key={index}
//                     correctOption={question.currect_answer}
//                     question={question.question}
//                     questionOrder={question.question_order}
//                     questionId={question?.question_id}
//                     questionMarks={question?.marks}
//                     location={"QB"}
//                     questionData={question}
//                   />
//                 );
//               case "FIB":
//                 return (
//                   <FIB
//                     key={index}
//                     correctOption={question.solution_array}
//                     fibCount={question.fib_count}
//                     question={question.question}
//                     questionOrder={question.question_order}
//                     questionId={question?.question_id}
//                     questionMarks={question?.marks}
//                     location={"QB"}
//                     questionData={question}
//                   />
//                 );
//               default:
//                 return null;
//             }
//           })}
//           {/* <MCQ
//             answer={mcqQuestion.answer}
//             correctOption={mcqQuestion.options}
//             question={mcqQuestion.question}
//             questionOrder={mcqQuestion.questionOrder}
//             location={"QB"}
//             questionId={1}
//             questionMarks={1}
//           />
//           <FIB
//             correctOption={fibQuestion.options}
//             question={fibQuestion.question}
//             questionOrder={1}
//             fibCount={2}
//             location={"QB"}
//           />
//           <TF
//             answer={trueFalseQuestion.answer}
//             question={trueFalseQuestion.question}
//             questionOrder={trueFalseQuestion.questionOrder}
//             location={"QB"}
//           />
//           <MCQMA
//             answer={mcqmaQuestion.answer}
//             question={mcqmaQuestion.question}
//             questionOrder={mcqmaQuestion.questionOrder}
//             correctOption={mcqmaQuestion.options}
//             location={"QB"}
//           />
//           <Integer
//             correctOption={integerQuestion.answer}
//             question={integerQuestion.question}
//             questionOrder={integerQuestion.questionOrder}
//             location={"QB"}
//           /> */}
//           {questionsData?.questionCount === 0 && (
//             <div className={styles.noDataContainer}>No Data Found</div>
//           )}
//           {questionsData?.questions?.length > 0 && (
//             <Pagination
//               currentPage={currentPage}
//               totalPages={totalPages}
//               onPageChange={handlePageChange}
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default QuestionLibrary;

// import { useEffect } from "react";
// import Editor from "../../Editor/Editor";
// const QuestionBank = () => {
//   return (
//     <div>
//       <Editor
//         key={1}
//         height={"8rem"}
//         width={"80%"}
//         placeholder="write your content ...."
//       />
//       <Editor
//         key={2}
//         height={"8rem"}
//         width={"80%"}
//         placeholder="write your content ...."
//       />
//       <Editor
//         key={3}
//         height={"8rem"}
//         width={"80%"}
//         placeholder="write your content ...."
//       />
//     </div>
//   );
// };

// export default QuestionBank;


import React, { useEffect, useState } from "react";
import styles from "./QuestionLibrary.module.css";
import {
  getAttendanceMonth,
  getChapters,
  getClasses,
  getQuestionDetailsQuestionBank,
  getQuestionlibraryQuestions,
  getSubjects,
  getTopics,
} from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../UI/SelectInput/SelectInput";
import MCQ from "../../UI/OuestionTypes/MCQ/MCQ";
import FIB from "../../UI/OuestionTypes/FIB/FIB";
import TF from "../../UI/OuestionTypes/TF/TF";
import MCQMA from "../../UI/OuestionTypes/MCQMA/MCQMA";
import Integer from "../../UI/OuestionTypes/Integer/Integer";
import Pagination from "../../UI/Pagination/Pagination";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { useLocation } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

const width = window.innerWidth;

const SelectInputField2 = ({
  heading,
  placeholder,
  field,
  type,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
  required,
}) => {
  return (
    <div className={styles.selectWrapper}>
      {required ? (
        <label className={styles.label}>
          {heading} <span className={styles.required}>*</span>
        </label>
      ) : (
        <label className={styles.label}>{heading}</label>
      )}
      <div className={styles.dropDownWrapper}>
        <SelectInput
          width={"100%"}
          placeHolder={placeholder}
          options={dropDownoptions}
          field={field}
          handleInputChange={handleInputChange}
          value={dropDownValues}
          backgroundColor={"#1b3156"}
          border={"none"}
          dropdownTextColor={"#FFFFFF"}
        />
      </div>
    </div>
  );
};

const QuestionLibrary = () => {
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [progressFlag, setProgressFlag] = useState("");
  const [questionsData, setQuestionsData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [questionId, setQuestionId] = useState(
    location.state?.questionId || ""
  );

  // console.log("currentPage", currentPage);

  const handlePageChange = (pageNumber) => {
    // console.log(pageNumber);

    setCurrentPage(pageNumber);
    handleView({ pageNumber });
  };

  const [dropDownValues, setDropDownValues] = useState({
    isVerified: "",
    class: "",
    subject: "",
    chapter: "",
    topics: "",
  });

  const [dropDownoptions, setDropDownOptions] = useState({
    class: [],
    subject: [],
    chapter: [],
    topics: [],
    isVerified: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
  });

  const fetchTopics = async ({ value }) => {
    const data = await getTopics(
      dropDownValues.class.value,
      dropDownValues.subject.value,
      value,
      dispatch
    );

    // console.log(data);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        topics: data,
      }));
    }
  };

  const fetchChapters = async ({ value }) => {
    const data = await getChapters(dropDownValues.class.value, value, dispatch);
    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        chapter: data,
      }));
    }
  };

  const fetchSubjects = async ({ value }) => {
    const data = await getSubjects(value, dispatch);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        subject: data,
      }));
    }
  };

  const fetchClasses = async () => {
    const data = await getClasses(dispatch);
    // console.log(data);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        class: data,
        pastConnectionClass: data,
      }));
    }
  };

  const handleInputChange = (field, value) => {
    setDropDownValues((prev) => {
      const updatedValues = { ...prev, [field]: value };

      if (field === "class") {
        updatedValues.subject = null;
        updatedValues.chapter = null;
        updatedValues.topics = "";
        fetchSubjects(value);
      }

      if (field === "subject") {
        updatedValues.chapter = null;
        updatedValues.topics = "";
        fetchChapters(value);
      }

      if (field === "chapter") {
        updatedValues.topics = "";
        fetchTopics(value);
      }

      return updatedValues;
    });
  };

  const handleView = async ({ pageNumber = 1 }) => {
    setQuestionsData([]);

    if (
      !dropDownValues.class?.value ||
      !dropDownValues.subject?.value ||
      !dropDownValues.chapter?.value
    ) {
      setProgressFlag("Please select all required fields");
      setTimeout(() => {
        setProgressFlag("");
      }, 3000);
      return;
    }

    setIsLoading(true);

    const apiData = {
      user_id: userState.userId,
      question_class: dropDownValues?.class?.value,
      question_subject: dropDownValues?.subject?.value,
      question_chapter: dropDownValues?.chapter?.value,
      is_verified: dropDownValues?.isVerified?.value,
      start_page: pageNumber,
    };

    const data = await getQuestionlibraryQuestions(
      apiData,
      userState?.token,
      dispatch
    );

    if (data?.data) {
      // console.log(data);

      // console.log(data?.data?.questionsData?.pagination?.page);
      setQuestionsData(data?.data);
      setTotalPages((prev) =>
        data?.data?.pagination?.total_pages > 0
          ? data?.data?.pagination?.total_pages
          : 1
      );
      setCurrentPage(pageNumber);
    }

    setIsLoading(false);
    // console.log("data", data);
  };

  useEffect(() => {
    if (location.state?.dropDownValues) {
      const prefilledValues = location.state.dropDownValues;
      setDropDownValues((prev) => ({
        ...prev,
        ...prefilledValues,
      }));
    }
  }, [location.state?.dropDownValues]);

  useEffect(() => {
    fetchClasses();
    handleView({ pageNumber: location.state?.currentPage });
  }, [dropDownValues]);

  const fetchQuestionDetails = async (questionId) => {
    if (!questionId) return;

    try {
      const data = await getQuestionDetailsQuestionBank(
        questionId,
        userState?.userId,
        dispatch
      );
      if (data) {
        const formattedData = {
          ...data.data,
          questions: [data.data.question],
        };
        setQuestionsData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching question details:", error);
    }
  };

  useEffect(() => {
    fetchQuestionDetails(questionId);
  }, [location.state?.questionId]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      fetchQuestionDetails(questionId);
    }
  };

  const clearInput = () => {
    setQuestionId("");
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerContainer}>
          <p className={styles.heading}>Question Library</p>
        </div>
        <div className={styles.questionTypeContainer}>
          <div className="row align-items-end gap-y-2">
            <div className="col-lg-2 col-md-4 ">
              <SelectInputField2
                heading={"Class"}
                field={"class"}
                placeholder={"Select Class"}
                dropDownValues={dropDownValues}
                dropDownoptions={dropDownoptions}
                handleInputChange={handleInputChange}
                required={true}
              />
            </div>
            <div className="col-lg-2 col-md-4 ">
              <SelectInputField2
                heading={"Subject"}
                field={"subject"}
                placeholder={"Select Class"}
                dropDownValues={dropDownValues}
                dropDownoptions={dropDownoptions}
                handleInputChange={handleInputChange}
                required={true}
              />
            </div>
            <div className="col-lg-2 col-md-4 ">
              <SelectInputField2
                heading={"Chapter"}
                field={"chapter"}
                placeholder={"Select Class"}
                dropDownValues={dropDownValues}
                dropDownoptions={dropDownoptions}
                handleInputChange={handleInputChange}
                required={true}
              />
            </div>
            <div className="col-lg-2 col-md-4 ">
              <SelectInputField2
                heading={"Topic"}
                field={"topics"}
                placeholder={"Select Class"}
                dropDownValues={dropDownValues}
                dropDownoptions={dropDownoptions}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 ">
              <SelectInputField2
                heading={"Is Verified"}
                field={"isVerified"}
                placeholder={"Select Class"}
                dropDownValues={dropDownValues}
                dropDownoptions={dropDownoptions}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className="col-lg-2 col-md-4 ">
              <div>
                {/* <p style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "14px", }}>
                  {progressFlag}
                </p> */}
                <div
                  className={styles.selectWrapper}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label className={styles.label}>Search Question</label>
                  <div className="flex items-center bg-[#1b3156] rounded-md px-3 py-2 w-full">
                    <FiSearch className="text-white opacity-80" size={18} />
                    <input
                      type="text"
                      field="searchQuestion"
                      placeholder="Enter Question ID"
                      className="w-full bg-transparent border-none text-white ml-2 focus:outline-none"
                      value={questionId}
                      onChange={(e) => setQuestionId(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />

                    {questionId && (
                      <button
                        onClick={clearInput}
                        className="text-white opacity-80"
                      >
                        <IoClose size={18} />
                      </button>
                    )}
                  </div>
                </div>


                {/* <div
                  className={styles.markButton}
                  onClick={() => {
                    handleView({ pageNumber: 1 });
                    setCurrentPage(1);
                  }}
                >
                  <p>View</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bodyWrapper}>
        {isLoading && <Spinner spinnerHeight={"50%"} />}
        <div className={styles.questionsContainer}>
          {questionsData?.questions?.map((question, index) => {
            console.log(question);
            switch (question?.type) {
              case "MCQ":
                return (
                  <MCQ
                    key={index}
                    correctOption={question?.answer}
                    answer={question?.currect_answer}
                    question={question?.question}
                    questionOrder={question?.question_order}
                    questionId={question?.question_id}
                    questionMarks={question?.marks}
                    location={"QB"}
                    questionData={question}
                    dropDownValues={dropDownValues}
                    currentPage={currentPage}
                  />
                );
              case "MCQMA":
                return (
                  <MCQMA
                    key={index}
                    correctOption={question.answer}
                    answer={question.solution_array}
                    question={question.question}
                    questionOrder={question.question_order}
                    questionId={question?.question_id}
                    questionMarks={question?.marks}
                    location={"QB"}
                    questionData={question}
                    dropDownValues={dropDownValues}
                    currentPage={currentPage}
                  />
                );
              case "TF":
                return (
                  <TF
                    key={index}
                    correctOption={question.answer}
                    answer={question.currect_answer}
                    question={question.question}
                    questionOrder={question.question_order}
                    questionId={question?.question_id}
                    questionMarks={question?.marks}
                    location={"QB"}
                    questionData={question}
                    dropDownValues={dropDownValues}
                    currentPage={currentPage}
                  />
                );
              case "INTT":
                return (
                  <Integer
                    key={index}
                    correctOption={question.currect_answer}
                    question={question.question}
                    questionOrder={question.question_order}
                    questionId={question?.question_id}
                    questionMarks={question?.marks}
                    location={"QB"}
                    questionData={question}
                    dropDownValues={dropDownValues}
                    currentPage={currentPage}
                  />
                );
              case "FIB":
                return (
                  <FIB
                    key={index}
                    correctOption={question.solution_array}
                    fibCount={question.fib_count}
                    question={question.question}
                    questionOrder={question.question_order}
                    questionId={question?.question_id}
                    questionMarks={question?.marks}
                    location={"QB"}
                    questionData={question}
                    dropDownValues={dropDownValues}
                    currentPage={currentPage}
                  />
                );
              default:
                return null;
            }
          })}
          {/* <MCQ
            answer={mcqQuestion.answer}
            correctOption={mcqQuestion.options}
            question={mcqQuestion.question}
            questionOrder={mcqQuestion.questionOrder}
            location={"QB"}
            questionId={1}
            questionMarks={1}
          />
          <FIB
            correctOption={fibQuestion.options}
            question={fibQuestion.question}
            questionOrder={1}
            fibCount={2}
            location={"QB"}
          />
          <TF
            answer={trueFalseQuestion.answer}
            question={trueFalseQuestion.question}
            questionOrder={trueFalseQuestion.questionOrder}
            location={"QB"}
          />
          <MCQMA
            answer={mcqmaQuestion.answer}
            question={mcqmaQuestion.question}
            questionOrder={mcqmaQuestion.questionOrder}
            correctOption={mcqmaQuestion.options}
            location={"QB"}
          />
          <Integer
            correctOption={integerQuestion.answer}
            question={integerQuestion.question}
            questionOrder={integerQuestion.questionOrder}
            location={"QB"}
          /> */}
          {questionsData?.questionCount === 0 && (
            <div className={styles.noDataContainer}>No Data Found</div>
          )}
          {questionsData?.questions?.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionLibrary;