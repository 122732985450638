import React from "react";
import TabButtons from "./TabButtons";

// Data for Leaderboard
const leaderboardData = [
  {
    name: "Vivean Stella",
    school: "DPS AGRA",
    rank: 1,
    image: "/avatar.png",
    badgeColor: "bg-orange-500",
  },
  {
    name: "Vivean Stella",
    school: "DPS AGRA",
    rank: 2,
    image: "/avatar.png",
    badgeColor: "bg-purple-500",
  },
  {
    name: "Vivean Stella",
    school: "DPS RAJNAGAR",
    rank: 3,
    image: "/avatar.png",
    badgeColor: "bg-purple-500",
  },
  {
    name: "Vivean Stella",
    school: "XYZ School",
    rank: 4,
    image: "/avatar.png",
    badgeColor: "bg-teal-400",
  },
  {
    name: "Vivean Stella",
    school: "DPS AGRA",
    rank: 5,
    image: "/avatar.png",
    badgeColor: "bg-teal-400",
  },
];

// Data for Topic Wise Analysis
const topics = [
  { title: "Law of Inertia", percentage: 5 },
  {
    title: "Force-Mass-Acceleration Relation",
    percentage: 25,
  },
  { title: "Action-Reaction", percentage: 65 },
  {
    title: "Friction: Static, Kinetic, and Rolling Friction",
    percentage: 100,
  },
  {
    title: "Circular Motion and Centripetal Force",
    percentage: 18,
  },
  {
    title: "Impulse and Impulse-Momentum Theorem",
    percentage: 45,
  },
];

const Leaderboard = ({
  leaderBoardActiveSession,
  setLeaderBoardActiveSession,
  analyticsData,
}) => {
  // Function to get the color of the progress bar
  const getProgressBarColor = (percentage) => {
    if (percentage <= 30) return "bg-red-400";
    if (percentage <= 60) return "bg-orange-400";
    if (percentage <= 90) return "bg-blue-400";
    return "bg-green-400";
  };

  return (
    <div className="text-white p-6 rounded-xl flex flex-col lg:flex-row gap-10">
      {/* Leaderboard Section */}
      <div className="lg:w-2/3 w-full">
        <h2 className="text-3xl font-bold text-white">Leaderboard</h2>

        {/* Tabs Section */}
        <div className="flex space-x-3 mt-3">
          <TabButtons
            title="Top Rankers"
            activeTab={leaderBoardActiveSession}
            onClick={setLeaderBoardActiveSession}
          />
          <TabButtons
            title="Your School Ranking"
            activeTab={leaderBoardActiveSession}
            onClick={setLeaderBoardActiveSession}
          />
        </div>

        {/* Leaderboard Section */}
        <div className="mt-4 space-y-4">
          {(leaderBoardActiveSession === "Top Rankers"
            ? analyticsData?.leaderboardData_all_india
            : analyticsData?.leaderboardData_school
          )?.map((user, index) => (
            <div
              key={index}
              className="flex items-center bg-white rounded-xl px-3 py-2 text-black shadow-md"
            >
              <img
                src="./images/profile-img.png"
                alt={user.name}
                className="w-10 h-10 rounded-full"
              />
              <div className="flex-1 ml-3">
                <p className="font-semibold text-base">{user.name}</p>
                <p className="text-xs opacity-70">{user.school}</p>
              </div>
              <span
                className={`px-2 py-0.5 rounded-lg text-white text-sm bg-purple-400`}
              >
                Rank {user.rank}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Topic Wise Analysis */}
      <div className="lg:w-1/3 w-full">
        <h2 className="text-3xl font-bold text-white">Topic Wise Analysis</h2>

        {/* Topic Wise Analysis Section */}
        <div className="mt-4 space-y-3">
          {analyticsData?.topic_analysis?.map((topic, index) => (
            <div key={index}>
              <p className="text-base mb-2">{topic.topic || "-"}</p>
              <div className="bg-gray-700 h-2 rounded-lg relative">
                <div
                  className={`absolute top-0 left-0 h-2 rounded-lg ${getProgressBarColor(
                    topic.percentage
                  )}`}
                  style={{ width: `${topic.percentage}%` }}
                ></div>
              </div>
              <p className="text-xs text-right">{topic.percentage}%</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
