// import { useState } from "react";
// import CheckIcon from "@mui/icons-material/Check";
// import { useDispatch, useSelector } from "react-redux";
// import { uploadFile, verifyFile } from "../../../Store/API/API";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// export default function UploadTimeTable() {
//   const userState = useSelector((state) => state.userData.userData);
//   const dispatch = useDispatch();

//   const [file, setFile] = useState(null);
//   const [isVerified, setIsVerified] = useState(false);
//   const [verificationErrors, setVerificationErrors] = useState([]);
//   const [verificationResponse, setVerificationResponse] = useState([]);
//   const [expandedSection, setExpandedSection] = useState("upload");

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     setFile(selectedFile);
//     setVerificationErrors([]);
//     setIsVerified(false);
//     setVerificationResponse([]);
//   };

//   const verifyFileHandler = async () => {
//     if (!file) return;

//     try {
//       const data = await verifyFile(userState, file, dispatch);

//       if (data?.statuscode === "200" && Array.isArray(data.data)) {
//         const formattedResponse = data.data.map(
//           (item) => ` ${item.row} - ${item.msg}`
//         );
//         setVerificationResponse(formattedResponse);
//         setIsVerified(true);
//       } else {
//         setVerificationResponse(["Invalid response from server."]);
//         setIsVerified(false);
//       }
//     } catch (error) {
//       setVerificationResponse(["An error occurred while verifying the file."]);
//       setIsVerified(false);
//     }
//   };

//   // const uploadFileHandler = async () => {
//   //   if (!file || !isVerified) return;
//   //   try {
//   //     const data = await uploadFile(userState, file, dispatch);

//   //     if (data?.statusCode === "200") {
//   //       alert("File uploaded successfully!");
//   //     } else {
//   //       alert(data?.error || "File upload failed.");
//   //     }
//   //   } catch (error) {
//   //     setVerificationErrors(["An error occurred while uploading the file."]);
//   //   }
//   // };

//   const uploadFileHandler = async () => {
//     // if (!file) {
//     //   return;
//     // }

//     // if (!isVerified) {
//     //   return;
//     // }

//     try {
//       const data = await uploadFile(userState, file, dispatch);

//       if (data?.statuscode === "200") {
//         alert("File uploaded successfully!");
//       } else {
//         alert(data?.error || "File upload failed.");
//       }
//     } catch (error) {
//       alert("An error occurred while uploading the file.");
//     }
//   };

//   return (
//     <div className="m-auto w-1/2 py-6 flex flex-col overflow-auto">
//       <div className="space-y-6">
//         <div className="border rounded-lg p-4 shadow-sm">
//           <div
//             className="flex justify-between items-center cursor-pointer"
//             onClick={() =>
//               setExpandedSection(expandedSection === "upload" ? "" : "upload")
//             }
//           >
//             <h2 className="font-bold text-base md:text-xl lg:text-2xl xl:text-2xl">
//               Upload Time Table
//             </h2>
//             <ExpandMoreIcon
//               className={expandedSection === "upload" ? "rotate-180" : ""}
//             />
//           </div>
//           {expandedSection === "upload" && (
//             <div className="mt-4 border-2 border-dashed border-textLightgrey p-6 rounded-lg flex flex-col items-center justify-center">
//               <label htmlFor="fileUpload" className="cursor-pointer">
//                 <img
//                   src="/images/uploadfile_icon.svg"
//                   className="h-20 w-20 mb-2"
//                   alt="Upload Icon"
//                 />
//                 <p className=" text-base text-textBlack font-normal">Select Your File</p>
//               </label>
//               <input
//                 type="file"
//                 id="fileUpload"
//                 className="hidden"
//                 accept=".xls, .xlsx"
//                 onChange={handleFileChange}
//               />
//               {file && (
//                 <p className="text-textBlack text-2xl font-medium">Selected File: {file.name}</p>
//               )}
//             </div>
//           )}
//         </div>
//         <div className="flex gap-6 p-2">
//           <button
//             onClick={verifyFileHandler}
//             className="bg-primaryBlue text-textWhite px-4 py-3  rounded-xl gap-2"
//           >
//             <CheckIcon className="text-textWhite" />
//             <span> Verify File</span>
//           </button>
//           <button
//             onClick={uploadFileHandler}
//             className="bg-primaryOrange text-textWhite px-4 py-3 rounded-xl gap-2"
//             disabled={!isVerified}
//           >
//             <span>Upload File</span>
//           </button>
//         </div>

//         {verificationResponse.length > 0 && (
//           <div className="text-secondaryGreen text-sm mt-2">
//             {verificationResponse.map((item, index) => (
//               <p key={index}>{JSON.stringify(item)}</p>
//             ))}
//           </div>
//         )}
//         {verificationErrors.length > 0 && (
//           <div className="text-secondaryRed text-sm mt-2">
//             {verificationErrors.map((error, index) => (
//               <p key={index}>{error}</p>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile, verifyFile } from "../../../Store/API/API";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import { FaExclamationTriangle } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";

export default function UploadTimeTable() {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [loader, setLoader] = useState(false);
  const [verificationResponse, setVerificationResponse] = useState([]);
  const [expandedSection, setExpandedSection] = useState("upload");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setIsVerified(false);
    // setVerificationResponse([]);
  };

  const verifyFileHandler = async () => {
    if (!file) return;

    try {
      setLoader(true);
      const data = await verifyFile(userState, file, dispatch);
      setLoader(false);

      if (data?.statuscode === "200" && Array.isArray(data.data)) {
        const formattedMessages = data.data.map((item) => ({
          row: item.row,
          message: `${item.row} - ${item.msg}`,
          status: item.status,
        }));

        setVerificationResponse(formattedMessages);

        setIsVerified(
          formattedMessages.every((item) => item.status === "success")
        );
      } else {
        setVerificationResponse({
          errors: ["Invalid response from server."],
          successes: [],
        });
        setIsVerified(false);
      }
    } catch (error) {
      setVerificationResponse({
        errors: ["An error occurred while verifying the file."],
      });
      setIsVerified(false);
      setLoader(false);
    }
  };

  const uploadFileHandler = async () => {
    try {
      const data = await uploadFile(userState, file, dispatch);

      if (data?.statuscode === "200") {
        dispatch(
          applicationActions.setAlertData({
            isAlert: true,
            alertMessage: "File uploaded successfully!",
            alertType: "success",
          })
        );
        const formattedMessages = data.data.map((item) => ({
          row: item.row,
          message: `${item.row} - ${item.msg}`,
          status: item.status,
        }));

        setVerificationResponse(formattedMessages);
      } else {
        dispatch(
          applicationActions.setAlertData({
            isAlert: true,
            alertMessage: data?.error || "File upload failed.",
            alertType: "error",
          })
        );
      }
    } catch (error) {

      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage:
            error.message || "An error occurred while uploading the file.",
          alertType: "error",
        })
      );
    } finally {
      setTimeout(() => {
        dispatch(
          applicationActions.setAlertData({
            isAlert: false,
            alertMessage: "",
            alertType: "",
          })
        );
      }, 3000);
    }
  };

  return (
    <div className="m-auto w-[85%] md:w-[90%] py-20 flex flex-col overflow-auto">
      <div className="space-y-3">
        <div className="border rounded-lg p-3 shadow-sm">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() =>
              setExpandedSection(expandedSection === "upload" ? "" : "upload")
            }
          >
            <h2 className="font-bold text-base md:text-xl lg:text-2xl xl:text-2xl">
              Upload Time Table
            </h2>
            <ExpandMoreIcon
              className={expandedSection === "upload" ? "rotate-180" : ""}
            />
          </div>
          {expandedSection === "upload" && (
            <div className="mt-4 border-2 border-dashed border-textLightgrey p-6 rounded-lg flex flex-col items-center justify-center">
              <label htmlFor="fileUpload" className="cursor-pointer">
                <img
                  src="/images/uploadfile_icon.svg"
                  className="h-20 w-20 mb-2"
                  alt="Upload Icon"
                />
                <p className=" text-base text-textBlack font-normal">
                  Select Your File
                </p>
              </label>
              <input
                type="file"
                id="fileUpload"
                className="hidden"
                accept=".xls, .xlsx"
                onChange={handleFileChange}
              />
              {file && (
                <p className="text-textBlack text-base font-medium">
                  Selected File: {file.name}
                </p>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-4 p-2 w-full">
          <div className="flex flex-col sm:flex-row gap-4 w-full">
            <button
              onClick={verifyFileHandler}
              className={`w-full sm:w-auto px-4 py-3 rounded-xl gap-2 flex items-center justify-center ${
                verificationResponse.length > 0
                  ? "bg-primaryOrange"
                  : "bg-primaryBlue"
              } text-textWhite`}
              disabled={loader}
            >
              <span className="flex items-center gap-2">
                {loader ? (
                  <CircularProgress
                    size={20}
                    className="text-white animate-spin"
                  />
                ) : (
                  <CheckIcon className="text-textWhite" />
                )}
                {loader
                  ? "Verifying"
                  : isVerified
                  ? "Verified"
                  : verificationResponse.length > 0
                  ? "Re-Verify"
                  : "Verify File"}
              </span>
            </button>

            <button
              onClick={uploadFileHandler}
              className={`w-full sm:w-auto ${
                file ? "bg-primaryOrange" : "bg-primaryOrangeDisabled"
              } text-textWhite px-4 py-3 rounded-xl`}
              disabled={!file}
              style={{ cursor: file ? "pointer" : "not-allowed" }}
            >
              Upload File
            </button>
          <button
            onClick={() => {
              const url = "https://innovartan.s3.amazonaws.com/2437fc5e306c5590f5dfda78580c3bc11108936698/3fd10679fb6281c2c1863fe3078c8be4.xlsx";
              window.open(url, "_blank", "noopener,noreferrer");
            }}
            className={`bg-primaryOrange text-textWhite px-4 py-3 rounded-xl gap-2`}
            style={{ cursor: "pointer" }}
          >
            Download Sample File
          </button>
          <button
            onClick={() => {
              console.log("Download Chapter Codes");              
              const url = "https://innovartan.s3.ap-southeast-1.amazonaws.com/resource/4/file-1739344283-803240574.xlsx";
              window.open(url, "_blank", "noopener,noreferrer");
            }}
            className={`bg-primaryOrange text-textWhite px-4 py-3 rounded-xl gap-2`}
            style={{ cursor: "pointer" }}
          >
           Download Chapter Codes
          </button>
         <button
  onClick={() => {             
    const url = `https://app.innovartan.com/scripts/tools/utils/get-batch-data.php?institute_id=${userState.userId}&report=batch`;
     window.open(url, "_blank", "noopener,noreferrer");
  }}
  className="bg-primaryOrange text-textWhite px-4 py-3 rounded-xl gap-2"
  style={{ cursor: "pointer" }}
>
  Download Batch Codes
</button>
  <button
  onClick={() => {             
    const url = `https://app.innovartan.com/scripts/tools/utils/get-batch-data.php?institute_id=${userState.userId}&report=users`;
    window.open(url, "_blank", "noopener,noreferrer");
  }}
  className="bg-primaryOrange text-textWhite px-4 py-3 rounded-xl gap-2"
  style={{ cursor: "pointer" }}
>
  Download Teacher Codes
</button>
          </div>
        </div>
        {/* {verificationResponse.length > 0 && (
          <div className="mt-2 h-28 lg:h-32 xl:h-48 overflow-y-auto">
            {verificationResponse.map((item, index) => (
              <p
                key={index}
                className={
                  item.status === "success"
                    ? "text-secondaryGreen"
                    : "text-secondaryRed"
                }
              >
                {item.message}
              </p>
            ))}
          </div>
        )} */}
        {verificationResponse.length > 0 && (
          <div className="mt-2 h-28 lg:h-32 xl:h-48 overflow-y-auto">
            {verificationResponse.map((item, index) => (
              <div
                key={index}
                className={`flex items-center gap-2 ${
                  item.status === "success"
                    ? "text-secondaryGreen"
                    : "text-secondaryRed"
                }`}
              >
                {item.status === "success" ? (
                  <FaCircleCheck className="text-secondaryGreen" />
                ) : (
                  <FaExclamationTriangle className="text-secondaryRed" />
                )}
                <p>{item.message}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
