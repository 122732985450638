import { useEffect } from "react";
import Editor from "../../Editor/Editor";
const QuestionBank = () => {
  return (
    <div>
      <Editor
        key={1}
        height={"8rem"}
        width={"80%"}
        placeholder="write your content ...."
      />
      <Editor
        key={2}
        height={"8rem"}
        width={"80%"}
        placeholder="write your content ...."
      />
      <Editor
        key={3}
        height={"8rem"}
        width={"80%"}
        placeholder="write your content ...."
      />
    </div>
  );
};

export default QuestionBank;
