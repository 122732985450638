import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../UI/SelectInput/SelectInput";
import { createBatch, getCourseList } from "../../../Store/API/API";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";

const colors = [
  "#EDBBB4",
  "#797B84",
  "#603140",
  "#D3D57C ",
  "#0ACDFF",
  "#60AB9A",
  "#1B1725",
  "#0A8754",
  "#0353A4",
  "#C44536",
];

const SelectInputField = ({
  heading,
  placeholder,
  field,
  type,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
  isMulti,
}) => {
  return (
    <div className="flex flex-col">
      <label className="text-[16px] font-medium text-black">
        {heading} <span className="text-[#f36421]">*</span>
      </label>
      <div className="rounded-xl">
        <SelectInput
          width={"100%"}
          placeHolder={placeholder}
          options={dropDownoptions}
          field={field}
          handleInputChange={handleInputChange}
          value={dropDownValues}
          // dropdownTextColor={"#FFFFFF"}
          isMulti={isMulti}
        />
      </div>
    </div>
  );
};

const TextInputField = ({
  heading,
  placeholder,
  field,
  handleInputChange,
  value,
}) => {
  return (
    <div className="flex flex-col">
      <label className="text-[16px] font-medium text-black">
        {heading} <span className="text-[#f36421]">*</span>
      </label>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleInputChange(field, e.target.value)}
        className="w-full rounded-md border border-gray-300 px-4 py-[6px] mt-1 text-black focus:outline-none focus:ring-2 focus:ring-[#f36421]"
      />
    </div>
  );
};

const ColorPicker = ({ heading, colors, selectedColor, setSelectedColor }) => {
  return (
    <div className="flex flex-col">
      <label className="text-[16px] font-medium text-black mb-2">
        {heading} <span className="text-[#f36421]">*</span>
      </label>
      <div className="grid grid-cols-10 gap-2">
        {colors.map((color) => (
          <button
            key={color}
            className={`w-10 h-10 rounded-full border-2 ${
              selectedColor === color
                ? "border-[#f36421] scale-110"
                : "border-transparent"
            }`}
            style={{ backgroundColor: color }}
            onClick={() => setSelectedColor(color)}
          />
        ))}
      </div>
    </div>
  );
};

const CreateBatchModal = ({
  modalHeading,
  onClose,
  handleModalSubmit,
  instituteId,
  batchData,
}) => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

//   console.log("batchData", batchData);

  const [dropDownValues, setDropDownValues] = useState({
    courseName: batchData?.course_id ? batchData?.course_id : "",
    acadmicYear: batchData?.session
      ? { value: batchData?.session, label: batchData?.session }
      : "",
    batchName: batchData?.group_name ? batchData?.group_name : "",
  });
  const [dropDownoptions, setDropDownOptions] = useState({
    courseName: [],
    acadmicYear: [],
    batchName: "",
  });
  const [selectedColor, setSelectedColor] = useState(
    batchData?.color_code ? batchData?.color_code : "#EDBBB4"
  );

  const handleInputChange = (field, value) => {
    // console.log(field, value);

    setDropDownValues({
      ...dropDownValues,
      [field]: value,
    });
  };

  const handleCreate = async () => {
    // console.log(dropDownValues, selectedColor);

    if (
      !dropDownValues.courseName ||
      !dropDownValues.acadmicYear ||
      !dropDownValues.batchName
    ) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Please fill all the fields",
          alertType: "error",
        })
      );
      return;
    }

    const apiData = {
      user_id: userState.userId,
      name: dropDownValues.batchName,
      color_code: selectedColor,
      course_id: dropDownValues.courseName.value,
      group_id: batchData?.group_id ? batchData?.group_id : "",
      parent_institute_id: instituteId * 1,
      session: dropDownValues.acadmicYear.value,
    };

    // console.log(apiData);

    const data = await createBatch(apiData, userState.token, dispatch);

    // console.log(data);

    if (data?.data) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Batch Created Successfully",
          alertType: "success",
        })
      );
      handleModalSubmit();
    }
  };

  const generateAcademicYears = (startYear = 2024) => {
    const currentYear = new Date().getFullYear();
    let academicYears = [];

    for (let year = startYear; year <= currentYear; year++) {
      academicYears.push({
        value: `${year}-${year + 1}`,
        label: `${year}-${year + 1}`,
      });
    }

    return academicYears;
  };

  const fetchCourseList = async () => {
    const data = await getCourseList(userState.token, dispatch);

    if (data?.data) {
      const academicYearOptions = generateAcademicYears();

      if (batchData?.course_id) {
        const course = data?.data.find(
          (course) => course.value === batchData?.course_id
        );
        setDropDownValues({
          ...dropDownValues,
          courseName: course,
        });
      }

      setDropDownOptions({
        ...dropDownoptions,
        courseName: data?.data,
        acadmicYear: academicYearOptions,
      });
    }

    //
  };

  useEffect(() => {
    fetchCourseList();
    // eslint-disable-next-line
  }, [userState]);

  return (
    <div className="rounded-none flex min-h-[400px] xl:w-[700px] lg:w-[600px] md:w-[500px] flex-col">
      <header className="flex w-full gap-x-[77px] gap-y-[40px] items-center justify-center self-end">
        <div className="bg-gradient-to-br from-[#fff5ef] to-white rounded-[20px]">
          <p className="text-black text-center font-bold text-[20px] leading-[1.2] font-[Metropolis,-apple-system,Roboto,sans-serif] flex-grow p-[14px_70px]">
            {modalHeading}
          </p>
        </div>
      </header>
      <ColorPicker
        heading="Choose a Color"
        colors={colors}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
      />
      <div className="grid grid-cols-2 gap-5 mt-5">
        <SelectInputField
          field={"courseName"}
          heading={"Course Name"}
          placeholder={"Select Course"}
          dropDownValues={dropDownValues}
          dropDownoptions={dropDownoptions}
          handleInputChange={handleInputChange}
        />
        <SelectInputField
          field={"acadmicYear"}
          heading={"Academic Year"}
          placeholder={"Select Year"}
          dropDownValues={dropDownValues}
          dropDownoptions={dropDownoptions}
          handleInputChange={handleInputChange}
        />
        <TextInputField
          field={"batchName"}
          heading={"Batch Name"}
          placeholder={"Enter Batch Name"}
          handleInputChange={handleInputChange}
          value={dropDownValues.batchName}
        />
        <div className="grid grid-cols-2 gap-5">
          <div
            className={
              "bg-gray-500 hover:bg-gray-700 text-white border-none rounded-[12px] px-5 py-2 lg:text-[16px] text-[12px] font-medium cursor-pointer h-[40px] self-end flex justify-center items-center"
            }
            onClick={onClose}
          >
            <p>Cancel</p>
          </div>
          <div
            className={
              "bg-[#f36421] hover:bg-orange-700 text-white border-none rounded-[12px] px-5 py-2 lg:text-[16px] text-[12px] font-medium cursor-pointer h-[40px] self-end flex justify-center items-center"
            }
            onClick={handleCreate}
          >
            <p>{modalHeading}</p>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default CreateBatchModal;
