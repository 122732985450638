// import React, { useState } from "react";
// import styles from "../../Pages/Session/CommonSession.module.css";
// import CustomModal from "../../UI/CustomModal/CustomModal";
// import Spinner from "../../UserInterface/Spinner/Spinner";
// import { useDispatch, useSelector } from "react-redux";
// import { verifyFile, uploadFile, fetchTimetable } from "../../../Store/API/API";
// import Table from "./Table";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const TimeTable = () => {
//   const [creatingSession, setCreatingSession] = useState(false);
//   const [file, setFile] = useState(null);
//   const [isVerified, setIsVerified] = useState(false);
//   const [verificationErrors, setVerificationErrors] = useState([]);
//   const [openUploadModal, setOpenUploadModal] = useState(false);
//   const [fromDate, setFromDate] = useState(null);
//   const [toDate, setToDate] = useState(null);
//   const [timetableData, setTimetableData] = useState([]);
//   const [errors, setErrors] = useState(null);

//   const userState = useSelector((state) => state.userData.userData);
//   const dispatch = useDispatch();

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     setFile(selectedFile);
//     setVerificationErrors([]);
//     setIsVerified(false);
//   };

//   const verifyFileHandler = async () => {
//     if (!file) return;
//     try {
//       const data = await verifyFile(userState, file, dispatch);
//       if (data.statusCode === "200") {
//         setIsVerified(true);
//         alert("File verified successfully!");
//       } else {
//         setVerificationErrors([data.error || "Verification failed."]);
//         setIsVerified(false);
//       }
//     } catch (error) {
//       setVerificationErrors(["An error occurred while verifying the file."]);
//       setIsVerified(false);
//     }
//   };

//   const uploadFileHandler = async () => {
//     if (!file || !isVerified) return;
//     try {
//       const data = await uploadFile(userState, file, dispatch);

//       if (data.statusCode === "200") {
//         alert("File uploaded successfully!");
//       } else {
//         alert("File upload failed.");
//       }
//     } catch (error) {
//       setVerificationErrors(["An error occurred while uploading the file."]);
//     }
//   };

//   const fetchTimetableHandler = async () => {
//     if (!fromDate || !toDate) {
//       alert("Please select both From and To dates.");
//       return;
//     }

//     setCreatingSession(true);
//     setErrors(null);

//     try {
//       const data = await fetchTimetable(
//         userState,
//         fromDate.toISOString().split("T")[0],
//         toDate.toISOString().split("T")[0],
//         dispatch
//       );

//       if (data && data.statuscode === "200") {
//         setTimetableData(data.data);
//       } else {
//         setErrors(data?.error || "Failed to fetch timetable data.");
//       }
//     } catch (error) {
//       setErrors("An error occurred while fetching the timetable data.");
//     } finally {
//       setCreatingSession(false);
//     }
//   };

//   return (
//     <div className={styles.container}>
//       {creatingSession && <Spinner />}
//       <div className="flex items-center gap-4 mb-6 font-metropolis">
//         <h2 className=" md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl font-bold">Time Table</h2>
//         <div className="flex items-center gap-2">
//           <label className="text-lg font-medium">From</label>
//           <DatePicker
//             selected={fromDate}
//             onChange={(date) => setFromDate(date)}
//             placeholderText="DD / MM / YYYY"
//             className="p-2 border rounded-md"
//           />
//         </div>
//         <div className="flex items-center gap-2">
//           <label className="text-lgfont-medium">To</label>
//           <DatePicker
//             selected={toDate}
//             onChange={(date) => setToDate(date)}
//             placeholderText="DD / MM / YYYY"
//             minDate={fromDate}
//             className="p-2 border border-gray-300 rounded-md"
//           />
//         </div>
//         <button
//           onClick={fetchTimetableHandler}
//           className="bg-[#f36421] hover:bg-[#d9531e] text-white px-6 py-2 rounded-md"
//         >
//           Fetch Timetable
//         </button>
//         <button
//           onClick={() => setOpenUploadModal(true)}
//           className="bg-[#f36421] hover:bg-[#d9531e] text-white px-6 py-2 rounded-md"
//         >
//           Upload Time Table
//         </button>
//       </div>
//       <CustomModal
//         isOpen={openUploadModal}
//         onClose={() => setOpenUploadModal(false)}
//       >
//         <h2 className="text-xl font-semibold text-center mb-4">
//           Upload Time Table
//         </h2>
//         <div className="flex flex-col md:flex-row md:items-center gap-4">
//           <input
//             type="file"
//             accept=".xlsx, .xls"
//             className="block w-full md:w-auto p-2 border border-gray-300 rounded-md focus:outline-none "
//             onChange={handleFileChange}
//           />
//           <button
//             onClick={verifyFileHandler}
//             className={`text-white px-6 py-2 rounded-xl transition-all md:flex-shrink-0 ${
//               file
//                 ? "bg-secondaryGreen hover:bg- backgroundGreen"
//                 : "bg-backgroundGrey cursor-not-allowed"
//             }`}
//             disabled={!file}
//           >
//             Verify
//           </button>
//           <button
//             onClick={uploadFileHandler}
//             className={`text-white px-6 py-2 rounded-xl transition-all md:flex-shrink-0 ${
//               isVerified
//                 ? "bg-primaryOrange hover:bg-primaryOrangeDisabled"
//                 : "bg-backgroundGrey cursor-not-allowed"
//             }`}
//             disabled={!isVerified}
//           >
//             Upload
//           </button>
//         </div>
//         {verificationErrors.length > 0 && (
//           <div className="mt-4 text-secondaryRed">
//             {verificationErrors.map((error, index) => (
//               <p key={index}>{error}</p>
//             ))}
//           </div>
//         )}
//       </CustomModal>
//       <Table timetableData={timetableData} />
//     </div>
//   );
// };

// export default TimeTable;

import React, { useEffect, useState } from "react";
import styles from "../../Pages/Session/CommonSession.module.css";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { fetchTimetable } from "../../../Store/API/API";
import Table from "./Table";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import HeaderComponent from "../../Pages/Navbar/Header/HeaderComponent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const SelectedDatePicker = ({ value, onChange, label }) => {
  return (
    <div className="flex flex-col text-start justify-between text-textWhite font-metropolis">
      <label className="text-white font-medium">
        {label} <span className="text-secondaryRed">*</span>
      </label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          format="DD/MM/YYYY"
          onChange={onChange}
          placeholder="DD / MM / YYYY"
          sx={{
            "& .MuiInputBase-root": {
              width: "100%",
              color: "white",
              backgroundColor: "#1b3156",
              borderRadius: "4px",
              padding: "5px 10px",
              height: "40px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#1b3156",
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "white",
              },
            "& .MuiInputBase-input": {
              color: "white",
            },
            "& .MuiButtonBase-root": {
              color: "white",
            },
            "& .MuiPickersDay-dayWithMargin": {
              color: "white",
            },
            "& .MuiPickersDay-dayWithMargin.Mui-selected": {
              backgroundColor: "#1b3156",
              color: "white",
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};
const TimeTable = () => {
  const [creatingSession, setCreatingSession] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [timetableData, setTimetableData] = useState([]);
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();
  const userState = useSelector((state) => state.userData.userData);

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);

  const handleNavigation = () => {
    navigate("/upload-time-table");
  };

  const fetchTimetableHandler = async () => {
    if (!fromDate || !toDate) {
      return;
    }
    setCreatingSession(true);
    setErrors(null);

    try {
      const data = await fetchTimetable(
        userState,
        fromDate.format("YYYY-MM-DD"),
        toDate.format("YYYY-MM-DD"),
        
        dispatch
      );
      //console.log("data",data)

      if (data && data.statuscode === "200") {
        setTimetableData(data.data);
      } else {
        setErrors(data?.error || "Failed to fetch timetable data.");
      }
    } catch (error) {
      setErrors("An error occurred while fetching the timetable data.");
    } finally {
      setCreatingSession(false);
    }
  };
    const fetchTimetableReport = async () => {
        if (!fromDate || !toDate) {
            return;
        } else {
            const frm = fromDate.format("YYYY-MM-DD");
            const to = toDate.format("YYYY-MM-DD");
            const hostname = window.location.hostname;
            let hhost = 'https://app.innovartan.com'; 
            if (hostname === 'localhost' || hostname.includes('qa')) {
            hhost = 'http://qa.edvanz.com'; // Correctly reassigning variable
            }
            const url = `${hhost}/scripts/tools/utils/download-sessions.php?from=${frm}&to=${to}`;
            window.open(url, "_blank", "noopener,noreferrer");
        }
    };
  useEffect(() => {
    fetchTimetableHandler();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = timetableData.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div className={`${styles.container} pb-5`}>
      {creatingSession && <Spinner />}
      <div>
        <h2 className="text-[20px] md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl font-bold m-3 mb-0">
          Time Table
        </h2>
      </div>
      <div className="p-2 overflow-auto">
        <HeaderComponent>
          <div className="grid grid-cols-2 xl:grid-cols-5 gap-2 items-end">
            <SelectedDatePicker
              label="From"
              value={fromDate}
              onChange={setFromDate}
            />
            <SelectedDatePicker
              label="To"
              value={toDate}
              onChange={setToDate}
            />

            <button
              onClick={fetchTimetableHandler}
              className="bg-primaryOrange hover:bg-primaryOrangeDisabled text-textWhite  px-6 py-2 rounded-md sm:mt-0 sm:w-auto h-[40px] md:text-base text-xs"
            >
              Search
            </button>
            {userState.userType === "institute" && (
              <button onClick={handleNavigation} className="bg-primaryOrange hover:bg-primaryOrangeDisabled text-textWhite px-6 py-2 rounded-md sm:mt-0 sm:w-auto h-[40px] md:text-base text-xs">Upload </button>
            )}
            {userState.userType === "institute" && (
              <button onClick={fetchTimetableReport} className="bg-primaryOrange hover:bg-primaryOrangeDisabled text-textWhite px-6 py-2 rounded-md sm:mt-0 sm:w-auto h-[40px] md:text-base text-xs">Download Report</button>
            )}
          </div>
        </HeaderComponent>
      </div>
      <Table timetableData={currentRows} />
      <div className="flex justify-center mt-0">
        <Pagination
          count={Math.ceil(timetableData.length / rowsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
    </div>
  );
};

export default TimeTable;
