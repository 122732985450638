import React from "react";
import classes from "./TestCard.module.css";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuBar from "../../UI/MenuBar/MenuBar";
import { analaticsUrl } from "../../../Config";

const TestCards = ({
  title,
  publishFrom,
  publishTo,
  subject,
  chapters,
  TestMode,
  Rank,
  quizId,
  Actions,
  token,
  takeTest,
  analyticURL,
  attemptId,
}) => {
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);

  const analaticsDomain = analaticsUrl();

  return (
    <div className={classes.outerContainer}>
      {batchState?.batchUserType === "student" && (
        <div className={classes.infoContainer}>
          {takeTest === "1" && (
            <div
            // className={classes.takeButton}
            // onClick={() => {
            //   navigate("/startTest", { state: { quizId } });
            // }}
            >
              <MenuBar
                takeTest={true}
                navigateParam={"/startTest"}
                passedData={quizId}
              />
            </div>
          )}
          {takeTest === "3" && (
            <div
            // className={classes.takeButton}
            // onClick={() => {
            //   navigate("/ViewTestSubmission", { state: { quizId } });
            // }}
            >
              <MenuBar
                viewEvaluation={true}
                viewAnalysis={true}
                navigateParam={"ViewTestSubmission"}
                analysisURL={`${analaticsDomain + analyticURL}`}
                passedData={quizId}
                attemptId={attemptId}
              />
            </div>
          )}
          {takeTest === "4" && (
            // <div className={classes.awaitingButton}>
            <MenuBar awaitingEvaluation={true} />
            // </div>
          )}
        </div>
      )}
      {(batchState?.batchUserType === "teacher" ||
        batchState?.batchUserType === "institute") && (
        <div className={classes.infoContainer}>
          <div className={classes.takeButton}>
            <MenuBar
              viewDetails={true}
              navigateParam={"/teacher-test-view"}
              passedData={quizId}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default TestCards;
