import React, { useState, useEffect } from "react";
import ResourceFolders from "../../ResourceFolders/ResourceFolders";
import { getResources } from "../../../Store/API/Resouce";
import { FaList } from "react-icons/fa6";
import { IoGrid } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../UserInterface/Spinner/Spinner";
import classes from "./Resources.module.css";

const Resources = () => {
  const [folderId, setFolderId] = useState(0);
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [directory, setDirectory] = useState([]);
  const [isGrid, setIsGrid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const token = userState?.token;
  const group_id = batchState?.groupId;
  const group_owner_id = 4;
  const folder_user_id = 4;
  const user_id = userState?.userId;
  const type = "batch";
  const is_parent = 1;

  // useEffect(() => {
  //   const storedDirectory = JSON.parse(localStorage.getItem("curr_directory"));
  //   if (storedDirectory) {
  //     setDirectory(storedDirectory);
  //     setFolderId(storedDirectory[storedDirectory.length - 1]?.folder_id || 0);
  //     // setIsLoading(false);
  //   }
  // }, []);

  useEffect(() => {
    const get_resources = async () => {
      try {
        const { data } = await getResources(
          token,
          group_id,
          group_owner_id,
          folderId,
          folder_user_id,
          user_id,
          type,
          is_parent,
          dispatch
        );
        // console.log("Resources Data", data);
        setFolders(data.folders);
        setFiles(data.objects);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching resources:", error);
      }
    };

    get_resources();
    // eslint-disable-next-line
  }, [folderId]);

  const handleFolderClick = (clickedFolderId, folder_name) => {
    const folderExists = directory.some(
      (folder) => folder.folder_id === clickedFolderId
    );

    if (!folderExists) {
      const updatedDirectory = [
        ...directory,
        { folder_id: clickedFolderId, folder_name },
      ];
      setDirectory(updatedDirectory);
      localStorage.setItem("curr_directory", JSON.stringify(updatedDirectory));
    }

    setFolderId(clickedFolderId);
  };

  const handleDirectoryClick = (clickedFolderId) => {
    const index = directory.findIndex(
      (folder) => folder.folder_id === clickedFolderId
    );
    if (index !== -1) {
      const updatedDirectory = directory.slice(0, index + 1);
      setDirectory(updatedDirectory);
      localStorage.setItem("curr_directory", JSON.stringify(updatedDirectory));
      setFolderId(clickedFolderId);
    }

    if (clickedFolderId === 0) {
      setFolderId(clickedFolderId);
      setDirectory([]);
      localStorage.setItem("curr_directory", JSON.stringify([]));
    }
  };
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="flex h-screen ">
      <div className={classes.batchAssignments}>
        {folders.length === 0 && files.length === 0 && (
          <div className="flex justify-center items-center h-full">
            <h1 className="text-2xl text-[#F3A041]">No resources found</h1>
          </div>
        )}
        <ResourceFolders
          isGrid={isGrid}
          handleDirectoryClick={handleDirectoryClick}
          directory={directory}
          folders={folders}
          files={files}
          onFolderClick={handleFolderClick}
        />
        <div
          className={`toggle-list fixed top-20 right-6 md:right-28 flex gap-2 pt-3`}
        >
          {!isGrid ? (
            <IoGrid
              onClick={() => setIsGrid(true)}
              className="text-blue-800 cursor-pointer bg-gray-100"
              size={30}
            />
          ) : (
            <FaList
              onClick={() => setIsGrid(false)}
              className="text-blue-800 cursor-pointer bg-gray-100"
              size={30}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Resources;
