// import React from "react";
// import { useTimer } from "react-timer-hook";
// import classes from "../../TestScreen.module.css";
// import { useDispatch, useSelector } from "react-redux";
// import { submitQuiz } from "../../../Store/API/API";
// import useNetwork from "../../CustomHooks/useNetwork";

// const CustomTimer = ({
//   expiryTimestamp,
//   callFunction,
//   parentFunction,
//   quizId,
//   setAutoTestSubmitting,
// }) => {
//   const questionMendatory = useSelector((state) => state.answerData.mendatory);
//   const answerState = useSelector((state) => state.answerData.answer);
//   const userAttemptId = useSelector((state) => state.answerData.attemptId);
//   const userState = useSelector((state) => state.userData.userData);
//   const dispatch = useDispatch();

//   const networkState = useNetwork();
//   const { online } = networkState;

//   const { seconds, minutes, hours } = useTimer({
//     expiryTimestamp,
//     onExpire: async () => {
//       if (callFunction) {
//         if (online) {
//           if (userAttemptId) {
//             // eslint-disable-next-line
//             const data = await submitQuiz(
//               userState,
//               userAttemptId,
//               answerState,
//               questionMendatory,
//               quizId || userState?.testId,
//               dispatch
//             );

//             setAutoTestSubmitting(false);
//             // console.log(data, "data");
//           }
//         }

//         parentFunction();
//       }
//     },
//   });

//   return (
//     <div className={classes.time}>
//       <span className={classes.TimeLeft}>{hours}</span>:
//       <span className={classes.TimeLeft}>{minutes}</span>:
//       <span className={classes.TimeLeft}>{seconds}</span>
//     </div>
//   );
// };

// export default CustomTimer;

import React, { useEffect } from "react";
import { useTimer } from "react-timer-hook";
import classes from "../../TestScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import { submitQuiz } from "../../../Store/API/API";
import useNetwork from "../../CustomHooks/useNetwork";

const CustomTimer = ({
  expiryTimestamp,
  callFunction,
  parentFunction,
  quizId,
  setAutoTestSubmitting,
  onlineStatus,
}) => {
  const questionMendatory = useSelector((state) => state.answerData.mendatory);
  const answerState = useSelector((state) => state.answerData.answer);
  const userAttemptId = useSelector((state) => state.answerData.attemptId);
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  const networkState = useNetwork();
  const { online } = networkState;

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: async () => {
      if (callFunction) {
        if (online && userAttemptId) {
          await submitQuizData(); // Call API if all conditions are true immediately.
        } else if (callFunction && userAttemptId) {
          startPolling(); // Start polling if not online
        }
        parentFunction();
      } // Call parent
    },
  });

  // Function to call the submitQuiz API
  const submitQuizData = async () => {

    // Call the API
    const data = await submitQuiz(
      userState,
      userAttemptId,
      answerState,
      questionMendatory,
      quizId || userState?.testId,
      dispatch
    );
    setAutoTestSubmitting(false); // End auto-submission indication
    return data;
  };

  // Polling function to recheck network status every 10 seconds
  const startPolling = () => {
    const pollInterval = setInterval(async () => {
      console.log("Polling...");
      console.log("Online: ", onlineStatus);
      
      
      if (onlineStatus) {
        // clearInterval(pollInterval); // Clear interval once online
        console.log("Online, calling API...");
        await submitQuizData(); // Call the API once back online
      }
    }, 1000); // Check every 10 seconds
  };

  return (
    <div className={classes.time}>
      <span className={classes.TimeLeft}>{hours}</span>:
      <span className={classes.TimeLeft}>{minutes}</span>:
      <span className={classes.TimeLeft}>{seconds}</span>
    </div>
  );
};

export default CustomTimer;
