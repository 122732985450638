import { applicationActions } from "../Data/Slices/ApplicationSlice";
import { apiDomain, quizDomian } from "../../Config";
import axios from "axios";
// import { redirect } from "react-router-dom";
// // const domainQA = "https://app.innovartan.com/api/";
// const domainQuiz = "https://quiz.innovartan.com/api/";
// const qaDomainQuiz = "http://quiz.edvanz.com/api/";
// // const qaDomain = "http://qa.edvanz.com/api/";
// const qaDomain = "http://qa.edvanz.com/api/";
// const quizTest = "http://quiz.edvanz.com/api/";

const apiUrl = apiDomain();
const quizUrl = quizDomian();

const fetchWithTimeout = async (url, options = {}, dispatch) => {
  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(() => reject(new Error("Request Timeout")), 50000)
  );
  const fetchPromise = fetch(url, options);
  try {
    const response = await Promise.race([fetchPromise, timeoutPromise]);
    if (!response.ok) {
      throw new Error("Failed to fetch");
    }
    return response;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getSchoolCode = async (schoolCode, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/dashboard/get-school-by-code`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          school_code: schoolCode,
        }),
      },
      dispatch
    );
    const data = await response?.json();
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getUser = async (auth, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/get-user/${auth}`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getTestList = async (
  userId,
  groupId,
  token,
  dispatch,
  role,
  isDpp,
  tab = 1
) => {
  try {
    // console.log(apiDomain(), "apiDomain");

    const dpp = isDpp === "true" ? "dpp" : "test";

    // console.log("dpp", dpp);

    if (role === "student") {
      const response = await fetchWithTimeout(
        `${quizUrl}mobile/quiz/get-student-quiz/${userId}/${groupId}/web?mode=${dpp}&tab=${tab}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            TOKEN: token,
          },
        },
        dispatch
      );
      const data = await response.json();
      return data.data;
    } else if (role === "teacher" || role === "institute") {
      const response = await fetchWithTimeout(
        `${quizUrl}mobile/teacher-quiz/get-teacher-quiz/${userId}/${groupId}/batch?mode=${dpp}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            TOKEN: token,
          },
        },
        dispatch
      );
      const data = await response.json();
      return data.data;
    }
  } catch (e) {
    console.log("data", e);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return undefined;
  }
};

export const getQuizQuestion = async (token, quizID, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${quizUrl}mobile/quiz/get-test-questions/${quizID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      4000
    );
    const data = await response.json();
    // console.log(data);
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getQuizDetails = async (token, quizID, userId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${quizUrl}mobile/quiz/get-quiz-details/${userId}/${quizID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    // console.log(data);
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getQuizAttempt = async (userState, quizId, dispatch) => {
  // console.log(userState, "userState getQuizAttempt");
  try {
    const reponse = await fetchWithTimeout(
      `${quizUrl}mobile/quiz/get-quiz-attempt/${userState.userId}/${quizId}/${userState.groupId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
      },
      dispatch
    );
    const data = await reponse.json();
    // console.log(data);
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const submitQuiz = async (
  userState,
  attemptId,
  answerState,
  questionMendatory,
  quizId,
  dispatch
) => {
  // console.log(
  //   userState,
  //   attemptId,
  //   answerState,
  //   questionMendatory,
  //   "submitQuiz"
  // );
  let finalyArray = [];
  if (Object.keys(questionMendatory).length !== 0) {
    const tempAnswer = answerState.filter((answer) => answer.answer !== "");
    // console.log(tempAnswer, "tempAnswer before filter");
    // console.log(questionMendatory, "questionMendatory");
    const questionCount = {
      Biology: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
      Physics: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
      Chemistry: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
      Maths: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
    };
    tempAnswer.forEach((question) => {
      const key = `${question.subject}_${question.section}`;
      // console.log(key, "key");

      if (questionMendatory[question.subject] !== undefined) {
        const maxQuestions =
          questionMendatory[question.subject][
            `section_${question.section}_mandetory`
          ];

        // Initialize questionCount for the subject and section if not present
        if (!questionCount[key]) {
          questionCount[key] = 0;
        }

        // Check if adding this question exceeds the maximum allowed questions
        if (questionCount[key] < maxQuestions) {
          finalyArray.push({
            question_id: question.questionId,
            answer: question.answer,
            attempt_time: question.attempt_time,
            question_state: question.question_state,
            position: question.position,
            attachment: question.attachment,
          });
          questionCount[key]++; // Increment the question count for the subject and section
        }
      } else {
        // console.log(
        //   "Not mandetory",
        //   question.subject,
        //   `section_${question.section}_mandetory`
        // );
        finalyArray.push({
          question_id: question.questionId,
          answer: question.answer,
          attempt_time: question.attempt_time,
          question_state: question.question_state,
          position: question.position,
          attachment: question.attachment,
        });
      }
    });

    // console.log(finalyArray, "finalyArray");
  } else {
    finalyArray = answerState;
  }

  // console.log(finalyArray, "finalyArray------");

  const tempAnswer = [];
  if (answerState.length > 0) {
    finalyArray.forEach((answer) => {
      if (answer.answer === "True") {
        tempAnswer.push({
          question_id: answer.question_id || answer.questionId,
          answer: 1,
          attempt_time: answer.attempt_time,
          question_state: answer.question_state,
          position: answer.position,
          attachment: answer.attachment,
        });
      } else if (answer.answer === "False") {
        tempAnswer.push({
          question_id: answer.question_id || answer.questionId,
          answer: 2,
          attempt_time: answer.attempt_time,
          question_state: answer.question_state,
          position: answer.position,
          attachment: answer.attachment,
        });
      } else if (typeof answer.answer === "object") {
        tempAnswer.push({
          question_id: answer.question_id || answer.questionId,
          answer: answer.answer.join(","),
          attempt_time: answer.attempt_time,
          question_state: answer.question_state,
          position: answer.position,
          attachment: answer.attachment,
        });
      } else {
        tempAnswer.push({
          question_id: answer.question_id || answer.questionId,
          answer: answer.answer,
          attempt_time: answer.attempt_time,
          question_state: answer.question_state,
          position: answer.position,
          attachment: answer.attachment,
        });
      }
    });
  }
  if (tempAnswer.length === finalyArray.length) {
    const formData = new FormData();
    formData.append("student_id", userState.userId);
    formData.append("attempt_id", attemptId);
    formData.append("quiz_id", quizId);
    formData.append("data", JSON.stringify(tempAnswer));
    console.log(tempAnswer, "tempAnswer submitQuiz");
    try {
      const response = await fetchWithTimeout(
        `${quizUrl}mobile/quiz/save-student-quiz`,
        {
          method: "POST",
          headers: {
            TOKEN: userState.token,
          },
          body: formData,
        },
        dispatch
      );
      const data = await response.json();
      console.log(data, "submiteQuiz");

      return data.statuscode;
    } catch (e) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Internal Server Error",
          alertType: "error",
        })
      );
      setTimeout(() => {
        dispatch(
          applicationActions.setAlertData({
            isAlert: false,
            alertMessage: "",
            alertType: "",
          })
        );
      }, 3000);
      return 400;
    }
  }
};

export const getQuizSubmission = async (token, userId, testID, dispatch) => {
  console.log(
    "calling api",
    `${quizUrl}mobile/quiz/view-student-submition/${userId}/${testID}`
  );

  const response = await fetchWithTimeout(
    `${quizUrl}mobile/quiz/view-student-submition/${userId}/${testID}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        TOKEN: token,
      },
    },
    dispatch
  );

  const data = await response.json();
  return data.data;
};

export const loginEmail = async (authToken, dispatch) => {
  try {
    // console.log("calling api", `${apiUrl}mobile/user/login`);

    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authToken}`,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const userLogout = async (authToken, dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}mobile/user/logout`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: authToken,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const dashboardTest = async (userId, dispatch, token) => {
  try {
    // console.log("userId", userId);

    // console.log(
    //   "calling api",
    //   `${quizUrl}mobile/quiz/get-dashboard-test/${userId}/web`
    // );

    const response = await fetch(
      `${quizUrl}mobile/quiz/get-dashboard-test/${userId}/web`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    // console.log(data, "data");

    return data.data;
  } catch (e) {
    console.log("data", e);

    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const dashboardCourse = async (userId, requestType, token) => {
  try {
    const response = await fetch(
      `${apiUrl}mobile/dashboard/get-dashboard-shop-course`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify({
          user_id: userId,
          page_no: "1",
          request_type: requestType,
        }),
      }
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    // dispatch(
    //   applicationActions.setAlertData({
    //     isAlert: true,
    //             alertMessage:"Internal Server Error",
    //     alertType: "error",
    //   })
    // );
    // setTimeout(() => {
    //   dispatch(
    //     applicationActions.setAlertData({
    //       isAlert: false,
    //       alertMessage: "",
    //       alertType: "",
    //     })
    //   );
    // }, 3000);
  }
};

export const dashboardVedio = async (userId, token, page_no) => {
  try {
    const response = await fetch(
      `${apiUrl}mobile/dashboard/get-dashboard-videos`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify({
          user_id: userId,
          page_no: page_no,
        }),
      }
    );

    const data = await response.json();
    // console.log(data, "data");

    return data.data;
  } catch (e) {
    // dispatch(
    //   applicationActions.setAlertData({
    //     isAlert: true,
    //             alertMessage:"Internal Server Error",
    //     alertType: "error",
    //   })
    // );
    // setTimeout(() => {
    //   dispatch(
    //     applicationActions.setAlertData({
    //       isAlert: false,
    //       alertMessage: "",
    //       alertType: "",
    //     })
    //   );
    // }, 3000);
  }
};

export const dashboardSession = async (userId, token, dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}mobile/dashboard/get-dashboard-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify({
          user_id: userId,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getUserProfile = async (token, userId, dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}mobile/user/get-profile/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    console.log("data: ", data);
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const updateProfile = async (authToken, formData, dispatch) => {
  // try {
  //   const response = await fetch(
  //     `${apiUrl}mobile/user/update-profile`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         TOKEN: authToken,
  //       },
  //       body: JSON.stringify(formData),
  //     },
  //     dispatch
  //   );
  //   const data = await response.json();
  //   return data.data;
  try {
    let response = await axios.post(
      `${apiUrl}mobile/user/update-profile`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          TOKEN: authToken,
        },
      }
    );
    // console.log("data:", response.data);

    return response.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const changePassword = async (authToken, formData, dispatch) => {
  try {
    await fetch(
      `${apiUrl}mobile/user/change-user-password`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: authToken,
        },
        body: JSON.stringify(formData),
      },
      dispatch
    );
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getSessionJoinningUrl = async (
  token,
  userId,
  sessionId,
  groupId,
  dispatch
) => {
  // console.log(token, userId, sessionId, groupId);
  // console.log(
  //   `${apiUrl}mobile/user/get-session-url/${groupId}/${sessionId}/${userId}`
  // );
  try {
    const response = await fetch(
      `${apiUrl}mobile/user/get-session-url/${groupId}/${sessionId}/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    // console.log(data);
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getBtaches = async (
  token,
  userId,
  userType,
  institute,
  year,
  dispatch
) => {
  let callingUrl = "";
  if (userType === "institute") {
    callingUrl = `${apiUrl}mobile/user/get-user-batches/${userId}/${
      institute ? institute : 4
    }?session=${year}`;
  } else {
    callingUrl = `${apiUrl}mobile/user/get-user-batches/${userId}`;
  }
  // console.log(callingUrl, "callingUrl");

  try {
    const response = await fetch(
      callingUrl,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    // console.log(data, "data");
    
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getGroupSession = async (
  userState,
  groupId,
  state,
  isOnline,
  dispatch
) => {
  // console.log(userState,groupId, state, "getGroupSession");
  try {
    // console.log(
    //   "calling api",
    //   `${apiUrl}mobile/user/view-session/${userState.userId}/${groupId}/${
    //     state === "cancel" ? "cancelled" : state
    //   }`
    // );

    const reponse = await fetchWithTimeout(
      `${apiUrl}mobile/user/view-session/${userState.userId}/${groupId}/${
        state === "cancel" ? "cancelled" : state
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
      },
      dispatch
    );
    const data = await reponse.json();
    // console.log(data);
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getchapterOfGroup = async (
  userState,
  groupId,
  subject,
  dispatch
) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/get-group-chapters`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
        body: JSON.stringify({
          uid: userState.userId,
          group_id: groupId,
          subject: subject,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const addFeedback = async (
  userState,
  postData,
  batchState,
  dispatch
) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/save-feedback`,
      {
        method: "POST",
        headers: {
          TOKEN: userState.token,
        },
        body: JSON.stringify({
          user_id: userState.userId,
          group_id: batchState.groupId,
          data: postData,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getPayments = async (token, userId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/get-student-payments/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const updateVideoViewTime = async (videoData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/add-video-view-time`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(videoData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getVedioViewTime = async (videoData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-video-view-time`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(videoData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getMyCourses = async (
  userId,
  token,
  userType,
  institute,
  dispatch
) => {
  let callingUrl = "";
  if (userType === "institute") {
    callingUrl = `${apiUrl}mobile/shop/get-teacher-shop/${userId}/${institute}`;
  } else if (userType === "teacher") {
    callingUrl = `${apiUrl}mobile/shop/get-teacher-shop/${userId}`;
  } else {
    callingUrl = `${apiUrl}mobile/shop/get-student-shop/${userId}`;
  }

  console.log(callingUrl, "callingUrl");

  try {
    const response = await fetchWithTimeout(
      callingUrl,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getCourseDetails = async (courseId, userId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-course-details/${courseId}/${userId}`,
      {
        method: "GET",
        headers:{
          "Content-Type": "application/json",
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getCourseContent = async (
  courseName,
  userId,
  chapterId,
  dispatch
) => {
  // console.log(`${apiUrl}mobile/shop/get-course-content/${courseName}/${userId}`);

  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-course-content/${courseName}/${userId}/${
        chapterId ? chapterId : 0
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getRecordingTime = async (videoData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/get-recording-time`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(videoData),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const saveRecordingTime = async (videoData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/save-recording-time`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(videoData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getNotifications = async (userId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/notification-list/${userId}/1`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getTestQuestions = async (token, testId) => {
  try {
    const request = await fetch(
      `${quizUrl}mobile/quiz/download-questions/${testId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      }
    );
    const data = await request.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const addCourse = async (token, courseData, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/add-course`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(courseData),
      },
      dispatch
    );
    const responseData = await response.json();

    return responseData.data;
  } catch (e) {
    console.log(e);
  }
};

export const addCoursePrice = async (token, courseData, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/add-course-price`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(courseData),
      },
      dispatch
    );
    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
};

export const getAllInstitute = async (dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/get-all-institute`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getAllEvents = async (dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/get-event-list`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getInstituteBatches = async (instituteId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/get-institute-batches/${instituteId}`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getGroupChapter = async (groupId, subject, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/get-group-chapters`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          group_id: groupId,
          subject: subject,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getFeedbackAnalysis = async (formData, dispatch) => {
  console.log(formData, "formData");
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/feedback-analysis`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const teacherTestDetail = async (testId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${quizUrl}mobile/teacher-quiz/view-teacher-test-details/${testId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getTestSubmissions = async (
  userId,
  batchId,
  testId,
  toke,
  dispatch
) => {
  try {
    // console.log(
    //   "quizUrl",
    //   `${quizUrl}mobile/teacher-quiz/get-test-submissions/${userId}/${batchId}/${testId}`
    // );

    const response = await fetchWithTimeout(
      `${quizUrl}mobile/teacher-quiz/get-test-submissions/${userId}/${batchId}/${testId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: toke,
        },
      },
      dispatch
    );
    const data = await response.json();
    // console.log(data, "getTestSubmissions");

    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getAttendanceMonth = async (batchId, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/get-attendance-months/${batchId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getStudentAttendance = async (attendanceData, token, dispatch) => {
  try {
    // console.log(
    //   attendanceData,
    //   token,
    //   `${apiUrl}mobile/group/student-attendance`
    // );

    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/student-attendance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(attendanceData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getClasses = async (dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/get-classes`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSubjects = async (className, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/get-subject-of-class`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          class: className,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getChapters = async (className, subject, dispatch) => {
  // console.log("fetching chapters", className, subject);

  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/get-chapters-of-subject`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          class: className,
          subject: subject,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTopics = async (className, subject, chapter, dispatch) => {
  try {
    // console.log("fetching topics", className, subject, chapter);
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/get-topics-of-chapter`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          class: className,
          subject: subject,
          chapter: chapter,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};


export const getContentTopic = async (courseId, contentId, type, chapterId,token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-content-topics`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token
        },
        body: JSON.stringify({
          course_id: courseId,
          content_id: contentId,
          type: "resource",
          chapter_id: chapterId,
        }),
      },
      dispatch
    );

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDppContentTopic = async (courseId, contentId, type, chapterId,token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-content-topics`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token
        },
        body: JSON.stringify({
          course_id: courseId,
          content_id: contentId,
          type: "tests",
          chapter_id: chapterId,
        }),
      },
      dispatch
    );

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};


export const getCurriculum = async (dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/get-curriculum`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const createSession = async (sessionData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/create-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(sessionData),
      },
      dispatch
    );
    const data = await response.json();
    // console.log(data, "createSession");

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const cancelSession = async (sessionData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/cancel-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(sessionData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return 500;
  }
};

export const uploadSessionItem = async (sessionData, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/upload-session-items`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sessionData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return 500;
  }
};

export const getSessionTopics = async (sessionId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/get-session-topics/${sessionId}`,
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const updateSessionTopics = async (sesssionData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/update-session-topics`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(sesssionData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const updateContentTopic = async (payload, token, dispatch) => {
  const { course_id, content_id, type="resource", topics } = payload;
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/update-content-topics`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify({
          course_id,
          content_id,
          type,
          topics: topics,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
}
};

export const updateDppContentTopic = async (payload, token, dispatch) => {
  const { course_id, content_id, type="tests", topics } = payload;
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/update-content-topics`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify({
          course_id,
          content_id,
          type,
          topics: topics,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
}
};

export const getBatchMembers = async (batchId, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/get-group-members/${batchId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const verifyFile = async (userState, file, dispatch) => {
  // console.log(userState, file);

  const formData = new FormData();
  formData.append("uploadFile", file);
  formData.append("user_id", userState.instituteId);
  formData.append("mode", "verify");
  // console.log("formData", formData);

  try {
    let response = await axios.post(
      `${apiUrl}mobile/session/upload-timetable`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          TOKEN: userState.token,
        },
      }
    );
    console.log("data:", response.data);

    return response.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const uploadFile = async (userState, file, dispatch) => {
  const formData = new FormData();
  formData.append("uploadFile", file);
  formData.append("user_id", userState.instituteId);
  formData.append("mode", "upload");

  try {
    let response = await axios.post(
      `${apiUrl}mobile/session/upload-timetable`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          TOKEN: userState.token,
        },
      }
    );

    return response.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const fetchTimetable = async (userState, fromDate, toDate, dispatch) => {
  const requestBody = {
    from_date: fromDate,
    to_date: toDate,
    user_id: userState.userId,
  };

  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/view-timetable`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
        body: JSON.stringify(requestBody),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const fetchLoginUser = async (userState, action, dispatch, id) => {
  const requestBody = {
    institute_id: id,
    user_id: userState.userId,
    action: action,
  };

  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/school-analysis`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
        body: JSON.stringify(requestBody),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const fetchLogAnalysis = async (
  userState,
  fromDate,
  toDate,
  action,
  dispatch,
  type
) => {
  const requestBody = {
    from: fromDate,
    to: toDate,
    user_id: userState.userId,
    institute_id: userState.instituteId,
    entity_source: userState.entitySource,
    action: action.value,
    user_type: type.value,
  };

  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/log-analysis`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
        body: JSON.stringify(requestBody),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const getLibrary = async (userState, dispatch) => {
  const requestBody = {
    user_id: userState.userId,
    type: "study_notes",
  };

  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-library`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
        body: JSON.stringify(requestBody),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const getDppModule = async (userState, dispatch) => {
  const requestBody = {
    user_id: userState.userId,
    type: "tests",
  };

  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-library`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
        body: JSON.stringify(requestBody),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const getVideoLibrary = async (userState, dispatch) => {
  const requestBody = {
    user_id: userState.userId,
    type: "video_course",
  };

  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-library`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
        body: JSON.stringify(requestBody),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const getDayWiseSession = async (attendanceData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/get-day-wise-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(attendanceData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const getTutorAttendance = async (attendanceData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/get-tutor-attendance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(attendanceData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const markBatchAttendance = async (attendanceData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/mark-batch-attendance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(attendanceData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const markStudentAttendances = async (
  attendanceData,
  token,
  dispatch
) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/mark-student-attendance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(attendanceData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const getSessionDetails = async (sessionId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/get-session-details/${sessionId}`,
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const getQuestionlibraryQuestions = async (apiData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/get-questions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(apiData),
      },
      dispatch
    );
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log(error);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return null;
  }
};

export const getQuestionDetails = async (apiData, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/get-question-details`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiData),
      },
      dispatch
    );
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log(error);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    return null;
  }
};

export const getQuestionBank = async (dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/get-question-bank/3`,
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    return null;
  }
};

export const uploadQuestionBank = async (
  token,
  uploadFile,
  questionBankId,
  dispatch
) => {
  try {
    // console.log(uploadFile, questionBankId, "uploadFile, questionBankId");

    const formData = new FormData();
    formData.append("uploadFile", uploadFile);
    formData.append("question_bank_id", questionBankId);
    formData.append("user_id", 4);

    let response = await axios.post(
      `${apiUrl}mobile/question-bank/upload-question-bank`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          TOKEN: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
  }
};

export const getQuestionBankDetails = async (
  questionBankId,
  token,
  dispatch
) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/get-question-bank-details/4/${questionBankId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
  }
};

export const deleteQuestionBankQuestion = async (
  token,
  questionId,
  questionBankId,
  dispatch
) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/delete-question/${questionId}/${questionBankId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    // dispatch(
    //   applicationActions.setAlertData({
    //     isAlert: true,
    //     alertMessage: "Internal Server Error",
    //     alertType: "error",
    //   })
    // );
  }
};

export const getQuestionDetailsQuestionBank = async (
  questionId,
  userId,
  dispatch
) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/get-question-details`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question_id: questionId,
          user_id: userId,
        }),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateTagQuestion = async (tagData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/tag-question`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(tagData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
  }
};

export const getContentAssignee = async (contentData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-content-assignee`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(contentData),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const saveContentAssignee = async (contentData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/save-content-assignee`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(contentData),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getPendingSessions = async (userId, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/get-pending-curricular/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePendingCurricular = async (sessionData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/update-pending-curricular`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(sessionData),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createQuestionBank = async (questionBankData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/create-question-bank`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(questionBankData),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getQuestionBankData = async (questionBankId, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/question-bank/get-question-bank-data/${questionBankId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAnalytics = async (apiData, dispatch) => {
  try {
    // console.log(apiData, "apiData");

    const response = await fetchWithTimeout(
      `${quizUrl}mobile/quiz/get-analytics`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiData),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getDPPSubmission = async (apiData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${quizUrl}mobile/teacher-quiz/view-dpp-submissions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(apiData),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getCourseList = async (token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/get-course-list/4`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createBatch = async (batchData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/create-batch`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(batchData),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }

}

export const assignGroupMembers = async (groupData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/assign-group-members`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(groupData),
      },
      dispatch
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}
