import React, { useEffect, useState } from "react";
import OverallAnalysis from "./OverallAnalysis/OverallAnalysis";
import MyPerformance from "./MyPerformance/MyPerformance";
import { getAnalytics } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../UserInterface/Spinner/Spinner";

const Analysis = () => {
  const [activeSection, setActiveSection] = useState("Overall Analysis");
  const [pieChartActiveSession, setPieChartActiveSession] =
    useState("Difficulty");
  const [leaderBoardActiveSession, setLeaderBoardActiveSession] =
    useState("Top Rankers");

  const [hasShadow, setHasShadow] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const batchData = useSelector((state) => state.applicationData.batchData);

  const location = useLocation();
  const navigate = useNavigate();

  const { quizId, attemptId, student_id, groupId } = location.state || "";

  const fetchAnalytics = async () => {
    // console.log("Fetching Analytics");
    // console.log(batchData, "batchData");

    const tempData = {
      quiz_id: quizId,
      group_id: groupId || batchData.groupId,
      student_id: student_id || userState.userId,
      attempt_id: attemptId,
    };

    const data = await getAnalytics(tempData, dispatch);

    if (data.data) {
      setAnalyticsData(data.data);
    }

    setIsLoading(false);
    // console.log(data, "data");
  };

  // Add shadow to navbar on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setHasShadow(true);
      } else {
        setHasShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!quizId || !attemptId) {
      navigate("/");
      return;
    }

    fetchAnalytics();

    // eslint-disable-next-line
  }, [userState]);

  if (isLoading) {
    return <Spinner />;
  }

  const SectionButton = ({ title, selected, onClick }) => {
    return (
      <div
        className={`flex justify-center px-3 py-2 border border-[#E7EDF6] 
        rounded-2xl shadow-[2px_4px_60px_0px_rgba(7,50,120,0.1)]
        cursor-pointer select-none ${
          selected ? "bg-[#0743A3] text-white" : "bg-white text-black"
        }`}
        onClick={onClick}
      >
        <p className="md:text-base text-sm font-extralight cursor-pointer">
          {title}
        </p>
      </div>
    );
  };

  return (
    <div className="mt-[60px] w-full h-full flex flex-col relative">
      <div
        className={`flex justify-center w-full gap-3 lg:p-6 md:p-6 p-4 fixed top-15 left-0 right-0 bg-white z-50 ${
          hasShadow ? "shadow-md" : ""
        } `}
      >
        <SectionButton
          title="Overall Analysis"
          selected={activeSection === "Overall Analysis"}
          onClick={() => setActiveSection("Overall Analysis")}
        />
        <SectionButton
          title="My Performance"
          selected={activeSection === "My Performance"}
          onClick={() => setActiveSection("My Performance")}
        />
      </div>

      <div className="mt-24">
        {activeSection === "Overall Analysis" ? (
          <OverallAnalysis
            pieChartActiveSession={pieChartActiveSession}
            setPieChartActiveSession={setPieChartActiveSession}
            leaderBoardActiveSession={leaderBoardActiveSession}
            setLeaderBoardActiveSession={setLeaderBoardActiveSession}
            analyticsData={analyticsData}
            quizId={quizId}
            attemptStudentId={student_id}
          />
        ) : (
          <MyPerformance />
        )}
      </div>
    </div>
  );
};

export default Analysis;
