
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import classes from "./Dpp.module.css";
import { getDppModule} from "../../../Store/API/API"; 
import { useNavigate } from "react-router-dom";
import Spinner from "../../UserInterface/Spinner/Spinner";

const SectionCard = ({ data, isCourse }) => {
    const navigate = useNavigate();
  return (
    <div
      className={classes.sectionCard}
      onClick={() => {
        if (isCourse) {
          navigate("/course-details", {
            state: { course_id: data.course_id },
          });
        }
      }}
      style={{ cursor: isCourse ? "pointer" : "default" }}
    >
      <img
        alt="thumbnail"
        src={data?.cover_photo_url || "/images/sessionLogo.png"}
        className={classes.cardImage}
      />
      <div className={classes.contentContainer}>
        <p className={classes.cardHeading}>{data?.name}</p>
        <p>{data?.subject}</p>
        {/* <p>{data?.course_format}</p> */}
        <p className={classes.cardSubHeading}>By: Innovartan</p>
</div>

    </div>
  );
};

const  DppModule = () => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const [dashboardCourses, setDashboardCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await getDppModule(userState, dispatch);
      if (response && response.status) {
        setDashboardCourses(response.data);
      }
      setIsLoading(false);
    };
    
    fetchCourses();
  }, [userState, dispatch]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={classes.courseDashboard}>
    
        <div className={classes.section}>
          {/* <div className={classes.sectionOuter}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ImportContactsIcon />
              <span style={{ marginLeft: "10px" }}>DPP Module</span>
            </div>
          </div> */}
           <div className="sticky top-0 bg-white z-10">
        <h2 className="text-[20px] md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl font-bold m-3 mb-0">
         DPP Module
        </h2>
      </div>
          {/* <div className={classes.horizontalLine}/> */}
          {dashboardCourses.length > 0 ? (
          <div className={classes.sectionCardContainer}>
            {dashboardCourses.map((course, index) => (
              <SectionCard key={index} data={course} isCourse={true} />
            ))}
          </div>
           ) : (
            <p>No courses available.</p>
          )}
        </div>
    </div>
  );
};

export default DppModule;
