import React from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuBar from "../../UI/MenuBar/MenuBar";
import { analaticsUrl } from "../../../Config";

const TableCardButtons = ({ quizId, groupId, attemptId, studentId }) => {
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);

  const analaticsDomain = analaticsUrl();

  return (
    <div>
      <div>
        <MenuBar
          viewEvaluation={true}
          viewAnalysis={true}
          navigateParam={"viewAnalysis"}
          passedData={quizId}
          attemptId={attemptId}
          groupId={groupId}
          studentId={studentId}
        />
      </div>
    </div>
  );
};
export default TableCardButtons;
