// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LiveSession_container__YinK0 {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f5f5f5;
  margin-top: 60px;
  padding: 20px;
  height: 92vh;
}

@media (max-width: 768px) {
  .LiveSession_container__YinK0 {
    padding: 15px;
  }
}

@media (max-width: 500px) {
  .LiveSession_container__YinK0 {
    margin-top: 60px;
    padding: 10px;
  }
}

.LiveSession_iframe__JzOfg {
  width: 100%;
  height: 85vh;
  border: none;
}

@media (max-width: 768px) {
  .LiveSession_iframe__JzOfg {
    height: 85vh;
  }
}

@media (max-width: 500px) {
  .LiveSession_iframe__JzOfg {
    height: 90vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Session/LiveSession.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EAEtB,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,aAAa;EACf;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  width: 100%;\n  background-color: #f5f5f5;\n  margin-top: 60px;\n  padding: 20px;\n  height: 92vh;\n}\n\n@media (max-width: 768px) {\n  .container {\n    padding: 15px;\n  }\n}\n\n@media (max-width: 500px) {\n  .container {\n    margin-top: 60px;\n    padding: 10px;\n  }\n}\n\n.iframe {\n  width: 100%;\n  height: 85vh;\n  border: none;\n}\n\n@media (max-width: 768px) {\n  .iframe {\n    height: 85vh;\n  }\n}\n\n@media (max-width: 500px) {\n  .iframe {\n    height: 90vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LiveSession_container__YinK0`,
	"iframe": `LiveSession_iframe__JzOfg`
};
export default ___CSS_LOADER_EXPORT___;
