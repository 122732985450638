// import React, { useEffect } from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import TestList from "./Components/Pages/TestList/TestList";
// import NoPage from "./Components/Pages/NoPage/NoPage";
// import { getUser } from "./Store/API/API";
// import { useDispatch, useSelector } from "react-redux";
// import { userDataActions } from "./Store/Data/Slices/UserDataSlice";
// import TestSubmision from "./Components/Pages/TestSubmision/TestSubmision";
// import ViewTestSubmission from "./Components/Pages/ViewTestSubmission/ViewTestSubmission";
// import Login from "./Components/Pages/Login/Login";
// import Dashboard from "./Components/Pages/Dashboard/Dashboard";
// import Post from "./Components/Pages/Post/Post";
// import BatchAssignments from "./Components/Pages/Assignment/BatchAssignments";
// import AssignmentDetails from "./Components/Pages/Assignment/AssignmentDetails";
// import AssignmentSubmit from "./Components/Pages/Assignment/AssignmentSubmit";
// import Profile from "./Components/Pages/Profile/Profile";
// import ChangePassword from "./Components/Pages/ChangePassword/ChangePassword";
// import UpcommingSession from "./Components/Pages/Session/Upcomming";
// import PastSession from "./Components/Pages/Session/Past";
// import CancelSession from "./Components/Pages/Session/Cancel";
// import QuestionBank from "./Components/Pages/QuestionBank/QuestionBank";
// import Report from "./Components/Pages/Report/Report";
// import Resources from "./Components/Pages/Resources/Resources";
// import Feedback from "./Components/Pages/Feedback/Feedback";
// import ViewFeedback from "./Components/Pages/Feedback/ViewFeedback";
// import Batch from "./Components/Pages/Batch/Batch";
// import Payment from "./Components/Pages/Payment/Payment";
// // import Cookies from "universal-cookie";
// import MiniDrawer from "./Components/Pages/Navbar/Drawer";
// import TaxInvoice from "./Components/Pages/TaxInvoice/TaxInvoice";
// import { applicationActions } from "./Store/Data/Slices/ApplicationSlice";
// // import TestScreen1 from "./Components/Pages/Test/TestScreen1";
// import App from "./App";
// import Courses from "./Components/Pages/Courses/Course";
// import CourseDetails from "./Components/Pages/Courses/courseDetails";
// import CourseContent from "./Components/Pages/Courses/CourseContent";
// import TeacherTestView from "./Components/Pages/TestDetails/TeacherTestView/TeacherTestView";
// import InnovartanAI from "./Components/Pages/InnovartanAI/InnovartanAI";
// import TeacherSubmissionView from "./Components/Pages/TestDetails/TeacherSubmissionView/TeacherSubmissionView";
// import AddTest from "./Components/Pages/TestDetails/AddTest/AddTest";
// import AddCourse from "./Components/Pages/Courses/AddCourse";
// import QuestionPreview from "./Components/Pages/QuestionPreview/QuestionPreview";
// import ReadPDF from "./Components/Pages/ReadPDF/ReadPDF";
// import PrivacyPolicy from "./Components/Pages/PrivacyPolicy/PrivacyPolicy";
// import TermOfUse from "./Components/Pages/TermsCondition/TermOfUse";
// import StudentAttendance from "./Components/Pages/Attendance/StudentAttendance/StudentAttendance";
// import LiveSession from "./Components/Pages/Session/LiveSession";
// import Login2 from "./Components/Pages/Login/Login2.0/Login2";
// import Upcomming2 from "./Components/Pages/Session/CommonSession";
// import StudentResultPDF from "./Components/Pages/Dashboard/StudentResultPDF/StudentResultPDF";
// import MiniDrawer2 from "./Components/Pages/Navbar/Drawer2";
// import Members from "./Components/Pages/Members/Members";
// import TeacherAttendance from "./Components/Pages/Attendance/TeacherAttendance/TeacherAttendance";
// import StudentAttendance2 from "./Components/Pages/Attendance/StudentAttendance2.0/StudentAttendance2";
// import PDFView from "./Components/ResourceFolders/PDFView";
// import ViewQuestionBank from "./Components/Pages/QuestionBank/ViewQuestionBank/ViewQuestionBank";
// import ViewAnalysis from "./Components/Pages/ViewAnalysis/ViewAnalysis";
// import TimeTable from "./Components/Pages/TimeTable/TimeTable";
// import EditorView from "./Components/Pages/EditorView";
// import UploadTimeTable from "./Components/Pages/TimeTable/UploadTimeTable";
// import  Navbar2  from "./Components/Pages/Navbar/Navbar2";
// import { Footer2 } from "./Components/Pages/Footer/Footer2";
// import Sidebar2 from "./Components/Pages/Navbar/Sidebar2";

// const RootPage = () => {
//   const dispatch = useDispatch();
//   const userState = useSelector((state) => state.userData.userData);
//   // const cookies = new Cookies();

//   const fetchAuthUser = async (auth) => {
//     // console.log("Fetching Auth User", auth);
//     const data = await getUser(auth, dispatch);
//     let tempData = {
//       token: data?.token,
//       userId: data?.user_id,
//       testId: data?.test_id,
//       groupId: data?.test_group_id,
//       firstName: data?.first_name,
//       lastName: data?.last_name,
//     };
//     dispatch(userDataActions.setUserData(tempData));
//     // console.log("User Data Using Auth ", tempData);
//   };

//   useEffect(() => {
//     const params = window.location.search;
//     const auth = new URLSearchParams(params).get("auth");

//     if (auth) {
//       fetchAuthUser(auth);
//     } else {
//       // console.log("No auth token, Bridge Implimenting...");
//       // fetchAuthUser("WZpfrnveovCvsGeWX2OYkIOU");
//     }
//     // eslint-disable-next-line
//   }, []);

//   useEffect(() => {
//     // const cookieData = cookies.get("userData");
//     const cookieData = JSON.parse(localStorage.getItem("userData"));
//     const batchData = JSON.parse(localStorage.getItem("batchData"));
//     const schoolData = JSON.parse(localStorage.getItem("schoolData"));
//     // console.log("Cookie Data", cookieData);
//     dispatch(userDataActions.setUserData(cookieData));
//     dispatch(applicationActions.setBatchData(batchData));
//     dispatch(applicationActions.setSchoolData(schoolData));
//     // eslint-disable-next-line
//   }, []);

//   if (!userState?.token) {
//     // console.log("user not found", userState);
//     return (
//       <BrowserRouter>
//         <Routes>
//           {/* <Route path="/" element={<Login />} /> */}
//           <Route path="/" element={<Login2 />} />
//         </Routes>
//       </BrowserRouter>
//     );
//   }

//   return (
//     <BrowserRouter>
//       <Routes>
//         {userState?.hideDashboard ? (
//           <Route
//             path="/"
//             element={
//               <MiniDrawer2>
//                 <StudentResultPDF />
//               </MiniDrawer2>
//             }
//           />
//         ) : (
//           <Route
//             path="/"
//             element={
//               <MiniDrawer>
//                 <Dashboard />
//               </MiniDrawer>
//             }
//           />
//         )}
//         <Route
//           path="/courses"
//           element={
//             <MiniDrawer>
//               <Courses />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/course-details"
//           element={
//             <MiniDrawer>
//               <CourseDetails />
//             </MiniDrawer>
//           }
//         />
//          <Route
//           path="/new"
//           element={
//             <Navbar2/>
//           }
//         />
//         <Route
//           path="/new1"
//           element={
//             <Footer2/>
//           }
//         />
//         <Route
//           path="/new2"
//           element={
//             <Sidebar2/>
//           }
//         />
//         <Route
//           path="/course-content"
//           element={
//             <MiniDrawer>
//               <CourseContent />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/testList"
//           element={
//             <MiniDrawer>
//               <TestList />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/teacher-test-view"
//           element={
//             <MiniDrawer>
//               <TeacherTestView />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/teacher-submission-view"
//           element={
//             <MiniDrawer>
//               <TeacherSubmissionView />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/view-analysis"
//           element={
//             <MiniDrawer>
//               <ViewAnalysis />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/assignment"
//           element={
//             <MiniDrawer>
//               <BatchAssignments />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/assignment/details"
//           element={
//             <MiniDrawer>
//               <AssignmentDetails />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/assignment/submittions"
//           element={
//             <MiniDrawer>
//               <AssignmentSubmit />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/members"
//           element={
//             <MiniDrawer>
//               <Members />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/post"
//           element={
//             <MiniDrawer>
//               <Post />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/resources"
//           element={
//             <MiniDrawer>
//               <Resources />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/profile"
//           element={
//             userState?.hideDashboard ? (
//               <MiniDrawer2>
//                 <Profile />
//               </MiniDrawer2>
//             ) : (
//               <MiniDrawer>
//                 <Profile />
//               </MiniDrawer>
//             )
//           }
//         />

//         <Route
//           path="/change-password"
//           element={
//             <MiniDrawer>
//               <ChangePassword />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/upcomming-session"
//           element={
//             <MiniDrawer>
//               {/* <UpcommingSession /> */}
//               <Upcomming2 />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/past-session"
//           element={
//             <MiniDrawer>
//               <PastSession />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/live-session"
//           element={
//             <MiniDrawer>
//               <LiveSession />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/feedback"
//           element={
//             <MiniDrawer>
//               <Feedback />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/ViewFeedback"
//           element={
//             <MiniDrawer>
//               <ViewFeedback />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/report"
//           element={
//             <MiniDrawer>
//               <Report />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/AddTest"
//           element={
//             <MiniDrawer>
//               <AddTest />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/AddCourse"
//           element={
//             <MiniDrawer>
//               <AddCourse />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/cancel-session"
//           element={
//             <MiniDrawer>
//               <CancelSession />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/question-library"
//           element={
//             <MiniDrawer>
//               <QuestionBank />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/question-library/view"
//           element={
//             <MiniDrawer>
//               <ViewQuestionBank />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/resources"
//           element={
//             <MiniDrawer>
//               <Resources />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/batch"
//           element={
//             <MiniDrawer>
//               <Batch />
//             </MiniDrawer>
//           }
//         />

//         <Route
//           path="/time-table"
//           element={
//             <MiniDrawer>
//               <TimeTable />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/upload-time-table"
//           element={
//             <MiniDrawer>
//               <UploadTimeTable />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/payment"
//           element={
//             <MiniDrawer>
//               <Payment />
//             </MiniDrawer>
//           }
//         />
//         <Route path="/startTest" element={<App />} />
//         <Route path="/test/retest/submission" element={<TestSubmision />} />
//         <Route
//           path="/ViewTestSubmission"
//           element={
//             <MiniDrawer>
//               <ViewTestSubmission />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/ViewTestSubmission/mobile"
//           element={<ViewTestSubmission />}
//         />
//         <Route
//           path="/privacy-policy"
//           element={
//             <MiniDrawer>
//               <PrivacyPolicy />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/terms-condition"
//           element={
//             <MiniDrawer>
//               <TermOfUse />
//             </MiniDrawer>
//           }
//         />
//         <Route path="/taxInvoice" element={<TaxInvoice />} />
//         <Route path="*" element={<NoPage />} />
//         <Route
//           path="/innovartan-ai"
//           element={
//             <MiniDrawer>
//               <InnovartanAI />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/student-attendance"
//           element={
//             <MiniDrawer>
//               {/* <StudentAttendance /> */}
//               <StudentAttendance2 />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/teacher-attendance"
//           element={
//             <MiniDrawer>
//               <TeacherAttendance />
//             </MiniDrawer>
//           }
//         />
//         <Route
//           path="/questionPreview/:id/:grid"
//           element={<QuestionPreview />}
//         />
//         <Route path="/read-pdf" element={<ReadPDF />} />
//         <Route path="/EditorView" element={<EditorView />} />
//       </Routes>
//     </BrowserRouter>
//   );
// };

// export default RootPage;

import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TestList from "./Components/Pages/TestList/TestList";
import NoPage from "./Components/Pages/NoPage/NoPage";
import { getUser } from "./Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "./Store/Data/Slices/UserDataSlice";
import TestSubmision from "./Components/Pages/TestSubmision/TestSubmision";
import ViewTestSubmission from "./Components/Pages/ViewTestSubmission/ViewTestSubmission";
// import Login from "./Components/Pages/Login/Login";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";
import Post from "./Components/Pages/Post/Post";
import BatchAssignments from "./Components/Pages/Assignment/BatchAssignments";
import AssignmentDetails from "./Components/Pages/Assignment/AssignmentDetails";
import AssignmentSubmit from "./Components/Pages/Assignment/AssignmentSubmit";
import Profile from "./Components/Pages/Profile/Profile";
import ChangePassword from "./Components/Pages/ChangePassword/ChangePassword";
// import UpcommingSession from "./Components/Pages/Session/Upcomming";
import PastSession from "./Components/Pages/Session/Past";
import CancelSession from "./Components/Pages/Session/Cancel";
import QuestionLibrary from "./Components/Pages/QuestionBank/QuestionLibrary";
import Report from "./Components/Pages/Report/Report";
import Resources from "./Components/Pages/Resources/Resources";
import Feedback from "./Components/Pages/Feedback/Feedback";
import ViewFeedback from "./Components/Pages/Feedback/ViewFeedback";
import Batch from "./Components/Pages/Batch/Batch";
import Payment from "./Components/Pages/Payment/Payment";
// import Cookies from "universal-cookie";
// import MiniDrawer from "./Components/Pages/Navbar/Drawer";
import TaxInvoice from "./Components/Pages/TaxInvoice/TaxInvoice";
import { applicationActions } from "./Store/Data/Slices/ApplicationSlice";
// import TestScreen1 from "./Components/Pages/Test/TestScreen1";
import App from "./App";
import Courses from "./Components/Pages/Courses/Course";
// import CourseDetails from "./Components/Pages/Courses/courseDetails";
import CourseContent from "./Components/Pages/Courses/CourseContent";
import TeacherTestView from "./Components/Pages/TestDetails/TeacherTestView/TeacherTestView";
import InnovartanAI from "./Components/Pages/InnovartanAI/InnovartanAI";
import TeacherSubmissionView from "./Components/Pages/TestDetails/TeacherSubmissionView/TeacherSubmissionView";
import AddTest from "./Components/Pages/TestDetails/AddTest/AddTest";
import AddCourse from "./Components/Pages/Courses/AddCourse";
import QuestionPreview from "./Components/Pages/QuestionPreview/QuestionPreview";
import ReadPDF from "./Components/Pages/ReadPDF/ReadPDF";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy/PrivacyPolicy";
import TermOfUse from "./Components/Pages/TermsCondition/TermOfUse";
// import StudentAttendance from "./Components/Pages/Attendance/StudentAttendance/StudentAttendance";
import LiveSession from "./Components/Pages/Session/LiveSession";
import Upcomming2 from "./Components/Pages/Session/CommonSession";
import StudentResultPDF from "./Components/Pages/Dashboard/StudentResultPDF/StudentResultPDF";
import MiniDrawer2 from "./Components/Pages/Navbar/Drawer2";
import Members from "./Components/Pages/Members/Members";
import TeacherAttendance from "./Components/Pages/Attendance/TeacherAttendance/TeacherAttendance";
import StudentAttendance2 from "./Components/Pages/Attendance/StudentAttendance2.0/StudentAttendance2";
// import PDFView from "./Components/ResourceFolders/PDFView";
import ViewQuestionBank from "./Components/Pages/QuestionBank/ViewQuestionBank/ViewQuestionBank";
import ViewAnalysis from "./Components/Pages/ViewAnalysis/ViewAnalysis";
import TimeTable from "./Components/Pages/TimeTable/TimeTable";
import EditorView from "./Components/Pages/EditorView";
import UploadTimeTable from "./Components/Pages/TimeTable/UploadTimeTable";
import MiniDrawerNew from "./Components/Pages/Navbar/MiniDrawerNew";
import EditQuestionTag from "./Components/Pages/QuestionBank/EditQuestionTag/EditQuestionTag";
import QuestionBank from "./Components/Pages/QuestionBank/QuestionFrontView/QuestionLibrary";
import CourseDetail from "./Components/Pages/Course_2/CourseDetail";
import CourseVideoPlayer from "./Components/UI/Course/CourseVideoPlayer";
import Analysis from "./Components/Pages/Analysis/Analysis";
import PDFViewer from "./Components/Pages/PDFViewer.js/PDFViewer";
import ModuleLibrary from "./Components/Pages/ModuleLibrary/ModuleLibrary";
import DppModule from "./Components/Pages/DppModule/DppModule";
import VideoLibrary from "./Components/Pages/VideoLibrary/VideoLibrary";
import Reports from "./Components/Pages/Reports/Reports";
import TextEditor from "./Components/UI/TextEditor/TextEditor";
import DPPSubmissionView from "./Components/Pages/Course_2/DPPSubmissionView";
import LoginPageTheme from "./Components/Pages/Login/LoginTheme/LoginPageTheme";

import QuestionTaggingAI from "./Components/Pages/QuestionTaggingAI/QuestionTaggingAI";

const RootPage = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);
 
  // const cookies = new Cookies();

  const fetchAuthUser = async (auth) => {
    // console.log("Fetching Auth User", auth);
    const data = await getUser(auth, dispatch);
    let tempData = {
      token: data?.token,
      userId: data?.user_id,
      testId: data?.test_id,
      groupId: data?.test_group_id,
      firstName: data?.first_name,
      lastName: data?.last_name,
    };
    dispatch(userDataActions.setUserData(tempData));
    // console.log("User Data Using Auth ", tempData);
  };

  useEffect(() => {
    const params = window.location.search;
    const auth = new URLSearchParams(params).get("auth");

    if (auth) {
      fetchAuthUser(auth);
    } else {
      // console.log("No auth token, Bridge Implimenting...");
      // fetchAuthUser("WZpfrnveovCvsGeWX2OYkIOU");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // const cookieData = cookies.get("userData");
    const cookieData = JSON.parse(localStorage.getItem("userData"));
    const batchData = JSON.parse(localStorage.getItem("batchData"));
    const schoolData = JSON.parse(localStorage.getItem("schoolData"));
    // console.log("Cookie Data", cookieData);
    dispatch(userDataActions.setUserData(cookieData));
    dispatch(applicationActions.setBatchData(batchData));
    dispatch(applicationActions.setSchoolData(schoolData));
    // eslint-disable-next-line
  }, []);

  if (!userState?.token) {
    // console.log("user not found", userState);
    return (
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<LoginPageTheme/>} />
          {/* <Route path="/" element={<Login3 />} /> */}
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        {userState?.hideDashboard ? (
          <Route
            path="/"
            element={
              <MiniDrawer2>
                <StudentResultPDF />
              </MiniDrawer2>
            }
          />
        ) : (
          <Route
            path="/"
            element={
              <MiniDrawerNew>
                <Dashboard />
              </MiniDrawerNew>
            }
          />
        )}
        <Route
          path="/courses"
          element={
            <MiniDrawerNew>
              <Courses />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/course-details"
          element={
            <MiniDrawerNew>
              <CourseDetail />
              {/* <CourseDetails /> */}
            </MiniDrawerNew>
          }
        />
        <Route
          path="/dpp-submission-view"
          element={
            <MiniDrawerNew>
              <DPPSubmissionView />
              {/* <CourseDetails /> */}
            </MiniDrawerNew>
          }
        />
        <Route
          path="/course-details/videoplayer"
          element={
            <MiniDrawerNew>
              <CourseVideoPlayer />
              {/* <CourseDetails /> */}
            </MiniDrawerNew>
          }
        />
        <Route
          path="/course-content"
          element={
            <MiniDrawerNew>
              <CourseContent />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/testList"
          element={
            <MiniDrawerNew>
              <TestList />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/teacher-test-view"
          element={
            <MiniDrawerNew>
              <TeacherTestView />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/teacher-submission-view"
          element={
            <MiniDrawerNew>
              <TeacherSubmissionView />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/view-analysis"
          element={
            <MiniDrawerNew>
              <ViewAnalysis />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/assignment"
          element={
            <MiniDrawerNew>
              <BatchAssignments />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/assignment/details"
          element={
            <MiniDrawerNew>
              <AssignmentDetails />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/assignment/submittions"
          element={
            <MiniDrawerNew>
              <AssignmentSubmit />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/members"
          element={
            <MiniDrawerNew>
              <Members />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/post"
          element={
            <MiniDrawerNew>
              <Post />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/resources"
          element={
            <MiniDrawerNew>
              <Resources />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/profile"
          element={
            userState?.hideDashboard ? (
              <MiniDrawer2>
                <Profile />
              </MiniDrawer2>
            ) : (
              <MiniDrawerNew>
                <Profile />
              </MiniDrawerNew>
            )
          }
        />

        <Route
          path="/change-password"
          element={
            <MiniDrawerNew>
              <ChangePassword />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/upcomming-session"
          element={
            <MiniDrawerNew>
              {/* <UpcommingSession /> */}
              <Upcomming2 />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/past-session"
          element={
            <MiniDrawerNew>
              <PastSession />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/live-session"
          element={
            <MiniDrawerNew>
              <LiveSession />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/feedback"
          element={
            <MiniDrawerNew>
              <Feedback />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/ViewFeedback"
          element={
            <MiniDrawerNew>
              <ViewFeedback />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/report"
          element={
            <MiniDrawerNew>
              <Report />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/AddTest"
          element={
            <MiniDrawerNew>
              <AddTest />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/AddCourse"
          element={
            <MiniDrawerNew>
              <AddCourse />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/cancel-session"
          element={
            <MiniDrawerNew>
              <CancelSession />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/question-library"
          element={
            <MiniDrawerNew>
              <QuestionLibrary />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/question-bank/view"
          element={
            <MiniDrawerNew>
              <ViewQuestionBank />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/question-bank"
          element={
            <MiniDrawerNew>
              <QuestionBank />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/question/edit-tag"
          element={
            <MiniDrawerNew>
              <EditQuestionTag />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/resources"
          element={
            <MiniDrawerNew>
              <Resources />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/batch"
          element={
            <MiniDrawerNew>
              <Batch />
            </MiniDrawerNew>
          }
        />

        <Route
          path="/time-logs"
          element={
            <MiniDrawerNew>
              <Reports />
            </MiniDrawerNew>
          }
        />

        <Route
          path="/time-table"
          element={
            <MiniDrawerNew>
              <TimeTable />
            </MiniDrawerNew>
          }
        />

        <Route
          path="/module-library"
          element={
            <MiniDrawerNew>
              <ModuleLibrary />
            </MiniDrawerNew>
          }
        />

        <Route
          path="/dpp-module"
          element={
            <MiniDrawerNew>
              <DppModule />
            </MiniDrawerNew>
          }
        />

        <Route
          path="/video-library"
          element={
            <MiniDrawerNew>
              <VideoLibrary />
            </MiniDrawerNew>
          }
        />

        <Route
          path="/upload-time-table"
          element={
            <MiniDrawerNew>
              <UploadTimeTable />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/payment"
          element={
            <MiniDrawerNew>
              <Payment />
            </MiniDrawerNew>
          }
        />
        <Route path="/startTest" element={<App />} />
        <Route path="/test/retest/submission" element={<TestSubmision />} />
        <Route
          path="/ViewTestSubmission"
          element={
            <MiniDrawerNew>
              <ViewTestSubmission />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/ViewTestSubmission/mobile"
          element={<ViewTestSubmission />}
        />
        <Route
          path="/privacy-policy"
          element={
            <MiniDrawerNew>
              <PrivacyPolicy />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/terms-condition"
          element={
            <MiniDrawerNew>
              <TermOfUse />
            </MiniDrawerNew>
          }
        />
        <Route path="/taxInvoice" element={<TaxInvoice />} />
        <Route path="*" element={<NoPage />} />
        <Route
          path="/innovartan-ai"
          element={
            <MiniDrawerNew>
              <InnovartanAI />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/tagging-ai"
          element={
            <MiniDrawerNew>
              <QuestionTaggingAI />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/student-attendance"
          element={
            <MiniDrawerNew>
              {/* <StudentAttendance /> */}
              <StudentAttendance2 />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/teacher-attendance"
          element={
            <MiniDrawerNew>
              <TeacherAttendance />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/analysis"
          element={
            <MiniDrawerNew>
              <Analysis />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/pdf-viewer"
          element={
            <MiniDrawerNew>
              <PDFViewer />
            </MiniDrawerNew>
          }
        />
        <Route
          path="/questionPreview/:id/:grid"
          element={<QuestionPreview />}
        />
        <Route path="/text-editor" element={<TextEditor />} />

        <Route path="/read-pdf" element={<ReadPDF />} />
        <Route path="/EditorView" element={<EditorView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RootPage;
