import React, { useEffect, useState } from "react";
import styles from "./SetupFeePage.module.css";
import Select from "react-select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";

const SetupFeePage = ({
  registration,
  setRegistration,
  emiDetails,
  setEmiDetails,
  setRegistrationOpenTill,
  gst,
  setGst,
  totalAmount,
  setTotalAmount,
  emiCard,
  setEmiCard,
  couponDetails,
  setCouponDetails,
}) => {
  const enableEMIOption = [
    { value: "No", label: "No" },
    { value: "Yes", label: "Yes" },
  ];

  const enableCouponOption = [
    { value: "No", label: "No" },
    { value: "Yes", label: "Yes" },
  ];

  const couponTypeOption = [
    { value: "fixed", label: "Flat" },
    { value: "percent", label: "Percentage" },
  ];

  const fieldOption = [
    { value: "admission", label: "Registration Fees" },
    { value: "registration_fee", label: "Admission Fees" },
    { value: "token_amount", label: "Token Amount" },
    { value: "course_fees", label: "Course Fees" },
    { value: "other_fee", label: "Others" },
  ];

  const numberOfEMIsOption = [
    { value: "3", label: "3" },
    { value: "6", label: "6" },
    { value: "9", label: "9" },
    { value: "12", label: "12" },
  ];

  const gstOption = [{ value: "18", label: "18" }];

  // const interestRateOption = [
  //   { value: "0", label: "0" },
  //   { value: "1", label: "1" },
  //   { value: "2", label: "2" },
  //   { value: "3", label: "3" },
  //   { value: "4", label: "4" },
  //   { value: "5", label: "5" },
  //   { value: "6", label: "6" },
  //   { value: "7", label: "7" },
  //   { value: "8", label: "8" },
  //   { value: "9", label: "9" },
  //   { value: "10", label: "10" },
  // ];

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F1EFFE", // Your desired background color
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#D9D1FE" : provided.backgroundColor,
      color: state.isSelected ? "#000" : provided.color,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000", // Optional: Set text color
    }),
  };

  const addField = () => {
    setRegistration([
      ...registration,
      {
        fieldTitle: "",
        fieldAmount: "",
      },
    ]);
  };

  const removeField = (index) => {
    const newRegistration = registration.filter((_, i) => i !== index);
    setRegistration(newRegistration);
  };

  const handleRegistrationChange = (index, field, value) => {
    const newRegistration = [...registration];
    if (field === "title") {
      newRegistration[index].fieldTitle = value;
    } else {
      newRegistration[index].fieldAmount = Number(value);
    }
    setRegistration(newRegistration);
  };

  useEffect(() => {
    const total = registration.reduce(
      (acc, item) => acc + Number(item.fieldAmount),
      0
    );
    setTotalAmount(total);
  }, [registration]);

  useEffect(() => {
    setEmiCard([]);
    for (let i = 0; i < emiDetails.numberOfEMIs.value; i++) {
      setEmiCard((prev) => [
        ...prev,
        {
          emiNumber: i + 1,
          emiAmount: (totalAmount / emiDetails.numberOfEMIs.value).toFixed(0),
          gst:
            ((totalAmount / emiDetails.numberOfEMIs.value) * gst.value) / 100,
          TotalAmount: (
            Number((totalAmount / emiDetails.numberOfEMIs.value).toFixed(0)) +
            Number(
              ((totalAmount / emiDetails.numberOfEMIs.value) * gst.value) / 100
            )
          ).toFixed(0),
        },
      ]);
    }
    // eslint-disable-next-line
  }, [emiDetails.numberOfEMIs.value, totalAmount]);

  return (
    <div>
      <h1 className={styles.heading1}>One-Time Setup Fee</h1>
      <div className={styles.setupFeeContainer}>
        <p className={styles.subTopicheading}>Basic Details:</p>
        <div className={styles.costBreakdown}>
          {registration.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <p className={styles.fieldSNO}>{index + 1}.</p>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={fieldOption[0]}
                name="color"
                placeholder="Example: Registration Fees"
                required="Please select an option"
                options={fieldOption}
                value={registration[index].fieldTitle}
                onChange={(e) => {
                  handleRegistrationChange(index, "title", e);
                }}
                styles={{
                  ...selectStyle, // Spread any existing styles
                  container: (provided) => ({
                    ...provided,
                    width: "300px", // Set fixed width
                  }),
                }}
              />
              <input
                type="number"
                className={styles.fieldTitle}
                value={item.fieldAmount}
                placeholder="Example Rs 3000"
                onChange={(e) =>
                  handleRegistrationChange(index, "amount", e.target.value)
                }
              />
              <button
                type="button"
                className={styles.addButton}
                onClick={addField}
              >
                +
              </button>
              {/* Remove Button */}
              {registration.length > 1 && (
                <button
                  type="button"
                  className={styles.addButton}
                  onClick={() => removeField(index)}
                  style={{ marginLeft: "10px" }} // Add some space between buttons
                >
                  Remove
                </button>
              )}
            </div>
          ))}
        </div>

        <p className={styles.subTopicheading}>Open Registration Till:</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={["DatePicker"]}
            sx={{ width: "50%", padding: "50px" }}
          >
            <DatePicker
              type="date"
              required="Enter Publish Date"
              className="custom-datepicker"
              renderInput={(params) => <TextField {...params} />}
              onChange={(e) => {
                const date = new Date(e.$d);
                const formattedDate = `${date.getFullYear()}-${(
                  date.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${date
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;

                setRegistrationOpenTill(formattedDate);
              }}
            />
          </DemoContainer>
        </LocalizationProvider>

        <div className={styles.totals}>
          <p className={styles.valueTagHeading}>Total Amount: {totalAmount}</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0px",
            }}
          >
            <p
              className={styles.valueTagHeading}
              style={{ marginRight: "10px" }}
            >
              GST:{" "}
            </p>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={gstOption[0]}
              name="color"
              placeholder="Example: 18%"
              required="Please select an option"
              options={gstOption}
              value={gst}
              onChange={(e) => setGst(e)}
              styles={selectStyle}
            />
            <p
              className={styles.valueTagHeading}
              style={{ marginLeft: "10px" }}
            >
              = {(((totalAmount || 0) * (gst?.value || 0)) / 100).toFixed(0)}
            </p>
          </div>
          <p className={styles.valueTagHeading}>
            Grand Total:{" "}
            {(
              (totalAmount || 0) +
              ((totalAmount || 0) * (gst.value || 0)) / 100
            ).toFixed(0)}
          </p>
        </div>

        <div className={styles.couponSection}>
          <div style={{ width: "45%" }}>
            <p className={styles.subTopicheading}>Enable Coupon:</p>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={enableCouponOption[0]}
              name="color"
              placeholder="Enable Coupon"
              required="Please select an option"
              options={enableCouponOption}
              value={couponDetails.enableCoupon}
              onChange={(e) =>
                setCouponDetails({ ...couponDetails, enableCoupon: e })
              }
              styles={selectStyle}
            />
          </div>
        </div>

        {couponDetails.enableCoupon.value === "Yes" && (
          <div>
            <div className={styles.couponInnerContainer}>
              <div>
                <p className={styles.subTopicheading}>Enable Coupon Till:</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DatePicker"]}
                    sx={{ width: "100%", padding: "50px" }}
                  >
                    <DatePicker
                      type="date"
                      required="Enter Publish Date"
                      className="custom-datepicker"
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(e) => {
                        const date = new Date(e.$d);
                        const formattedDate = `${date.getFullYear()}-${(
                          date.getMonth() + 1
                        )
                          .toString()
                          .padStart(2, "0")}-${date
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`;
                        setCouponDetails({
                          ...couponDetails,
                          couponValidTill: formattedDate,
                        });
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div>
                <p className={styles.subTopicheading}>Coupon Type:</p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={couponTypeOption[0]}
                  name="color"
                  placeholder="Example: Flat"
                  required="Please select an option"
                  options={couponTypeOption}
                  value={couponDetails.couponType}
                  onChange={(e) =>
                    setCouponDetails({ ...couponDetails, couponType: e })
                  }
                  styles={{
                    ...selectStyle, // Spread any existing styles
                    container: (provided) => ({
                      ...provided,
                      width: "300px", // Set fixed width
                    }),
                  }}
                />
              </div>
              <div>
                <p className={styles.subTopicheading}>No of Coupons:</p>
                <input
                  type="number"
                  className={styles.fieldTitle}
                  value={couponDetails.noOfCoupons}
                  placeholder="Example 10"
                  onChange={(e) =>
                    setCouponDetails({
                      ...couponDetails,
                      noOfCoupons: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className={styles.couponInnerContainer}>
              <div>
                <p className={styles.subTopicheading}>Coupon Name:</p>
                <input
                  type="text"
                  className={styles.fieldTitle2}
                  value={couponDetails.couponValue}
                  placeholder="Innovartan@100"
                  onChange={(e) =>
                    setCouponDetails({
                      ...couponDetails,
                      couponName: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <p className={styles.subTopicheading}>Coupon Value:</p>
                <input
                  type="text"
                  className={styles.fieldTitle2}
                  value={couponDetails.couponValue}
                  placeholder="Example Rs 100"
                  onChange={(e) =>
                    setCouponDetails({
                      ...couponDetails,
                      couponDiscount: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        )}

        {couponDetails.enableCoupon.value === "No" && (
          <div className={styles.emiSection}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "45%" }}>
                <p className={styles.subTopicheading}>Enable EMI :</p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={enableEMIOption[0]}
                  name="color"
                  placeholder="Enable EMI"
                  required="Please select an option"
                  options={enableEMIOption}
                  value={emiDetails.enableEMI}
                  onChange={(e) =>
                    setEmiDetails({ ...emiDetails, enableEMI: e })
                  }
                  styles={selectStyle}
                />
              </div>
              {emiDetails.enableEMI.value === "Yes" && (
                <div style={{ width: "45%" }}>
                  <p className={styles.subTopicheading}>Number of EMI's :</p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={numberOfEMIsOption[0]}
                    placeholder="Select number of EMIs"
                    required="Please select number of EMIs"
                    name="color"
                    options={numberOfEMIsOption}
                    value={emiDetails.numberOfEMIs}
                    onChange={(e) =>
                      setEmiDetails({ ...emiDetails, numberOfEMIs: e })
                    }
                    styles={selectStyle}
                  />
                </div>
              )}
              {/* {emiDetails.enableEMI.value === "Yes" && (
              <div style={{ width: "30%" }}>
                <p className={styles.subTopicheading}>Interest on EMI's:</p>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={interestRateOption[0]}
                  placeholder="Select Interest Rate"
                  required="Please select number of EMIs"
                  name="color"
                  options={interestRateOption}
                  value={emiDetails.interestRate}
                  onChange={(e) =>
                    setEmiDetails({ ...emiDetails, interestRate: e })
                  }
                  styles={selectStyle}
                />
              </div>
            )} */}
            </div>
            {emiDetails.enableEMI.value === "Yes" && (
              <div className={styles.emiCardContainer}>
                {emiCard.map((item, index) => (
                  <div key={index} className={styles.emiCardItem}>
                    <p className={styles.emiCardTitle}>EMI {item.emiNumber}</p>
                    <p className={styles.emiCardTitle}>
                      Amount: {Number(item.emiAmount).toFixed(0)}
                    </p>
                    <p className={styles.emiCardTitle}>
                      GST: {((item.emiAmount * gst.value) / 100).toFixed(0)}
                    </p>
                    <p className={styles.emiCardTitle}>
                      Total EMI Amount:{" "}
                      {(
                        Number(item.emiAmount) +
                        Number((item.emiAmount * gst.value) / 100)
                      ).toFixed(0)}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SetupFeePage;
