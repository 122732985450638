import React, { useEffect, useState } from "react";
import classes from "./QuestionInfoArea.module.css";

const QuestionInfoArea = ({ questionsData, handleButtonClicks }) => {
  const [correct, setCorrect] = useState(0);
  const [incorrect, setIncorrect] = useState(0);
  const [notAttempted, setNotAttempted] = useState(0);
  const QuestionBox = ({ index, question }) => {
    let backgroundColor = "";
    if (question?.attempt_status === "0") {
      backgroundColor = "#FF5A64";
    }
    if (question?.attempt_status === "1") {
      backgroundColor = "#92FF3D";
    }
    return (
      <div
        className={classes.QuestionBox}
        onClick={() => handleButtonClicks(index, "jump")}
        style={{ backgroundColor }}
      >
        <div className={classes.QuestionNumber}>{index + 1}</div>
      </div>
    );
  };

  useEffect(() => {
    let correct = 0;
    let incorrect = 0;
    let notAttempted = 0;
    questionsData?.question_details?.forEach((question) => {
      if (question?.attempt_status === "0") {
        incorrect++;
      }
      if (question?.attempt_status === "1") {
        correct++;
      }
      if (question?.attempt_status === "2") {
        notAttempted++;
      }
    });
    setCorrect(correct);
    setIncorrect(incorrect);
    setNotAttempted(notAttempted);
  }, [questionsData]);
  return (
    <div className={classes.QuestionInfoOuter}>
      <div
        style={{ display: "flex", flexDirection: "column", padding: "10px" }}
      >
        <p className={classes.topHeading}>Question List</p>
        <div className={classes.horizontalLine}></div>
        <div className={classes.QuestionList}>
          {questionsData?.question_details?.map((question, index) => (
            <QuestionBox key={index} index={index} question={question} />
          ))}
        </div>
      </div>
      <div>
        <div className={classes.displayMarksContainer}>
          <div className={classes.displayMarksContainerInner}>
            <p className={classes.marksBage}>
              {questionsData?.quiz_submission_detail?.total_points}
            </p>
            <p className={classes.marksText}>Total Marks</p>
          </div>
          <div className={classes.displayMarksContainerInner}>
            <p className={classes.marksBage}>
              {questionsData?.quiz_submission_detail?.my_marks}
            </p>
            <p className={classes.marksText}>Marks Obtained</p>
          </div>
        </div>
        <div className={classes.evaluationContainer}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              className={classes.evaluationText}
              style={{ backgroundColor: "#92FF3D" }}
            >
              {correct}
            </p>
            <p className={classes.evaluationHeading}>Correct</p>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              className={classes.evaluationText}
              style={{ backgroundColor: "#FF5A64" }}
            >
              {incorrect}
            </p>
            <p className={classes.evaluationHeading}>Incorrect</p>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              className={classes.evaluationText}
              style={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #717171",
              }}
            >
              {notAttempted}
            </p>
            <p className={classes.evaluationHeading}>Not Attempted</p>
          </div>
        </div>
        <div className={classes.guideContainer}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p className={classes.guideHeading}>
              Marks provided for each correct answer
            </p>
            <p
              className={classes.guideText}
              style={{
                backgroundColor: "#92FF3D",
                border: "1px solid #717171",
              }}
            >
              {questionsData?.question_details[0]?.correct_points}
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p className={classes.guideHeading}>
              Marks provided for each incorrect answer
            </p>
            <p
              className={classes.guideText}
              style={{
                backgroundColor: "#FF5A64",
                border: "1px solid #717171",
              }}
            >
              {questionsData?.question_details[0]?.negative_marks}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionInfoArea;
