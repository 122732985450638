import React, { useState } from "react";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import styles from "./BillingSummary.module.css"; // Import the CSS module

const BillingSummary = () => {
  const [viewBreakup, setViewBreakup] = useState(false);

  return (
    <div className={styles.billDetails}>
      <div className={styles.cuponCode}>
        <input
          placeholder="Have a discount code?"
          className={styles.couponText}
          style={{ border: "none" }}
        ></input>
        <Button variant="contained" className="btn">
          <EastIcon />
        </Button>
      </div>
      <div className={styles.heading}>Billing Summary</div>
      <div className={styles.billDetails}>
        <div className={styles.bill}>
          <div>
            <div className={styles.billTitle}>Course Fee</div>
            <div
              className={styles.viewBreakup}
              onClick={() => {
                setViewBreakup(!viewBreakup);
              }}
            >
              View Breakup
            </div>
          </div>
          <div className={styles.billAmount}>₹ 10,000</div>
        </div>
        {viewBreakup && (
          <div className={styles.breakupContainer}>
            <div className={styles.breakup} style={{ marginTop: "0px" }}>
              <div className={styles.breakupTitle}>Base Price</div>
              <div className={styles.breakupAmount}>₹ 10,000</div>
            </div>
            <div className={styles.breakup}>
              <div className={styles.breakupTitle}>Tax</div>
              <div className={styles.breakupAmount}>₹ 0</div>
            </div>
          </div>
        )}
        <div className={styles.bill}>
          <div className={styles.billTitle}>GST 18%</div>
          <div className={styles.billAmount}>₹ 300</div>
        </div>
        <div className={styles.bill}>
          <div className={styles.billTitle}>Discount</div>
          <div className={styles.billAmount}>₹ 3,000</div>
        </div>
        <div className={styles.bill} >
          <div className={styles.billTitle}>Total Amount</div>
          <div className={styles.billAmount}>₹ 7,000</div>
        </div>
      </div>
    </div>
  );
};

export default BillingSummary;
