import React, { useState } from "react";

import ReactQuill from "react-quill"; 

import "react-quill/dist/quill.snow.css"; 

const TextEditor = () => {
  const [content, setContent] = useState("");

  const handleContentChange = (value) => {
    setContent(value);
  };

  // Define custom toolbar
  const modules = {
    toolbar: [
      [{ font: [] }], // Font family
      [{ size: [] }], // Font size
      ["bold", "italic", "underline", "strike"], // Bold, italic, underline, strikethrough
      [{ script: "sub" }, { script: "super" }], // Subscript / superscript
      [{ header: "1" }, { header: "2" }], // Header levels
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      [{ align: [] }], // Alignments
      ["blockquote", "code-block"], // Blockquote & Code block
      ["link", "image", "video"], // Links, images, videos
      ["clean"], // Clear formatting
    ],
  };

  // Allowed formats
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "header",
    "list",
    "align",
    "blockquote",
    "code-block",
    "link",
    "image",
    "video",
  ];

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={content}
        onChange={handleContentChange}
        modules={modules}
        formats={formats}
        className="h-96"
      />
    </div>
  );
};

export default TextEditor;
