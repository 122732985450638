import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../SelectInput/SelectInput";
import {
  createQuestionBank,
  getChapters,
  getClasses,
  getCurriculum,
  getQuestionBankData,
  getSubjects,
} from "../../../Store/API/API";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";

const SelectInputField = ({
  heading,
  placeholder,
  field,
  type,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
  isMulti,
  fieldType,
}) => {
  return (
    <div className="flex flex-col">
      <label className="text-[16px] font-medium text-black">
        {heading} <span className="text-[#f36421]">*</span>
      </label>
      <div className="rounded-xl">
        {fieldType === "DropDown" ? (
          <SelectInput
            width={"100%"}
            placeHolder={placeholder}
            options={dropDownoptions}
            field={field}
            handleInputChange={handleInputChange}
            value={dropDownValues}
            // dropdownTextColor={"#FFFFFF"}
            isMulti={isMulti}
          />
        ) : (
          <input
            type={type}
            className="w-full h-[40px] border border-[#b3b3b3c3] rounded-md px-2"
            placeholder={placeholder}
            value={dropDownValues}
            onChange={(e) => handleInputChange(field, e.target.value)}
          />
        )}
      </div>
    </div>
  );
};

const CreateQuestionBankModal = ({
  onClose,
  selectedQuestionBankId,
  setIsCreateQuestionBankModalOpen,
}) => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  // Drop down values
  const [dropDownValues, setDropDownValues] = useState({
    curriculum: "",
    class: "",
    subject: "",
    chapter: "",
    topics: "",
  });

  // Drop down options
  const [dropDownoptions, setDropDownOptions] = useState({
    class: [],
    subject: [],
    chapter: [],
    curriculum: [],
    topics: "",
  });

  // Function to fetch chapters
  const fetchChapters = async ({ value }) => {
    const data = await getChapters(dropDownValues.class.value, value, dispatch);
    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        chapter: data,
      }));
    }
  };

  // Function to fetch subjects
  const fetchSubjects = async ({ value }) => {
    const data = await getSubjects(value, dispatch);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        subject: data,
      }));
    }
  };

  // Function to fetch classes
  const fetchClasses = async () => {
    const data = await getClasses(dispatch);
    // console.log(data);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        class: data,
      }));
    }
  };

  // Function to fetch curriculum
  const fetchCurriculum = async () => {
    // console.log("fetchCurriculum");

    const data = await getCurriculum(dispatch);

    // console.log(data);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        curriculum: data,
      }));
    }
  };

  // Function to handle input change
  const handleInputChange = (field, value) => {
    setDropDownValues((prev) => {
      const updatedValues = { ...prev, [field]: value };

      if (field === "curriculum") {
        updatedValues.class = null;
        updatedValues.subject = null;
        updatedValues.chapter = null;
        updatedValues.topics = "";
      }

      if (field === "class") {
        updatedValues.subject = null;
        updatedValues.chapter = null;
        updatedValues.topics = "";
        fetchSubjects(value);
      }

      if (field === "subject") {
        updatedValues.chapter = null;
        updatedValues.topics = "";
        fetchChapters(value);
      }

      if (field === "chapter") {
        updatedValues.topics = "";
      }

      return updatedValues;
    });
  };

  // Function to create question bank
  const handleCreate = async () => {
    // console.log(dropDownValues);

    // If any of the field is empty
    if (
      dropDownValues.curriculum === "" ||
      dropDownValues.class === "" ||
      dropDownValues.subject === "" ||
      dropDownValues.chapter === "" ||
      dropDownValues.topics === ""
    ) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Please fill all the fields",
          alertType: "error",
        })
      );
      return;
    }

    // tempData is the data that will be sent to the API
    const tempData = {
      user_id: userState?.userId,
      curriculum: dropDownValues.curriculum.value,
      class: dropDownValues.class.value,
      subject: dropDownValues.subject.value,
      chapter: dropDownValues.chapter.value,
      title: dropDownValues.topics,
      question_bank_id: selectedQuestionBankId || "",
    };

    console.log("tempData", tempData);

    // Api call to create question bank
    const data = await createQuestionBank(tempData, userState?.token, dispatch);

    // console.log(data);

    if (
      data?.message === "Question Bank created successfully" ||
      data?.message === "Question Bank updated successfully"
    ) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: selectedQuestionBankId
            ? "Question Bank updated successfully"
            : "Question Bank created successfully",
          alertType: "success",
        })
      );
      onClose("create");
      return;
    }

    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Failed to create Question Bank",
        alertType: "error",
      })
    );
  };

  const fetchQuestionBankData = async () => {
    // console.log("fetchQuestionBankData");

    const data = await getQuestionBankData(
      selectedQuestionBankId,
      userState?.token,
      dispatch
    );

    // console.log(data);

    if (data?.message === "Question Found") {
      setDropDownOptions((prev) => ({
        ...prev,
        curriculum: data?.data?.curriculum,
        class: data?.data?.classes,
        subject: data?.data?.subjects,
        chapter: data?.data?.chapters,
        topics: data?.data?.title,
      }));

      setDropDownValues((prev) => ({
        ...prev,
        curriculum: data?.data?.question_bank?.question_curriculum,
        class: data?.data?.question_bank?.question_class,
        subject: data?.data?.question_bank?.question_subject,
        chapter: data?.data?.question_bank?.question_chapter,
        topics: data?.data?.question_bank?.title,
      }));
    }
  };

  useEffect(() => {
    // console.log("selectedQuestionBankId", selectedQuestionBankId);

    setDropDownValues({
      curriculum: "",
      class: "",
      subject: "",
      chapter: "",
      topics: "",
    });

    if (selectedQuestionBankId) {
      fetchQuestionBankData();
      return;
    }

    fetchCurriculum();
    fetchClasses();

    // eslint-disable-next-line
  }, [selectedQuestionBankId]);

  return (
    <div className="rounded-none flex min-h-[400px] xl:w-[700px] lg:w-[600px] md:w-[500px] flex-col">
      <header className="flex w-full gap-x-[77px] gap-y-[40px] items-center justify-center self-end">
        <div className="bg-gradient-to-br from-[#fff5ef] to-white rounded-[20px]">
          <p className="text-black text-center font-bold text-[20px] leading-[1.2] font-[Metropolis,-apple-system,Roboto,sans-serif] flex-grow p-[14px_70px]">
            Update Session Details
          </p>
        </div>
      </header>
      <div className="mt-3">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <SelectInputField
            heading={"Curriculum"}
            placeholder={"Select Curriculum"}
            field={"curriculum"}
            type={"text"}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
            dropDownValues={dropDownValues}
            isMulti={false}
            fieldType={"DropDown"}
          />
          <SelectInputField
            heading={"Class"}
            placeholder={"Select Class"}
            field={"class"}
            type={"text"}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
            dropDownValues={dropDownValues}
            isMulti={false}
            fieldType={"DropDown"}
          />
          <SelectInputField
            heading={"Subject"}
            placeholder={"Select Subject"}
            field={"subject"}
            type={"text"}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
            dropDownValues={dropDownValues}
            isMulti={false}
            fieldType={"DropDown"}
          />
          <SelectInputField
            heading={"Chapter"}
            placeholder={"Select Chapter"}
            field={"chapter"}
            type={"text"}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
            dropDownValues={dropDownValues}
            isMulti={false}
            fieldType={"DropDown"}
          />
        </div>
        <div className="mt-4">
          <SelectInputField
            heading={"Title"}
            placeholder={"Enter Title"}
            field={"topics"}
            type={"text"}
            dropDownoptions={dropDownoptions.topics}
            handleInputChange={handleInputChange}
            dropDownValues={dropDownValues.topics}
            isMulti={false}
            fieldType={"Input"}
          />
        </div>
        <div className="mt-4 space-x-2 flex justify-end ">
          <div
            className={`py-2 px-4 rounded-lg text-white hover:bg-primaryOrangeDisabled cursor-pointer  bg-primaryOrange`}
            onClick={handleCreate}
          >
            {selectedQuestionBankId ? "Update" : "Create"}
          </div>

          <div
            className="bg-primaryOrange py-2 px-4 rounded-lg text-white hover:bg-primaryOrangeDisabled cursor-pointer"
            onClick={onClose}
          >
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateQuestionBankModal;
