import React from 'react'

const TOUText = ({text}) => {
  return (
    <div>
      <p style={{textAlign: "justify"}} >{text}</p>
    </div>
  )
}

export default TOUText
