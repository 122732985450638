import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Font,
} from "@react-pdf/renderer";

import classes from "./StudentResultPDF.module.css";
import Spinner from "../../../UserInterface/Spinner/Spinner";
import { useSelector } from "react-redux";

Font.register({
  family: "Metropolis",
  fonts: [
    { src: "fonts/Metropolis-Bold.otf", fontWeight: "bold" },
    { src: "fonts/Metropolis-Regular.otf" },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: { padding: 0, fontFamily: "Helvetica" },
  container: { flex: 1 },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    height: "100px",
    position: "relative",
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "270px",
    zIndex: -1,
    overflow: "hidden",
  },
  headerContainer: {
    width: "100%",
    padding: 20,
    position: "relative",
  },
  trophyImage: {
    width: "100%",
    height: "270px",
    position: "absolute",
    right: "15px",
    bottom: "-173px",
  },
  schoolLogo: {
    width: "190px",
    height: "50px",
    position: "absolute",
    top: 20,
    left: 30,
  },
  gantavyaLogo: {
    width: "200px",
    height: "45px",
    position: "absolute",
    top: 25,
    right: 30,
  },
  gsatLogo: {
    width: "200px",
    height: "40px",
    position: "absolute",
    top: 140,
    left: 50,
  },
  resultLogo: {
    width: "120px",
    height: "20px",
    position: "absolute",
    top: 190,
    left: 53,
  },
  body: { padding: 30, marginTop: 145 },
  message: { fontSize: 13, marginBottom: 10, lineHeight: 1.5, marginTop: 0 },
  bold: { fontWeight: "bold", color: "#f57c00" },
  greenGradient: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
  scoreCard: {
    padding: 10,
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginBottom: 20,
  },
  messageScore: {
    fontSize: 14,
    lineHeight: 1.5,
    marginTop: 0,
    marginBottom: -10,
  },
  contact: {
    borderRadius: 8,
    textAlign: "center",
    marginTop: 25,
    height: 50,
  },
  footer: { textAlign: "center", marginTop: -10, marginBottom: 10 },
  footerText: {
    fontSize: 14,
    color: "#000000",
    fontFamily: "Metropolis",
    fontWeight: "bold",
  },
});

// Document Component
const MyDocumentCleared = ({ firstName, institute, pdfScore }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.container}>
        {/* Header Section */}
        <View style={styles.header}>
          <Image src="gradientEclips.png" style={styles.backgroundImage} />
          <View style={styles.headerContainer}>
            <Image src="report3.png" style={styles.trophyImage} />
            <Image src="report1.png" style={styles.schoolLogo} />
            <Image src="report2.png" style={styles.gantavyaLogo} />
            <Image src="report4.png" style={styles.gsatLogo} />
            <Image src="report5.png" style={styles.resultLogo} />
          </View>
        </View>

        {/* Message Body */}
        <View style={styles.body}>
          <View style={styles.message}>
            <Text style={{ fontFamily: "Metropolis", fontWeight: "bold" }}>
              Dear Parent,
            </Text>
            <Text style={{ fontFamily: "Metropolis" }}>
              We extend our heartfelt gratitude to all the Students and Parents
              for their enthusiastic participation in the recently conducted{" "}
              <Text style={styles.bold}>GSAT</Text> Your support and involvement
              have truly inspired us and strengthened our vision of empowering
              students to achieve their academic aspirations through{" "}
              <Text style={styles.bold}>Gantavya</Text> .
            </Text>
          </View>

          {/* Score Section */}
          <View style={styles.scoreCard}>
            <Image src="report6.png" style={styles.greenGradient} />
            <View style={styles.messageScore}>
              <Text style={{ fontFamily: "Metropolis" }}>
                We are glad to inform you that your ward
                <Text style={{ fontFamily: "Metropolis", fontWeight: "bold" }}>
                  {" "}
                  {firstName}
                </Text>
                , has been positioned in{" "}
                <Text style={{ fontFamily: "Metropolis", fontWeight: "bold" }}>
                  {`Top ${pdfScore}`}
                </Text>{" "}
                of appeared students and is eligible for the next round of
                personal interview and counselling.
              </Text>
            </View>
          </View>

          <View style={styles.message}>
            <Text style={{ fontFamily: "Metropolis" }}>
              On the basis of your ward result in the{" "}
              <Text style={{ fontFamily: "Metropolis", fontWeight: "bold" }}>
                GSAT
              </Text>{" "}
              and performance in the Interview, Scholarships for the admission
              in the Gantavya program for the session{" "}
              <Text style={{ fontFamily: "Metropolis", fontWeight: "bold" }}>
                2025-26
              </Text>{" "}
              can be offered to your ward
            </Text>
          </View>

          <View style={styles.message}>
            <Text style={{ fontFamily: "Metropolis" }}>
              Being in{" "}
              <Text style={styles.bold}>{`Top ${pdfScore}`} Students</Text>{" "}
              reflects the dedication and hard work of the students. It should
              also serve as a stepping stone toward their journey of academic
              excellence in future. Our team is always available to address any
              queries you may have regarding the program or the test results.
            </Text>
          </View>

          <View style={styles.message}>
            <Text style={{ fontFamily: "Metropolis" }}>
              Once again, we sincerely thank you for your participation and
              support.
            </Text>
          </View>

          <View style={styles.footer}>
            <Text style={styles.footerText}>
              <Text style={{ color: "#f57c00" }}>Together</Text>, let us
              continue to nurture and{" "}
              <Text style={{ color: "#f57c00" }}>inspire</Text> the bright minds
              of <Text style={{ color: "#f57c00" }}>tomorrow</Text>.
            </Text>
          </View>

          <View style={[styles.message, { marginTop: "10px" }]}>
            <Text style={{ fontFamily: "Metropolis", fontWeight: "bold" }}>
              Warm regards,
            </Text>
          </View>

          <View
            style={[
              styles.message,
              { marginBottom: "-10px", marginTop: "-10px" },
            ]}
          >
            <Text style={{ fontFamily: "Metropolis" }}>
              Gantavya Co-ordinator
            </Text>
          </View>

          {/* Contact Section */}
          <View style={styles.contact}>
            <Image src="report7.png" style={styles.contactPic} />
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

const MyDocumentNotCleared = ({ firstName }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.container}>
        {/* Header Section */}
        <View style={styles.header}>
          <Image src="gradientEclips.png" style={styles.backgroundImage} />
          <View style={styles.headerContainer}>
            <Image src="report3.png" style={styles.trophyImage} />
            <Image src="report1.png" style={styles.schoolLogo} />
            <Image src="report2.png" style={styles.gantavyaLogo} />
            <Image src="report4.png" style={styles.gsatLogo} />
            <Image src="report5.png" style={styles.resultLogo} />
          </View>
        </View>

        {/* Message Body */}
        <View style={[styles.body, { marginTop: "130px" }]}>
          <View style={styles.message}>
            <Text style={{ fontFamily: "Metropolis", fontWeight: "bold" }}>
              Dear Parent,
            </Text>
            <Text style={{ fontFamily: "Metropolis" }}>
              We extend our heartfelt gratitude to all the Students and Parents
              for their enthusiastic participation in the recently conducted{" "}
              <Text style={styles.bold}>GSAT</Text> Your support and involvement
              have truly inspired us and strengthened our vision of empowering
              students to achieve their academic aspirations through{" "}
              <Text style={styles.bold}>Gantavya</Text> .
            </Text>
          </View>

          {/* Score Section */}
          <View style={[styles.scoreCard, { marginTop: "-10px" }]}>
            <Image src="report8.png" style={styles.greenGradient} />
            <View style={styles.messageScore}>
              <Text style={{ fontFamily: "Metropolis" }}>
                Your ward
                <Text style={{ fontFamily: "Metropolis", fontWeight: "bold" }}>
                  {" "}
                  {firstName}
                </Text>
                , could not make it to the merit list of{" "}
                <Text style={{ fontFamily: "Metropolis", fontWeight: "bold" }}>
                  GSAT
                </Text>{" "}
                in his/her first attempt.
              </Text>
            </View>
          </View>

          <View style={[styles.message, { marginTop: "-10px" }]}>
            <Text style={{ fontFamily: "Metropolis" }}>
              While your ward has not been selected for the scholarship this
              time, we firmly believe that every student has immense potential
              to excel with the right guidance and practice.
            </Text>
          </View>

          <View style={styles.message}>
            <Text style={{ fontFamily: "Metropolis" }}>
              To further support and nurture their growth, we are delighted to
              offer them a unique opportunity to prepare for{" "}
              <Text style={styles.bold}>GSAT II</Text>. For the interested
              students Online/Offline sessions would be taken by{" "}
              <Text style={styles.bold}>Gantavya</Text> Faculty to help them
              prepare better.
            </Text>
          </View>

          <View style={styles.message}>
            <Text style={{ fontFamily: "Metropolis" }}>
              In these sessions some key topics would be covered in{" "}
              <Text style={styles.bold}>Mathematics</Text>,{" "}
              <Text style={styles.bold}>Science</Text> and{" "}
              <Text style={styles.bold}>Mental Ability</Text>. We are confident
              that this initiative will provide your child with the tools they
              need to succeed in future assessments and competitive examinations
            </Text>
          </View>

          <View style={styles.message}>
            <Text style={{ fontFamily: "Metropolis" }}>
              Once again, we sincerely thank you for your participation and
              support. Our team is always available to address any queries you
              may have regarding the program or the test results.
            </Text>
          </View>

          <View style={styles.footer}>
            <Text style={styles.footerText}>
              <Text style={{ color: "#f57c00" }}>Together</Text>, let us
              continue to nurture and{" "}
              <Text style={{ color: "#f57c00" }}>inspire</Text> the bright minds
              of <Text style={{ color: "#f57c00" }}>tomorrow</Text>.
            </Text>
          </View>

          <View style={styles.message}>
            <Text style={{ fontFamily: "Metropolis", fontWeight: "bold" }}>
              Warm regards,
            </Text>
          </View>

          <View
            style={[
              styles.message,
              { marginBottom: "-20px", marginTop: "-10px" },
            ]}
          >
            <Text style={{ fontFamily: "Metropolis" }}>
              Gantavya Co-ordinator
            </Text>
          </View>

          {/* Contact Section */}
          <View style={styles.contact}>
            <Image src="report7.png" style={styles.contactPic} />
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

const GeneratePDF = () => {
  const [pdfBlob, setPdfBlob] = useState(null);
  const [cleared, setCleared] = useState();
  const userState = useSelector((state) => state.userData.userData);
  console.log(userState, userState);

  useState(() => {
    if (userState?.inst === "all" || userState?.remark === "") {
      setCleared(false);
    } else {
      setCleared(true);
    }
    //eslint-disable-next-line
  }, []);

  const Class10PDFViewPass = () => {
    return (
      <div className={classes.page}>
        <div className={classes.container1}>
          {/* <!-- Header Section --> */}
          <div className={classes.header}>
            <img
              src="gradientEclips.png"
              className={classes.backgroundImage}
              alt="Background"
            />
            <div className={classes.headerContainer}>
              <img
                src="report3.png"
                className={classes.trophyImage}
                alt="Trophy"
              />
              <img
                src="report1.png"
                className={classes.schoolLogo}
                alt="School Logo"
              />
              <img
                src="report2.png"
                className={classes.gantavyaLogo}
                alt="Gantavya Logo"
              />
              <img
                src="report4.png"
                className={classes.gsatLogo}
                alt="GSAT Logo"
              />
              <img
                src="report5.png"
                className={classes.resultLogo}
                alt="Result Logo"
              />
            </div>
          </div>

          <div className={classes.body}>
            <div className={classes.message}>
              <p className={classes.bold}>Dear Parent,</p>
              <p>
                We extend our heartfelt gratitude to all the Students and
                Parents for their enthusiastic participation in the recently
                conducted{" "}
                <span className={classes.bold} style={{ color: "#F36421" }}>
                  GSAT
                </span>
                . Your support and involvement have truly inspired us and
                strengthened our vision of empowering students to achieve their
                academic aspirations through{" "}
                <span className={classes.bold} style={{ color: "#F36421" }}>
                  Gantavya
                </span>
                .
              </p>
            </div>

            {/* <!-- Score Section --> */}
            <div className={classes.scoreCard}>
              <div className={classes.messageScore}>
                <p>
                  We are glad to inform you that your ward
                  <span className={classes.bold}> {userState?.firstName}</span>,
                  has been positioned in{" "}
                  <span className={classes.bold}>
                    {`Top ${userState?.pdfScore}`}
                  </span>{" "}
                  of appeared students and is eligible for the next round of
                  personal interview and counselling.
                </p>
              </div>
            </div>

            <div className={classes.message}>
              <p>
                On the basis of your ward result in the{" "}
                <span className={classes.bold}>GSAT</span> and performance in
                the Interview, Scholarships for the admission in the Gantavya
                program for the session{" "}
                <span className={classes.bold}>2025-26</span> can be offered to
                your ward.
              </p>
            </div>

            <div className={classes.message}>
              <p>
                Being in{" "}
                <span className={classes.bold} style={{ color: "#F36421" }}>
                  {" "}
                  {`Top ${userState?.pdfScore} students`}
                </span>{" "}
                reflects the dedication and hard work of the students. It should
                also serve as a stepping stone toward their journey of academic
                excellence in the future. Our team is always available to
                address any queries you may have regarding the program or the
                test results.
              </p>
            </div>

            <div className={classes.message}>
              <p>
                Once again, we sincerely thank you for your participation and
                support.
              </p>
            </div>

            <div className={classes.footer}>
              <p style={{ fontWeight: "bold" }}>
                <span className={classes.highlight}>Together</span>, let us
                continue to nurture and{" "}
                <span className={classes.highlight}>inspire</span> the bright
                minds of <span className={classes.highlight}>tomorrow</span>.
              </p>
            </div>

            <div className={classes.message}>
              <p className={classes.bold}>Warm regards,</p>
            </div>

            <div className={classes.message} style={{ marginTop: "-10px" }}>
              <p>Gantavya Co-ordinator</p>
            </div>

            {/* <!-- Contact Section --> */}
            <div className={classes.contact}>
              <img
                src="report7.png"
                className={classes.contactPic}
                alt="Contact"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Class10PDFViewFail = () => {
    return (
      <div className={classes.page}>
        <div className={classes.container1}>
          {/* <!-- Header Section --> */}
          <div className={classes.header}>
            <img
              src="gradientEclips.png"
              className={classes.backgroundImage}
              alt="Background"
            />
            <div className={classes.headerContainer}>
              <img
                src="report3.png"
                className={classes.trophyImage}
                alt="Trophy"
              />
              <img
                src="report1.png"
                className={classes.schoolLogo}
                alt="School Logo"
              />
              <img
                src="report2.png"
                className={classes.gantavyaLogo}
                alt="Gantavya Logo"
              />
              <img
                src="report4.png"
                className={classes.gsatLogo}
                alt="GSAT Logo"
              />
              <img
                src="report5.png"
                className={classes.resultLogo}
                alt="Result Logo"
              />
            </div>
          </div>

          {/* <!-- Message Body --> */}
          <div className={classes.body}>
            <div className={classes.message}>
              <p>
                <strong>Dear Parent,</strong>
              </p>
              <p>
                We extend our heartfelt gratitude to all the Students and
                Parents for their enthusiastic participation in the recently
                conducted <span className={classes.bold}>GSAT</span>. Your
                support and involvement have truly inspired us and strengthened
                our vision of empowering students to achieve their academic
                aspirations through{" "}
                <span className={classes.bold}>Gantavya</span>.
              </p>
            </div>

            {/* <!-- Score Section --> */}
            <div className={classes.scoreCard2}>
              <div className={classes.messageScore}>
                <p>
                  Your ward <strong>{userState?.firstName}</strong>, could not
                  make it to the merit list of <strong>GSAT</strong> in his/her
                  first attempt.
                </p>
              </div>
            </div>

            <div className={classes.message}>
              <p>
                While your ward has not been selected for the scholarship this
                time, we firmly believe that every student has immense potential
                to excel with the right guidance and practice.
              </p>
            </div>

            <div className={classes.message}>
              <p>
                To further support and nurture their growth, we are delighted to
                offer them a unique opportunity to prepare for{" "}
                <span className={classes.bold} style={{ color: "#f57c00" }}>
                  GSAT II
                </span>
                . For the interested students, Online/Offline sessions would be
                taken by{" "}
                <span className={classes.bold} style={{ color: "#f57c00" }}>
                  Gantavya
                </span>{" "}
                Faculty to help them prepare better.
              </p>
            </div>

            <div className={classes.message}>
              <p>
                In these sessions, some key topics would be covered in{" "}
                <span className={classes.bold} style={{ color: "#f57c00" }}>
                  Mathematics
                </span>
                ,{" "}
                <span className={classes.bold} style={{ color: "#f57c00" }}>
                  Science
                </span>
                , and{" "}
                <span className={classes.bold} style={{ color: "#f57c00" }}>
                  Mental Ability
                </span>
                . We are confident that this initiative will provide your child
                with the tools they need to succeed in future assessments and
                competitive examinations.
              </p>
            </div>

            <div className={classes.message}>
              <p>
                Once again, we sincerely thank you for your participation and
                support. Our team is always available to address any queries you
                may have regarding the program or the test results.
              </p>
            </div>

            <div className={classes.footer} style={{ fontWeight: "bold" }}>
              <p>
                <span style={{ color: "#f57c00" }}>Together</span>, let us
                continue to nurture and{" "}
                <span style={{ color: "#f57c00" }}>inspire</span> the bright
                minds of <span style={{ color: "#f57c00" }}>tomorrow</span>.
              </p>
            </div>

            <div className={classes.message}>
              <p>
                <strong>Warm regards,</strong>
              </p>
            </div>

            <div className={classes.message} style={{ marginTop: "-10px" }}>
              <p>Gantavya Co-ordinator</p>
            </div>

            {/* <!-- Contact Section --> */}
            <div className={classes.contact}>
              <img
                src="report7.png"
                className={classes.contactPic}
                alt="Contact"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   const generatePdf = async () => {
  //     // const blob = await pdf(<MyDocumentCleared />).toBlob();
  //     // const blob = await pdf(<MyDocumentNotCleared />).toBlob();
  //     const blob = await pdf(
  //       cleared ? (
  //         <MyDocumentCleared
  //           firstName={userState?.firstName}
  //           institute={userState?.inst}
  //         />
  //       ) : (
  //         <MyDocumentNotCleared firstName={userState?.firstName} />
  //       )
  //     ).toBlob();
  //     setPdfBlob(URL.createObjectURL(blob));
  //   };
  //   generatePdf();

  //   //eslint-disable-next-line
  // }, []);

  return (
    <div className={classes.container}>
      <div className={classes.pdfContainer}>
        {cleared ? <Class10PDFViewPass /> : <Class10PDFViewFail />}
      </div>

      {/* Download Button */}
      <PDFDownloadLink
        document={
          cleared ? (
            <MyDocumentCleared
              firstName={userState?.firstName}
              institute={userState?.inst}
              pdfScore={userState?.pdfScore}
            />
          ) : (
            <MyDocumentNotCleared firstName={userState?.firstName} />
          )
        }
        fileName="GSAT_Result_2025.pdf"
      >
        {({ loading }) =>
          loading ? (
            <Spinner />
          ) : (
            <button className={classes.downloadButton}>Download PDF</button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default GeneratePDF;
