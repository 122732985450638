import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import classes from "./ViewFeedback.module.css";
import makeAnimated from 'react-select/animated';
import StarIcon from '@mui/icons-material/Star';
import CircularWithValueLabel from './CircularProgressWithLabel';
import { useDispatch, useSelector } from "react-redux";
import { getAllInstitute, getFeedbackAnalysis, getGroupChapter, getInstituteBatches } from '../../../Store/API/API';
const ViewFeedback = () => {
  const dispatch = useDispatch();
  const animatedComponents = makeAnimated();
  const [analysisData, setAnalysisData] = useState([]);
  const [courseDetails, setCourseDetails] = useState({
    institute: "",
    batch: "",
    subject: "",
    topic: "",
  })
  const [dropdownoptions, setDropdownoptions] = useState({
    institute: [],
    batch: [],
    subject: [],
    topic: [],
  });
  const selectStyle = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F1EFFE", // Your desired background color
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#D9D1FE" : provided.backgroundColor,
      color: state.isSelected ? "#000" : provided.color,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000", // Optional: Set text color
    }),
  };

  const handleInputChange = async (selectedOption, type) => {
    if (type === 'institute') {
      setCourseDetails({
        institute: selectedOption,
        batch: null,
        subject: null,
        topic: null,
      });
      const data = await getInstituteBatches(selectedOption.value, dispatch);
      setDropdownoptions((prevOptions) => ({
        ...prevOptions,
        batch: data,
        subject: [],
        topic: [],
      }));

      setAnalysisData([]);
    }

    if (type === 'batch') {
      setCourseDetails((prev) => ({
        ...prev,
        batch: selectedOption,
        subject: null, // Reset subject
        topic: null, // Reset topic
      }));

      const subjectOptions = selectedOption.subjects?.map((subject) => ({
        value: subject,
        label: subject,
      })) || [];

      setDropdownoptions((prevOptions) => ({
        ...prevOptions,
        subject: subjectOptions,
      }));
      setAnalysisData([]);
    }


    if (type === 'subject') {
      setCourseDetails((prev) => ({
        ...prev,
        subject: selectedOption,
        topic: null, // Reset topic
      }));

      const data = await getGroupChapter(courseDetails.batch.value, selectedOption.value, dispatch);

      const topicOptions = data?.map((topic) => ({
        value: topic,
        label: topic,
      })) || [];

      setDropdownoptions((prevOptions) => ({
        ...prevOptions,
        topic: topicOptions
      }));
      setAnalysisData([]);
    }

    if (type === 'topic') {
      setCourseDetails((prev) => ({
        ...prev,
        topic: selectedOption,
      }));
      setAnalysisData([]);
    }
  };

  const handleView = async () => {
    console.log("View Button CLicked");
    console.log("Course Details", courseDetails);

    setAnalysisData([]);

    const formData = {
      institute_id: courseDetails.institute.value,
      group_id: courseDetails.batch.value,
      subject: courseDetails.subject.value,
      chapter: courseDetails.topic.value,
    }

    const data = await getFeedbackAnalysis(formData, dispatch);
    console.log("Feedback Analysis", data);
    if (data) {
      setAnalysisData(data.feedback);
    }

  }


  useEffect(() => {
    const fetchAllInstitue = async () => {
      const data = await getAllInstitute(dispatch);
      setDropdownoptions((prevOptions) => ({
        ...prevOptions,
        institute: data
      }));
    }
    fetchAllInstitue();
  }, [dispatch])

  const getScreenWidth = window.innerWidth;
  const selectWidth = getScreenWidth >= 1440 ? "230px" : getScreenWidth >= 1024 ? "200px" : getScreenWidth >= 768 ? "200px" : getScreenWidth >= 425 ? "300px" : "250px";

  return (
    <div className={classes.MainContainer}>
      <div className={classes.container}>
        <div className={`grid gap-4 md:grid-cols-3 sm:grid-cols-1 lg:grid-cols-4 xl:grid-cols-5 ${classes.view}`}>
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={dropdownoptions.institute}
            placeholder={'Select Institute'}
            required="Enter Institute Type"
            classNamePrefix="custom-select"
            className={classes.whitebg}
            value={courseDetails.institute}
            onChange={(selectedOptions) => {
              handleInputChange(selectedOptions, 'institute')
            }
            }
            styles={{
              ...selectStyle, // Spread any existing styles
              container: (provided) => ({
                ...provided,
                width: selectWidth, // Set fixed width
              }),
            }}
          />
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={dropdownoptions.batch}
            placeholder={'Select Batch'}
            required="Enter Batch"
            classNamePrefix="custom-select"
            value={courseDetails.batch}
            onChange={(selectedOptions) => {
              handleInputChange(selectedOptions, 'batch')
            }}
            styles={{
              ...selectStyle, // Spread any existing styles
              container: (provided) => ({
                ...provided,
                width: selectWidth, // Set fixed width
              }),
            }}
          />
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={dropdownoptions.subject}
            placeholder={'Select Subject'}
            required="Enter Subject"
            classNamePrefix="custom-select"
            value={courseDetails.subject}
            styles={{
              ...selectStyle, // Spread any existing styles
              container: (provided) => ({
                ...provided,
                width: selectWidth, // Set fixed width
              }),
            }}
            onChange={(selectedOptions) => {
              handleInputChange(selectedOptions, 'subject')
            }}
          />
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={dropdownoptions.topic}
            placeholder={'Select Topic'}
            required="Enter Topic Type"
            classNamePrefix="custom-select"
            value={courseDetails.topic}
            onChange={(selectedOptions) => {
              handleInputChange(selectedOptions, 'topic')
            }}
            styles={{
              ...selectStyle, // Spread any existing styles
              container: (provided) => ({
                ...provided,
                width: selectWidth, // Set fixed width
              }),
            }}
          />
          <div className={classes.btns}>
            <button onClick={handleView} >View</button>
            <button className='flex justify-center items-center'>Report <img src="downloadBox.png" alt='download-button' /></button>
          </div>
        </div>
        <div className={classes.scores}>
          <div className={classes.cols}>
            <div className={classes.colsDetails}>
              <p style={{ color: '#FF9330' }}>Scores</p>
              <p>{`${analysisData?.all_members || 0} / ${analysisData?.total_feedback || 0} `}</p>
            </div>
            <div className={classes.images} style={{ backgroundColor: '#FF933059' }}><StarIcon style={{ color: '#FF9330', fontSize: '40px' }} /></div>
          </div>
          <div className={classes.cols}>
            <div className={classes.colsDetails}>
              <p style={{ color: '#FF30A0' }}>Submission</p>
              <p>{`${analysisData?.submit_percent || 0} % `}</p>
            </div>
            <div className={classes.images} style={{ backgroundColor: '#FF30A04F' }}><img src="question.png" alt='question' /></div>

          </div>
          <div className={classes.cols}>
            <div className={classes.colsDetails}>
              <p style={{ color: '#61C454' }}>Performance</p>
              <p>{`${analysisData?.performance_percent || 0} %`}</p>
            </div>
            <div className={classes.images} style={{ backgroundColor: '#61C4545E' }}><img src="performance.png" alt='performance' /></div>
          </div>
        </div>
        {analysisData?.total_feedback && <div>
          <p className="font-inter text-[20px] font-medium  text-[#717171] mb-1 mt-4">Analytics</p>
          <div className={classes.analytics}>
            <div className={classes.box} style={{ backgroundColor: '#47D3FF80' }}>
              <div className={classes.feedback}>
                <h2 style={{ color: '#0085FF' }}>Effective Usage of Board</h2>
                <p>Detailed Steps/ Working Shown<span style={{ color: '#0085FF' }}>{analysisData?.efective_uses?.[0] || 0}</span></p>
                <p>Important Points are Highlighted<span style={{ color: '#0085FF' }}>{analysisData?.efective_uses?.[1] || 0}</span></p>
                <p>Recap/ Summarisation done on Board<span style={{ color: '#0085FF' }}>{analysisData?.efective_uses?.[2] || 0}</span></p>
              </div>
              <div className={classes.prmeter}>
                <CircularWithValueLabel
                  targetValue={analysisData?.efective_uses?.['percent'] || 0}
                  barColor="#0085FF"
                  textColor="#0085FF"
                />
              </div>
            </div>
            <div className={classes.box} style={{ backgroundColor: '#61C45461' }} >
              <div className={classes.feedback}>
                <h2 style={{ color: '#14AF00' }}>Content Delivery</h2>
                <p>Theory delivered upto the mark<span style={{ color: '#14AF00' }}>{analysisData?.content_delivery?.[0] || 0}</span></p>
                <p>Relevance & Applications are discussed<span style={{ color: '#14AF00' }}>{analysisData?.content_delivery?.[1] || 0}</span></p>
                <p>Examples to support comprehension of the topic<span style={{ color: '#14AF00' }}>{analysisData?.content_delivery?.[2] || 0}</span></p>
                <p>DPPs discussed in classroom<span style={{ color: '#14AF00' }}>{analysisData?.content_delivery?.[3] || 0}</span></p>
              </div>
              <div className={classes.prmeter}>
                <CircularWithValueLabel
                  targetValue={analysisData?.content_delivery?.['percent'] || 0}
                  barColor="#14AF00"
                  textColor="#14AF00"
                />
              </div>
            </div>
            <div className={classes.box} style={{ backgroundColor: '#8B30FF4A' }}>
              <div className={classes.feedback}>
                <h2 style={{ color: '#892EFF' }}>Student Interaction</h2>
                <p>Engage Students in meaningful discussion<span style={{ color: '#892EFF' }}>{analysisData?.student_interaction?.[0] || 0}</span></p>
                <p>Able to clarify doubts<span style={{ color: '#892EFF' }}>{analysisData?.student_interaction?.[1] || 0}</span></p>
                <p>Ensures active participation of all studentss<span style={{ color: '#892EFF' }}>{analysisData?.student_interaction?.[2] || 0}</span></p>
                <p>Receptive to listening to student queries/suggestions<span style={{ color: '#892EFF' }}>{analysisData?.student_interaction?.[3] || 0}</span></p>
              </div>
              <div className={classes.prmeter}>
                <CircularWithValueLabel
                  targetValue={analysisData?.student_interaction?.['percent'] || 0}
                  barColor="#892EFF"
                  textColor="#892EFF"
                />
              </div>
            </div>
            <div className={classes.box} style={{ backgroundColor: '#F101014F' }}>
              <div className={classes.feedback}>
                <h2 style={{ color: '#FF2A2A' }}>Applications/ Numericals
                  from Module</h2>
                <p>Case Based questions<span style={{ color: '#FF2A2A' }}>{analysisData?.questions_based?.[0] || 0}</span></p>
                <p>Assertion Reason Questions<span style={{ color: '#FF2A2A' }}>{analysisData?.questions_based?.[1] || 0}</span></p>
                <p>Application Based questions<span style={{ color: '#FF2A2A' }}>{analysisData?.questions_based?.[2] || 0}</span></p>
                <p>Numericals( Wherever Applicable)<span style={{ color: '#FF2A2A' }}>{analysisData?.questions_based?.[3] || 0}</span></p>
              </div>
              <div className={classes.prmeter}>
                <CircularWithValueLabel
                  targetValue={analysisData?.questions_based?.['percent'] || 0}
                  barColor="#FF2A2A"
                  textColor="#FF2A2A"
                />
              </div>
            </div>
          </div>
        </div>}
        {!analysisData?.total_feedback &&
          <p className={classes.noAnalysisFoundText} >No Analysis Found</p>
        }

      </div>
    </div>
  )
}
export default ViewFeedback