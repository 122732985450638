import React, { useEffect, useState } from "react";
import classes from "./Batch.module.css";
import BatchCard from "./BatchCard/BatchCard";
import { getAllInstitute, getBtaches } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../UserInterface/Spinner/Spinner";
import HeaderComponent from "../Navbar/Header/HeaderComponent";
import SelectInput from "../../UI/SelectInput/SelectInput";
import CustomModal from "../../UI/CustomModal/CustomModal";
import CreateBatchModal from "./CreateBatchModal";

const SelectInputField = ({
  placeholder,
  handleInputChange,
  value,
  options,
}) => {
  return (
    <div>
      <SelectInput
        width={"100%"}
        handleInputChange={handleInputChange}
        value={value}
        placeHolder={placeholder}
        backgroundColor={"#1b3156"}
        border={"none"}
        dropdownTextColor={"#fff"}
        options={options}
      />
    </div>
  );
};

const Batches = () => {
  const generateAcademicYears = (startYear = 2024) => {
    const currentYear = new Date().getFullYear();
    let academicYears = [];

    for (let year = startYear; year <= currentYear; year++) {
      academicYears.push({
        value: `${year}-${year + 1}`,
        label: `${year}-${year + 1}`,
      });
    }

    return academicYears;
  };
  const getAcademicYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const startYear = today.getMonth() + 1 >= 4 ? currentYear : currentYear - 1;
    return `${startYear}-${startYear + 1}`;
  };

  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const [year, setYear] = useState({
    value: getAcademicYear(),
    label: getAcademicYear(),
  });
  const yearOptions = generateAcademicYears();
  const [institute, setInstitute] = useState({
    value: "4",
    label: "Innovartan",
  });
  const [createBatchModal, setCreateBatchModal] = useState(false);
  const [institutesOption, setInstitutesOption] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleInputChange = async (field, selectedValue) => {
    // console.log(selectedValue);
    if (field === "year") {
      setIsLoading(true);
      setYear(selectedValue);
      fetchBatches(selectedValue.value);
    } else if (field === "institute") {
      setInstitute(selectedValue);
      setIsLoading(true);
      const data = await getBtaches(
        userState.token,
        userState.userId,
        userState.userType,
        selectedValue.value,
        dispatch
      );
      if (data) {
        setBatches(data);
      }
      setIsLoading(false);
    }
  };

  const fetchBatches = async (selectedYear) => {
    const data = await getBtaches(
      userState.token,
      userState.userId,
      userState.userType,
      institute.value,
      selectedYear || year.value,
      dispatch
    );
    if (data) {
      setBatches(data);
    }
    setIsLoading(false);
  };

  const fetchInstitutes = async () => {
    const data = await getAllInstitute(dispatch);
    // console.log(data);

    if (data) {
      setInstitutesOption(data);
    }
  };

  const updateBatchDetails = () => {
    setIsLoading(true);
    fetchBatches();
  };

  useEffect(() => {
    fetchBatches();

    if (userState.userType === "institute") {
      fetchInstitutes();
    }
    // eslint-disable-next-line
  }, [institute, year]);

  return (
    <div className="">
      <div className="flex flex-col mt-16">
        {isLoading && <Spinner />}
        <div className="p-2">
          <div className="text-[20px] md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl font-bold m-3 ">
            <span>Batches</span>
          </div>
          {userState.userType === "institute" && (
            <HeaderComponent>
              <div className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                <div className="lg:col-start-2 xl:col-start-3">
                  <p className="text-white font-medium">Institute</p>
                  <SelectInputField
                    value={institute}
                    placeholder={"Select Institute"}
                    handleInputChange={(field, value) =>
                      handleInputChange("institute", value)
                    }
                    options={institutesOption}
                  ></SelectInputField>
                </div>

                <div className="">
                  <p className="text-white font-medium">Acadmic Year</p>
                  <SelectInputField
                    value={year}
                    placeholder={"Select Year"}
                    handleInputChange={(field, value) =>
                      handleInputChange("year", value)
                    }
                    options={yearOptions}
                  ></SelectInputField>
                </div>

                <div
                  className="col-span-full lg:col-span-1"
                  style={{ alignSelf: "flex-end" }}
                >
                  <div
                    className={
                      "bg-[#f36421] text-white border-none rounded-[12px] px-5 py-2 text-[16px] font-medium cursor-pointer h-[40px] self-end flex justify-center items-center"
                    }
                    onClick={() => setCreateBatchModal(true)}
                  >
                    <p>Create Batch</p>
                  </div>
                </div>
              </div>
            </HeaderComponent>
          )}
        </div>
        <div className={classes.cardOuterContainer}>
          <div
            className={
              "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-5 gap-5"
            }
          >
            {batches?.length === 0 && (
              <div className={classes.noData}>
                <h5>No Batches Found</h5>
              </div>
            )}
            {batches?.map((batch, index) => (
              <BatchCard
                key={index}
                batch={batch}
                updateBatchDetails={updateBatchDetails}
                instituteId={institute.value}
              />
            ))}
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={createBatchModal}
        onClose={() => setCreateBatchModal(false)}
      >
        <CreateBatchModal
          handleModalSubmit={() => {
            setIsLoading(true);
            setCreateBatchModal(!createBatchModal);
            fetchBatches();
          }}
          onClose={() => setCreateBatchModal(!createBatchModal)}
          modalHeading={"Create Batch"}
          instituteId={institute.value}
        />
      </CustomModal>
    </div>
  );
};

export default Batches;
