import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../UI/SelectInput/SelectInput";
import {
  assignGroupMembers,
  getAllInstitute,
  getInstituteBatches,
} from "../../../Store/API/API";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";

const SelectInputField = ({
  heading,
  placeholder,
  field,
  type,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
  isMulti,
}) => {
  return (
    <div className="flex flex-col">
      <label className="text-[16px] font-medium text-black">
        {heading} <span className="text-[#f36421]">*</span>
      </label>
      <div className="rounded-xl">
        <SelectInput
          width={"100%"}
          placeHolder={placeholder}
          options={dropDownoptions}
          field={field}
          handleInputChange={handleInputChange}
          value={dropDownValues}
          // dropdownTextColor={"#FFFFFF"}
          isMulti={isMulti}
        />
      </div>
    </div>
  );
};

const MemberOnBoardModal = ({
  modalHeading,
  onClose,
  handleModalSubmit,
  checkedMembers,
}) => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  //   console.log("batchData", batchData);

  const [dropDownValues, setDropDownValues] = useState({
    institute: "",
    batch: "",
  });
  const [dropDownoptions, setDropDownOptions] = useState({
    institute: [],
    batch: [],
  });

  const handleInputChange = (field, value) => {
    if (field === "institute") {
      setDropDownValues({
        institute: value,
        batch: null,
      });
      fetchInstitutebatches(value);
    }

    if (field === "batch") {
      setDropDownValues((prev) => ({
        ...prev,
        batch: value,
      }));
    }
  };

  const handleCreate = async () => {
    if (!dropDownValues?.institute || !dropDownValues?.batch) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Please select Institute and Batch",
          alertType: "error",
        })
      );
      return;
    }

    const apiData = {
      user_id: userState?.userId,
      user_ids: checkedMembers.map((member) => member.user_id),
      group_id: dropDownValues?.batch?.value,
    };

    // console.log("apiData", apiData);

    const data = await assignGroupMembers(apiData, userState?.token, dispatch);

    if (data?.statuscode === "200") {
      onClose();
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Members added successfully",
          alertType: "success",
        })
      );
    }
  };

  const fetchInstitutebatches = async (selectedOption) => {
    const data = await getInstituteBatches(selectedOption.value, dispatch);
    setDropDownOptions((prevOptions) => ({
      ...prevOptions,
      batch: data,
    }));
  };

  const fetchInstitutes = async () => {
    const data = await getAllInstitute(dispatch);
    setDropDownOptions((prevOptions) => ({
      ...prevOptions,
      institute: data,
    }));
  };

  useEffect(() => {
    fetchInstitutes();
    // eslint-disable-next-line
  }, [userState]);

  return (
    <div className="rounded-none flex min-h-[400px] xl:w-[700px] lg:w-[600px] md:w-[500px] flex-col">
      <header className="flex w-full gap-x-[77px] gap-y-[40px] items-center justify-center self-end">
        <div className="bg-gradient-to-br from-[#fff5ef] to-white rounded-[20px]">
          <p className="text-black text-center font-bold text-[20px] leading-[1.2] font-[Metropolis,-apple-system,Roboto,sans-serif] flex-grow p-[14px_70px]">
            {modalHeading}
          </p>
        </div>
      </header>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
        <SelectInputField
          field={"institute"}
          heading={"Select Institute"}
          placeholder={"Select Institute"}
          dropDownValues={dropDownValues}
          dropDownoptions={dropDownoptions}
          handleInputChange={handleInputChange}
        />
        <SelectInputField
          field={"batch"}
          heading={"Select Batch"}
          placeholder={"Select Batch"}
          dropDownValues={dropDownValues}
          dropDownoptions={dropDownoptions}
          handleInputChange={handleInputChange}
        />
        <div className="grid grid-cols-1 gap-5 col-start-1 md:grid-cols-2 md:col-start-2 ">
          <div
            className={
              "bg-gray-500 hover:bg-gray-700 text-white border-none rounded-[12px] px-5 py-2 lg:text-[16px] text-[12px] font-medium cursor-pointer h-[40px] self-end flex justify-center items-center"
            }
            onClick={onClose}
          >
            <p>Cancel</p>
          </div>
          <div
            className={
              "bg-[#f36421] hover:bg-orange-700 text-white border-none rounded-[12px] px-5 py-2 lg:text-[16px] text-[12px] font-medium cursor-pointer h-[40px] self-end flex justify-center items-center"
            }
            onClick={handleCreate}
          >
            <p>{"Submit"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberOnBoardModal;
