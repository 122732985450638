import React, { useEffect, useState } from "react";
import styles from "./EditQuestionTag.module.css";
import MCQ from "../../../UI/OuestionTypes/MCQ/MCQ";
import QuestionEditBar from "../../../UI/OuestionTypes/QuestionEditBar/QuestionEditBar";
import FIB from "../../../UI/OuestionTypes/FIB/FIB";
import TF from "../../../UI/OuestionTypes/TF/TF";
import MCQMA from "../../../UI/OuestionTypes/MCQMA/MCQMA";
import Integer from "../../../UI/OuestionTypes/Integer/Integer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getQuestionDetailsQuestionBank } from "../../../../Store/API/API";

const EditQuestionTag = () => {
 const location = useLocation();
const { questionId, questionBankId } = location?.state || {};

  
  console.log("questionId", questionId);
  console.log("questionBankId", questionBankId);

  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  const [questionData, setQuestionData] = useState({});
  const renderQuestion = (questionData) => {
    if (!questionData?.question) return null;

    switch (questionData?.question?.type?.toUpperCase()) {
      case "FIB":
        return (
          <FIB
            // key={index}
            correctOption={questionData?.question?.solution_array}
            fibCount={questionData?.question?.fib_count}
            question={questionData?.question?.question}
            questionOrder={questionData?.question?.question_order}
            questionId={questionData?.question?.question_id}
            questionMarks={questionData?.question?.marks}
            questionBankId={questionBankId}
            location={"TAG"}
            questionData={questionData?.question}
            // handleButtonClick={handleButtonClick}
          />
        );

      case "TF":
        return (  
          <TF
            // key={index}
            correctOption={questionData?.question?.answer}
            answer={questionData?.question?.currect_answer}
            question={questionData?.question?.question}
            questionOrder={questionData?.question?.question_id}
            questionMarks={questionData?.question?.marks}
            questionBankId={questionBankId}
            location={"TAG"}
            questionData={questionData?.question}
            // handleButtonClick={handleButtonClick}
          />
        );

      case "MCQ":
        return (
          <MCQ
            // key={index}
            correctOption={questionData?.question?.answer}
            answer={questionData?.question?.currect_answer}
            question={questionData?.question?.question}
            questionOrder={questionData?.question?.question_order}
            questionId={questionData?.question?.question_id}
            questionMarks={questionData?.question?.marks}
            questionBankId={questionBankId}
            location={"TAG"}
            questionData={questionData?.question}
            // handleButtonClick={handleButtonClick}
          />
        );

      case "MCQMA":
        return (
          <MCQMA
            // key={index}
            correctOption={questionData?.question?.answer}
            answer={questionData?.question?.solution_array}
            question={questionData?.question?.question}
            questionOrder={questionData?.question?.question_order}
            questionId={questionData?.question?.question_id}
            questionMarks={questionData?.question?.marks}
            questionBankId={questionBankId}
            location={"TAG"}
            questionData={questionData?.question}
            // handleButtonClick={handleButtonClick}
          />
        );

      case "INTT":
        return (
          <Integer
            // key={index}
            correctOption={questionData?.question?.currect_answer}
            question={questionData?.question?.question}
            questionOrder={questionData?.question?.question_order}
            questionId={questionData?.question?.question_id}
            questionMarks={questionData?.question?.marks}
            questionBankId={questionBankId}
            location={"TAG"}
            questionData={questionData?.question}
            // handleButtonClick={handleButtonClick}
          />
        );

      default:
        return <div>No Available</div>;
    }
  };

  const fetchQuestionDetails = async () => {
    const data = await getQuestionDetailsQuestionBank(
      questionId,
      userState?.userId,
      dispatch
    );
    console.log("data", data);

    if (data) {
      setQuestionData(data?.data);
    }
  };

  useEffect(() => {
    if (questionId) {
      fetchQuestionDetails();
    }

    // eslint-disable-next-line
  }, [questionId]);

  return (
    <div className={`${styles.container} p-2 p-lg-3 `}>
      <p className={styles.topHeading}>Edit Question / Class IX / Biology </p>
      {/* <MCQ
        // key={index}
        correctOption={duumyData?.question?.answer}
        answer={duumyData?.question?.currect_answer}
        question={duumyData?.question?.question}
        questionOrder={duumyData?.question?.question_order}
        questionId={duumyData?.question?.question_id}
        questionMarks={duumyData?.question?.marks}
        location={"TAG"}
        questionData={duumyData?.question}
      /> */}
      {renderQuestion(questionData)}
      <QuestionEditBar
        userState={userState}
        questionData={questionData}
        questionBankId={questionBankId}
        location={location}
      />
    </div>
  );
};

export default EditQuestionTag;
