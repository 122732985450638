import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSessionJoinningUrl } from "../../../Store/API/API";

const Table = ({ timetableData }) => {
  const [timers, setTimers] = useState({});
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const dispatch = useDispatch();

  const handleJoinSession = async (session) => {
    try {
      let newTab = "";
      newTab = window.open("about:blank", "_blank");
      if (!newTab) {
        throw new Error("Link opened on new tab. Please check your browser.");
      }
      const data = await getSessionJoinningUrl(
        userState.token,
        userState.userId,
        session,
        batchState.groupId,
        dispatch
      );

      if (!data || !data.meetingUrl) {
        throw new Error("Invalid response or missing meeting URL");
      }

      newTab.location.href = data.meetingUrl;
    } catch (error) {
      console.error("Error joining session:", error);
      window.alert(`${error.message || error}`);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimers((prevTimers) => {
        const updatedTimers = { ...prevTimers };
        timetableData.forEach((item, index) => {
          if (item.state === "Today") {
            const sessionStartTime = new Date(
              `${item.date}T${item.fromTime}`
            ).getTime();
            const currentTime = new Date().getTime();
            const timeLeft = sessionStartTime - currentTime;

            if (timeLeft <= 0) {
              updatedTimers[index] = "Join";
            } else {
              const hours = Math.floor((timeLeft / 1000 / 60 / 60) % 24);
              const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
              // const seconds = Math.floor((timeLeft / 1000) % 60);
              updatedTimers[index] = `${hours}h ${minutes}m  `;
            }
          }
        });
        return updatedTimers;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timetableData]);

  return (
    // <div className="overflow-auto w-[45%] md:w-[55%] lg:w-[76%] xl:w-[95%] 2xl:w-full h-auto p-2 font-metropolis">
    //   <table className="min-w-full border border-backgroundGrey bg-textwhite">
    //     <thead>
    //       <tr className="bg-backgroundBlue text-center font-bold">
    //         <th className="px-2 py-2 border-b">Date</th>
    //         <th className="px-2 py-2 border-b">Time</th>
    //         <th className="px-2 py-2 border-b">Batch</th>
    //         <th className="px-2 py-2 border-b">Class</th>
    //         <th className="px-2 py-2 border-b">Subject</th>
    //         <th className="px-2 py-2 border-b">Chapter</th>
    //         <th className="px-2 py-2 border-b">Tutor</th>
    //         <th className="px-2 py-2 border-b">Started BY</th>
    //       </tr>
    //     </thead>
    //     <tbody className="text-base text-textBlack text-center">
    //       {timetableData.length > 0 ? (
    //         timetableData.map((item, index) => (
    //           <tr key={index}>
    //             <td className="px-3 py-2 border-b ">
    //               {new Date(item.date).toLocaleDateString("en-GB")}
    //             </td>
    //             <td className="px-3 py-2 border-b truncate w-[80px]">
    //               {item.fromTime} - {item.toTime}
    //             </td>
    //             <td className="px-3 py-2 border-b truncate w-[200px]">
    //               {item.batch_name}
    //             </td>
    //             <td className="px-3 py-2 border-b truncate w-[100px]">
    //               {item.session_class}
    //             </td>
    //             <td className="px-3 py-2 border-b">{item.session_subject}</td>
    //             <td className="px-3 py-2 border-b truncate max-w-[250px]">
    //               {item.session_chapter}
    //             </td>
    //             <td className="px-3 py-2 border-b truncate max-w-[200px]">
    //               {item.session_tutor_name}
    //               {/* <div className="flex items-center gap-2">
    //                 <img
    //                   src={item.tutor_profile_image}
    //                   alt="Tutor"
    //                   className="w-8 h-8 rounded-full"
    //                 />
    //                 <span>{item.session_tutor_name}</span>
    //               </div> */}
    //             </td>
    //             <td className="border-b p-2  font-semibold">
    //               <span
    //                 className={`w-28 flex justify-center items-center px-2 py-2 border-b text-center text-textWhite rounded-xl ${
    //                   item.state === "Future"
    //                     ? "bg-secondaryBlue"
    //                     : item.state === "Today"
    //                     ? "bg-primaryOrange"
    //                     : "bg-secondaryGreen"
    //                 }`}
    //               >
    //                 {item.state === "Today" ? (
    //                   timers[index] === "Join" ? (
    //                     <button
    //                       onClick={() => handleJoinSession(item.sessionId)}
    //                     >
    //                       Join
    //                     </button>
    //                   ) : (
    //                     timers[index] || "Calculating..."
    //                   )
    //                 ) : item.state === "Future" ? (
    //                   "Upcoming"
    //                 ) : (
    //                   "Completed"
    //                 )}
    //               </span>
    //             </td>
    //           </tr>
    //         ))
    //       ) : (
    //         <tr>
    //           <td
    //             colSpan="8"
    //             className="text-center px-4 py-2 border-b text-textGrey"
    //           >
    //             No timetable data available.
    //           </td>
    //         </tr>
    //       )}
    //     </tbody>
    //   </table>
    // </div>
    <div className="overflow-x-auto px-2 ">
      <div className="relative w-full hidden lg:block">
        <table className="w-full border-separate border-spacing-y-3">
          <thead className="sticky top-0 bg-gray-100 z-10">
            <tr className="">
              <th className="py-3 text-center rounded-l-lg w-[8%]">
                Date
              </th>
              <th className="py-3 text-center w-[8%]">Time</th>
              <th className="py-3 text-center w-[10%]">Batch</th>
              <th className="p-3 text-center w-[10%]">Class</th>
              <th className="p-3 text-center w-[8%]">Subject</th>
              <th className="p-3 text-center w-[18%]">Chapter</th>
              <th className="p-3 text-center w-[8%]">Tutor</th>
              <th className="p-3 text-center rounded-r-lg w-[15%]">Action</th>
            </tr>
          </thead>
          <tbody className="overflow-y-auto">
            {timetableData.map((item, index) => (
              <tr key={index} className={`${item.bgColor}`}>
                <td className="py-3 text-center rounded-l-lg border-l border-t border-b border-backgroundBlue xl:text-base lg:text-sm">
                  {new Date(item.date).toLocaleDateString("en-GB") || "-"}
                </td>
                <td className="p-3 text-center border-t border-b border-backgroundBlue xl:text-base lg:text-sm">
                  {item.fromTime} - {item.toTime}
                </td>
                <td className="p-3 text-center border-t border-b border-backgroundBlue xl:text-base lg:text-sm">
                  {item.batch_name || "-"}
                </td>
                <td className="p-3 text-center border-t border-b border-backgroundBlue xl:text-base lg:text-sm">
                  {item.session_class || "-"}
                </td>
                <td className="p-3 text-center border-t border-b whitespace-nowrap border-backgroundBlue xl:text-base lg:text-sm">
                  {item.session_subject || "-"}
                </td>
                <td className="p-3 text-center border-t border-b border-backgroundBlue xl:text-base lg:text-sm">
                  {item.session_chapter || "-"}
                </td>
                <td className="p-3 text-center border-t border-b  border-backgroundBlue xl:text-base lg:text-sm">
                  {item.session_tutor_name || "-"}
                </td>
                <td className="p-3 text-center rounded-r-lg border-t border-b border-r border-backgroundBlue xl:text-base lg:text-sm">
                  <span
                    className={`flex justify-center items-center px-2 py-2 border-b text-center text-textWhite rounded-xl ${item.state === "Future"
                      ? "bg-secondaryBlue"
                      : item.state === "Today"
                        ? "bg-primaryOrange"
                        : "bg-secondaryGreen"
                      }`}
                  >
                    {item.state === "Today" ? (
                      timers[index] === "Join" ? (
                        <button
                          onClick={() => handleJoinSession(item.sessionId)}
                        >
                          Join
                        </button>
                      ) : (
                        timers[index] || "Calculating..."
                      )
                    ) : item.state === "Future" ? (
                      "Upcoming"
                    ) : (
                      "Completed"
                    )}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div >
      {timetableData?.map((item, index) => (
        <div
          key={index}
          className="block lg:hidden bg-white p-4 rounded-lg border-l-4 md:mb-5 shadow-md relative mt-3"
        >
          <p className="flex items-center gap-2">
            <strong>Date:</strong> {new Date(item.date).toLocaleDateString("en-GB") || "-"}
          </p>
          <p className="flex items-center gap-2">
            <strong>Time:</strong> {item.fromTime} - {item.toTime}
          </p>
          <p className="flex items-center gap-2">
            <strong>Batch:</strong> {item.batch_name || "-"}
          </p>
          <p className="flex items-center gap-2">
            <strong>Class:</strong> {item.session_class || "-"}
          </p>
          <p className="flex items-center gap-2">
            <strong>Subject:</strong> {item.session_subject || "-"}
          </p>
          <p className="flex items-center gap-2">
            <strong>Chapter:</strong> {item.session_chapter || "-"}
          </p>
          <p className="flex items-center gap-2">
            <strong>Tutor:</strong> {item.session_tutor_name || "-"}
          </p>
          <p className="flex items-center gap-2 absolute top-4 right-3">
            <span
              className={`flex justify-center items-center px-2 py-2 border-b text-center text-textWhite rounded-xl ${item.state === "Future"
                ? "bg-secondaryBlue"
                : item.state === "Today"
                  ? "bg-primaryOrange"
                  : "bg-secondaryGreen"
                }`}
            >
              {item.state === "Today" ? (
                timers[index] === "Join" ? (
                  <button
                    onClick={() => handleJoinSession(item.sessionId)}
                  >
                    Join
                  </button>
                ) : (
                  timers[index] || "Calculating..."
                )
              ) : item.state === "Future" ? (
                "Upcoming"
              ) : (
                "Completed"
              )}
            </span>
          </p>
        </div>
      ))}
    </div>
  );
};

export default Table;
