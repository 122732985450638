import React, { useEffect, useState } from "react";
import CourseTopHeader from "../../UI/Course/CourseTopHeader";
import AboutCourseSection from "../../UI/Course/AboutCourseSection";
import SyllabusSection from "../../UI/Course/SyllabusSection";
import DPPSection from "../../UI/Course/DPPSection";
import { useDispatch, useSelector } from "react-redux";
import { getCourseDetails } from "../../../Store/API/API";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../UserInterface/Spinner/Spinner";

const SectionCard = ({ title, activeSection, onChange }) => {
  const isActive = title === activeSection;
  return (
    <div
      className={`w-48 flex justify-center py-1 rounded-lg ${
        isActive ? "bg-[#0743A3]" : "bg-white"
      } ${isActive ? "text-white" : "text-black"} cursor-pointer border ${
        isActive ? "border-[#0743A3]" : "border-[#E7EDF6]"
      }`}
      onClick={() => onChange(title)}
    >
      <p
        className={` md:text-base text-xs ${
          isActive ? "font-medium" : "font-extralight"
        }`}
      >
        {title}
      </p>
    </div>
  );
};

const CourseDetail = () => {
  const [activeSection, setActiveSection] = useState("About Course");
  const userState = useSelector((state) => state.userData.userData);
  const [isLoading, setIsLoading] = useState(true);
  const [courseDetails, setCourse] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { course_id, activeSession } = location?.state || "";

  const fetchCourse = async () => {
    const data = await getCourseDetails(course_id, userState.userId, dispatch);
    if (data) {
      // console.log(data);
      setCourse(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!course_id) {
      navigate("/courses");
      return;
    }

    if (activeSession) {
      setActiveSection("Syllabus");
    }

    fetchCourse();

    // eslint-disable-next-line
  }, [userState]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="mt-[60px] w-full h-full lg:p-10 md:p-6 p-4">
      <p className="text-lg font-semibold">
        <span
          className="hover:text-orange-500 cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          Courses /
        </span>{" "}
        {courseDetails?.course_name || " "} / Course Details
      </p>
      <CourseTopHeader courseDetails={courseDetails} />
      <div className="flex gap-3 mt-5">
        <SectionCard
          title="About Course"
          activeSection={activeSection}
          onChange={setActiveSection}
        />
        {courseDetails?.course_format !== "tests" && (
          <SectionCard
            title="Syllabus"
            activeSection={activeSection}
            onChange={setActiveSection}
          />
        )}
        {courseDetails?.course_format === "tests" && (
          <SectionCard
            title="DPP"
            activeSection={activeSection}
            onChange={setActiveSection}
          />
        )}
      </div>
      <div className="mt-0">
        {activeSection === "About Course" && (
          <AboutCourseSection courseDetails={courseDetails} />
        )}
        {activeSection === "Syllabus" && (
          <SyllabusSection
            courseFormat={courseDetails?.course_format}
            courseName={courseDetails?.course_name}
            activeSection={activeSection}
            courseId={course_id}
          />
        )}
        {activeSection === "DPP" && (
          <DPPSection
            courseFormat={courseDetails?.course_format}
            courseName={courseDetails?.course_name}
            activeSection={activeSection}
            courseId={course_id}
          />
        )}
      </div>
    </div>
  );
};

export default CourseDetail;
