import React, { useEffect, useState } from "react";
import classes from "./Batch.module.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import BatchCard from "./BatchCard/BatchCard";
import { getAllInstitute, getBtaches } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../UserInterface/Spinner/Spinner";

const Batches = () => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const [year, setYear] = useState("2024");
  const [institute, setInstitute] = useState("4");
  const [institutesOption, setInstitutesOption] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleInstituteChange = async (event) => {
    setInstitute(event.target.value);

    setIsLoading(true);

    const data = await getBtaches(
      userState.token,
      userState.userId,
      userState.userType,
      event.target.value,
      dispatch
    );
    if (data) {
      // console.log(data);
      setBatches(data);
    }
    setIsLoading(false);
  };

  const fetchBatches = async () => {
    const data = await getBtaches(
      userState.token,
      userState.userId,
      userState.userType,
      institute,
      dispatch
    );
    if (data) {
      // console.log(data);
      setBatches(data);
    }
    setIsLoading(false);
  };

  const fetchInstitutes = async () => {
    const data = await getAllInstitute();
    if (data) {
      setInstitutesOption(data);
    }
  };

  useEffect(() => {
    fetchBatches();

    if (userState.userType === "institute") {
      fetchInstitutes();
    }

    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-[100vh] w-[100%] overflow-y-auto overflow-x-hidden">
        <div className={classes.batchesContainer}>
          <div className={classes.sectionOuter}>
            <div>
              <i className="fa-solid fa-book-open"></i>
              <span style={{ marginLeft: "10px" }}>Batches</span>
            </div>
          </div>
          <div className={classes.horizontalLine}></div>
          <div className={classes.batchContainer}>
            <div className={classes.dropdownContainer}>
              <div style={{ margin: "10px" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>Select Academic Year</InputLabel>
                  <Select
                    value={year}
                    onChange={handleYearChange}
                    label="Select Year"
                  >
                    <MenuItem value={"2019"}>Academic Year 2019-2020</MenuItem>
                    <MenuItem value={"2020"}>Academic Year 2020-2021</MenuItem>
                    <MenuItem value={"2021"}>Academic Year 2021-2022</MenuItem>
                    <MenuItem value={"2022"}>Academic Year 2022-2023</MenuItem>
                    <MenuItem value={"2023"}>Academic Year 2023-2024</MenuItem>
                    <MenuItem value={"2024"}>Academic Year 2024-2025</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className={classes.horizontalLine}></div>
          <div className={classes.loader}>
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-[100vh] w-[100%] overflow-y-auto overflow-x-hidden">
      <div className={classes.batchesContainer}>
        <div className={classes.sectionOuter}>
          <div>
            <i className="fa-solid fa-book-open"></i>
            <span style={{ marginLeft: "10px" }}>Batches</span>
          </div>
        </div>
        <div className={classes.horizontalLine}></div>
        <div className={classes.batchContainer}>
          {userState.userType === "institute" && (
            <div className={classes.dropdownContainer}>
              <div
                style={{
                  margin: "20px 0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 300 }}
                >
                  <InputLabel>Select Institute</InputLabel>
                  <Select
                    value={institute}
                    onChange={handleInstituteChange}
                    label="Select Institute"
                    style={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: "5px",
                    }}
                  >
                    {institutesOption?.map((institute, index) => (
                      <MenuItem key={index} value={institute.value}>
                        {institute.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          <div className={classes.dropdownContainer}>
            <div
              style={{
                margin: "20px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ minWidth: 300 }}
              >
                <InputLabel>Select Academic Year</InputLabel>
                <Select
                  value={year}
                  onChange={handleYearChange}
                  label="Select Year"
                  style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                  }}
                >
                  <MenuItem value={2019}>Academic Year 2019-2020</MenuItem>
                  <MenuItem value={2020}>Academic Year 2020-2021</MenuItem>
                  <MenuItem value={2021}>Academic Year 2021-2022</MenuItem>
                  <MenuItem value={2022}>Academic Year 2022-2023</MenuItem>
                  <MenuItem value={2023}>Academic Year 2023-2024</MenuItem>
                  <MenuItem value={2024}>Academic Year 2024-2025</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className={classes.horizontalLine}></div>
        <div className={classes.cardContainer}>
          {batches?.length === 0 && (
            <div className={classes.noData}>
              <h5>No Batches Found</h5>
            </div>
          )}
          {batches?.map((batch, index) => (
            <BatchCard key={index} batch={batch} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Batches;
