import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";

// Data For Area Chart
const data = [
  { day: 0 },
  { day: 1, score: 50 },
  { day: 2, score: 60 },
  { day: 3, score: 60 },
  { day: 4, score: 70 },
  { day: 5, score: 70 },
  { day: 6, score: 70 },
  { day: 7, score: 80 },
  { day: 8, score: 80 },
  { day: 9, score: 80 },
  { day: 10, score: 80 },
  { day: 11, score: 80 },
  { day: 12, score: 80 },
  { day: 13, score: 80 },
  { day: 11 },
];

const PerformanceChart = () => {
  const [selectedTest, setSelectedTest] = useState("All");
  const [selectedMonth, setSelectedMonth] = useState("November 2024");

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg">
      {/* Title */}
      <h2 className="text-2xl font-bold text-center mb-4">
        Test Performance Growth
      </h2>

      {/* Filters */}
      <div className="flex justify-center gap-4 mb-6">
        <select
          className="border border-gray-300 rounded-lg px-4 py-2"
          value={selectedTest}
          onChange={(e) => setSelectedTest(e.target.value)}
        >
          <option>All</option>
          <option>Math</option>
          <option>Science</option>
        </select>

        <select
          className="border border-gray-300 rounded-lg px-4 py-2"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
        >
          <option>November 2024</option>
          <option>December 2024</option>
          <option>January 2025</option>
        </select>
      </div>

      {/* Chart */}
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <YAxis domain={[0, 100]} tickCount={7} />
          <Tooltip />
          <Area
            type="stepAfter"
            dataKey="score"
            stroke="#1E3A8A"
            fill="#1E3A8A33"
            strokeWidth={2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

const MyPerformance = () => {
  return (
    <div>
      <div className="mt-10">
        <PerformanceChart />
      </div>
      {/* Download & Share Button */}
      <div className="flex justify-center mt-10 mb-10 gap-2">
        <button className="bg-[#FEF0E9] text-sm text-white p-2 rounded-full font-medium hover:bg-primaryOrange transition">
          <ShareIcon className="text-lg text-primaryOrange hover:text-white" />
        </button>
        <button className="bg-primaryOrange text-sm text-white px-6 py-2 rounded-full font-medium hover:bg-[#073278] transition hover:bg-transparent border border-primaryOrange hover:text-primaryOrange">
          <DownloadIcon className="text-base" /> Download Results
        </button>
      </div>
    </div>
  );
};

export default MyPerformance;
