import React from "react";
import styles from "./CommonSessionCard.module.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const CommonSessionCard = () => {
  const Tag = ({ name, color }) => {
    return (
      <div className={styles.tag} style={{ backgroundColor: color }}>
        <p className={styles.tagText}>{name}</p>
      </div>
    );
  };
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.tagContainer}>
          <Tag name="Chemistry" color="#0743A3" />
          <Tag name="Class XII" color="#1CB2B7" />
        </div>

        <div
          style={{
            border: "1px solid #E0E0E0",
            borderRadius: "20px",
            width: "26px",
            height: "26px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MoreVertIcon
            style={{ color: "#000", width: "20px", height: "20px" }}
          />
        </div>
      </div>
      <p className={styles.heading}>Career Guidance Program for XII</p>
      <p className={styles.subheading}>
        Chapter : Principles of Inheritance and Variation{" "}
      </p>
      <div className={styles.infoContainer}>
        <i className="fa-solid fa-calendar-days"></i>
        <p className={styles.infoText}>Tuesday, 03 Dec 2024</p>
      </div>
      <div className={styles.infoContainer}>
        <i className="fa-regular fa-clock"></i>
        <p className={styles.infoText}>Tuesday, 03 Dec 2024</p>
      </div>
      <div className={styles.horizontalLine}></div>
      <div className={styles.bottomContainer}>
        <div className={styles.bottomLeft}>
          <img
            src="/images/sessionImageDemo.svg"
            alt="Institute Demo"
            className={styles.image}
          />
          <p className={styles.bottomLeftText}>by Himanshu Bhutani</p>
        </div>
        <div className={styles.bottomRight}>
          <div className={styles.bottomRightClip}>
            <i
              className="fa-solid fa-paperclip"
              style={{ rotate: "-45deg" }}
            ></i>
          </div>
          <div className={styles.bottomRightButton}>
            <i className="fa-solid fa-circle-play" style={{color: '#fff'}} ></i>
            <p className={styles.startButton}>Start</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonSessionCard;
