import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCourseContent, getVedioViewTime } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import VideoPlayer from "../../Pages/Dashboard/Videoplayer/VideoPlayer";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";

const CourseVideoPlayer = () => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  const location = useLocation();
  const { video, courseName, courseId, videoTitle, chapterName, chapterId } =
    location?.state || "";

  // console.log(chapterId);

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [currentViewTime, setCurrentViewTime] = useState(0);
  const [selectedMP4, setSelectedMP4] = useState();
  const [showVideo, setShowVideo] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [itemContentState, setItemContentState] = useState([]);
  const [videoState, setVideoState] = useState([]);
  const [isLoading2, setIsLoading2] = useState(true);
  const [updateLecture, setUpdateLecture] = useState("");
  const [currentVideoTitle, setCurrentVideoTitle] = useState(videoTitle);
  const [courseContent, setCourseContent] = useState([]);
  const navigate = useNavigate();

  //   console.log(video);

  const formatVideoData = (apiData, course_id, course_name) => {
    return {
      video_id: apiData.content_id || "",
      url: apiData.value,
      title: apiData.title,
      course_id: course_id,
      course_name: course_name,
      content_id: apiData.content_id,
      duration: apiData.duration || "0",
      thumb_nail: apiData.thumb_nail || "",
    };
  };

  const playVideo = useCallback(
    async (internalContent) => {
      if (!internalContent) return;
      setIsLoading(true);
      setSelectedVideo(null);
      setSelectedMP4(null);

      const formattedData = formatVideoData(
        internalContent,
        internalContent?.content_id,
        courseName
      );
      setSelectedVideo(internalContent?.value);
      setSelectedMP4(formattedData);
      setShowVideo(true);

      const tempData = {
        student_id: userState?.userId,
        content_type: "shop",
        content_id: internalContent?.content_id,
      };

      const apiData = await getVedioViewTime(
        tempData,
        userState.token,
        dispatch
      );
      if (apiData) {
        setCurrentViewTime(apiData.data.current_view_time);
      }
      setIsLoading(false);
    },
    [userState, dispatch, courseName]
  );

  const handlePlayClicked = (data, videoContentId) => {
    const index = itemContentState.indexOf(videoContentId * 1);

    for (let i = 0; i < index; i++) {
      // console.log("i", i);
      // console.log("itemContentState[i]", itemContentState[i]);

      if (!videoState[itemContentState[i]]) {
        // console.log("Past Video not completed");
        dispatch(
          applicationActions.setAlertData({
            isAlert: true,
            alertMessage:
              "Please complete the previous video to unlock this video",
            alertType: "error",
          })
        );
        return;
      }
    }

    // console.log(data);
    setUpdateLecture(data?.title);
    setCurrentVideoTitle(data?.title);
    playVideo(data);
  };

  const processData = (data) => {
    // console.log("data", data);

    const chapters = data;

    let chapterState = [];
    let videoState = {};

    chapters?.forEach((chapter, chapterIndex) => {
      // console.log("chapter", chapter);
      //   chapterState[chapter.chapterTitle] = chapterIndex === 0;
      chapter?.content?.forEach((video, videoIndex) => {
        // console.log("video", video);
        // if (videoIndex === 0) {
        //   contentState[chapter.chapterTitle] = [video?.content_id];
        // } else {
        //   contentState[chapter.chapterTitle] = [
        //     ...contentState[chapter.chapterTitle],
        //     video?.content_id,
        //   ];
        // }
        // console.log("video id", video?.content_id);
        chapterState.push(video?.content_id);

        if (video?.linked_test?.length > 0) {
          const mandatoryTest = video?.mandatory_test;

          video?.linked_test?.forEach((test) => {
            // console.log("test", test);
            if (test?.test_id === Number(mandatoryTest)) {
              if (test?.lastAttemptStatus) {
                videoState[video?.content_id] = true;
              } else {
                videoState[video?.content_id] = false;
              }
            }
          });
        } else {
          videoState[video?.content_id] = true;
        }
      });
    });

    // console.log("videoState", videoState);
    // console.log("chapterState", chapterState);

    setVideoState({ ...videoState });
    setItemContentState([...chapterState]);
  };

  const fetchMoreVideos = async (chapterId) => {
    // console.log("Fetching More Videos");
    const data = await getCourseContent(
      courseName,
      userState.userId,
      chapterId,
      dispatch
    );

    if (data) {
      setCourseContent(data?.chapter_content);
      processData(data?.chapter_content);
      setIsLoading2(false);
    }

    // console.log(data?.chapter_content[0]?.content);
  };

  useEffect(() => {
    if (!courseName) {
      navigate("/");
      return;
    }

    if (chapterId) {
      fetchMoreVideos(chapterId);
    }

    if (video) {
      playVideo(video);
    }

    // eslint-disable-next-line
  }, [video, courseName, chapterId]);

  if (isloading) {
    return <Spinner />;
  }

  return (
    <div className="mt-[60px] w-full h-full lg:p-10 md:p-6 p-4 mb-3 ">
      <p className="text-lg font-semibold">
        <span
          className="hover:text-orange-500 cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          Courses /
        </span>
        <span
          className="hover:text-orange-500 cursor-pointer"
          onClick={() => {
            navigate("/course-details", {
              state: { course_id: courseId, activeSession: "Syllabus" },
            });
          }}
        >
          {courseName} /
        </span>
        Course Details
      </p>

      <div className="flex flex-col lg:flex-row gap-3">
        <div className="w-full lg:w-[70%]">
          <div className="flex items-center border border-[#E7EDF6] rounded-lg lg:p-5 p-4 flex-col md:flex-row mt-2 ">
            {/* Left Section */}
            <div className="w-full 2xl:h-[700px] h-full rounded-lg mb-0 md:mb-0 ">
              {showVideo && (
                <VideoPlayer
                  videoUrl={selectedVideo}
                  fileData={selectedMP4}
                  currentViewTimeParent={currentViewTime}
                />
              )}
            </div>
          </div>

          <div className="flex-1 md:ml-4 ml-0 mt-2">
            <h3 className="font-semibold lg:text-2xl md:text-xl text-lg">
              Chapter: {chapterName}
            </h3>
            <h3 className="font-light lg:text-lg md:text-xl text-lg">
              Lecture: {updateLecture || videoTitle}
            </h3>
            {/* <div className="flex flex-row gap-2 flex-wrap mt-2">
          <p className="font-semibold text-sm text-gray-600 mt-1">
            Topics Covered:
          </p>
          <p className="font-thin bg-gray-200 px-2 py-1 text-sm rounded-md">
            Energy Bands
          </p>
          <p className="font-thin bg-gray-200 px-2 py-1 text-sm rounded-md">
            Electric Fields
          </p>
          <p className="font-thin bg-gray-200 px-2 py-1 text-sm rounded-md">
            Magnetic Fields
          </p>
        </div> */}

            {/* <div className="flex flex-row mt-2">
          <p className="text-sm text-gray-600">Progress:</p>
          <div className="md:w-[60%] w-[100%] bg-gray-200 rounded-full h-2 mt-1 mx-2">
            <div
              className="bg-green-500 h-2 rounded-full"
              style={{ width: `${90}%` }}
            ></div>
          </div>
          <p className="text-xs">{90}%</p>
        </div> */}
          </div>
        </div>

        <div className="w-full lg:w-[30%]">
          <div className="w-full h-[0.05rem] bg-slate-200 rounded-2xl mt-2 mb-5 lg:hidden"></div>
          <p className="font-bold lg:text-2xl text-xl mb-2 mt-1">More Videos</p>
          {isLoading2 && <Spinner />}
          {courseContent[0]?.content?.map((video, index) => (
            <div
              className="rounded-lg overflow-hidden lg:mb-3 mb-4"
              key={index}
            >
              <div
                className={`flex items-center justify-between p-3 border  
                    border-gray-200 flex-col md:flex-row
                  `}
              >
                {/* Thumbnail */}
                <img
                  src={
                    video?.thumb_nail ||
                    "https://innovartan.s3.amazonaws.com/4eadcc8be572813e7923174fb796a0dd791780125/caf40280c994c80d7be183281d645eeb.png"
                  }
                  alt="Thumbnail"
                  className="md:w-25 w-30 md:h-12 h-26 rounded-lg object-cover"
                />

                {/* Video Info */}
                <div className="flex-1 md:ml-4 ml-0 md:mt-0 mt-2 ">
                  <h3 className="font-semibold text-base">
                    Lecture: {video?.lesson_no}
                  </h3>
                  {video?.topics?.length > 0 && (
                    <div className="flex flex-row gap-2 flex-wrap">
                      <p className="font-semibold text-xs text-gray-600 mt-1">
                        Topics Covered:
                      </p>
                      {video?.topics?.map((topic, index) => (
                        <p
                          className="font-thin bg-gray-200 px-2 py-1 text-xs rounded-md"
                          key={index}
                        >
                          {topic?.label}
                        </p>
                      ))}
                    </div>
                  )}

                  <div className="mt-1 flex flex-row items-center">
                    <p className="text-xs text-gray-600">Progress:</p>
                    <div className="md:w-[60%] w-[100%] bg-gray-200 rounded-full h-1 mt-1 mx-2">
                      <div
                        className="bg-green-500 h-1 rounded-full"
                        style={{ width: `${video?.progress || "0"}%` }}
                      ></div>
                    </div>
                    <p className="text-xs">{video?.progress || "0"}%</p>
                    {/* <p className="text-xs">{video.content_id || "0"}%</p> */}
                  </div>
                </div>

                {/* Watch Button */}
                <div className="flex flex-row md:flex-col gap-2 mt-2 md:mt-0">
                  <button
                    className={`text-white text-sm px-4 py-2 rounded-lg flex items-center ${
                      currentVideoTitle === video?.title
                        ? "bg-blue-600 cursor-not-allowed hover:bg-blue-400"
                        : "bg-orange-500 hover:bg-orange-300"
                    }`}
                    // disabled={!isVideoViewable[video?.content_id]}
                    onClick={() => {
                      if (currentVideoTitle === video?.title) return;

                      handlePlayClicked(video, video?.content_id);
                    }}
                  >
                    {currentVideoTitle === video?.title
                      ? "Playing..."
                      : "▶ Watch"}
                  </button>
                </div>
              </div>
              <div className="bg-[#E7EDF6]">
                {video?.linked_test?.map((test, index) => (
                  <div
                    key={index}
                    className={`flex md:items-center items-stretch justify-between p-3 border 
                    border-gray-200 flex-col md:flex-row w-full
                  `}
                  >
                    {/* Video Info */}
                    <div className="flex-1 md:ml-4 ml-0 md:mt-0 mt-2">
                      <div className="flex items-center space-x-3">
                        <div
                          className={`w-4 h-4 rounded-full border border-black ${
                            test?.lastAttemptStatus === true
                              ? "bg-[#016f36] border-[#016f36]"
                              : ""
                          }`}
                        ></div>
                        <h3 className="font-semibold text-base">
                          {test?.name}
                        </h3>
                      </div>

                      {/* <p className="font-thin px-2 py-1 text-xs rounded-md ml-5">
                            50 Questions
                          </p> */}
                      {/* <p className="font-thin px-2 py-1 text-xs rounded-md ml-5">
                            {video?.isCompleted ? "completed" : "notcompleted"}
                          </p> */}
                    </div>

                    {/* Watch Button */}
                    <div className="flex flex-row gap-2 mt-2 md:mt-0">
                      {test?.lastAttemptStatus === true && (
                        <button
                          className={`hover:bg-[#0743a3] hover:text-white text-[#0743a3] text-sm px-4 py-2 rounded-lg flex items-center border border-[#0743a3] ${
                            video?.isCompleted
                              ? "cursor-pointer"
                              : "opacity-50 cursor-not-allowed"
                          }   `}
                          disabled={video?.isCompleted ? false : true}
                          onClick={() => {
                            navigate("/ViewTestSubmission", {
                              state: { quizId: test?.test_id },
                            });
                          }}
                        >
                          View Result
                        </button>
                      )}
                      {test?.lastAttemptStatus !== true && (
                        <button
                          className={` hover:bg-orange-500 hover:text-white text-sm px-4 py-2 rounded-lg flex items-center border border-orange-500 text-orange-500 ${
                            video?.isCompleted
                              ? "cursor-pointer"
                              : "opacity-50 cursor-not-allowed"
                          }`}
                          disabled={video?.isCompleted ? false : true}
                          onClick={() => {
                            navigate("/startTest", {
                              state: { quizId: test?.test_id },
                            });
                          }}
                        >
                          Attempt
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseVideoPlayer;
