// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionMenuBar_awardContainer__59Ou\\+ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.QuestionMenuBar_inputContainer__qlZod {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  width: 60px;
  align-items: center;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/OuestionTypes/QuestionMenuBar/QuestionMenuBar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".awardContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.inputContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  border-radius: 10px;\n  border: 1px solid #e0e0e0;\n  width: 60px;\n  align-items: center;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"awardContainer": `QuestionMenuBar_awardContainer__59Ou+`,
	"inputContainer": `QuestionMenuBar_inputContainer__qlZod`
};
export default ___CSS_LOADER_EXPORT___;
