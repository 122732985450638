export const apiDomain = () => {
  const isLocalhost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "qa.innovartan.com";

  if (isLocalhost) {
    return "http://qa.edvanz.com/api/";
  }
  return "https://app.innovartan.com/api/";
};

export const quizDomian = () => {
  const isLocalhost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "qa.innovartan.com";

  if (isLocalhost) {
    return "http://quiz.edvanz.com/api/";
  }
  return "https://quiz.innovartan.com/api/";
};

export const assignmentDomian = () => {
  const isLocalhost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "qa.innovartan.com";

  if (isLocalhost) {
    return "http://assignments.edvanz.com/api/";
  }
  return "https://assignments.innovartan.com/api/";
};

export const analaticsUrl = () => {
  const isLocalhost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "qa.innovartan.com";

  if (isLocalhost) {
    return "http://quiz.edvanz.com/";
  }
  return "https://quiz.innovartan.com/";
};
