import React, { useState, useEffect } from "react";
import "./Course.css";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getAllInstitute, getMyCourses } from "../../../Store/API/API";
import { useNavigate } from "react-router-dom";
import Spinner from "../../UserInterface/Spinner/Spinner";
import classes from "./../Batch/Batch.module.css";
const Courses = () => {
  const userState = useSelector((state) => state.userData.userData);
  const [myCourses, setCourses] = useState([]);
  const [displayCourses, setDisplayCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [institute, setInstitute] = useState("4");
  const [institutesOption, setInstitutesOption] = useState([]);
  const [courseType, setCourseType] = useState("online");
  const dispstch = useDispatch();
  const navigate = useNavigate();

  const handleInstituteChange = (event) => {
    setInstitute(event.target.value);
    fetchCourses(event.target.value);
  };

  const handleCourseTypeChange = (event) => {
    setCourseType(event.target.value);
    setDisplayCourses([]);
    const filterData = myCourses?.filter((course) => {
      return course.course_format_key === event.target.value;
    });
    setDisplayCourses(filterData);
  };

  const fetchCourses = async (instituteId = 4) => {
    const data = await getMyCourses(
      userState.userId,
      userState.token,
      userState.userType,
      instituteId,
      dispstch
    );
    if (data) {
      setCourses(data.shop);
      const filterData = data.shop?.filter((course) => {
        return course.course_format_key === courseType;
      });
      setDisplayCourses(filterData);
    }
    setIsLoading(false);
  };

  const fetchAllInstitutes = async () => {
    const data = await getAllInstitute();
    if (data) {
      setInstitutesOption(data);
    }
  };

  useEffect(() => {
    fetchCourses();
    if (userState.userType === "institute") {
      fetchAllInstitutes();
    }

    // eslint-disable-next-line
  }, [userState]);

  const SectionCard = ({ cardType, data, isVedio }) => {
    return (
      <div
        className="sectionCard"
        onClick={() => {
          navigate("/course-details", { state: { course_id: data.course_id } });
        }}
      >
        <img
          src={data?.cover_photo_url || data?.thumb_nail}
          className="cardImage"
          alt="course"
        ></img>
        <div className="contentContainer">
          <p className="cardHeading">{data?.name}</p>
          {!isVedio && (
            <p className="cardHeading">
              {data?.curriculum || data?.batch_name} |{" "}
              {data?.class || `${data?.fromTime} - ${data?.toTime}`} |{" "}
              {data?.subject || data?.date}
            </p>
          )}
          {<p className="cardHeading">{data?.title}</p>}
          <p className="cardSubHeading">By: Innovartan</p>
        </div>
      </div>
    );
  };
  const Section = ({ cardType, data }) => {
    return (
      <div className="section">
        <div className="sectionCardContainer">
          {data?.map((test, index) => (
            <SectionCard cardType={cardType} data={test} key={index} />
          ))}
        </div>
      </div>
    );
  };
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className="courseDashboard">
      <div className="sectionOuter">
        <div>
          <i className="fa-solid fa-book-open"></i>
          <span style={{ marginLeft: "10px" }}>My Courses</span>
        </div>
      </div>
      <div className="horizontalLine"></div>
      <div className={classes.batchContainer}>
        <div className={classes.dropdownContainer}>
          <div
            style={{
              margin: "20px 0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ maxWidth: "100%" }}
            >
              <InputLabel>Select Course Type</InputLabel>
              <Select
                value={courseType}
                onChange={handleCourseTypeChange}
                label="Select Institute"
                style={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "5px",
                }}
              >
                <MenuItem value={"video_course"}>Video Course</MenuItem>
                <MenuItem value={"online"}>Online Course</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {userState.userType === "institute" && (
          <div className={classes.dropdownContainer}>
            <div
              style={{
                margin: "20px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ maxWidth: "100%" }}
              >
                <InputLabel>Select Institute</InputLabel>
                <Select
                  value={institute}
                  onChange={handleInstituteChange}
                  label="Select Institute"
                  style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                  }}
                >
                  {institutesOption?.map((institute, index) => (
                    <MenuItem key={index} value={institute.value}>
                      {institute.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        )}
        {userState.userType === "institute" && (
          <div
            className="createCourseButton"
            onClick={() => {
              navigate("/AddCourse");
            }}
          >
            <p style={{ margin: "0" }}>Create Course</p>
          </div>
        )}
      </div>
      <div className="horizontalLine"></div>
      {displayCourses?.length > 0 ? (
        <Section
          heading={"My Courses"}
          cardType={"normal"}
          test={false}
          data={displayCourses}
        />
      ) : null}

      {displayCourses?.length === 0 && (
        <div className="noData">
          <h5>{`No ${
            courseType === "online" ? "Online Course" : "Video Course"
          } Found`}</h5>
        </div>
      )}
    </div>
  );
};
export default Courses;
