import React from "react";

const CourseTopHeader = ({ courseDetails }) => {
  return (
    <div className="flex items-center border border-[#E7EDF6] rounded-lg lg:p-5 p-4 w-full lg:w-[70%] flex-col md:flex-row mt-2 ">
      {/* Left Section */}
      <img
        src={courseDetails?.cover_photo_url}
        alt="Atom"
        className="md:w-1/3 w-full 2xl:h-48 h-full rounded-lg mb-0 md:mb-0 "
      ></img>

      {/* Right Section */}
      <div className="md:w-2/3 w-full md:pl-4 pl-0">
        <h2 className="font-bold text-lg xl:text-2xl m-0 mt-1 md:mt-0">
          {courseDetails?.name
            ? courseDetails.name.charAt(0).toUpperCase() +
              courseDetails.name.slice(1)
            : ""}
        </h2>
        <div className="lg:mt-2 mt-1">
          <p className="flex items-center text-gray-700 lg:text-lg md:text-base text-sm">
            <span className="mr-2 bg-[#E7EDF6] p-2 rounded-full">
              <img src="/languageIcon.png" alt="Language" className="h-3 w-3" />
            </span>
            <b className="mr-1">Curriculum :</b> {courseDetails?.curriculum}
          </p>
          <p className="flex items-center text-gray-700 mt-1 lg:text-lg md:text-base text-sm">
            <span className="mr-2 bg-[#E7EDF6] p-2 rounded-full">
              <img src="/duration.png" alt="Language" className="h-3 w-3" />
            </span>
            <b className="mr-1">Duration :</b>
            {`${courseDetails?.validity_days || "365"} Day's Program`}
          </p>
          <p className="flex items-center text-gray-700 mt-1 lg:text-lg md:text-base text-sm">
            <span className="mr-2 bg-[#E7EDF6] p-2 rounded-full">
              <img src="/level.png" alt="Language" className="h-3 w-3" />
            </span>
            <b className="mr-1">Level :</b> Advanced
          </p>
        </div>
      </div>
    </div>
  );
};

export default CourseTopHeader;
