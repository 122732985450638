import React, { useState, useEffect, useDebugValue } from "react";
import classes from "./ViewTestSubmission.module.css";
import TestInfoCard from "./TestInfoCard";
import QuestionInfoArea from "./QuestionInfoArea";
import { getQuizSubmission } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

const ViewTestSubmission = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const [options, setOptions] = useState([]);
  const [apiData, setAPiData] = useState({ question_details: [] });
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [selectedOption, setSelectedOption] = useState("2");
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const { quizId, attemptUserId } = location?.state || {};
  // console.log("quizId", quizId, attemptUserId);
  


  const handleButtonClicks = (index, type) => {
    if (type === "next") {
      if (currentIndex < apiData.question_details.length - 1) {
        setCurrentIndex((prev) => prev + 1);
      }
    }
    if (type === "previous") {
      if (currentIndex > 0) {
        setCurrentIndex((prev) => prev - 1);
      }
    }
    if (type === "jump") {
      setCurrentIndex(index);
    }
  };

  const MCQOption = ({ option }) => {
    // console.log("option", option);
    return (
      <div
        className={`${classes.scOptions} ${
          // selectedOption === option.answer_value ? classes.selectedOption : ""
          classes.selectedOption
        }`}
      >
        <div
          className={
            option.is_selected === "1" &&
            apiData?.question_details[currentIndex]?.is_right === "1"
              ? classes.customRadioChecked
              : option.is_selected === "1" &&
                apiData?.question_details[currentIndex]?.is_right === "0"
              ? classes.customRadioWrong
              : classes.customRadioUnchecked
          }
        >
          {option.answer_value === "1"
            ? "A"
            : option.answer_value === "2"
            ? "B"
            : option.answer_value === "3"
            ? "C"
            : option.answer_value === "4"
            ? "D"
            : option.answer_value === "True"
            ? "A"
            : "B"}
        </div>
        <p style={{ display: "ruby" }}>
          <Latex>{option.name}</Latex>
        </p>
        {/* <div
          dangerouslySetInnerHTML={{
            __html: option.name,
          }}
          className={classes.optionText}
        ></div> */}
      </div>
    );
  };

  useEffect(() => {
    const fetchSubmissionData = async () => {
      try {
        const data = await getQuizSubmission(
          userState.token,
          attemptUserId || userState.userId,
          quizId || userState?.testId,
          dispatch
        );
        // console.log("data", data);
        if (data?.question_details.length > 0) {
          setAPiData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSubmissionData();
  }, [userState]);

  useEffect(() => {
    if (apiData?.question_details[currentIndex]?.answer) {
      setOptions(apiData?.question_details[currentIndex]?.answer);
    }
  }, [currentIndex, apiData]);

  if (loading || apiData.question_details.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.ViewSubmissionOuterContainer}>
      <div className={classes.QuestionArea}>
        <div>
          <div className={classes.TestInfoArea}>
            <TestInfoCard
              heading={"Subject"}
              text={apiData?.question_details[currentIndex]?.question_subject}
            />
            <TestInfoCard
              heading={"Chapter"}
              text={apiData?.question_details[currentIndex]?.question_chapter}
            />
            <TestInfoCard
              heading={"Difficulty"}
              text={apiData?.question_details[currentIndex]?.difficulty_level}
            />
            <TestInfoCard
              heading={"Time Taken"}
              text={apiData?.question_details[currentIndex]?.attempt_time}
            />
          </div>
          <div className={classes.QuestionDisplayArea}>
            <div className={classes.QuestionDisplayAreaInner}>
              <div className={classes.QuestionDisplayAreaSubInner}>
                {apiData?.question_details[currentIndex]?.is_right === "1" ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p className={classes.correctContainer}>
                      {apiData?.question_details[currentIndex]?.correct_points}{" "}
                      Marks
                    </p>
                  </div>
                ) : (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p className={classes.wrongContainer}>-1 Marks</p>
                  </div>
                )}
                {apiData?.question_details[currentIndex]?.is_right === "1" ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p className={classes.correctContainer}>Correct</p>
                  </div>
                ) : (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p className={classes.wrongContainer}>InCorrect</p>
                  </div>
                )}
              </div>
              <p className={classes.QuestionNumberHeading}>
                Question{" "}
                {apiData?.question_details[currentIndex]?.question_counter}
              </p>
              <p style={{ display: "ruby" }}>
                <Latex>
                  {/* {convertLatexToInline(apiData?.question_details[currentIndex]?.question)} */}
                  {apiData?.question_details[currentIndex]?.question}
                </Latex>
              </p>
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: apiData?.question_details[currentIndex]?.question,
                }}
                className={classes.QuestionText}
              ></div> */}
              <div>
                {options?.map((option, index) => (
                  <MCQOption option={option} key={index} />
                ))}
              </div>
            </div>
          </div>
          <div className={classes.QuestionExplanationArea}>
            <h5>Solution</h5>
            <div className={classes.QuestionExplanationAreaInner}>
              <p className={classes.correctContainer2}>
                Your Answer:{" "}
                {apiData.question_details[currentIndex].selected_answer_key ===
                ""
                  ? "NA"
                  : apiData.question_details[currentIndex]
                      .selected_answer_key === "1"
                  ? "A"
                  : apiData.question_details[currentIndex]
                      .selected_answer_key === "2"
                  ? "B"
                  : apiData.question_details[currentIndex]
                      .selected_answer_key === "3"
                  ? "C"
                  : apiData.question_details[currentIndex]
                      .selected_answer_key === "4"
                  ? "D"
                  : apiData.question_details[currentIndex].selected_answer_key}
              </p>
              <p className={classes.wrongContainer2}>
                Correct Answer:{" "}
                {apiData.question_details[currentIndex].currect_answer}
              </p>
            </div>
          </div>
          <div className={classes.ButtonsContainer}>
            {currentIndex > 0 && (
              <div
                className={classes.ButtonContainer}
                onClick={() => {
                  handleButtonClicks(currentIndex, "previous");
                }}
              >
                <p>Previous</p>
              </div>
            )}
            {currentIndex !== apiData?.question_details.length - 1 && (
              <div
                className={classes.ButtonContainer}
                onClick={() => {
                  handleButtonClicks(currentIndex, "next");
                }}
              >
                <p>Next</p>
              </div>
            )}
          </div>
        </div>
        <div className={classes.EvaluationContainer}>
          <div className={classes.EvaluationContainerInner}>
            <p className={classes.EvaluationContainerHeading}>Test Rank</p>
            <p className={classes.EvaluationContainerTextCard}>
              {apiData.quiz_submission_detail.test_rank === ""
                ? "NA"
                : apiData.quiz_submission_detail.test_rank}
            </p>
          </div>
          <div className={classes.EvaluationContainerInner}>
            <p className={classes.EvaluationContainerHeading}>Group Rank</p>
            <p className={classes.EvaluationContainerTextCard}>
              {apiData.quiz_submission_detail.group_rank === ""
                ? "NA"
                : apiData.quiz_submission_detail.group_rank}
            </p>
          </div>
        </div>
      </div>
      <div className={classes.QuestionInfoArea}>
        <QuestionInfoArea
          questionsData={apiData}
          handleButtonClicks={handleButtonClicks}
        />
      </div>
    </div>
  );
};

export default ViewTestSubmission;
