import React, { useEffect, useRef, useState } from "react";
import styles from "./CommonSessionCard.module.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import {
  getSessionDetails,
  getSessionJoinningUrl,
  uploadSessionItem,
} from "../../../Store/API/API";
import VideoPlayer from "../Dashboard/Videoplayer/VideoPlayer";
import { getRecordingTime } from "../../../Store/API/API";
import CustomModal from "../../UI/CustomModal/CustomModal";
import CancelModal from "./CancelModal";
import { handleFileChange } from "../../../Store/API/File";
import CreateModal from "./CreateModal";
// import { Add } from "@mui/icons-material";
import AddTopicModal from "./AddTopicModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Tooltip from "@mui/material/Tooltip";
import UpdateSessionStatusModal from "../Navbar/UpdateSessionStatusModal";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// import { styled } from "@mui/material/styles";

const CommonSessionCard = ({
  session,
  sessionType,
  handleUpdateSession,
  setCreatingSession,
}) => {
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const dispatch = useDispatch();

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [selectedMP4, setSelectedMP4] = useState(null);
  const [currentViewTime, setCurrentViewTime] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [cancelModalData, setCancelModalData] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openAddTopicModal, setOpenAddTopicModal] = useState(false);
  const [isSessionStarted, setIsSessionStarted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState("");
  // eslint-disable-next-line
  const [updateSessionStatusModal, setUpdateSessionStatusModal] =
    useState(false);
  const [sessionDetail, setSessionDetail] = useState([]);
  const [LinkButtonText, setLinkButtonText] = useState("Copy Link");
  // const [selectedFile, setSelectedFile] = useState(null);

  const PDFfileInputRef = useRef(null);
  const PDFfileInputRefUpcoming = useRef(null);
  const VideofileInputRef = useRef(null);
  const menuRef = useRef(null);

  const [active, setActive] = useState(false);
  const [videoActive, setVideoActive] = useState(false);

  // console.log("session", session);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();

      const sessionDate = session.date;
      const sessionTime = session.fromTime;

      const fullSessionDateTime = `${sessionDate}T${sessionTime}:00`;

      const sessionStartTime = new Date(fullSessionDateTime);

      const today = new Date().toISOString().split("T")[0];

      if (sessionDate !== today) {
        setTimeRemaining("Upcoming");
        return;
      }

      const difference = sessionStartTime - now;

      if (difference <= 0) {
        setIsSessionStarted(true);
        setTimeRemaining("");
      } else {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        setTimeRemaining(
          `${hours > 0 ? hours + "h " : ""}${minutes}m ${seconds}s`
        );
      }
    };

    setIsSessionStarted(false);
    calculateTimeRemaining();

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [session.fromTime, session.date, sessionType]);

  const Tag = ({ name, color }) => {
    return (
      <div
        className={styles.tag}
        style={{
          backgroundColor: color,
          width: name === "Mathematics" ? "6rem" : "4.9rem",
        }}
      >
        <p className={styles.tagText}>
          {name.charAt(0).toUpperCase() + name.slice(1)}
        </p>
      </div>
    );
  };
  const Tag2 = ({ name, color, textColor, width, showBlinker }) => {
    return (
      <div
        className={styles.tag}
        style={{
          backgroundColor: color,
          width: width,
          // border: "0.5px solid #F36421",
        }}
      >
        <p
          className={styles.tagText}
          style={{
            color: textColor,
          }}
        >
          {showBlinker && (
            <span
              className={styles.blinkMe}
              style={{
                backgroundColor: textColor,
                border: `1px solid ${textColor}`,
              }}
            ></span>
          )}
          {name.charAt(0).toUpperCase() + name.slice(1)}
        </p>
      </div>
    );
  };

  const formatTo12Hour = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":").map(Number);
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  };

  const formatDate = (date) => {
    if (!date) return "";
    const options = {
      weekday: "long",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const dateObject = new Date(date);
    return new Intl.DateTimeFormat("en-US", options).format(dateObject);
  };

  const formatVideoData = (apiData) => {
    return {
      video_id: apiData.sessionId || "",
      url: apiData.recordingUrlmp4 || "",
      title: apiData.session_chapter || "Untitled",
      course_id: apiData.course_id || "",
      session_id: apiData.sessionId || "",
      duration: "0",
      thumb_nail: "",
    };
  };

  const openVideoModal = async (videoUrl, data) => {
    // console.log("Video URL:", videoUrl);
    // console.log("Received data:", data);
    const formattedData = formatVideoData(data);
    // console.log("Formatted data:", formattedData);
    setSelectedVideoUrl(videoUrl);
    setSelectedMP4(formattedData);
    try {
      // console.log("data", data);
      const tempData = {
        user_id: userState.userId,
        content_type: "session",
        content_id: data.sessionId,
      };
      const apiData = await getRecordingTime(
        tempData,
        userState.token,
        dispatch
      );
      // console.log("Get recording data:", apiData);
      if (apiData) {
        // console.log("current view time ", apiData.current_view_time);
        setCurrentViewTime(apiData.current_view_time);
        // console.log("set  view time ", currentViewTime);
      }
    } catch (error) {
      // console.error("Failed to fetch recording time:", error);
    } finally {
      setShowVideoModal(true);
    }
  };

  const closeVideoModal = () => {
    setSelectedVideoUrl("");
    setSelectedMP4();
    setCurrentViewTime(0);
    setShowVideoModal(false);
  };

  // const handleJoinSession = async (session) => {
  //   const data = await getSessionJoinningUrl(
  //     userState.token,
  //     userState.userId,
  //     session,
  //     batchState.groupId,
  //     dispatch
  //   );

  //   console.log("Join Session Data: ", data);
  //   window.alert("Join Session Data", data);

  //   if (!data) {
  //     window.alert("Failed to join session");
  //     return;
  //   }

  //   if (data) {
  //     window.open(data.meetingUrl, "_blank");
  //   }
  // };

  const handleJoinSession = async (session, triggerSource) => {
    // console.log("Join Session triggerSource: ", triggerSource);

    try {
      // Ensure that the function is called in response to a user action

      let newTab = "";

      if (triggerSource === "copyButton") {
        setLinkButtonText("Copying...");
      }

      if (triggerSource !== "copyButton") {
        newTab = window.open("about:blank", "_blank");
        if (!newTab) {
          throw new Error("Link opened on new tab. Please check your browser.");
        }
      }

      const data = await getSessionJoinningUrl(
        userState.token,
        userState.userId,
        session,
        batchState.groupId,
        dispatch
      );

      // console.log("API Response:", data);

      if (!data || !data.meetingUrl) {
        throw new Error("Invalid response or missing meeting URL");
      }

      if (triggerSource === "copyButton") {
        try {
          await navigator.clipboard.writeText(data.meetingUrl);
          setLinkButtonText("Link Copied");

          dispatch(
            applicationActions.setAlertData({
              isAlert: true,
              alertMessage: "Meeting URL copied to clipboard",
              alertType: "success",
            })
          );

          setTimeout(() => {
            dispatch(
              applicationActions.setAlertData({
                isAlert: false,
                alertMessage: "",
                alertType: "",
              })
            );
            setLinkButtonText("Copy Link");
          }, 3000);
        } catch (error) {
          // Handle clipboard copy failure
          console.error("Failed to copy the meeting URL: ", error);

          dispatch(
            applicationActions.setAlertData({
              isAlert: true,
              alertMessage: "Failed to copy the meeting URL. Please try again.",
              alertType: "error",
            })
          );

          setTimeout(() => {
            dispatch(
              applicationActions.setAlertData({
                isAlert: false,
                alertMessage: "",
                alertType: "",
              })
            );
            // setLinkButtonText("Copy Link");
          }, 3000);
        }

        setLinkButtonText("Copy Link");
        return;
      }

      // Open the meeting URL in the new tab
      newTab.location.href = data.meetingUrl;

      // Optionally, you can close the new tab if the URL is not valid
      // newTab.close(); // Uncomment if you want to close the tab on error
    } catch (error) {
      console.error("Error joining session:", error);
      window.alert(`${error.message || error}`);
    }
  };

  const handleFile = async (e, type) => {
    const file = e.target.files[0];
    const isVideo = e.target.accept === "video/*";

    if (file) {
      if (isVideo) {
        setVideoActive(true);
      } else {
        setActive(true);
      }
      const fileUrl = await handleFileChange(file, dispatch);
      if (isVideo) {
        setVideoActive(false);
      } else {
        setActive(false);
      }
      // console.log("File URL: ", fileUrl);
      if (fileUrl?.data?.file_url) {
        const tempData = {
          session_id: session.sessionId,
          type: e.target.accept === "video/*" ? "recording" : "notes",
          file_path: fileUrl?.data?.file_url,
        };

        console.log("Temp Data: ", tempData);

        const data = await uploadSessionItem(tempData, dispatch);

        // console.log("Data: ", data);

        if (data?.statuscode === "200") {
          if (type === "pdfUpcoming") {
            handleUpdateSession("upcoming");
          } else {
            handleUpdateSession(
              "history",
              session.sessionId,
              fileUrl?.data?.file_url,
              e.target.accept === "video/*" ? "recordingUrl" : "notes"
            );
          }
        }
        // console.log("Upload Session Item: ", data);
      }
    }
  };

  const triggerFileInput = (type) => {
    if (type === "video") {
      VideofileInputRef.current.click();
    }
    if (type === "pdf") {
      PDFfileInputRef.current.click();
    }
    if (type === "pdfUpcoming") {
      PDFfileInputRefUpcoming.current.click();
    }
  };

  const hanldeUpdateCurriculam = async (e) => {
    const tempData = await getSessionDetails(session?.sessionId, dispatch);

    if (tempData?.data?.session_id) {
      setSessionDetail(tempData?.data);
      setTimeout(() => setUpdateSessionStatusModal(true), 100);
    } else {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Curricular not available for this session",
          alertType: "error",
        })
      );
    }
  };

  const MenuTab = () => {
    return (
      <div className={styles.menuTab} ref={menuRef}>
        <div
          className={styles.menuItem}
          onClick={() => {
            // handleUpdateSession("cancelled", session.sessionId);
            setOpenCancelModal(!openCancelModal);
            setCancelModalData({ sessionId: session.sessionId });
          }}
        >
          <DeleteIcon />
          <p>Cancel Session</p>
        </div>
        {sessionType === "Upcoming" ? (
          <>
            <div
              className={styles.menuItem}
              onClick={() => {
                setOpenCreateModal(!openCreateModal);
              }}
            >
              <EditIcon />
              <p>Edit Session</p>
            </div>
            {session?.notes?.length !== 0 && (
              <div
                className={styles.menuItem}
                onClick={() => {
                  triggerFileInput("pdfUpcoming");
                }}
              >
                <AddIcon />
                <p>Upload New Notes</p>
              </div>
            )}
          </>
        ) : (
          <>
            <div
              className={styles.menuItem}
              onClick={() => {
                triggerFileInput("video");
              }}
            >
              <CloudUploadIcon />
              <p>Upload New Video</p>
            </div>
            <div
              className={styles.menuItem}
              onClick={() => {
                triggerFileInput("pdf");
              }}
            >
              <AddIcon />
              <p>Upload New Notes</p>
            </div>
            {/* <div
              className={styles.menuItem}
              onClick={() => {
                hanldeUpdateCurriculam();
              }}
            >
              <EditIcon />
              <p>Update Curriculum</p>
            </div> */}
          </>
        )}
      </div>
    );
  };

  // const renderTooltipContent = () => (
  //   <Typography
  //     style={{
  //       display: "-webkit-box",
  //       WebkitBoxOrient: "vertical",
  //       overflow: "hidden",
  //       textOverflow: "ellipsis",
  //       maxWidth: "300px", // Adjust width as needed
  //     }}
  //   >
  //     <div className={styles.infoInstructions}>
  //       <h3 className={styles.infoH3}>
  //         Instructions If you are using an iPad or mobile:
  //       </h3>
  //       <ul className={styles.infoUl}>
  //         <li className={styles.infoLi}>
  //           First download the Big Blue Button app from the iOS/Play Store.
  //         </li>
  //         <li className={styles.infoLi}>
  //           Copy the link from the session card.
  //         </li>
  //         <li className={styles.infoLi}>Paste the link into the app.</li>
  //       </ul>
  //       <p className={styles.infoNote}>
  //         Note: If you click on the "Start" button on an iPad, you will not be
  //         able to share your screen.
  //       </p>

  //       <h3 className={styles.infoH3}>If you are using a laptop:</h3>
  //       <ul className={styles.infoUl}>
  //         <li className={styles.infoLi}>Simply click the "Start" button.</li>
  //       </ul>
  //     </div>
  //   </Typography>
  // );

  // const LightTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.white,
  //     color: "rgba(0, 0, 0, 0.87)",
  //     // boxShadow: theme.shadows[1],
  //     boxShadow: "2px 4px 60px 0px #0732781A",
  //     borderRadius: "10px",
  //     maxWidth: 500,
  //   },
  // }));

  // console.log("session", sessionType);

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.tagContainer}>
          <Tag name={session?.session_subject || "NA"} color="#0743A3" />
          <Tag2
            width={"4rem"}
            name={session?.session_class || "NA"}
            color="#FEF0E9"
            textColor={"#F36421"}
          />
          {session?.type.toLowerCase() === "online" ? (
            <Tag2
              width={"4rem"}
              textColor={"#74AE42"}
              name={session?.type || "NA"}
              showBlinker={true}
            />
          ) : (
            <Tag2
              width={"4rem"}
              textColor={"#F32121"}
              name={session?.type || "NA"}
              showBlinker={true}
            />
          )}
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          {/* Instruction Tool Tip (REMOVED) */}
          {sessionType === "Upcoming" &&
            batchState?.batchUserType !== "student" && (
              // <LightTooltip
              //   title={renderTooltipContent()}
              //   placement="bottom"
              //   arrow
              // >
              //   <div
              //     style={{
              //       border: "1px solid #E0E0E0",
              //       borderRadius: "20px",
              //       width: "26px",
              //       height: "26px",
              //       display: "flex",
              //       justifyContent: "center",
              //       alignItems: "center",
              //       cursor: "pointer",
              //     }}
              //   >
              //     <InfoOutlinedIcon
              //       style={{ color: "#494949", fontSize: "22px" }}
              //     />
              //   </div>
              // </LightTooltip>
              <div></div>
            )}

          {/* Curriculum Progress Bar */}
          {batchState?.batchUserType !== "student" &&
            sessionType === "History" && (
              // <Tooltip
              //   title={session?.curricular_percent || 0}
              //   placement="bottom"
              //   arrow
              // >
              <div
                style={{ width: 40, height: 28 }}
                className="cursor-pointer mr-2"
                onClick={() => {
                  hanldeUpdateCurriculam();
                }}
              >
                <CircularProgressbar
                  value={session?.curricular_percent || 0}
                  maxValue={100}
                  text={`${session?.curricular_percent || "0"}%`}
                  strokeWidth={12}
                  styles={buildStyles({
                    pathColor: "#74AD41", // Set the progress bar color
                    textColor: "#000", // Set the text color
                    trailColor: "#E0E0E0", // Optional: Change the background track color
                    marginTop: "10px",
                    textSize: "28px", // Increase text size
                  })}
                />
              </div>
              // </Tooltip>
            )}

          {/* Menu Bar Button */}
          {batchState?.batchUserType !== "student" &&
            sessionType !== "Cancelled" && (
              <div
                style={{
                  border: "1px solid #E0E0E0",
                  borderRadius: "20px",
                  width: "26px",
                  height: "26px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowMenu(!showMenu)}
              >
                <MoreVertIcon
                  style={{ color: "#000", width: "20px", height: "20px" }}
                />

                {showMenu && <MenuTab />}
              </div>
            )}
        </div>
      </div>
      <Tooltip title={session?.session_chapter} placement="bottom" arrow>
        <p className={styles.heading}>
          {`${session?.session_chapter || "NA"}`}
        </p>
      </Tooltip>
      <Tooltip
        title={
          session?.session_topic?.length > 0
            ? session?.session_topic?.join(", ")
            : "NA"
        }
        placement="bottom"
        arrow
      >
        <p className={styles.subheading}>
          {`${
            session?.session_topic?.length > 0
              ? session?.session_topic?.join(", ")
              : "NA"
          }`}
        </p>
      </Tooltip>
      <div className={styles.infoContainer}>
        <CalendarMonthIcon
          style={{ color: "#494949", fontSize: "20px", marginTop: "-3px" }}
        />
        <p className={styles.infoText}>{`Date: ${formatDate(
          session?.date
        )}`}</p>
      </div>
      <div className={styles.infoContainer}>
        <AccessTimeIcon
          style={{ color: "#494949", fontSize: "20px", marginTop: "-3px" }}
        />
        <p className={styles.infoText}>{`Time: ${formatTo12Hour(
          session?.fromTime
        )} - ${formatTo12Hour(session?.toTime)}`}</p>
      </div>
      {sessionType !== "Upcoming" && (
        <div className={styles.bottomLeft}>
          <img
            src={session?.tutor_profile_image}
            alt="Institute Demo"
            className={styles.image2}
          />
          <p
            className={styles.bottomLeftText2}
          >{`Teacher: ${session?.session_tutor_name}`}</p>
        </div>
      )}
      <div className={styles.horizontalLine}></div>
      <div className={styles.bottomContainer}>
        {sessionType === "History" && (
          <div className={styles.historyBottomContainer}>
            <div className={styles.historyInnerContainer}>
              <Tooltip
                title={
                  session?.session_topic?.length > 0
                    ? session.session_topic.join(", ")
                    : "NA"
                }
                placement="bottom"
                arrow
              >
                <p className={styles.historyTopicText}>
                  Topics:{" "}
                  {session?.session_topic?.length > 0
                    ? session.session_topic.join(", ")
                    : "NA"}
                </p>
              </Tooltip>
              {batchState?.batchUserType !== "student" && (
                <div
                  className={styles.addTopicButton}
                  onClick={() => {
                    setOpenAddTopicModal(true);
                  }}
                >
                  <AddIcon style={{ fontSize: "20px" }} /> Add
                </div>
              )}
            </div>
            <div className={styles.historButtonContainer}>
              {session?.notes !== 0 ? (
                <div
                  className={styles.notesButton}
                  onClick={() => window.open(session.notes)}
                >
                  {active ? (
                    "Uploading Notes..."
                  ) : (
                    <>
                      <ArrowCircleDownIcon />
                      Download Notes
                    </>
                  )}
                </div>
              ) : (
                <>
                  {batchState?.batchUserType !== "student" && (
                    <div
                      className={styles.notesButton}
                      onClick={() => {
                        triggerFileInput("pdf");
                      }}
                    >
                      {active ? (
                        "Uploading Notes..."
                      ) : (
                        <>
                          <AddIcon />
                          Add Notes
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
              {session.recordingUrl !== "" ? (
                <div
                  className={styles.videoButton}
                  onClick={() => openVideoModal(session.recordingUrl, session)}
                >
                  {videoActive ? (
                    "Uploading Video..."
                  ) : (
                    <>
                      <PlayCircleIcon />
                      Watch Video
                    </>
                  )}
                </div>
              ) : (
                <>
                  {batchState?.batchUserType !== "student" && (
                    <div
                      className={styles.videoButton}
                      onClick={() => {
                        triggerFileInput("video");
                      }}
                    >
                      {videoActive ? (
                        "Uploading Video..."
                      ) : (
                        <>
                          <div className="mr-2">
                            <CloudUploadIcon />
                          </div>
                          Add Video
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {sessionType === "Upcoming" && (
          <div className={styles.upcomingBottomContainer}>
            <div className={styles.bottomLeft}>
              <img
                src={session?.tutor_profile_image}
                alt="Institute Demo"
                className={styles.image}
              />
              <p
                className={styles.bottomLeftText}
              >{`Teacher: ${session?.session_tutor_name}`}</p>
            </div>
            <div className={styles.bottomRight}>
              {isSessionStarted ? (
                session?.type.toLowerCase() === "online" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <div
                      className={styles.bottomRightButton}
                      // onTouchStart={() => handleJoinSession(session.sessionId)}
                      onClick={() => {
                        handleJoinSession(session.sessionId);
                      }}
                    >
                      <PlayCircleIcon
                        style={{ color: "#fff", fontSize: "20px" }}
                      />
                      <p className={styles.startButton}>Start</p>
                    </div>
                    {/* {batchState?.batchUserType !== "student" && (
                      <div
                        className={styles.bottomRightButton}
                        style={{
                          backgroundColor:
                            LinkButtonText !== "Copy Link"
                              ? "#ef9f7c"
                              : "#E7EDF6",
                          cursor:
                            LinkButtonText === "Copy Link"
                              ? "pointer"
                              : "not-allowed",
                        }}
                        // onTouchStart={() =>
                        //   handleJoinSession(session.sessionId, "copyButton")
                        // }
                        onClick={() => {
                          LinkButtonText === "Copy Link" &&
                            handleJoinSession(session.sessionId, "copyButton");
                        }}
                      >
                        <ContentCopyIcon
                          style={{
                            color:
                              LinkButtonText === "Copy Link"
                                ? "#494949"
                                : "#fff",
                            fontSize: "20px",
                          }}
                        />
                        <p
                          className={styles.startButton}
                          style={{
                            color:
                              LinkButtonText === "Copy Link"
                                ? "#494949"
                                : "#fff",
                          }}
                        >
                          {LinkButtonText}
                        </p>
                      </div>
                    )} */}
                    {/* {batchState?.batchUserType !== "student" && (
                      <Tooltip
                        title={renderTooltipContent()}
                        placement="bottom"
                        arrow
                      >
                        <div
                          className={styles.bottomRightButton}
                          style={{
                            backgroundColor:
                              LinkButtonText !== "Copy Link" ? "#ef9f7c" : "",
                            cursor:
                              LinkButtonText === "Copy Link"
                                ? "pointer"
                                : "not-allowed",
                          }}
                          // onTouchStart={() =>
                          //   handleJoinSession(session.sessionId, "copyButton")
                          // }
                          onClick={() => {
                            LinkButtonText === "Copy Link" &&
                              handleJoinSession(
                                session.sessionId,
                                "copyButton"
                              );
                          }}
                        >
                          <InfoIcon
                            style={{ color: "#fff", fontSize: "20px" }}
                          />
                        </div>
                      </Tooltip>
                    )} */}
                  </div>
                )
              ) : (
                <div
                  className={styles.bottomRightButton}
                  style={{ backgroundColor: "#ef9f7c" }}
                >
                  <p className={styles.startButton}>{timeRemaining}</p>
                </div>
              )}
            </div>
          </div>
        )}
        {sessionType === "Cancelled" && (
          <div className={styles.cancelledContainer}>
            <p className={styles.cancelledText}>
              Cancelled:{" "}
              <span className={styles.cancelledReason}>
                {session?.cancelReason || "NA"}
              </span>
            </p>
          </div>
        )}
      </div>
      {showVideoModal && (
        <div className="videoModal">
          <div className="videoModalContent">
            <span className="close" onClick={closeVideoModal}>
              &times;
            </span>
            <VideoPlayer
              videoUrl={selectedVideoUrl}
              fileData={selectedMP4}
              currentViewTimeParent={currentViewTime}
              type="session"
            />
          </div>
        </div>
      )}
      <input
        type="file"
        ref={PDFfileInputRef}
        style={{ display: "none" }}
        accept=".pdf"
        onChange={handleFile}
      />
      <input
        type="file"
        ref={VideofileInputRef}
        style={{ display: "none" }}
        accept="video/*"
        onChange={handleFile}
      />
      <input
        type="file"
        ref={PDFfileInputRefUpcoming}
        style={{ display: "none" }}
        accept=".pdf"
        id="pdfUpcoming"
        onChange={(e) => handleFile(e, "pdfUpcoming")}
      />
      <CustomModal
        isOpen={openCancelModal}
        onClose={() => setOpenCancelModal(!openCancelModal)}
      >
        <CancelModal
          onClose={() => setOpenCancelModal(!openCancelModal)}
          handleUpdateSession={handleUpdateSession}
          cancelModalData={cancelModalData}
        />
      </CustomModal>
      <CustomModal
        isOpen={openCreateModal}
        onClose={() => setOpenCreateModal(!openCreateModal)}
      >
        <CreateModal
          onClose={() => setOpenCreateModal(!openCreateModal)}
          modalType="edit"
          sessionId={session?.sessionId}
          setCreatingSession={setCreatingSession}
          handleUpdateSession={handleUpdateSession}
          // creatingSession={setCreatingSession}
        />
      </CustomModal>
      <CustomModal
        isOpen={openAddTopicModal}
        onClose={() => setOpenAddTopicModal(!openAddTopicModal)}
      >
        <AddTopicModal
          sessionId={session.sessionId}
          handleUpdateSession={handleUpdateSession}
          onClose={() => setOpenAddTopicModal(!openAddTopicModal)}
        />
      </CustomModal>
      <CustomModal
        isOpen={updateSessionStatusModal}
        onClose={() => setUpdateSessionStatusModal(false)}
      >
        <UpdateSessionStatusModal
          handleModalSubmit={(progress, videoLive, dppLive) => {
            handleUpdateSession(
              "curriculumUpdate",
              session?.sessionId,
              "",
              "",
              { progress, videoLive, dppLive }
            );

            console.log("progress", progress);
            console.log("videoLive", videoLive);
            console.log("dppLive", dppLive);

            setUpdateSessionStatusModal(false);
          }}
          sessionDetail={sessionDetail}
          isLiveDpp={
            session?.live_dpp === "1" || session?.live_dpp === 1 ? true : false
          }
          isLiveVideo={
            session?.live_video === "1" || session?.live_video === 1
              ? true
              : false
          }
          progressStatus={session?.curricular_percent || 0}
          // showEditButton={true}
          showEditButton={
            session?.curricular_percent === "0" ||
            session?.curricular_percent === "" || 
            session?.curricular_percent === 0
              ? true
              : false
          }
          onClose={() => setOpenAddTopicModal(!openAddTopicModal)}
        />
      </CustomModal>
    </div>
  );
};
export default CommonSessionCard;
