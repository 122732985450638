import React from "react";
import styles from "./QuestionsBottomTab.module.css";
import { Tooltip } from "@mui/material";

const QuestionsBottomTag = ({ questionData }) => {
  const tootltipTopic =
    questionData?.question_topics?.length > 0
      ? questionData?.question_topics.map((topic) => topic?.label).join(", ")
      : "No topics available";
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <span>Class :</span>
        <strong>{questionData?.question_class}</strong>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.item}>
        <span>Subject :</span>
        <strong>{questionData?.question_subject}</strong>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.item}>
        <span>Chapter :</span>
        <strong>{questionData?.question_chapter}</strong>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.item}>
        <span>Topic :</span>
        <Tooltip title={tootltipTopic}>
          <strong>
            {questionData?.question_topics?.length
              ? (() => {
                  const topicsString = questionData.question_topics
                    .map((topic) => topic?.label)
                    .join(", ");

                  return topicsString.length > 40
                    ? topicsString.slice(0, 40) + "..."
                    : topicsString;
                })()
              : " - "}
          </strong>{" "}
        </Tooltip>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.item}>
        <span>PYQ :</span>
        <strong>{questionData?.pyq || "-"}</strong>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.item}>
        <span>Last Updated :</span>
        <strong>
          {questionData?.updated_ts + ", " + questionData?.modify_by}
        </strong>
      </div>
    </div>
  );
};

export default QuestionsBottomTag;
