import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./TeacherSubmissionView.module.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { getTestSubmissions } from "../../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TimelineIcon from "@mui/icons-material/Timeline";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Spinner from "../../../UserInterface/Spinner/Spinner";

const TeacherSubmissionView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);

  const [submissionList, setSubmissionList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { testId, testDetails } = location?.state || "";
  // console.log(testId, "testId");

  const fetchSubmissionList = async () => {
    const data = await getTestSubmissions(
      userState.userId,
      batchState.groupId,
      testId,
      userState.token,
      dispatch
    );
    if (data) {
      setSubmissionList(data);
      setIsLoading(false);
      // console.log(data, "data");
    }
  };

  useEffect(() => {
    fetchSubmissionList();
    // eslint-disable-next-line
  }, [testId]);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.headerSection}>
        <div className={styles.headingContainer}>
          <div style={{ display: "flex" }}>
            <p className={styles.heading}>{`Test > ${
              testDetails?.title || ""
            }`}</p>
            {/* <p className={styles.heading}>{testId}</p> */}
          </div>
          <div>
            {/* <p className={styles.heading}>
              {testDetails?.status === "Published" ? (
                <span style={{ color: "green" }}>{testDetails?.status}</span>
              ) : (
                <span style={{ color: "red" }}>{testDetails?.status}</span>
              )}
            </p> */}
            <div style={{ display: "flex" }}>
              <p className={styles.heading2}>{`ID: ${testId}`}</p>
            </div>
          </div>
          <div className={styles.buttonContainer1}>
            <button
              className={styles.inactiveButton}
              onClick={() =>
                navigate("/teacher-test-view", {
                  state: { testId, testDetails },
                })
              }
            >
              View Test
            </button>
            <button className={styles.activeButton} disabled>
              View Submissions
            </button>
          </div>
        </div>
        <div className={styles.buttonContainer2}>
          <button
            className={styles.inactiveButton}
            onClick={() =>
              navigate("/teacher-test-view", { state: { testId, testDetails } })
            }
          >
            View Test
          </button>
          <button className={styles.activeButton} disabled>
            View Submissions
          </button>
        </div>

        {/* <div className={styles.detailcontainer}>
          <div style={{ display: "flex" }}>
            <p className={styles.heading2}>{`ID: ${testId}`}</p>
          </div>
          <div className={styles.buttonContainer3}>
            <button className={styles.extraButton} disabled>
              Solutions
            </button>
            <button className={styles.extraButton}>Download Test</button>
          </div>
        </div> */}
      </div>
      {isLoading ? (
        <Spinner spinnerHeight={"50%"} />
      ) : (
        <div className={styles.contentSection}>
          <Table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <Thead>
              <Tr className="bg-[#0A3C87] text-white uppercase text-sm leading-normal">
                <Th className="py-3 px-6 text-center">Student Name</Th>
                <Th className="py-3 px-6 text-center">
                  Submission Date - Time
                </Th>
                <Th className="py-3 px-6 text-center">Status</Th>
                <Th className="py-3 px-6 text-center">Rank</Th>
                <Th className="py-3 px-6 text-center">Actions</Th>
              </Tr>
            </Thead>
            <Tbody className="text-gray-600 text-sm font-light">
              {submissionList?.length === 0 && (
                <Tr>
                  <Td
                    colSpan="5"
                    className="py-3 px-6 text-center text-orange-500 font-bold"
                  >
                    No Submission Found
                  </Td>
                </Tr>
              )}

              {submissionList?.map((item, idx) => (
                <Tr
                  className="border-b border-gray-200 hover:bg-gray-100"
                  key="1"
                >
                  <Td className="py-3 px-6 text-center font-bold ">
                    {item?.student_name || " - "}
                  </Td>
                  <Td className="py-3 px-6 text-center">
                    {item?.date_time || " - "}
                  </Td>
                  <Td className="py-3 px-6 text-center">
                    {item?.status
                      ? item.status.charAt(0).toUpperCase() +
                        item.status.slice(1)
                      : "-"}
                  </Td>
                  <Td className="py-3 px-6 text-center">{item?.rank || "-"}</Td>
                  <Td className="py-3 px-6 text-center">
                    <div className={styles.buttonContainer}>
                      <div
                        onClick={() => {
                          navigate("/ViewTestSubmission", {
                            state: {
                              quizId: testId,
                              attemptUserId: item?.studentt_id,
                            },
                          });
                        }}
                        className={` text-white xl:w-[4rem] bg-green-500 rounded px-3 py-1 transition duration-200 ease-in-out hover:bg-green-600 cursor-pointer`}
                      >
                        <RemoveRedEyeIcon style={{ fontSize: "23px" }} />
                      </div>
                      <div
                        // onClick={() => {
                        //   navigate("/view-analysis", {
                        //     state: { framUrl: `https://${item?.analytic_url}` },
                        //   });
                        // }}
                        onClick={() => {
                          navigate("/analysis", {
                            state: {
                              quizId: testId,
                              attemptId: item?.attempt_id,
                              student_id: item?.studentt_id,
                            },
                          });
                        }}
                        className={` text-white xl:w-[4rem] bg-green-500 rounded px-3 py-1 transition duration-200 ease-in-out hover:bg-green-600 cursor-pointer`}
                      >
                        <TimelineIcon style={{ fontSize: "23px" }} />
                      </div>
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default TeacherSubmissionView;
