// src/components/Login.js
import React, { useEffect, useState } from "react";
import { MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styles from "./Login2.module.css";
// import sideImg from 'images/image.png';
// import logo from 'images/logo.png';
import { useAuth } from "../../../../Context/AuthContext"; // Adjust path based on your project structure
import { loginEmail } from "../../../../Store/API/API";
import { useDispatch } from "react-redux";
import { userDataActions } from "../../../../Store/Data/Slices/UserDataSlice";
import Cookies from "universal-cookie";

const Login2 = () => {
  const cookies = new Cookies();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();
  const dispatch = useDispatch();

  // change favicon based on hostname
  const hostname = window.location.hostname;
  console.log(hostname, "hostname");

  const school = hostname.split(".")[0];
  // const school = 'dpsg';
  console.log(school, "school");

  const changeFavicon = (iconUrl, title) => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = iconUrl;
    document.getElementsByTagName("head")[0].appendChild(link);

    if (title) {
      document.title = title;
    }
  };

  useEffect(() => {
    if (school === "dpsg")
      changeFavicon("dpsg.png", "Delhi Public School Ghaziabad");
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const credentials = btoa(`${username}:${password}`);
    const data = await loginEmail(credentials, dispatch);
    // console.log(data, "userData");
    let tempData = {
      token: data?.token,
      userId: data?.user_id,
      instituteId: data?.institute_id,
      firstName: data?.first_name,
      lastName: data?.last_name,
      userType: data?.user_type,
    };
    // console.log(tempData);
    if (tempData.token) {
      dispatch(userDataActions.setUserData(tempData));
      // navigate('/dashboard');
      cookies.set("userData", JSON.stringify(tempData), {
        sameSite: "None",
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
      });
      localStorage.setItem("userData", JSON.stringify(tempData));
    } else {
      setError("Invalid username or password.");
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}></div>
      <div className={styles.rightContainer}></div>
    </div>
  );
};

export default Login2;
