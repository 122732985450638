
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import HeaderComponent from "../Navbar/Header/HeaderComponent";
import styles from "../../Pages/Reports/Report.module.css";
import SelectInput from "../../UI/SelectInput/SelectInput";
import ReportData from "./ReportData";
import { useDispatch, useSelector } from "react-redux";
import { RiLoginBoxLine, RiLogoutBoxLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { fetchLogAnalysis } from "../../../Store/API/API";
import { getAllEvents } from "../../../Store/API/API"; 

const SelectInputField2 = ({
  heading,
  placeholder,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
  required,
}) => {
  return (
    <div className={styles.selectWrapper}>
      {required ? (
        <label className={styles.label}>
          {heading} <span className={styles.required}>*</span>
        </label>
      ) : (
        <label className={styles.label}>{heading}</label>
      )}
      <div className={styles.dropDownWrapper}>
        <SelectInput
          width={"100%"}
          placeHolder={placeholder}
          options={dropDownoptions}
          handleInputChange={handleInputChange}
          value={dropDownValues}
          backgroundColor={"#1b3156"}
          border={"none"}
          dropdownTextColor={"#FFFFFF"}
        />
      </div>
    </div>
  );
};

const SelectedDatePicker = ({ label, value, onChange }) => {
  return (
    <div className="flex flex-col text-white font-metropolis text-sm w-full">
      <label className="mb-1">{label}</label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onChange={onChange}
          format="DD/MM/YYYY"
          sx={{
            "& .MuiInputBase-root": {
              color: "white",
              backgroundColor: "#1b3156",
              borderRadius: "8px",
              height: "40px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#1b3156",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          }}
          slotProps={{
            textField: {
              placeholder: "DD/MM/YYYY",
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

const ReportFilter = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [event, setEvent] = useState({
    value: "login",
    label: "Logged In"
},); 
  const [eventsOption, setEventsOption] = useState([]); 

  const [type, setType] = useState({ value: "All", label: "All" });
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const fetchEvents = async () => {
    try {
       const data = await getAllEvents();
          if (data) {
            setEventsOption(data);
            if (data.length > 0) {
              setEvent(data[0]);
            }
          }
    } catch (e) { 
      console.log("Error fetching events:", e);
    }
  };
      
  const fetchLogAnalysisHandler = async () => {
    if (!fromDate || !toDate) {
      setErrors("Please select both From and To dates.");
      return;
    }

    setIsLoading(true);
    setErrors(null);

    try {
      const response = await fetchLogAnalysis(
        userState,
        fromDate.format("YYYY-MM-DD"),
        toDate.format("YYYY-MM-DD"),
        event,
        dispatch,
        type
      );

      if (response && response.statuscode === "200") {
        let filteredLogs = response.data;
        filteredLogs = filteredLogs.filter((log) => {
          const logDate = dayjs(log.time, "DD MMM YYYY hh:mm A");
          const isBetween =
            logDate.isAfter(fromDate.startOf("day")) &&
            logDate.isBefore(toDate.endOf("day"));
          const isUserTypeMatch =
            type.value.toLowerCase() === "all" ||
            type.value.toLowerCase() === log.user_type.toLowerCase();

          return isBetween && isUserTypeMatch;
        });

        const mappedData = filteredLogs.map((log) => ({
          time: log.time,
          user_name: log.user_name,
          user_type: log.user_type,
          entity_action: log.entity_action,
          entity_source: log.entity_source,
          icon: getIcon(log.entity_action),
        }));

        setReportData(mappedData);

        if (mappedData.length === 0) {
          setErrors("No logs found for selected filters.");
        }
      } else {
        setErrors(response?.message || "Failed to fetch report data.");
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
      setErrors("An error occurred while fetching the report data.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents(); 
  }, []);

  useEffect(() => {
      fetchLogAnalysisHandler();
  }, []);

  const getIcon = (action) => {
    switch (action.toLowerCase()) {
      case "login":
      case "user_logged_in":
        return <RiLoginBoxLine size={25} />;
      case "logout":
      case "user_logged_out":
        return <RiLogoutBoxLine size={25} />;
      default:
        return <FiEdit size={25} />;
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-base leading-[20px] lg:text-[24px] lg:leading-[28px] font-bold">
        Reports / View Report
      </h2>
      <div>
        <HeaderComponent>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 items-end">
            <SelectedDatePicker
              label="From"
              value={fromDate}
              onChange={setFromDate}
            />

            <SelectedDatePicker
              label="To"
              value={toDate}
              onChange={setToDate}
            />

            <SelectInputField2
              heading="Event"
              field="event"
              dropDownValues={event}
              dropDownoptions={eventsOption} 
              handleInputChange={(field, value) => setEvent(value)}
            />

            <SelectInputField2
              heading="Type"
              field="type"
              dropDownValues={type}
              dropDownoptions={[
                { value: "All", label: "All" },
                { value: "Teacher", label: "Teacher" },
                { value: "Student", label: "Student" },
              ]}
              handleInputChange={(field, value) => setType(value)}
            />

            <button
              onClick={fetchLogAnalysisHandler}
              disabled={isLoading}
              className={`${
                isLoading
                  ? "bg-textGrey cursor-not-allowed"
                  : "bg-primaryOrange hover:bg-primaryOrangeDisabled"
              } text-white rounded-lg px-6 py-2 h-[40px] text-sm font-semibold transition-all`}
            >
              {isLoading ? "Loading..." : "View Report"}
            </button>
          </div>
        </HeaderComponent>
      </div>
      {errors && <div className="mt-4 font-medium">{errors}</div>}
      <div className="mt-8 mb-10">
        <ReportData activities={reportData} />
      </div>
    </div>
  );
};

export default ReportFilter;
