import React from "react";
import classes from "./NoInternet.module.css";

const NoInternet = () => {
  return (
    <div className={classes.outerContainer}>
      <img
        className={classes.noInternetImg}
        src="./noInternet.jpg"
        alt="No Internet"
      />
      <p className={classes.noInternetHeading} >Oops...</p>
      <p className={classes.noInternetText}>
        There is a connection error. Please check your internet and try again
      </p>
    </div>
  );
};

export default NoInternet;
