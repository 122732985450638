import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Feedback.css";
import { getchapterOfGroup } from "../../../Store/API/API";
import { addFeedback } from "../../../Store/API/API";
import { useNavigate } from "react-router-dom";
const Feedback = () => {
  const subjects = ["Biology", "Chemistory", "Mathmetics", "Physics"];
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const handleSubjectChange = async (e) => {
    const selectedSubject = e.target.value;
    setSelectedSubject(selectedSubject);
    setLoading(true);
    try {
      const data = await getchapterOfGroup(
        userState,
        batchState.groupId,
        selectedSubject,dispatch
      );
      if (!data) {
        throw new Error("Invalid data structure");
      }
      setChapters(data);
      const tempData = {
        subject: selectedSubject,
      };
      setFormData(tempData);
    } catch (error) {
      console.error("Error fetching subject:", error);
      setChapters([]);
    } finally {
      setLoading(false);
    }
  };
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedSubject == "") {
      setError("Subject is mendatory !");
    }
    if (selectedSubject == "") {
      setError("Chapter is mendatory !");
    }
    var isValid = validateRadioButton();
    if (isValid == "0") {
      addFeedback(userState, formData, batchState);
    } else {
      setError("All Feedbacks are mendatory !");
      return false;
    }
  };

  const validateRadioButton = (e) => {
    var radios = document.getElementsByTagName("input");
    var value;
    var sum_radio = 0;
    var formValid = 0;
    for (var c = 0; c < radios.length; c++) {
      if (radios[c].type == "radio") {
        sum_radio = sum_radio + 1;
      }
    }
    for (var i = 0; i < radios.length; i++) {
      if (radios[i].type == "radio" && radios[i].checked) {
        value = radios[i].value;
        formValid = formValid + 1;
      }
    }
    var shouldBeChecked = sum_radio / 2;
    if (formValid == shouldBeChecked) {
      return "0";
    } else {
      return "1";
    }
  };

  return (
    <div className="flex h-[95vh] w-[100%]">
      <div className="batch-assignments dashboard-content overflow-auto flex md:w-[100%] w-full flex-col px-10 py-10 h-full bg-gray-100 mt-10 ">
        <div className="edit-profile">
          <div className="form-row">
            <div className="form-group">
              <h2>Student Feedback</h2>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-header">
              <div className="form-group">
                <label>Select Subject</label>
                <select
                  name="subject"
                  id="subject"
                  value={selectedSubject}
                  onChange={handleSubjectChange}
                >
                  <option value="">Select Subject</option>
                  {subjects.map((subject) => (
                    <option key={subject} value={subject}>
                      {subject}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Select Chapter</label>
                <select
                  name="chapter"
                  id="chapter"
                  disabled={!selectedSubject || loading}
                  onChange={handleChange}
                >
                  <option value="">Select Chapter</option>
                  {loading ? (
                    <option>Loading...</option>
                  ) : (
                    chapters.map((chapter) => (
                      <option key={chapter} value={chapter}>
                        {chapter}
                      </option>
                    ))
                  )}
                </select>
              </div>
              <div className="form-group">
                <div className="form-buttons">
                  <button type="submit">Submit</button>
                  <button onClick={()=>{navigate("/ViewFeedback")}}>View Feedback</button>
                </div>
              </div>
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="feedback-section">
              <div className="feedback-header">
                <span>Effective usage of Board</span>

                <span>Yes No</span>
              </div>
              <div className="feedback-item">
                <label>1. Detailed Steps / Working shown</label>
                <input
                  type="radio"
                  className="subjects"
                  name="effective_usage[1]"
                  id="Detailed"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="effective_usage[1]"
                  id="Detailed1"
                  value="no"
                  onChange={handleChange}
                />
              </div>
              <div className="feedback-item">
                <label>2. Important points are highlighted</label>
                <input
                  type="radio"
                  className="Important"
                  name="effective_usage[2]"
                  id="Important"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="Important"
                  name="effective_usage[2]"
                  id="Important2"
                  value="no"
                  onChange={handleChange}
                />
              </div>
              <div className="feedback-item">
                <label>3. Recap / Summarization is done on board</label>
                <input
                  type="radio"
                  className="Important"
                  name="effective_usage[3]"
                  id="Recap"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="Important"
                  name="effective_usage[3]"
                  id="Recap2"
                  value="no"
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Repeat for other sections like Content Delivery, Student Interaction, etc. */}

            <div className="feedback-section">
              <div className="feedback-header">
                <span>Content Delivery</span>
              </div>
              <div className="feedback-item">
                <label>1. Theory delivered upto the mark</label>
                <input
                  type="radio"
                  className="subjects"
                  name="content_delivery[1]"
                  id="content_delivery_1"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="content_delivery[1]"
                  id="content_delivery_112"
                  value="no"
                  onChange={handleChange}
                />
              </div>
              <div className="feedback-item">
                <label>2. Relevance & Applications are discussed</label>
                <input
                  type="radio"
                  className="subjects"
                  name="content_delivery[2]"
                  id="content_delivery_2"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="content_delivery[2]"
                  id="content_delivery_21"
                  value="no"
                  onChange={handleChange}
                />
              </div>
              <div className="feedback-item">
                <label>3. Examples to support comprehension of the topic</label>
                <input
                  type="radio"
                  className="subjects"
                  name="content_delivery[3]"
                  id="content_delivery_3"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="content_delivery[3]"
                  id="content_delivery_23"
                  value="no"
                  onChange={handleChange}
                />
              </div>
              <div className="feedback-item">
                <label>4. DPPs discussed in classroom</label>
                <input
                  type="radio"
                  className="subjects"
                  name="content_delivery[4]"
                  id="content_delivery_4"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="content_delivery[4]"
                  id="content_delivery_41"
                  value="no"
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Repeat for other sections like Student Interaction, Applications based questions, etc. */}

            <div className="feedback-section">
              <div className="feedback-header">
                <span>Student Interaction</span>
              </div>
              <div className="feedback-item">
                <label>
                  1. Teacher is able to engage students in meaningful discussion
                </label>
                <input
                  type="radio"
                  className="subjects"
                  name="student_interaction[1]"
                  id="student_interaction_1"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="student_interaction[1]"
                  id="student_interaction_12"
                  value="no"
                  onChange={handleChange}
                />
              </div>
              <div className="feedback-item">
                <label>2. Is able to clarify doubts</label>
                <input
                  type="radio"
                  className="subjects"
                  name="student_interaction[2]"
                  id="student_interaction_2"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="student_interaction[2]"
                  id="student_interaction_22"
                  value="no"
                  onChange={handleChange}
                />
              </div>
              <div className="feedback-item">
                <label>
                  3. The teacher ensures active participation of all students
                </label>
                <input
                  type="radio"
                  className="subjects"
                  name="student_interaction[3]"
                  id="student_interaction_3"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="student_interaction[3]"
                  id="student_interaction_31"
                  value="no"
                  onChange={handleChange}
                />
              </div>
              <div className="feedback-item">
                <label>
                  4. Is the teacher receptive to listening to students queries /
                  suggestions
                </label>
                <input
                  type="radio"
                  className="subjects"
                  name="student_interaction[4]"
                  id="student_interaction_4"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="student_interaction[4]"
                  id="student_interaction_41"
                  value="no"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="feedback-section">
              <div className="feedback-header">
                <span>
                  Applications based questions/ Numericals from modules are
                  discussed
                </span>
              </div>
              <div className="feedback-item">
                <label>1. Case Based questions</label>
                <input
                  type="radio"
                  className="subjects"
                  name="questions_based[1]"
                  id="questions_based_1"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="questions_based[1]"
                  id="questions_based_41"
                  value="no"
                  onChange={handleChange}
                />
              </div>
              <div className="feedback-item">
                <label>2. Assertion Reason Questions</label>
                <input
                  type="radio"
                  className="subjects"
                  name="questions_based[2]"
                  id="questions_based_2"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="questions_based[2]"
                  id="questions_based_21"
                  value="no"
                  onChange={handleChange}
                />
              </div>
              <div className="feedback-item">
                <label>3. Application Based Questions</label>
                <input
                  type="radio"
                  className="subjects"
                  name="questions_based[3]"
                  id="questions_based_3q"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="questions_based[3]"
                  id="questions_based_3"
                  value="no"
                  onChange={handleChange}
                />
              </div>
              <div className="feedback-item">
                <label>4. Numericals (Wherever applicable)</label>
                <input
                  type="radio"
                  className="subjects"
                  name="questions_based[4]"
                  id="questions_based_4"
                  value="yes"
                  onChange={handleChange}
                />
                <input
                  type="radio"
                  className="subjects"
                  name="questions_based[4]"
                  id="questions_based_43"
                  value="no"
                  onChange={handleChange}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
