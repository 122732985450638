// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Overlay to dim the background */
.CustomModal_overlay__3tfr0 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container */
.CustomModal_modal__yMFV1 {
  background: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: CustomModal_fadeIn__OuoKF 0.3s ease-out;
  overflow-y: auto;
}

@media (max-width: 1024px) {
  .CustomModal_modal__yMFV1 {
    height: 80%;
  }
}

@media (max-width: 768px) {
  .CustomModal_modal__yMFV1 {
    margin-left: 60px;
    width: 80%;
    height: 80%;
  }
}

/* Close button */
.CustomModal_closeButton__t0Edh {
  position: absolute;
  top: 25px;
  right: 25px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
}

/* Animation for modal appearance */
@keyframes CustomModal_fadeIn__OuoKF {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/CustomModal/CustomModal.module.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA,oBAAoB;AACpB;EACE,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,wCAAwC;EACxC,kBAAkB;EAClB,kDAA+B;EAC/B,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,UAAU;IACV,WAAW;EACb;AACF;;AAEA,iBAAiB;AACjB;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,UAAU;AACZ;;AAEA,mCAAmC;AACnC;EACE;IACE,UAAU;IACV,sBAAsB;EACxB;EACA;IACE,UAAU;IACV,mBAAmB;EACrB;AACF","sourcesContent":["/* Overlay to dim the background */\n.overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.6);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n/* Modal container */\n.modal {\n  background: white;\n  border-radius: 20px;\n  padding: 20px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);\n  position: relative;\n  animation: fadeIn 0.3s ease-out;\n  overflow-y: auto;\n}\n\n@media (max-width: 1024px) {\n  .modal {\n    height: 80%;\n  }\n}\n\n@media (max-width: 768px) {\n  .modal {\n    margin-left: 60px;\n    width: 80%;\n    height: 80%;\n  }\n}\n\n/* Close button */\n.closeButton {\n  position: absolute;\n  top: 25px;\n  right: 25px;\n  background: none;\n  border: none;\n  font-size: 20px;\n  cursor: pointer;\n  z-index: 1;\n}\n\n/* Animation for modal appearance */\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n    transform: scale(0.95);\n  }\n  to {\n    opacity: 1;\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `CustomModal_overlay__3tfr0`,
	"modal": `CustomModal_modal__yMFV1`,
	"fadeIn": `CustomModal_fadeIn__OuoKF`,
	"closeButton": `CustomModal_closeButton__t0Edh`
};
export default ___CSS_LOADER_EXPORT___;
