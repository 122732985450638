// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditQuestionTag_container__5WpHu {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 50px;
}

.EditQuestionTag_topHeading__lVO\\+P {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}

@media (max-width: 500px) {
  .EditQuestionTag_topHeading__lVO\\+P {
    font-size: 18px;
    margin-bottom: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/QuestionBank/EditQuestionTag/EditQuestionTag.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;IACf,kBAAkB;EACpB;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  margin-top: 60px;\n  margin-bottom: 50px;\n}\n\n.topHeading {\n  font-size: 20px;\n  font-weight: 600;\n  color: #000;\n  margin-bottom: 10px;\n}\n\n@media (max-width: 500px) {\n  .topHeading {\n    font-size: 18px;\n    margin-bottom: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EditQuestionTag_container__5WpHu`,
	"topHeading": `EditQuestionTag_topHeading__lVO+P`
};
export default ___CSS_LOADER_EXPORT___;
