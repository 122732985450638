import React, { useEffect, useState } from "react";
import Login3 from "../Login3.0/Login3";
import Login2 from "../Login2.0/Login2";
import Login1 from "../Login1.0/Login1";
import { getSchoolCode } from "../../../../Store/API/API";
import { useDispatch } from "react-redux";

const LoginPageTheme = ({}) => {
  const [theme, setTheme] = useState("1");
  const [schoolData, setSchoolData] = useState(null);
  const dispatch = useDispatch();

  const fetchLoginTheme = async () => {
    try {
      const hostname = window.location.hostname;
      const schoolCode = hostname.split(".")[0]; 
  
      const data = await getSchoolCode(schoolCode, dispatch); 
      if (data?.statuscode === "200") {
        setTheme(data?.data?.theme);
        setSchoolData(data?.data);
      }
    } catch (error) {
      console.error("Error fetching login theme:", error);
    }
  };

  useEffect(() => {
    fetchLoginTheme();
  }, []);

  const renderLoginPage = () => {

    switch (theme) {
      case "1":
        return <Login1  schoolData={schoolData} />;
      case "2":
        return <Login2  schoolData={schoolData}/>;
      case "3":
        return <Login3  schoolData={schoolData}/>;
      default:
        return <Login1 schoolData={schoolData}/>;
    }
  };

  return <div>{schoolData ? renderLoginPage() :""}</div>;
};

export default LoginPageTheme;