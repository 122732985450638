import React, { useState } from "react";
import styles from "./FIB.module.css";
import Tag from "../Tag/Tag";
// import Integer from "../Integer/Integer";
import EditIcon from "@mui/icons-material/Edit";
import QuestionsBottomTag from "../QuestionsBottomTag/QuestionsBottomTag";
import QuestionTopTag from "../QuestionsTopTag/QuestionTopTag";
import Latex from "react-latex-next";
import QuestionEditBar from "../QuestionEditBar/QuestionEditBar";
import he from "he";

const FIB = ({
  correctOption,
  fibCount,
  question,
  questionOrder,
  location,
  questionId,
  questionBankId,
  questionMarks,
  questionData,
  dropDownValues,
  currentPage,
  handleButtonClick
}) => {
  // Generate placeholders for options based on fibCount
  const fib_Count = fibCount * 1;
  const options = Array.from({ length: fib_Count }, (_, index) => index);

  // console.log("correctOption", correctOption);

  return (
    <div className={styles.mcqContainer}>
      <QuestionTopTag
        questionId={questionId}
        questionMarks={questionMarks}
        location={location}
        questionBankId={questionBankId}
        handleButtonClick={handleButtonClick}
        currentPage={currentPage}
        dropDownValues={dropDownValues}
      />
      <div className={styles.headerSectionContainer}>
        <div className={styles.mcqQuestion}>
          <div style={{ display: "flex" }}>
            {location !== "QB" ? (
              <input
                type="text"
                className={styles.questionInputBox}
                defaultValue={questionOrder}
              />
            ) : (
              <div className={styles.questionInputBox}>{questionOrder}</div>
            )}
            <div style={{ display: "ruby" }}>
              <Latex>{he.decode(question || " - ")}</Latex>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mcqOptions}>
        {options?.map((item, index) => (
          <div
            key={index}
            className={styles.mcqOptionContainer}
            style={{ marginLeft: "30px" }}
          >
            <div
              className={
                correctOption[index] === "True"
                  ? styles.mcqOptionCorrect
                  : styles.mcqOption
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `Answer: ${correctOption[index] || ""}`,
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
      {(location === "QB" || location === "QL") && (
        <div className={styles.QuestionBankContainer}>
          <QuestionsBottomTag questionData={questionData}    />
        </div>
      )}
    </div>
  );
};

export default FIB;
