import axios from "axios";
import{applicationActions} from "../Data/Slices/ApplicationSlice";

import { apiDomain, assignmentDomian } from "../../Config";

const apiUrl = apiDomain();
const assignmentUrl = assignmentDomian();

// const url = "http://qa.edvanz.com";
// const domain = "https://assignments.innovartan.com";
//const url = "https://app.innovartan.com"

export const getUserAllFeeds = async (token, group_id, user_id,dispatch) => {
  try {
    const { data } = await axios.get(
      `${apiUrl}mobile/user/get-group-feeds/${group_id}/${user_id}/1`,
      {
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      }
    );
    return data;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const handleCreatePost = async (
  token,
  group_id,
  user_id,
  group_owner_id,
  text,
  attachments,dispatch
) => {
  try {
    console.log(group_id, user_id, group_owner_id, text, attachments);

    const { data } = await axios.post(
      `${apiUrl}mobile/user/create-post`,
      {
        group_id,
        user_id,
        group_owner_id,
        text,
        attachments,
      },
      {
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      }
    );

    // console.log(data);
    return data;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const handleLikePost = async (token, formData,dispatch) => {
  // console.log(Array.from(formData))
  try {
    const { data } = await axios({
      method: "post",
      url: `${apiUrl}mobile/user/like`,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        TOKEN: token,
      },
    });
    return data;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const handleCommentPost = async (token, formData,dispatch) => {
  // console.log(Array.from(formData));
  try {
    const { data } = await axios({
      method: "post",
      url: `${apiUrl}mobile/user/comment`,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        TOKEN: token,
      },
    });
    // console.log(data);
    return data;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const deleteComment = async (activityId, userId, token,dispatch) => {
  try {
    const formData = new FormData();
    formData.append("activity_id", activityId);
    formData.append("activity_user_id", userId);
    formData.append("user_id", userId);

    const { data } = await axios({
      method: "post",
      url: `${apiUrl}mobile/user/delete-comment`,
      data: formData,
      headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        TOKEN: token,
      },
    });
    return data;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const deletePost = async (
  activityId,
  activityUserId,
  groupId,
  token,dispatch
) => {
  try {
    const formData = new FormData();
    formData.append("group_id", groupId);
    formData.append("activity_user_id", activityUserId);
    formData.append("activity_id", activityId);

    const { data } = await axios({
      method: "post",
      url: `${apiUrl}mobile/user/delete-group-post`,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        TOKEN: token,
      },
    });
    return data;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getUserNotifications = async () => {
  const { data } = await axios(`${apiUrl}mobile/user/notification-list/45/1`);
  return data;
};

export const fetchAssignmentDetails = async (
  token,
  assignmentId,
  userId,
  type,dispatch
) => {

  // console.log(token, assignmentId, userId, type);
  const tempUrl = `${assignmentUrl}mobile/assignments/view-assignment-details/${userId}/${assignmentId}/${type}`;

  try {
    const {
      data: { data },
    } = await axios.get(tempUrl, {
      headers: {
        "Content-Type": "application/json",
        TOKEN: token,
      },
    });
    // console.log(data);
    return data;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    
  }
};
export const fetchSubmissionDetails = async (
  token,
  studentId,
  assignmentId,dispatch
) => {
  const tempUrl = `${assignmentUrl}mobile/assignments/student-view-submissions/${studentId}/${assignmentId}`;

  try {
    const {
      data: { data },
    } = await axios.get(tempUrl, {
      headers: {
        "Content-Type": "application/json",
        TOKEN: token,
      },
    });
    // console.log(data);
    return data;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    throw new Error("Failed to fetch submission details.");
  }
};
export const fetchAssignments = async (token, userId, groupId,dispatch) => {
  const tempUrl = `${assignmentUrl}mobile/assignments/view-assignments/${userId}/${groupId}`;
  try {
    const {
      data: { data },
    } = await axios.get(tempUrl, {
      headers: {
        "Content-Type": "application/json",
        TOKEN: token,
      },
    });
    // console.log(data);
    return data;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return [];
  }
};

export const handleSubmitPost = async (
  token,
  assignment_id,
  group_id,
  student_id,
  comment,
  attachments,dispatch
) => {
  const tempUrl = `${assignmentUrl}mobile/assignments/submit-assignment`;
  // console.log(token, assignment_id, group_id, student_id, comment, attachments);
  try {
    const { data } = await axios.post(
      tempUrl,
      {
        assignment_id,
        group_id,
        student_id,
        comment,
        attachments,
      },
      {
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      }
    );
    return data;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "error",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return [];
  }
};
