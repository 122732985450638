// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewAnalysis_container__JKltE {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 100%;
  height: 100%;
  margin-bottom: 35px;
  padding: 20px;
}

@media (max-width: 500px) {
  .ViewAnalysis_container__JKltE {
    margin-bottom: 0px;
    padding: 10px;
  }
}

.ViewAnalysis_frameBox__mHpZq {
  width: 100%;
  height: 80vh;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/ViewAnalysis/ViewAnalysis.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE;IACE,kBAAkB;IAClB,aAAa;EACf;AACF;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  margin-top: 60px;\n  width: 100%;\n  height: 100%;\n  margin-bottom: 35px;\n  padding: 20px;\n}\n\n@media (max-width: 500px) {\n  .container {\n    margin-bottom: 0px;\n    padding: 10px;\n  }\n}\n\n.frameBox {\n  width: 100%;\n  height: 80vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ViewAnalysis_container__JKltE`,
	"frameBox": `ViewAnalysis_frameBox__mHpZq`
};
export default ___CSS_LOADER_EXPORT___;
