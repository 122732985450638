import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "./AddTopicModal.module.css";
import { use } from "react";
import {
  getSessionTopics,
  getTopics,
  updateSessionTopics,
} from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../UI/SelectInput/SelectInput";

const width = window.innerWidth;

const SelectInputField = ({
  heading,
  placeholder,
  field,
  type,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
  isMulti,
}) => {
  return (
    <div className={styles.selectWrapper}>
      <label className={styles.label}>
        {heading} <span className={styles.required}>*</span>
      </label>
      <div className={styles.dropDownWrapper}>
        <SelectInput
          width={width > 500 ? "350px" : "100%"}
          placeHolder={placeholder}
          options={dropDownoptions}
          field={field}
          handleInputChange={handleInputChange}
          value={dropDownValues}
          // dropdownTextColor={"#FFFFFF"}
          isMulti={isMulti}
        />
      </div>
    </div>
  );
};

const AddTopicModal = ({ onClose, sessionId, handleUpdateSession }) => {
  const [selectedValue, setSelectedValue] = useState();
  const [dropDownOptions, setDropDownOption] = useState();
  const [error, setError] = useState(null);

  const [dropDownValues, setDropDownValues] = useState({
    sessionChapter: "",
    sessionTopics: "",
  });
  const [dropDownoptions, setDropDownOptions] = useState({
    sessionChapter: [],
    sessionTopics: [],
  });

  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);

  const fetchChapterTopics = async (chapter) => {
    const data = await getTopics("", "", chapter, dispatch);
    // console.log("data", data);
    setDropDownOptions((prevState) => {
      return {
        ...prevState,
        sessionTopics: data,
      };
    });
  };

  const handleInputChange = (field, value) => {
    setDropDownValues((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });

    if (field === "sessionChapter") {
      fetchChapterTopics(value.value);
      setDropDownValues((prevState) => {
        return {
          ...prevState,
          sessionTopics: "",
        };
      });
    }
  };

  const handleSubmit = async () => {
    // console.log("dropDownValues", dropDownValues);

    const selectedTopicValue = dropDownValues?.sessionTopics?.map(
      (item) => item.value
    );
    const selectedTopicLabel = dropDownValues?.sessionTopics?.map(
      (item) => item.label
    );
    const selectedChapterValue = dropDownValues?.sessionChapter?.value;
    const selectedChapterLabel = dropDownValues?.sessionChapter?.label;

    // console.log("selectedValueArray", selectedTopicValue, selectedChapterValue);

    const tempData = {
      user_id: userState.userId,
      session_id: sessionId,
      session_topic: selectedTopicValue,
      session_chapter: selectedChapterValue,
    };

    const data = await updateSessionTopics(tempData, userState.token, dispatch);
    // console.log("data", data);

    if (data.statuscode === "200") {
      handleUpdateSession("topicChanged", sessionId, "", "", {
        selectedTopicLabel,
        selectedChapterLabel,
      });
      onClose();
    }
  };

  const fetchTopics = async () => {
    const data = await getSessionTopics(sessionId, dispatch);
    // console.log("data", data);

    if (data.statuscode === "200") {
      setDropDownOptions((prevState) => {
        return {
          ...prevState,
          sessionChapter: data?.data?.all_chapters,
          sessionTopics: data?.data?.all_topics,
        };
      });
      setDropDownValues((prevState) => {
        return {
          ...prevState,
          sessionChapter: data?.data?.session_chapter,
          sessionTopics: data?.data?.session_topic,
        };
      });
    }
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={styles.crossIcon}
        onClick={() => {
          onClose();
        }}
      ></div>
      <header className={styles.header}>
        <div className={styles.headerContainer}>
          <p className={styles.title}>Edit Topics & Chapter</p>
        </div>
      </header>
      <div className={styles.form}>
        {/* <Select
          defaultValue={selectedValue}
          isMulti
          name="colors"
          options={dropDownOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          value={selectedValue}
          onChange={(e) => setSelectedValue(e)}
        /> */}
        <SelectInputField
          field={"sessionChapter"}
          heading={"Session Chapter"}
          placeholder={"Select Chapteer"}
          dropDownValues={dropDownValues}
          dropDownoptions={dropDownoptions}
          handleInputChange={handleInputChange}
          isMulti={false}
        />
        <SelectInputField
          field={"sessionTopics"}
          heading={"Session Topic's"}
          placeholder={"Select Topic"}
          dropDownValues={dropDownValues}
          dropDownoptions={dropDownoptions}
          handleInputChange={handleInputChange}
          isMulti={true}
        />

        {error && <div className={styles.errorText}>{error}</div>}

        <div className={styles.actions} onClick={handleSubmit}>
          <button className={styles.submitButton}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default AddTopicModal;
