import React, { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { getCourseContent } from "../../../Store/API/API";
import { useNavigate } from "react-router-dom";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import Spinner from "../../UserInterface/Spinner/Spinner";
import CustomModal from "../CustomModal/CustomModal";
import AssignVideoModal from "./AssignVideoModal";
import { styled, Tooltip, tooltipClasses, Typography } from "@mui/material";
import PDFViewer from "../../ResourceFolders/PDFView";
import AddIcon from "@mui/icons-material/Add";
import TopicAddModal from "./TopicAddModal";
const SyllabusSection = ({
  courseName,
  activeSection,
  courseId,
  courseFormat,
}) => {
  const userState = useSelector((state) => state.userData.userData);
  const [openChapter, setOpenChapter] = useState(0);
  const [videoState, setVideoState] = useState([]);
  const [itemContentState, setItemContentState] = useState([]);
  const [courseContent, setCourseContent] = useState([]);
  const [contentId, setContentId] = useState("");
  const [chapterId, setChapterId] = useState(null);
  // console.log(courseContent,"content");
  const [isLoading, setIsLoading] = useState(true);
  const [assignVideoModal, setAssignVideoModal] = useState(false);
  const [editAssignVideoModal, setEditAssignVideoModal] = useState(false);
  const [openAddTopicModal, setOpenAddTopicModal] = useState(false);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [selectedPDF, setSelectedPDF] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("courseFormat", courseFormat);

  const handleWatchClick = (
    video,
    courseName,
    videoContentId,
    videoTitle,
    chapterName,
    chapterId
  ) => {
    // console.log("Watch clicked", videoContentId);

    // console.log("itemContentState", itemContentState);
    // console.log("videoState", videoState);
    // console.log("video, courseName, videoContentId, videoTitle, chapterName", video, courseName, videoContentId, videoTitle, chapterName);
    // return;

    const index = itemContentState.indexOf(videoContentId * 1);

    if (index === 0) {
      //   console.log("First video");
      navigate("/course-details/videoplayer", {
        state: {
          video,
          courseName,
          courseId,
          videoTitle,
          chapterName,
          chapterId,
        },
      });
      return;
    }

    for (let i = 0; i < index; i++) {
      // console.log("i", i);
      // console.log("itemContentState[i]", itemContentState[i]);

      if (!videoState[itemContentState[i]]) {
        // console.log("Past Video not completed");
        dispatch(
          applicationActions.setAlertData({
            isAlert: true,
            alertMessage:
              "Please complete the previous video to unlock this video",
            alertType: "error",
          })
        );
        return;
      }
    }

    // console.log("index", index);

    navigate("/course-details/videoplayer", {
      state: {
        video,
        courseName,
        courseId,
        videoTitle,
        chapterName,
        chapterId,
      },
    });
  };

  const processData = (data) => {
    const chapters = data;

    let chapterState = [];
    let videoState = {};

    chapters?.forEach((chapter, chapterIndex) => {
      // console.log("chapter", chapter);
      //   chapterState[chapter.chapterTitle] = chapterIndex === 0;
      chapter?.content?.forEach((video, videoIndex) => {
        // console.log("video", video);
        // if (videoIndex === 0) {
        //   contentState[chapter.chapterTitle] = [video?.content_id];
        // } else {
        //   contentState[chapter.chapterTitle] = [
        //     ...contentState[chapter.chapterTitle],
        //     video?.content_id,
        //   ];
        // }
        // console.log("video id", video?.content_id);
        chapterState.push(video?.content_id);

        if (video?.linked_test?.length > 0) {
          const mandatoryTest = video?.mandatory_test;

          video?.linked_test?.forEach((test) => {
            // console.log("test", test);
            if (test?.test_id === Number(mandatoryTest)) {
              if (test?.lastAttemptStatus) {
                videoState[video?.content_id] = true;
              } else {
                videoState[video?.content_id] = false;
              }
            }
          });
        } else {
          videoState[video?.content_id] = true;
        }
      });
    });

    // console.log("videoState", videoState);
    // console.log("chapterState", chapterState);

    setVideoState({ ...videoState });
    setItemContentState([...chapterState]);
  };

  const fetchContent = async () => {
    const data = await getCourseContent(
      courseName,
      userState.userId,
      "",
      dispatch
    );
    if (data) {
      // console.log("courseContent", data?.chapter_content);
      processData(data?.chapter_content);
      setCourseContent(data?.chapter_content);
    }
    setIsLoading(false);
  };

  const updateContent = (selectedChapterId, updatedValues) => {
    // console.log("updateContent", selectedChapterId, updatedValues);

    const updatedContent = courseContent.map((chapter) => {
      if (chapter.chapter_id === selectedChapterId) {
        return {
          ...chapter,
          content_lives_schools: updatedValues,
        };
      }
      return chapter;
    });

    setCourseContent(updatedContent);

    // fetchContent();
  };

  const renderTooltipContent = (chapter) => (
    <Typography
      style={{
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "300px", // Adjust width as needed
      }}
    >
      <ul className="grid grid-cols-1 gap-2 w-[170px]">
        {chapter?.content_lives_schools?.map((school, index) => (
          <li key={index} className="flex items-center space-x-2 py-0">
            <div className="flex items-center">
              <span className="text-gray-700 text-sm">{school?.label}</span>
            </div>
          </li>
        ))}
      </ul>
    </Typography>
  );

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#fff",
      // boxShadow: theme.shadows[1],
      boxShadow: "2px 4px 60px 0px #0732781A",
      borderRadius: "10px",
      maxWidth: 500,
    },
  }));

  useEffect(() => {
    // console.log("activeSection", activeSection);

    if (!activeSection === "Syllabus") {
      return;
    }

    fetchContent();

    //eslint-disable-next-line
  }, [userState, activeSection, courseName]);

  if (isLoading) {
    return <Spinner spinnerHeight={"20%"} />;
  }

  return (
    <div className="w-full lg:w-[70%] md:mb-10 mb-3 md:mt-10 mt-5">
      <h2 className="text-xl font-semibold mb-4">
        {courseFormat === "study_notes" ? "PDF" : "Videos"}
      </h2>

      {courseContent.length === 0 && (
        <div className="text-center text-orange-500">
          {" "}
          {courseFormat === "study_notes"
            ? "No PDF available"
            : "No videos available"}
        </div>
      )}

      {courseContent.map((chapter, index) => (
        <div key={index} className="mb-4">
          {/* Chapter Header */}
          <div
            className={`flex p-3 rounded-lg border cursor-pointer flex-col`}
            onClick={() => {
              setOpenChapter(openChapter === index ? "" : index);
            }}
          >
            <div className="flex justify-between w-full">
              <div className="flex md:items-center items-start space-x-2 flex-col md:flex-row">
                <div className="flex gap-2">
                  <span className="px-3 py-1 text-sm font-medium bg-[#E7EDF6] text-gray-600 rounded-lg">
                    Chapter {index + 1}
                  </span>
                  <span className="px-2 py-0 border flex items-center rounded-lg text-black text-sm font-light md:hidden">
                    {chapter?.content?.length}{" "}
                    {courseFormat === "study_notes" ? "PDF" : "videos"}
                  </span>
                </div>
                <span className="font-semibold">{chapter?.chapterTitle}</span>
                <span className="px-2 py-0 border rounded-lg text-black text-sm font-light md:block hidden">
                  {chapter?.content?.length}{" "}
                  {courseFormat === "study_notes" ? "PDF" : "videos"}
                </span>
                {/* eslint-disable-next-line eqeqeq */}
                {(userState?.userId == 4 || userState?.instituteId == 4) &&
                  userState?.userType !== "student" &&
                  courseFormat !== "study_notes" && (
                    <LightTooltip
                      title={renderTooltipContent(chapter)}
                      placement="bottom"
                      arrow
                    >
                      <h3 className="text-base w-fit font-semibold">
                        <div className="bg-red-500 hover:bg-red-400 text-white px-2 py-[2px] text-center text-xs rounded">
                          LIVE IN{" "}
                          <span>{chapter?.content_lives_schools?.length}</span>
                        </div>
                      </h3>
                    </LightTooltip>
                  )}
              </div>
              <div className="flex flex-row items-center space-x-2">
                {/* eslint-disable-next-line eqeqeq */}
                {(userState?.userId == 4 || userState?.instituteId == 4) &&
                  userState?.userType !== "student" &&
                  courseFormat !== "study_notes" && (
                    <div>
                      {chapter?.content_lives_schools?.length > 0 ? (
                        <button
                          className={`hover:bg-orange-500 hover:text-white border border-orange-500  text-orange-500 text-sm px-4 py-2 rounded-lg flex items-center`}
                          // disabled={!isVideoViewable[video?.content_id]}
                          onClick={() => {
                            setEditAssignVideoModal(!editAssignVideoModal);
                            setSelectedChapterId(chapter?.chapter_id);
                          }}
                        >
                          Assign <ExpandMoreIcon className="ml-1" />
                        </button>
                      ) : (
                        <button
                          className={`hover:bg-orange-500 hover:text-white border border-orange-500  text-orange-500 text-sm px-2 py-2 rounded-lg flex items-center`}
                          // disabled={!isVideoViewable[video?.content_id]}
                          onClick={() => {
                            setAssignVideoModal(!assignVideoModal);
                          }}
                        >
                          Assign <ExpandMoreIcon className="ml-1" />
                        </button>
                      )}
                    </div>
                  )}
                {openChapter === index ? (
                  <ExpandLessIcon className="text-3xl" />
                ) : (
                  <ExpandMoreIcon className="text-3xl" />
                )}
              </div>
            </div>
          </div>

          {/* Videos List */}
          {openChapter === index && chapter?.content.length > 0 && (
            <div className="mt-3 space-y-3">
              {chapter?.content.map((video, index) => (
                <div className="rounded-lg overflow-hidden" key={index}>
                  <div
                    className={`flex items-center justify-between p-3 border  
                    border-gray-200 flex-col md:flex-row
                  `}
                  >
                    {/* Thumbnail */}
                    <img
                      src={
                        courseFormat === "study_notes"
                          ? "https://innovartan.s3.amazonaws.com/eed41ce53599ed8b76ecc245af61d7da1596812087/e1c924ea8405e75a8f95d40adaccae38.png"
                          : video?.thumb_nail ||
                            "https://innovartan.s3.amazonaws.com/4eadcc8be572813e7923174fb796a0dd791780125/caf40280c994c80d7be183281d645eeb.png"
                      }
                      alt="Thumbnail"
                      className="md:w-30 w-30 md:h-16 h-26 rounded-lg object-cover"
                    />

                    {/* Video Info */}
                    <div className="flex-1 md:ml-4 ml-0 md:mt-0 mt-2">
                      {courseFormat !== "study_notes" ? (
                        <h3 className="font-semibold text-base">
                          {" "}
                          Lecture: {video?.lesson_no}
                        </h3>
                      ) : (
                        <h3 className="font-semibold text-base">
                          {video.title}
                        </h3>
                      )}

                      {video?.topics?.length > 0 && (
                        <div className="flex flex-row gap-2 flex-wrap">
                          <p className="font-semibold text-xs text-gray-600 mt-1">
                            Topics Covered:
                          </p>
                          {video?.topics?.map((topic, index) => (
                            <p
                              className="font-thin bg-gray-200 px-2 py-1 text-xs rounded-md"
                              key={index}
                            >
                              {topic?.label}
                            </p>
                          ))}
                        </div>
                      )}

                      {courseFormat !== "study_notes" && (
                        <div className="mt-1 flex flex-row items-center">
                          <p className="text-xs text-gray-600">Progress:</p>
                          <div className="md:w-[60%] w-[100%] bg-gray-200 rounded-full h-1 mt-1 mx-2">
                            <div
                              className="bg-green-500 h-1 rounded-full"
                              style={{ width: `${video?.progress || "0"}%` }}
                            ></div>
                          </div>
                          <p className="text-xs">{video?.progress || "0"}%</p>
                          {/* <p className="text-xs">{video.content_id || "0"}%</p> */}
                        </div>
                      )}
                    </div>
                    <div className="flex  gap-2">

                       {(userState?.userId == 4 || userState?.instituteId == 4) && userState?.userType !== "student" && (            
                         <div
                        className="px-2 py-1 border border-primaryOrange rounded-lg text-xs flex items-center gap-1 text-primaryOrange hover:bg-primaryOrange hover:text-textWhite "
                        onClick={() => {
                          setOpenAddTopicModal(true);
                          setContentId(video.content_id);
                          setChapterId(chapter?.dataChapterId);
                        }}
                      >
                        <AddIcon style={{ fontSize: "16px" }} /> Topic
                      </div>
                      )}
                      
                      {/* Watch Button */}
                      <div className="flex flex-row gap-2 mt-2 md:mt-0">
                        <button
                          className={`bg-primaryOrange hover:bg-primaryOrangeDisabled text-white text-xs px-2 py-1 rounded-lg flex items-center ${
                            courseFormat !== "study_notes"
                              ? video?.open_video === false
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                              : ""
                          } `}
                          disabled={
                            courseFormat !== "study_notes"
                              ? video?.open_video === false
                                ? true
                                : false
                              : false
                          }
                          onClick={() => {
                            if (courseFormat === "study_notes") {
                              // navigate("/pdf-viewer", {
                              //   state: {
                              //     pdfUrl: video?.value,
                              //   },
                              // });
                              const btobUrl = btoa(video?.value);
                              setSelectedPDF(btobUrl);
                              return;
                            }

                          handleWatchClick(
                            video,
                            courseName,
                            video?.content_id,
                            video?.lesson_no, // video lesson no instead of video title
                            chapter?.chapterTitle,
                            chapter?.chapter_id
                          );
                        }}
                      >
                        {courseFormat === "study_notes" ? "View" : "▶ Watch"}
                      </button>
                    </div>
                  </div>
                  </div>
                  <div className="bg-[#E7EDF6]">
                    {video?.linked_test?.map((test, index) => (
                      <div
                        key={index}
                        className={`flex md:items-center items-stretch justify-between p-3 border 
                    border-gray-200 flex-col md:flex-row w-full
                  `}
                      >
                        {/* Video Info */}
                        <div className="flex-1 md:ml-4 ml-0 md:mt-0 mt-2">
                          <div className="flex items-center space-x-3">
                            <div
                              className={`w-4 h-4 rounded-full border border-black ${
                                test?.lastAttemptStatus === true
                                  ? "bg-[#016f36] border-[#016f36]"
                                  : ""
                              }`}
                            ></div>
                            <h3 className="font-semibold text-base">
                              {test?.name}
                            </h3>
                          </div>

                          {/* <p className="font-thin px-2 py-1 text-xs rounded-md ml-5">
                            50 Questions
                          </p> */}
                          {/* <p className="font-thin px-2 py-1 text-xs rounded-md ml-5">
                            {video?.isCompleted ? "completed" : "notcompleted"}
                          </p> */}
                        </div>

                        {/* Watch Button */}
                        <div className="flex flex-row gap-2 mt-2 md:mt-0">
                          {test?.lastAttemptStatus === true && (
                            <button
                              className={`hover:bg-[#0743a3] hover:text-white text-[#0743a3] text-sm px-4 py-2 rounded-lg flex items-center border border-[#0743a3] ${
                                video?.isCompleted
                                  ? "cursor-pointer"
                                  : "opacity-50 cursor-not-allowed"
                              }   `}
                              disabled={video?.isCompleted ? false : true}
                              onClick={() => {
                                navigate("/ViewTestSubmission", {
                                  state: { quizId: test?.test_id },
                                });
                              }}
                            >
                              View Result
                            </button>
                          )}
                          {test?.lastAttemptStatus !== true && (
                            <button
                              className={` hover:bg-orange-500 hover:text-white text-sm px-4 py-2 rounded-lg flex items-center border border-orange-500 text-orange-500 ${
                                video?.isCompleted
                                  ? "cursor-pointer"
                                  : "opacity-50 cursor-not-allowed"
                              }`}
                              disabled={video?.isCompleted ? false : true}
                              onClick={() => {
                                navigate("/startTest", {
                                  state: { quizId: test?.test_id },
                                });
                              }}
                            >
                              Attempt
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <CustomModal
        isOpen={assignVideoModal}
        onClose={() => setAssignVideoModal(!assignVideoModal)}
      >
        <AssignVideoModal
          courseId={courseId}
          modalType={"assign"}
          onClose={() => setAssignVideoModal(!assignVideoModal)}
          handleUpdateContent={updateContent}
        />
      </CustomModal>
      <CustomModal
        isOpen={editAssignVideoModal}
        onClose={() => setEditAssignVideoModal(!editAssignVideoModal)}
      >
        <AssignVideoModal
          courseId={courseId}
          selectedChapterId={selectedChapterId}
          modalType={"edit"}
          onClose={() => setEditAssignVideoModal(!editAssignVideoModal)}
          handleUpdateContent={updateContent}
        />
      </CustomModal>

      <CustomModal
        isOpen={openAddTopicModal}
        onClose={() => setOpenAddTopicModal(!openAddTopicModal)}
      >
        <TopicAddModal
          courseId={courseId}
          contentId={contentId}
          chapterId={chapterId}
          fetchContent={fetchContent}
          type={"resource"}
          onClose={() => setOpenAddTopicModal(!openAddTopicModal)}
          handleUpdateContent={updateContent}
        />
      </CustomModal>
      {selectedPDF && (
        <div className="fixed  w-full inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50  ">
          <div className="bg-white p-4 rounded-lg shadow-lg w-[95%] flex flex-col justify-center items-center ml-20 mr-5">
            <PDFViewer selectedPDF={selectedPDF} />
            <button
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 w-[100px] self-center"
              onClick={() => setSelectedPDF(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SyllabusSection;
