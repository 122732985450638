import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import SelectInput from "../SelectInput/SelectInput";
import {
  getAllInstitute,
  getContentAssignee,
  saveContentAssignee,
} from "../../../Store/API/API";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";

const SelectInputField = ({
  heading,
  placeholder,
  field,
  type,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
  isMulti,
}) => {
  return (
    <div className="flex flex-col">
      <label className="text-[16px] font-medium text-black">
        {heading} <span className="text-[#f36421]">*</span>
      </label>
      <div className="rounded-xl">
        <SelectInput
          width={"100%"}
          placeHolder={placeholder}
          options={dropDownoptions}
          field={field}
          handleInputChange={handleInputChange}
          value={dropDownValues}
          // dropdownTextColor={"#FFFFFF"}
          isMulti={isMulti}
        />
      </div>
    </div>
  );
};

const AssignVideoModal = ({
  onClose,
  handleUpdateContent,
  modalType,
  courseId,
  selectedChapterId,
}) => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);

  console.log("courseId", courseId);
  

  const [dropDownValues, setDropDownValues] = useState({
    schools: "",
  });
  const [dropDownOptions, setDropDownOption] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    const contentData = {
      user_id: userState?.userId,
      course_id: courseId,
      chapter_id: selectedChapterId,
      institute_ids: dropDownValues.schools.map((school) => school.value),
    };

    // console.log("contentData", contentData);

    const data = saveContentAssignee(contentData, userState?.token, dispatch);

    if (data) {
      handleUpdateContent(selectedChapterId, dropDownValues.schools);
      onClose();
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Content Assigned Successfully",
          alertType: "success",
        })
      );
    }
    setIsSubmitting(false);

    // saveContentAssignee
  };

  const handleInputChange = (field, value) => {
    setDropDownValues((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const fetchContentAssignee = async () => {
    const contentData = {
      user_id: userState?.userId,
      course_id: courseId,
      chapter_id: selectedChapterId,
    };

    // console.log("contentData", contentData);

    const data = await getContentAssignee(
      contentData,
      userState?.token,
      dispatch
    );

    if (data?.data) {
      setDropDownValues((prevState) => {
        return {
          ...prevState,
          schools: data.data,
        };
      });
    }

    // console.log("data", data);
  };

  const fetchSchools = async () => {
    const data = await getAllInstitute(dispatch);

    if (data) {
      setDropDownOption((prevState) => {
        return {
          ...prevState,
          schools: data,
        };
      });
    }

    // console.log("data", data);
  };

  useEffect(() => {
    if (modalType === "edit") {
      fetchContentAssignee();
    }

    fetchSchools();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="rounded-none flex min-h-[400px] md:w-[500px] flex-col">
      <CloseIcon
        className="absolute right-5 top-7 text-[25px] font-bold cursor-pointer"
        onClick={() => {
          onClose();
        }}
      ></CloseIcon>
      <header className="flex w-full gap-x-[77px] gap-y-[40px] items-center justify-center self-end">
        <div className="bg-gradient-to-br from-[#fff5ef] to-white rounded-[20px]">
          <p className="text-black text-center font-bold text-[20px] leading-[1.2] font-[Metropolis,-apple-system,Roboto,sans-serif] flex-grow p-[14px_70px]">
            Assign Videos
          </p>
        </div>
      </header>
      <div className="mt-3">
        <SelectInputField
          field={"schools"}
          heading={"Select School"}
          placeholder={"Select School"}
          dropDownValues={dropDownValues}
          dropDownoptions={dropDownOptions}
          handleInputChange={handleInputChange}
          isMulti={true}
        />
        <div
          className="flex items-center gap-4 mt-5 self-end flex-wrap md:justify-end"
          //   onClick={handleSubmit}
        >
          <button
            className="rounded-[12px] bg-[#f36421] text-white px-4 py-[13px] w-[208px] font-medium text-[18px] leading-none self-center border-none cursor-pointer"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Update & Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignVideoModal;
