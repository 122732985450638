// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Drawer_logoContainer__H5CFF {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Drawer_TGTLogo__rzt\\+F {
  width: 9%;
  height: 3%;
  background-color: transparent;
}
.Drawer_entryLogo__iw\\+Ya {
  width: 15%;
  height: 7%;
  background-color: transparent;
}

@media (max-width: 800px) {
  .Drawer_TGTLogo__rzt\\+F {
    width: 17%;
    height: 6%;
  }
  .Drawer_entryLogo__iw\\+Ya {
    width: 27%;
    height: 12%;
  }
} 
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Navbar/Drawer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,SAAS;EACT,UAAU;EACV,6BAA6B;AAC/B;AACA;EACE,UAAU;EACV,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE;IACE,UAAU;IACV,UAAU;EACZ;EACA;IACE,UAAU;IACV,WAAW;EACb;AACF","sourcesContent":[".logoContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.TGTLogo {\n  width: 9%;\n  height: 3%;\n  background-color: transparent;\n}\n.entryLogo {\n  width: 15%;\n  height: 7%;\n  background-color: transparent;\n}\n\n@media (max-width: 800px) {\n  .TGTLogo {\n    width: 17%;\n    height: 6%;\n  }\n  .entryLogo {\n    width: 27%;\n    height: 12%;\n  }\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoContainer": `Drawer_logoContainer__H5CFF`,
	"TGTLogo": `Drawer_TGTLogo__rzt+F`,
	"entryLogo": `Drawer_entryLogo__iw+Ya`
};
export default ___CSS_LOADER_EXPORT___;
