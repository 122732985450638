import { useState } from "react";
import { IoMdNotifications } from "react-icons/io";
import { LiaUserEditSolid } from "react-icons/lia";
import {  MdMoveToInbox } from "react-icons/md";
import { LiaSignOutAltSolid } from "react-icons/lia";
import { RxHamburgerMenu } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNotifications, userLogout } from "../../../Store/API/API";
import { userDataActions } from "../../../Store/Data/Slices/UserDataSlice";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import CancelIcon from "@mui/icons-material/Cancel";
const Navbar2 = ({ toggleSidebar, isSidebarOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);

  const closeMenu = () => setIsMenuOpen(false);
  const userState = useSelector((state) => state.userData.userData);
  const schoolState = useSelector((state) => state.applicationData.schoolData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const alertState = useSelector((state) => state.applicationData.alertData);
  const dispatch = useDispatch();

  const hostname = window.location.hostname;
  const school = hostname.split(".")[0];
  const width = window.innerWidth;

  const navigate = useNavigate();

  const handleNotificationOpen = async () => {
    if (!openNotification) {
      const data = await getNotifications(userState.userId, dispatch);
      // console.log("Notification Data", data.notifications);
      setNotificationData(data.notifications);
    }
    setOpenNotification(!openNotification);
  };

  const IconWrapper = ({ children }) => (
    <div className="flex items-center justify-center w-12 h-12">
      {children}
    </div>
  );
  
  const contentTypeIconMap = {
    post: (
      <IconWrapper>
        <img src="/images/post-icon.png" alt="icon" className=" w-8 h-8" />
      </IconWrapper>
    ),
    sessions: (
      <IconWrapper>
        <img src="/images/sessions-icon.png" alt="icon" className=" w-8 h-8" />
      </IconWrapper>
    ),
    resources: (
      <IconWrapper>
        <img src="/images/resourses-icon.png" alt="icon" className=" w-8 h-8" />
      </IconWrapper>
    ),
    assignment: (
      <IconWrapper>
        <img src="/images/assignment-icon.png" alt="icon" className=" w-8 h-8" />
      </IconWrapper>
    ),
    default: (
      <IconWrapper>
        <img src="/images/default-icon.png" alt="icon" className=" w-8 h-8" />
      </IconWrapper>
    ),
  };
  

  const NotificationContainer = ({
    notificationHeading,
    createdAt,
    contentType,
    isLast,
  }) => {
    const icon = contentTypeIconMap[contentType] || contentTypeIconMap["default"];
    return (
      <div className="flex flex-col ">
        <div className="flex items-center w-full justify-start">
          {/* <TextsmsIcon className="text-[#000E8F75] my-6" /> */}
          {icon}
          <div className="flex flex-col ">
            <p className="ml-5 mt-3 text-sm flex items-center justify-center">{notificationHeading}</p>
            <p className="ml-5 text-[10px] text-gray-500">- {createdAt}</p>
          </div>
        </div>
        {!isLast && (
          <div className="h-px w-full opacity-50 bg-gray-500"/>
        )}
      </div>
    );
  };
  
  const handleLogut = async () => {
    const data = await userLogout(userState.token, dispatch);
    if (data?.status) {
      localStorage.removeItem("userData");
      localStorage.removeItem("batchData");
      localStorage.removeItem("schoolData");
      dispatch(userDataActions.setUserData({}));
      dispatch(applicationActions.setBatchData({}));
      dispatch(applicationActions.setSchoolData({}));
      navigate("/");
    }
  };

  return (
    <nav
      className={`flex items-center ${
        isSidebarOpen ? "pl-60" : "md:pl-20"
      } justify-between bg-textWhite h-16 shadow-md py-2 w-full fixed top-0`}
      style={{ zIndex: "9999" }}
    >
      <div className="flex items-center gap-4">
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-textGrey bg-opacity-50 z-50 md:hidden"
            onClick={toggleSidebar}
          />
        )}
        {!isSidebarOpen && (
          <button className="md:hidden ml-4 p-2 " onClick={toggleSidebar}>
            <RxHamburgerMenu size={20} className="text-primaryBlue" />
          </button>
        )}

        {school !== "dpsg" ? (
          <>
            {schoolState?.schoolImage && (
              <img
                src={schoolState?.schoolImage}
                className={
                  width > 600
                    ? "w-full md:w-[10rem] h-12 object-contain "
                    : "w-full md:w-[9rem] h-10 object-contain"
                }
                alt="schoolLogo"
              />
            )}
          </>
        ) : (
          <div className="flex items-center">
            <img
              src={`${school}.jpg`}
              alt={`${school}-logo`}
              className={`w-[9%] h-[3%] bg-transparent ${
                school === "dpsg"
                  ? " hidden md:block w-full p-4  md:w-[10rem] h-12 object-contain"
                  : ""
              }`}
            />
            <img
              src={"TGT.png"}
              alt={`${school}-logo`}
              className={`w-[15%] h-[7%] bg-transparent ${
                school === "dpsg"
                  ? " hidden md:block w-full px-2 py-4 md:w-[10rem] h-10 object-contain"
                  : ""
              }`}
            />
          </div>
        )}
        <div className="hidden lg:block h-10 w-[2px] bg-backgroundBlue" />
        {/* <button className="bg-textWhite  border-2 border-backgroundBlue text-textBlack px-4 py-2 rounded-xl">
          Batches
        </button> */}

        <button
          className="hidden lg:block bg-textWhite border-2 border-backgroundBlue text-textBlack text-xs lg:text-sm px-2 py-1 rounded-xl object-contain capitalize"
          onClick={() => {
            navigate("/batch");
          }}
        >
          {alertState.isAlert
            ? "Batches"
            : `${batchState?.batchName || "None"} (${
                batchState?.groupId || "-"
              })`}
          {batchState?.batchUserType !== "student" && (
            <div>{batchState?.batchUserType || "None"}</div>
          )}
        </button>
      </div>
      <div className="flex items-center gap-8 space-x-10  md:space-x-0 md:gap-4">
        <div onClick={handleNotificationOpen} className="cursor-pointer">
          <IoMdNotifications className="w-8 h-8" />
        </div>
        {openNotification && (
            <div className="notification-slide-in absolute bottom-[-500px] right-[5px] bg-white p-[10px_25px] rounded-[5px] shadow-lg flex flex-col w-[100%] md:w-[60%] lg:w-[45%] xl:w-[35%] h-[500px] text-black text-[16px] z-[999] overflow-hidden">
            <div className="flex  items-center justify-between">
              <p className="text-[20px] font-normal mt-[15px] mb-[20px] leading-[2px] text-left ">
                Your Notifications
              </p>
              <CancelIcon
                className="mt-[-3px] cursor-pointer"
                onClick={handleNotificationOpen}
              />
            </div>
            <div className="h-px ml-[-30px] w-full bg-grey "></div>
            
            <div className="w-full h-[500px] overflow-y-auto scrollbar-hide items-start">
              {notificationData.length > 0 ? (
                notificationData.map((notification, index) => (
                  <NotificationContainer
                    notificationHeading={notification.notification_text}
                    contentType={notification.content_type}
                    createdAt={notification.created_time}
                    key={index}
                    isLast={index === notificationData.length - 1}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center h-full">
                  <p>No Notifications Found</p>
                </div>
              )}
            </div>
          </div>
        )}

        <style>
          {`
              @keyframes slideIn {
                from {
                  transform: translateX(100%);
                }
                to {
                  transform: translateX(0);
                }
              }
              .notification-slide-in {
                animation: slideIn 0.5s ease-out; 
              }
            `}
        </style>

        <div className="hidden md:block h-10 w-[2px] bg-backgroundBlue"/>
        <div className="hidden md:block text-center md:w-[8rem] lg:w-[10rem]">
          <p className="text-xs md:text-xs lg:text-sm text-textBlack font-semibold">
            {userState?.userType === "student" && (
              <>
                Student ID:{" "}
                <span className="font-semibold">{userState.userId}</span>
              </>
            )}
            {userState?.userType === "teacher" && (
              <>
                Teacher ID:{" "}
                <span className="font-semibold">{userState.userId}</span>
              </>
            )}
          </p>
          <p className="text-xs md:text-xs lg:text-sm text-textBlack font-semibold">
            Institute ID:{" "}
            <span className="font-semibold">{userState?.instituteId}</span>
          </p>
        </div>

        <div className="hidden md:block h-10 w-[2px] bg-backgroundBlue"></div>

        <div className="relative">
          <div className="flex items-center justify-center text-center gap-2 cursor-pointer px-2 mr-5 -ml-5 ">
            <div className="hidden md:flex md:flex-col text-center justify-center items-center  ">
              <span className="font-semibold text-textBlack text-sm truncate w-[8rem] ">
                {`${userState?.firstName} ${userState?.lastName}`}
              </span>
              <span className="bg-secondaryGreen text-textWhite w-[60%] text-xs py-1 rounded-lg capitalize">
                {userState.userType}
              </span>
            </div>
            {/* <IoMdContact
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className=" w-10 h-10 cursor-pointer transition-transform "
            /> */}
            <img
              src="/images/profile-img.png"
              alt="Profile"
              className="h-10 w-10 rounded-full border-2 border-secondaryGreen cursor-pointer transition-transform"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          </div>
          {isMenuOpen && (
            <div className="absolute right-0 text-sm mt-3 w-56 bg-textWhite shadow-lg rounded-md py-2 z-50">
              <div
                onClick={() => {
                  closeMenu();
                  navigate("/profile");
                }}
                className="flex items-center gap-3 px-4 py-2 text-textBlack cursor-pointer">
                <LiaUserEditSolid className="h-5 w-5 text-textBlack" />
                <span>Edit Profile</span>
              </div>
              <div
                onClick={() => {
                  closeMenu();
                  navigate("/change-password");
                }}
                className="flex items-center gap-3 px-4 py-2 text-textBlack cursor-pointer"
              >
                <MdMoveToInbox className="h-5 w-5 text-textBlack" />
                <span>Change Password</span>
              </div>
              {/* <div
                onClick={closeMenu}
                className="flex items-center gap-3 px-4 py-2 text-textBlack cursor-pointer"
              >
                <BiSolidHelpCircle className="h-5 w-5 text-textBlack" />
                <span>Help</span>
              </div> */}
              <div
                onClick={handleLogut}
                className="flex items-center gap-3 px-4 py-2 text-secondaryRed cursor-pointer"
              >
                <LiaSignOutAltSolid className="h-5 w-5 text-secondaryRed" />
                <span>Sign Out</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar2;
