import axios from "axios";

const OPENAI_API_KEY =
  "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"; // Store securely in .env

export const QuestionTaggingAPI = async (questions) => {
  const prompt = `
      You are an AI that assigns the most relevant topics to given questions.
      Each question has predefined topic options, and you must choose the most appropriate ones.
  
      Questions and their topic options:
      ${questions
        .map(
          (q) => `
        Question ID: ${q.question_id}
        Question: "${q.question}"
        Options: "${q.answer.map((t) => t.name).join(", ")}"
        Available Topics: ${q.topic_to_tags
          .map((t) => `${t.value}: ${t.label}`) // Ensure correct ID mapping
          .join(", ")}
  
        Choose the most relevant topic IDs and their names from the list above for this question.
      `
        )
        .join("\n")}
  
      Return the result as a JSON array where each object contains:
      {
        "question_id": QUESTION_ID,
        "question": QUESTION_TEXT,
        "question_topics": [
          { "id": TOPIC_ID, "name": "TOPIC_NAME" }
        ]
      }
    `;

  try {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-4",
        messages: [{ role: "system", content: prompt }],
        temperature: 0.2,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
      }
    );

    return JSON.parse(response.data.choices[0].message.content);
  } catch (error) {
    console.error("Error tagging questions:", error);
    return [];
  }
};
