import React, { useState } from 'react';
import classes from './AddCourse.module.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import makeAnimated from 'react-select/animated';
import Button from '@mui/material/Button';
import Select from 'react-select';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import "../TestDetails/AddTest/custom-select.css";
import Editor from '../../Editor/Editor';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Form from 'react-bootstrap/Form';
import SetupFeePage from './SetupFeePage';
import { handleFileChange } from '../../../Store/API/File';
import { useDispatch, useSelector } from 'react-redux';
import { addCourse, addCoursePrice } from '../../../Store/API/API';
import { applicationActions } from '../../../Store/Data/Slices/ApplicationSlice';
// import { set } from 'react-datepicker/dist/date_utils';

const AddCourse = () => {
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userData.userData);


  const animatedComponents = makeAnimated();
  const navigate = useNavigate();
  const stepsFree = ['Course Info', 'Overview & Content', 'Pricing Options'];
  const stepsPaid = ['Course Info', 'Overview & Content', 'Pricing Options', 'Payment Setup'];

  const [activeStep, setActiveStep] = useState(0);
  const [courseDetails, setCourseDetails] = useState({
    name: "",
    subtitle: "",
    subject: [],
    curriculum: [],
    classes: [],
    coverPhoto: "",
    includes: "",
    description: "",
    requirements: "",
    whatWillYouLearn: "",
    courseValidityFree: "Lifetime Access",
    courseValidityPaid: "",
    courseType: "free",
  })
  const [registration, setRegistration] = useState([
    {
      fieldTitle: "",
      fieldAmount: "",
    },
  ]);
  const [checkedItems, setCheckedItems] = useState({
    Promote: false,
    Show: false,
  });
  const [emiDetails, setEmiDetails] = useState({
    enableEMI: "No",
    numberOfEMIs: "",
    interestRate: "",
  });
  const [couponDetails, setCouponDetails] = useState({
    enableCoupon: "",
    couponValidTill: "",
    noOfCoupons: "",
    couponType: "",
    couponName: "",
    couponDiscount: "",
  }
  );
  const [emiCard, setEmiCard] = useState([]);
  const [registrationOpenTill, setRegistrationOpenTill] = useState("");
  const [gst, setGst] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);


  const curriculumOptions = [
    { value: 'JEE', label: 'JEE' },
    { value: 'CBSE', label: 'CBSE' },
  ];
  const subjectOptions = [
    { value: 'Physics', label: 'Physics' },
    { value: 'Chemistry', label: 'Chemistry' },
    { value: 'Maths', label: 'Maths' },
    { value: 'Biology', label: 'Biology' },
  ];
  const classesOptions = [
    { value: 'IX', label: 'IX' },
    { value: 'X', label: 'X' },
    { value: 'XI', label: 'XI' },
    { value: 'XII', label: 'XII' },
  ];

  const handelCancel = () => {
    navigate("/courses")
  }
  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };
  const handleInputChange = (e) => {
    setCourseDetails({
      ...courseDetails,
      [e.target.name]: e.target.value
    })
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleFinish = async () => {
    // console.log(courseDetails);
    // console.log(checkedItems);
    // console.log(registration, "registration");
    // console.log(emiDetails, "emiDetails");
    // console.log(registrationOpenTill, "registrationOpenTill");
    // console.log(gst, "gst");
    // console.log(totalAmount, "totalAmount");
    // console.log(emiCard, "emiCard");
    // console.log(couponDetails, "couponDetails");

    const fileUrl = await handleFileChange(courseDetails.coverPhoto, dispatch)
    if (fileUrl.data.file_url) {
      const courseData = {
        user_id: userState.userId,
        name: courseDetails.name,
        sub_title: courseDetails.subtitle,
        course_id: "",
        subject: courseDetails.subject.map((item) => item.value),
        classes: courseDetails.classes.map((item) => item.value),
        curriculum: courseDetails.curriculum.value,
        cover_photo: fileUrl.data.file_url,
        description: courseDetails.description,
        requirement: courseDetails.requirements,
        topic: courseDetails.whatWillYouLearn,
        include: courseDetails.includes,
        content_pricing: 'free',
        amount: "",
        dashboard_view: checkedItems.Show ? "yes" : "no",
        parent_institute_id: 4,
        validity_days: 365
      }
      const courseDataPaid = {
        user_id: userState.userId,
        name: courseDetails.name,
        sub_title: courseDetails.subtitle,
        course_id: "",
        subject: courseDetails.subject.map((item) => item.value),
        classes: courseDetails.classes.map((item) => item.value),
        curriculum: courseDetails.curriculum.value,
        cover_photo: fileUrl.data.file_url,
        description: courseDetails.description,
        requirement: courseDetails.requirements,
        topic: courseDetails.whatWillYouLearn,
        include: courseDetails.includes,
        content_pricing: 'paid',
        amount: Number(totalAmount) + Number(totalAmount * gst.value / 100),
        dashboard_view: checkedItems.Show ? "yes" : "no",
        parent_institute_id: 4,
        validity_days: courseDetails.courseValidityPaid
      }
      if (courseDetails.courseType === 'free') {
        const response = await addCourse(userState.token, courseData, dispatch);
        if (response) {
          navigate("/courses")
          dispatch(applicationActions.setAlertData({
            isAlert: true,
            alertMessage: "Course Added Successfully",
            alertType: "success",
          }))
          setTimeout(() => {
            dispatch(applicationActions.setAlertData({
              isAlert: false,
              alertMessage: "",
              alertType: "",
            }))
          }, 3000);
        }

      }
      if (courseDetails.courseType === 'paid') {

        // console.log(courseDataPaid, "courseDataPaid");

        const response = await addCourse(userState.token, courseDataPaid, dispatch);
        // console.log(response, "response");

        if (response.course_id) {
          const transformedData = {
            heads: registration.map((item) => {
              const titleKey = Object.values(item.fieldTitle)[0];
              return { [titleKey]: item.fieldAmount.toString() };
            }),
          };
          if (couponDetails.enableCoupon.value === 'Yes') {
            // console.log("Course ID", response.course_id);

            const feesData = {
              course_id: response.course_id,
              gst_amount: Number(totalAmount) * Number(gst.value) / 100,
              amount: totalAmount,
              registration_open_till: registrationOpenTill,
              frequency: 'onetime',
              heads: transformedData.heads,
              coupons: [
                {
                  valid_till: couponDetails.couponValidTill,
                  coupon_type: couponDetails.couponType.value,
                  coupon_value: couponDetails.couponDiscount,
                  coupon_name: couponDetails.couponName,
                  no_of_coupon: couponDetails.noOfCoupons,
                }
              ]
            }
            const response2 = await addCoursePrice(userState.token, feesData, dispatch);
            if (response2.statuscode === "200") {
              navigate("/courses")
              dispatch(applicationActions.setAlertData({
                isAlert: true,
                alertMessage: "Course Added Successfully",
                alertType: "success",
              }))
              setTimeout(() => {
                dispatch(applicationActions.setAlertData({
                  isAlert: false,
                  alertMessage: "",
                  alertType: "",
                }))
              }, 3000);
            }
          }
          else if (emiDetails.enableEMI.value === 'Yes') {
            const feesData = {
              course_id: response.course_id,
              gst_amount: Number(totalAmount) * Number(gst.value) / 100,
              amount: totalAmount,
              registration_open_till: registrationOpenTill,
              frequency: 'installment',
              heads: transformedData.heads,
              installments: emiCard,
            }
            console.log(feesData, "feesData");

            const response2 = await addCoursePrice(userState.token, feesData, dispatch);
            if (response2.statuscode === "200") {
              navigate("/courses")
              dispatch(applicationActions.setAlertData({
                isAlert: true,
                alertMessage: "Course Added Successfully",
                alertType: "success",
              }))
              setTimeout(() => {
                dispatch(applicationActions.setAlertData({
                  isAlert: false,
                  alertMessage: "",
                  alertType: "",
                }))
              }, 3000);
            }
          }
          else {
            // console.log("no emi no coupon");

            const feesData = {
              course_id: response.course_id,
              gst_amount: Number(totalAmount) * Number(gst.value) / 100,
              amount: totalAmount,
              registration_open_till: registrationOpenTill,
              frequency: 'onetime',
              heads: transformedData.heads,
            }
            const response2 = await addCoursePrice(userState.token, feesData, dispatch);
            // console.log(response2, "response2");

            if (response2.statuscode === "200") {
              navigate("/courses")
              dispatch(applicationActions.setAlertData({
                isAlert: true,
                alertMessage: "Course Added Successfully",
                alertType: "success",
              }))
              setTimeout(() => {
                dispatch(applicationActions.setAlertData({
                  isAlert: false,
                  alertMessage: "",
                  alertType: "",
                }))
              }, 3000);
            }
          }
        }
      }

    }

  };

  return (
    <div className={classes.MainContainer}>
      <div className={classes.container}>
        <div className={classes.steper}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} orientation="horizontal" >
              {courseDetails?.courseType === "free" ? ( // Check if courseDetails indicates it's free
                stepsFree.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))
              ) : ( // If not free, render paid course steps
                stepsPaid.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))
              )}
            </Stepper>
          </Box>
        </div>
      </div>
      <div className={classes.rows}>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          {activeStep === 0 && (
            <>
              <div className={`${classes.inputTitel}`}>Course Name</div>
              <input type="text" required className={classes.titel} placeholder='Example: Photography Masterclass: A Complete Guide to Photography' value={courseDetails.name} onChange={(e) => {
                handleInputChange(e);
              }} name="name" />
              <div className={`${classes.inputTitel}`}>Course Subtitle</div>
              <input type="text" required className={classes.titel} placeholder='Example: The Best Online Professional Photography Class: How to Take Amazing Photos for Beginners & Advanced Photographers' value={courseDetails.subtitle} onChange={(e) => {
                handleInputChange(e);
              }} name="subtitle" />
              <div className={classes.columns}>
                <div className={classes.col1}>
                  <div className={`${classes.inputTitel}`}>Subject</div>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={subjectOptions}
                    placeholder={'Select Subject'}
                    required="Enter test Type"
                    classNamePrefix="custom-select"
                    value={courseDetails.subject}
                    onChange={(selectedOptions) => {
                      setCourseDetails({
                        ...courseDetails,
                        subject: selectedOptions
                      })
                    }}
                  />

                  <div className={`${classes.inputTitel}`}>Curriculum</div>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={curriculumOptions}
                    placeholder={'Select Curriculum'}
                    required="Enter test Type"
                    classNamePrefix="custom-select"
                    value={courseDetails.curriculum}
                    onChange={(selectedOptions) => {
                      setCourseDetails({
                        ...courseDetails,
                        curriculum: selectedOptions
                      })
                    }}
                  />
                </div>
                <div className={classes.col2}>
                  <div className={`${classes.inputTitel}`}>Classes</div>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={classesOptions}
                    placeholder={'Select Class'}
                    required="Enter test Type"
                    classNamePrefix="custom-select"
                    value={courseDetails.classes}
                    onChange={(selectedOptions) => {
                      setCourseDetails({
                        ...courseDetails,
                        classes: selectedOptions
                      })
                    }}
                  />
                  <div className={`${classes.inputTitel}`}>Cover Photo</div>
                  <Form.Group controlId="formFileLg">
                    <Form.Control
                      type="file"
                      style={{
                        backgroundColor: "#F3F1FF",
                        height: "40px",
                        padding: "0",
                        lineHeight: "40px",
                        borderRadius: "5px",
                        // overflow: "hidden", // Hides any overflow from file input
                        paddingLeft: "10px",
                        borderColor: "#717171",
                      }}
                      required
                      onChange={(e) => {
                        setCourseDetails({
                          ...courseDetails,
                          coverPhoto: e.target.files[0]
                        })
                      }}
                    />
                    {courseDetails.coverPhoto && (
                      <div style={{ marginTop: "10px", color: "#555" }}>
                        Selected file: {courseDetails.coverPhoto.name}
                        <Button
                          variant="outlined"
                          style={{ marginLeft: "10px" }}
                          onClick={() => setCourseDetails({ ...courseDetails, coverPhoto: null })}
                        >
                          Remove File
                        </Button>
                      </div>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className={`${classes.inputTitel}`}>Includes</div>
              <div className={classes.textArea}>
                <Editor
                  key={4}
                  height={"7rem"}
                  width={"100%"}
                  placeholder="What's included in this course"
                  value={courseDetails.includes}
                  parentFunction={(data) => {
                    setCourseDetails({
                      ...courseDetails,
                      includes: data
                    })
                  }
                  }
                />
              </div>
            </>
          )}
          {activeStep === 1 && (
            <>
              <div className={`${classes.inputTitel}`}>Description</div>
              <div className={classes.textArea}>
                <Editor
                  key={1}
                  height={"7rem"}
                  width={"100%"}
                  placeholder="Write Description here"
                  style={{ border: "none" }}
                  parentFunction={(data) => {
                    setCourseDetails({
                      ...courseDetails,
                      description: data
                    })
                  }}
                  value={courseDetails.description}
                />
              </div>
              <div className={`${classes.inputTitel}`}>Requirements</div>
              <div className={classes.textArea}>
                <Editor
                  key={1}
                  height={"7rem"}
                  width={"100%"}
                  placeholder="Write Requirements here"
                  parentFunction={(data) => {
                    setCourseDetails({
                      ...courseDetails,
                      requirements: data
                    })
                  }
                  }
                  value={courseDetails.requirements}
                />
              </div>
              <div className={`${classes.inputTitel}`}>What will you learn</div>
              <div className={classes.textArea}>
                <Editor
                  key={3}
                  height={"7rem"}
                  width={"100%"}
                  placeholder="What will you learn in this course"
                  parentFunction={(data) => {
                    setCourseDetails({
                      ...courseDetails,
                      whatWillYouLearn: data
                    })
                  }
                  }
                  value={courseDetails.whatWillYouLearn}
                />
              </div>
            </>
          )}
          {activeStep === 2 && (
            <div style={{ padding: '10px', }}>
              <div className={classes.courseTypeContainer}>
                <div>
                  <div className={`${classes.inputTitel}`}>Course Type</div>
                  <div>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={courseDetails.courseType === 'free'}
                          onChange={(event) => {
                            setCourseDetails({
                              ...courseDetails,
                              courseType: event.target.value
                            })
                          }}
                          value="free"
                          name="classType"
                          className={classes.radiobtn}
                        />
                      }
                      label="Free"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={courseDetails.courseType === 'paid'}
                          onChange={(event) => {
                            setCourseDetails({
                              ...courseDetails,
                              courseType: event.target.value
                            })
                          }}
                          value="paid"
                          name="classType"
                          className={classes.radiobtn}
                        />
                      }
                      label="Paid"
                    />
                  </div>
                </div>
                <div>
                  <div className={`${classes.inputTitel}`}>Course Validity</div>
                  <input type='text' className={classes.textArea} style={{ height: '2rem', borderRadius: '10px', paddingLeft: '5px', margin: 0 }} value={courseDetails.courseType === "free" ? courseDetails.courseValidityFree : courseDetails.courseValidityPaid} disabled={courseDetails.courseType === 'free'} placeholder='Example: 90Days' onChange={(event) => {
                    setCourseDetails({
                      ...courseDetails,
                      courseValidityPaid: event.target.value
                    })
                  }} ></input>
                </div>
              </div>
              <div className={`${classes.inputTitel}`}>Choose what to show</div>
              <div className="flex flex-col">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems.checkbox1}
                      onChange={handleCheckboxChange}
                      name="Promote"
                      className="custom-checkbox"
                    />
                  }
                  label="Promote on Online Courses"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems.checkbox2}
                      onChange={handleCheckboxChange}
                      name="Show"
                      className="custom-checkbox"
                    />
                  }
                  label="Show on Dashboard"
                />
              </div>
            </div>
          )}
          {activeStep === 3 && (
            <SetupFeePage registration={registration} setRegistration={setRegistration} emiDetails={emiDetails} setEmiDetails={setEmiDetails} registrationOpenTill={registrationOpenTill} setRegistrationOpenTill={setRegistrationOpenTill} gst={gst} setGst={setGst} totalAmount={totalAmount} setTotalAmount={setTotalAmount} emiCard={emiCard} setEmiCard={setEmiCard} couponDetails={couponDetails} setCouponDetails={setCouponDetails} />
          )
          }
        </form>
        <span className={classes.buttonContainer}>
          <div className={classes.btns}>
            <div style={{ gap: "20px", display: "flex" }} >
              <Button variant="contained" onClick={handelCancel}>
                Cancel
              </Button>
              {activeStep >= 1 && <Button onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              }} variant="contained" >
                Back
              </Button>}
            </div>
            <Button
              onClick={() => {
                if (courseDetails.courseType === 'free') {
                  activeStep === stepsFree.length - 1 ? handleFinish() : handleNext();
                } else {
                  activeStep === stepsPaid.length - 1 ? handleFinish() : handleNext();
                }

              }}
              variant="contained"
            >
              {courseDetails.courseType === 'free'
                ? activeStep === stepsFree.length - 1
                  ? 'Create'
                  : 'Next'
                : activeStep === stepsPaid.length - 1
                  ? 'Create'
                  : 'Next'}
            </Button>

          </div>
        </span>
      </div>
    </div>
  );
};
export default AddCourse;