import React from "react";
import styles from "./FIB.module.css";
import Tag from "../Tag/Tag";
import Integer from "../Integer/Integer";

const FIB = ({ correctOption, fibCount, question, questionOrder }) => {
  // Generate placeholders for options based on fibCount
  const fib_Count = fibCount * 1;

  const options = Array.from({ length: fib_Count }, (_, index) => index);

  return (
    <div className={styles.mcqContainer}>
      <div className={styles.headerSectionContainer}>
        <div className={styles.mcqQuestion}>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              className={styles.questionInputBox}
              value={questionOrder}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: question || " ",
              }}
            ></div>
          </div>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.infoSubContainer}>
            <Tag
              tagName="ID"
              tagValue="90"
              tagColor="#FF933063"
              tagButtonColor={"#FFE4CC"}
            />
            <Tag
              tagName="Marks"
              tagValue="5"
              tagColor="#ffcccc"
              tagButtonColor={"#ff9191"}
            />
          </div>
          <div className={styles.infoSubContainer}>
            <Tag
              tagName="Actions"
              tagValue="Easy"
              tagColor="#cce5ff"
              tagButtonColor={"#91caff"}
            />
          </div>
        </div>
      </div>
      <div className={styles.mcqOptions}>
        {options?.map((item, index) => (
          <div
            key={index}
            className={styles.mcqOptionContainer}
            style={{ marginLeft: "30px" }}
          >
            <div
              className={
                correctOption[index] === "True"
                  ? styles.mcqOptionCorrect
                  : styles.mcqOption
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `Answer: ${correctOption[index] || ""}`,
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FIB;
