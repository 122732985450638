// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoInternet_outerContainer__4H7fc {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
}

.NoInternet_noInternetImg__RIVwP {
  width: 20%;
  height: 30%;
}

.NoInternet_noInternetHeading__j7Bg8 {
  font-size: 2rem;
  font-weight: 700;
  color: #000;
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.NoInternet_noInternetText__toRhf {
  font-size: 1.2rem;
  font-weight: 700;
  color: grey;
  margin-top: 1rem;
  margin-bottom: 0rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/NoInternet/NoInternet.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".outerContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100%;\n  background-color: #fff;\n  flex-direction: column;\n}\n\n.noInternetImg {\n  width: 20%;\n  height: 30%;\n}\n\n.noInternetHeading {\n  font-size: 2rem;\n  font-weight: 700;\n  color: #000;\n  margin-top: 1rem;\n  margin-bottom: 0rem;\n}\n\n.noInternetText {\n  font-size: 1.2rem;\n  font-weight: 700;\n  color: grey;\n  margin-top: 1rem;\n  margin-bottom: 0rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `NoInternet_outerContainer__4H7fc`,
	"noInternetImg": `NoInternet_noInternetImg__RIVwP`,
	"noInternetHeading": `NoInternet_noInternetHeading__j7Bg8`,
	"noInternetText": `NoInternet_noInternetText__toRhf`
};
export default ___CSS_LOADER_EXPORT___;
