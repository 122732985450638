import React, { useEffect, useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
import SelectInput from "../SelectInput/SelectInput";
import { uploadQuestionBank } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";

const SelectInputField = ({
  heading,
  placeholder,
  field,
  type,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
}) => {
  return (
    <div className={"flex flex-col"}>
      <label className={"text-base font-medium"}>
        {heading} <span className={"text-primaryOrange"}>*</span>
      </label>
      <div className={"rounded-xl"}>
        <SelectInput
          width={"100%"}
          placeHolder={placeholder}
          options={dropDownoptions}
          field={field}
          handleInputChange={handleInputChange}
          value={dropDownValues}
        />
      </div>
    </div>
  );
};

const ImportQuestionModal = ({
  handleImportModalClose,
  isImportModalOpen,
  questionBank,
  calculateImportModalvalues,
}) => {
  const [dropDownValues, setDropDownValues] = useState({
    questionBank: [],
  });
  const [dropDownoptions, setDropDownOptions] = useState({
    questionBank: [],
  });
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);

  const handleInputChange = (field, value) => {
    setDropDownValues((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const handleImport = async () => {
    // console.log("Importing");
    // console.log(dropDownValues, file);
    setIsUploading(true);
    const data = await uploadQuestionBank(
      userState.token,
      file,
      dropDownValues.questionBank.value,
      dispatch
    );
    // console.log(data, "data");

    if (data.statuscode === "200") {
      handleImportModalClose("fileUploaded");
      setFile("");
      setDropDownValues({
        questionBank: [],
      });
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Question Uploaded Successfully",
          alertType: "success",
        })
      );
    }
    setIsUploading(false);
  };

  useEffect(() => {
    // console.log("UseEffect Running");

    if (calculateImportModalvalues) {
      //   console.log("calculateImportModalvalues");

      setDropDownOptions({
        questionBank: questionBank.map((item) => {
          return {
            value: item.question_bank_id,
            label: item.title,
          };
        }),
      });
    }
  }, [questionBank, calculateImportModalvalues]);

  return (
    <CustomModal
      isOpen={isImportModalOpen}
      onClose={handleImportModalClose}
      size="large"
    >
      <div className="w-full md:w-[500px] h-[402px]">
        <p className="text-center text-lg font-semibold ">
          Import Question Bank
        </p>
        <div className="mt-4">
          <SelectInputField
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            placeholder={"Select Question Bank"}
            field={"questionBank"}
            heading={"Select Question Bank"}
            handleInputChange={handleInputChange}
          />
        </div>
        <div className="mt-4">
          <input
            type="file"
            className="w-full py-2 px-4 border border-gray-300 rounded-lg"
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />

          <div className="mt-4 space-x-2 flex ">
            <div
              className={`py-2 px-4 rounded-lg text-white hover:bg-primaryOrangeDisabled cursor-pointer  ${
                isUploading ? "bg-primaryOrangeDisabled" : "bg-primaryOrange"
              } `}
              onClick={!isUploading && handleImport}
            >
              {isUploading ? "Importing" : "Import"}
            </div>

            <div
              className="bg-primaryOrange py-2 px-4 rounded-lg text-white hover:bg-primaryOrangeDisabled cursor-pointer"
              onClick={handleImportModalClose}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ImportQuestionModal;
