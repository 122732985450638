import { useState } from "react";
import styles from "./CreateModal.module.css";
import Select from "react-select";

function CreateModal({ setOpenCreateModal, openCreateModal }) {
  const handleClose = () => setOpenCreateModal(false);

  const [dropDownOptions, setDropDownOptions] = useState({
    batch: {
      options: [
        { value: "Batch 1", label: "Batch 1" },
        { value: "Batch 2", label: "Batch 2" },
        { value: "Batch 3", label: "Batch 3" },
        { value: "Batch 4", label: "Batch 4" },
      ],
      selectedValue: null,
    },
  });

  const SelectInput = ({ label, dropdownOptions, icon, required }) => {
    return (
      <div className={styles.selectWrapper}>
        <label className={styles.label}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
        <div className={styles.inputContainer}>
          {icon && (
            <img loading="lazy" src={icon} alt="" className={styles.icon} />
          )}
          <Select
            defaultValue={[dropdownOptions?.[0]]}
            isMulti
            name="colors"
            options={dropdownOptions}
            className="basic-multi-select"
            placeholder="Select name"
            styles={{
              control: (base) => ({
                ...base,
                border: "none", // Remove border
                boxShadow: "none", // Remove default focus outline
                width: "100%", // Set width to 100%
              }),
              container: (base) => ({
                ...base,
                width: "100%", // Ensures container also spans full width
              }),
            }}
          />
        </div>
      </div>
    );
  };

  const DateInput = ({ label, value, required }) => {
    return (
      <div className={styles.timeWrapper}>
        <label className={styles.label}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
        <div className={styles.inputContainer}>
          <input type="date" className={styles.timeInput} value={value} />
        </div>
      </div>
    );
  };

  const TimeInput = ({ label, value, required }) => {
    return (
      <div className={styles.timeWrapper}>
        <label className={styles.label}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
        <div className={styles.inputContainer}>
          <input type="time" className={styles.timeInput} value={value} />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.crossIcon}></div>
          <header className={styles.header}>
            <div className={styles.headerContainer}>
              <p className={styles.title}>Create Live Sessions</p>
            </div>
          </header>

          <form className={styles.form}>
            <SelectInput
              label="Select Course"
              placeholder="Select batch"
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/d3a1c55656a37b94e40dec5775ede8ab72164373dfbd3252bd9c95d7a545f0fe?placeholderIfAbsent=true&apiKey=65c3b0a390fd4311b72fcd91fc18e875"
              dropdownOptions={dropDownOptions.batch.options}
              required
            />

            <div className={styles.grid}>
              <DateInput
                label="Session Date"
                placeholder="Select date"
                icon="https://cdn.builder.io/api/v1/image/assets/TEMP/142bf89b75926edc803a4c8538be1d12a70b9dcc35fa5d08edd4c782ee37e85a?placeholderIfAbsent=true&apiKey=65c3b0a390fd4311b72fcd91fc18e875"
                required
              />
              <SelectInput
                label="Select Tutor"
                placeholder="Select Tutor"
                required
              />
              <TimeInput label="Time (From)" value="12:00 PM" required />
              <TimeInput label="Time (To)" value="01:00 PM" required />
              <SelectInput label="Class" placeholder="XII" required />
              <SelectInput label="Subject" placeholder="Physics" required />
            </div>

            <SelectInput
              label="Chapter"
              placeholder="Select chapter"
              required
            />

            <button type="button" className={styles.addTopics}>
              + Add Topics
            </button>

            <div className={styles.actions}>
              <button type="button" className={styles.cancelButton}>
                Cancel
              </button>
              <button type="submit" className={styles.submitButton}>
                Create Session
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CreateModal;
