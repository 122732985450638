// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPolicy_heading__kbnuu {
    width: 100%;
    height: 100px;
    background-color: #104a9c;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PrivacyPolicy_heading__kbnuu h1 {
    color: white;
}

.PrivacyPolicy_subContainer__mD\\+Bl {
    display: flex;
    flex-direction: column;
    margin: 80px 120px 0px 120px;
    height: 85vh;
    overflow-y: auto;   
}

@media screen and (max-width: 1024px) {
    .PrivacyPolicy_subContainer__mD\\+Bl {
        margin: 80px 80px 50px 80px;
    }
}

@media screen and (max-width: 768px) {
    .PrivacyPolicy_subContainer__mD\\+Bl {
        margin: 80px 50px 50px 50px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/PrivacyPolicy/PrivacyPolicy.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,4BAA4B;IAC5B,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":[".heading {\n    width: 100%;\n    height: 100px;\n    background-color: #104a9c;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.heading h1 {\n    color: white;\n}\n\n.subContainer {\n    display: flex;\n    flex-direction: column;\n    margin: 80px 120px 0px 120px;\n    height: 85vh;\n    overflow-y: auto;   \n}\n\n@media screen and (max-width: 1024px) {\n    .subContainer {\n        margin: 80px 80px 50px 80px;\n    }\n}\n\n@media screen and (max-width: 768px) {\n    .subContainer {\n        margin: 80px 50px 50px 50px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `PrivacyPolicy_heading__kbnuu`,
	"subContainer": `PrivacyPolicy_subContainer__mD+Bl`
};
export default ___CSS_LOADER_EXPORT___;
