import React from "react";
import styles from "./LiveSession.module.css";

const LiveSession = () => {
  return (
    <div className={styles.container}>
      <iframe
        src="https://meet.google.com/qjy-isax-msn?authuser=0&hl=en_GB"
        title="G-MEET "
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        className={styles.iframe}
      ></iframe>
    </div>
  );
};

export default LiveSession;
