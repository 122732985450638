import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { FaPlayCircle } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import { updatePendingCurricular } from "../../../Store/API/API";

const HeaderComponent = ({ sessionDetail }) => {
  //   console.log("sessionDetail", sessionDetail);

  return (
    <div className="bg-[#e7edf6] p-4 rounded-2xl w-full">
      <ul className="text-gray-700">
        <li className="flex text-base font-metropolis items-center gap-2 font-medium">
          <span className="text-[#F36421]">+</span> Tutor Name :{" "}
          {sessionDetail?.session_tutor_name || "-"}
        </li>
        <li className="flex text-base font-metropolis items-center gap-2 font-medium">
          <span className="text-[#F36421]">+</span> Subject :{" "}
          {sessionDetail?.session_subject?.label || "-"} • Class :{" "}
          {sessionDetail?.session_class?.label || "-"}
        </li>
        <li className="flex text-base font-metropolis items-center gap-2 font-medium">
          <span className="text-[#F36421]">+</span> Chapter :{" "}
          {sessionDetail?.session_chapter?.label || "-"}
        </li>
        <li className="flex text-base font-metropolis items-center gap-2 font-medium">
          <span className="text-[#F36421]">+</span> Topics :{" "}
          {sessionDetail?.session_topic
            ?.map((topic) => topic.label)
            .join(", ") || "-"}
        </li>
      </ul>
      <div className="bg-white px-4 py-2 mt-2 rounded-2xl shadow-sm flex flex-col md:flex-row w-fit items-center gap-4">
        <div className="flex items-center gap-2 text-blue-600">
          <WatchLaterOutlinedIcon />{" "}
          <span className="text-gray-700 text-base font-metropolis">
            {sessionDetail?.fromTime || "-"} - {sessionDetail?.toTime || "-"}
          </span>
        </div>
        <div className="flex items-center gap-2 text-blue-600">
          <CalendarMonthIcon />{" "}
          <span className="text-gray-700 text-base font-metropolis">
            {sessionDetail?.fromDate || "-"}
          </span>
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ progress, setProgress, setVideoLive, setDppLive }) => {
  const handleChange = (event) => {
    setProgress(Number(event.target.value));

    if (event.target.value >= 80) {
      setDppLive(true);
      setVideoLive(true);
    } else {
      setDppLive(false);
      setVideoLive(false);
    }
  };

  return (
    <div className="w-full p-4">
      <label className="block font-semibold mb-7">
        Select Completion Status
      </label>

      {/* Scale with percentage values */}
      <div className="flex justify-between items-end text-sm font-semibold text-gray-700 mb-1">
        <span className="h-3 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-3 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-3 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-3 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-3 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-3 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-3 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-1 w-[0.1rem] bg-slate-300"></span>
        <span className="h-3 w-[0.1rem] bg-slate-300"></span>
      </div>

      <div className="relative w-full">
        {/* Progress Indicator */}
        <div
          className="absolute -top-12 bg-white text-lg font-semibold p-1 rounded-full"
          style={{
            left: `calc(${Math.min(Math.max(progress, 2), 95)}% - 15px)`,
          }}
        >
          {progress}%
        </div>

        {/* Progress Bar */}
        <input
          type="range"
          min="0"
          max="100"
          value={progress}
          onChange={handleChange}
          className="w-full h-4 rounded-lg appearance-none cursor-pointer"
          style={{
            WebkitAppearance: "none",
            appearance: "none",
            background: `linear-gradient(to right, #f97316 ${progress}%, #e5e7eb ${progress}%)`,
          }}
        />
        <style>
          {`
            input[type="range"]::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              width: 40px;
              height: 40px;
              background: #f97316;
              border: 3px solid white;
              border-radius: 50%;
              cursor: pointer;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            }
            input[type="range"]::-moz-range-thumb {
              width: 30px;
              height: 30px;
              background: #f97316;
              border: 3px solid white;
              border-radius: 50%;
              cursor: pointer;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            }
          `}
        </style>
      </div>
    </div>
  );
};

const Cards = ({ videoLive, setVideoLive, dppLive, setDppLive }) => {
  return (
    <div className="w-full p-4">
      <label className="block font-semibold mb-2">Select Session Status</label>
      <div className="flex gap-4">
        {/* Video Live Card */}
        <div
          className={`flex items-center justify-between w-1/2 p-4 border rounded-lg cursor-pointer ${
            videoLive ? "border-orange-500" : "border-gray-300"
          }`}
          onClick={() => setVideoLive(!videoLive)}
        >
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 flex items-center justify-center bg-orange-100 rounded-full">
              <FaPlayCircle className="text-orange-500 text-xl" />
            </div>
            <span className="text-gray-700 text-base font-medium w-[60%]">
              Mark this session video LIVE
            </span>
          </div>
          {videoLive ? (
            <FaCheckSquare className="text-orange-500 text-xl" />
          ) : (
            <FaRegSquare className="text-gray-400 text-xl" />
          )}
        </div>

        {/* DPP Live Card */}
        <div
          className={`flex items-center justify-between w-1/2 p-4 border rounded-lg cursor-pointer ${
            dppLive ? "border-blue-500" : "border-gray-300"
          }`}
          onClick={() => setDppLive(!dppLive)}
        >
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
              <FaGraduationCap className="text-blue-500 text-xl" />
            </div>
            <span className="text-gray-700 text-base font-medium w-[60%]">
              Mark this session DPP’s LIVE
            </span>
          </div>
          {dppLive ? (
            <FaCheckSquare className="text-blue-500 text-xl" />
          ) : (
            <FaRegSquare className="text-gray-400 text-xl" />
          )}
        </div>
      </div>
    </div>
  );
};

const UpdateSessionStatusModal = ({
  currentSessionIndex,
  handleModalSubmit,
  sessionDetail,
  isLiveDpp,
  isLiveVideo,
  progressStatus,
  showEditButton,
}) => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  // console.log("sessionDetail", sessionDetail);

  const [videoLive, setVideoLive] = useState(isLiveVideo ? isLiveVideo : false);
  const [dppLive, setDppLive] = useState(isLiveDpp ? isLiveDpp : false);
  const [progress, setProgress] = useState(progressStatus ? progressStatus : 0);

  const handleSubmit = async () => {
    // console.log("Submitting...");
    // console.log(
    //   "Video Live:",
    //   videoLive,
    //   "DPP Live:",
    //   dppLive,
    //   "Progress:",
    //   progress
    // );

    if (!userState?.userId || !sessionDetail?.session_id) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "User ID or Session ID not found",
          alertType: "error",
        })
      );
      return;
    }

    const sessionData = {
      user_id: userState?.userId,
      session_id: sessionDetail?.session_id,
      curricular_percent: progress,
      live_video: videoLive ? "1" : "0",
      live_dpp: dppLive ? "1" : "0",
    };

    const data = await updatePendingCurricular(sessionData);

    if (data?.message === "Curricular Updated") {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Session details updated successfully",
          alertType: "success",
        })
      );
    }

    // console.log(sessionData);

    handleModalSubmit(progress, videoLive, dppLive);
  };

  return (
    <div className="rounded-none flex min-h-[400px] xl:w-[700px] lg:w-[600px] md:w-[500px] flex-col">
      <header className="flex w-full gap-x-[77px] gap-y-[40px] items-center justify-center self-end">
        <div className="bg-gradient-to-br from-[#fff5ef] to-white rounded-[20px]">
          <p className="text-black text-center font-bold text-[20px] leading-[1.2] font-[Metropolis,-apple-system,Roboto,sans-serif] flex-grow p-[14px_70px]">
            Update Curriculum Details
          </p>
        </div>
      </header>
      <div className="mt-3">
        {/* <div>current session: {currentSessionIndex}</div> */}
        <HeaderComponent sessionDetail={sessionDetail} />
        <ProgressBar
          progress={progress}
          setProgress={setProgress}
          setDppLive={setDppLive}
          setVideoLive={setVideoLive}
        />
        <Cards
          videoLive={videoLive}
          setVideoLive={setVideoLive}
          dppLive={dppLive}
          setDppLive={setDppLive}
        />
        {showEditButton && (
          <div
            className="flex gap-4 mt-5 self-end flex-wrap justify-center"
            //   onClick={handleSubmit}
          >
            <button
              className="rounded-[12px] bg-[#f36421] text-white px-4 py-[13px] w-[208px] font-medium text-[18px] leading-none self-center border-none cursor-pointer"
              onClick={handleSubmit}
              // disabled={isSubmitting}
            >
              Update & Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateSessionStatusModal;
