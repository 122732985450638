import React, { useState } from 'react';
import classes from './AddTest.module.css';
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import makeAnimated from 'react-select/animated';
import "react-datepicker/dist/react-datepicker.css";
import Editor from '../../../Editor/Editor';
import "./custom-select.css";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
const AddTest = () => {
    const animatedComponents = makeAnimated();
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [submission, setSubmission] = useState('');
    const [editorContent, setEditorContent] = useState('');
    const [formData, setFormData] = useState({
        formTitle: '',
        publishDate: null,
        publishTime: null,
        hours: '',
        minutes: '',
        timmings: 'no',
        submission: '',
        testChapters: [],
        testMode: [],
        classType: [],
        testType: [],
        dueDate: null,
        dueTime: null,
        autoPublish: false,
        testSubjects: [],
        instructions: '',
    });
    const [errors, setErrors] = useState({
        publishDate: false,
        publishTime: false,
        dueDate: false,
        dueTime: false
    });
    const [isValid, setIsValid] = useState(true);


    const testOptions = [
        { value: 'JEE', label: 'JEE' },
        { value: 'NEET', label: 'NEET' },
        { value: 'CBSE', label: 'CBSE' },
    ];
    const classOptions = [
        { value: 'IX', label: 'IX' },
        { value: 'X', label: 'X' },
        { value: 'XI', label: 'XI' },
        { value: 'XII', label: 'XII' },
    ];
    const subjectOptions = [
        { value: 'Physics', label: 'Physics' },
        { value: 'Chemistry', label: 'Chemistry' },
        { value: 'Math', label: 'Math' },
        { value: 'Biology', label: 'Biology' },
    ];
    const testModeOptions = [
        { value: 'DPP', label: 'DPP' },
        { value: 'Test', label: 'Test' },
        { value: 'Module Exercies', label: 'Module Exercies' },

    ];
    const chapterOptions = [
        { value: '3d Geometry', label: '3d Geometry' },
        { value: 'Derivation', label: 'Derivation' },
        { value: 'Permutation', label: 'Permutation' },
        { value: 'Combination', label: 'Combination' },
        { value: 'Eco System', label: 'Eco System' },
        { value: 'Respiratory System', label: 'Respiratory System' },
        { value: 'Human growth Cycle', label: 'Human growth Cycle' },
        { value: 'Biodiversity', label: 'Biodiversity' },
        { value: 'Speed', label: 'Speed' },
        { value: 'Wave Optics', label: 'Wave Optics' },
        { value: 'Light', label: 'ight' },
        { value: 'Motion', label: 'Motion' },
        { value: 'Organic', label: 'Organic' },
        { value: 'Inoraganic', label: 'Inoraganic' },
        { value: 'ThermoDyanmic', label: 'ThermoDyanmic' },
        { value: 'Periodic Table', label: 'Periodic Table' },
    ];


    const onlyNumber = (event, field) => {
        const inputValue = event.target.value;
        const filteredValue = inputValue.replace(/\D/g, '');
        if (field === 'hours') {
            setHours(filteredValue);
            handelSubmit('hours', filteredValue);
        } else if (field === 'mins') {
            setMinutes(filteredValue);
            handelSubmit('minutes', filteredValue);
        }
        else if (field === 'sub') {
            setSubmission(filteredValue);
            handelSubmit('submission', filteredValue);
        }
    };
    const handelSubmit = (key, value) => {
        setFormData(prevState => ({ ...prevState, [key]: value }));
    };
    const handleEditorChange = (content) => {
        setEditorContent(content);
        handelSubmit('instructions', content)
    };
    const submit = (e) => {
        e.preventDefault();

        let formIsValid = true;
        if (formIsValid) {
            console.log(formData);
            console.log(editorContent);
        }
    };
    return (
        <div className={classes.mainContainer}>
            <form onSubmit={submit}>
                <div className={classes.Container}>
                    <h2>Create New Test</h2>
                    <div className={classes.columns}>
                        <div className={classes.col1}>
                            <div className={`${classes.inputTitel} ${classes.heading}`}>Test Title</div>
                            <input type="text" required className={classes.titel} placeholder="Example: Physics Chapter II" onChange={(e) => { handelSubmit('formTitle', e.target.value) }} name="titel" />
                            <div className={classes.columnelements}>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Class Type</div>
                                    <div className={classes.twoelements}>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            placeholder={'Select Class type'}
                                            options={classOptions}
                                            classNamePrefix="custom-select"
                                            required="Enter Class Type"
                                            onChange={(selectedOptions) => {
                                                const values = selectedOptions.map(option => option.value);
                                                handelSubmit('classType', values);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Test Type</div>
                                    <div className={classes.twoelements}>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={testOptions}
                                            placeholder={'Select Test type'}
                                            required="Enter test Type"
                                            classNamePrefix="custom-select"
                                            onChange={(selectedOptions) => {
                                                const values = selectedOptions.map(option => option.value);
                                                handelSubmit('testType', values);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.columnelements}>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Test Subject</div>
                                    <div className={classes.twoelements}>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={subjectOptions}
                                            placeholder={'Select Test Subject'}
                                            required="Enter Chapter "
                                            classNamePrefix="custom-select"
                                            onChange={(selectedOptions) => {
                                                const values = selectedOptions.map(option => option.value);
                                                handelSubmit('testSubjects', values);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Test Chapter</div>
                                    <div className={classes.twoelements}>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={chapterOptions}
                                            placeholder={'Select Test Chapter'}
                                            required="Enter Subject"
                                            classNamePrefix="custom-select"
                                            onChange={(selectedOptions) => {
                                                const values = selectedOptions.map(option => option.value);
                                                handelSubmit('testChapters', values);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.columnelements}>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Test Mode</div>
                                    <div className={classes.twoelements}>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={testModeOptions}
                                            placeholder={'Select Test Mode'}
                                            required
                                            classNamePrefix="custom-select"
                                            onChange={(selectedOptions) => {
                                                const values = selectedOptions.map(option => option.value);
                                                handelSubmit('testMode', values);
                                            }}

                                        />
                                    </div>
                                </div>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Submissions</div>
                                    <div className={classes.twoelements}>
                                        <div className={classes.timmings}>
                                            <input
                                                type="number"
                                                required="Enter no of Submission Allowed"
                                                value={submission}
                                                max={10}
                                                onChange={(e) => onlyNumber(e, 'sub')}
                                                style={{ marginRight: '10px' }}
                                            />
                                            <label>Submission(s) allowed</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.col2}>
                            <div className={classes.columnelements}>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Publish Date</div>
                                    <div className={classes.twoelements}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                    type="date"
                                                    required="Enter Publish Date"
                                                    className="custom-datepicker"
                                                    renderInput={(params) => <TextField {...params}
                                                    />}
                                                    onChange={(e) => {
                                                        const options = { day: '2-digit', month: 'short', year: 'numeric' };
                                                        const formattedDate = e.$d.toLocaleDateString('en-GB', options);
                                                        handelSubmit('publishDate', formattedDate);
                                                        setErrors(prevErrors => ({ ...prevErrors, publishDate: false }));
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Publish Time</div>
                                    <div className={classes.twoelements}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['TimePicker']}>
                                                <TimePicker className="custom-datepicker"
                                                    required="Enter Publish Time"
                                                    renderInput={(params) => <TextField {...params} />} onChange={(e) => {
                                                        const date = e.$d;
                                                        const hours = date.getHours().toString().padStart(2, '0');
                                                        const minutes = date.getMinutes().toString().padStart(2, '0');
                                                        const formattedTime = `${hours}:${minutes}`;
                                                        handelSubmit('publishTime', formattedTime);
                                                        setErrors(prevErrors => ({ ...prevErrors, publishTime: false }));
                                                    }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.columnelements}>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Due Date</div>
                                    <div className={classes.twoelements}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                    className="custom-datepicker"
                                                    required
                                                    renderInput={(params) => <TextField {...params} />}
                                                    onChange={(e) => {
                                                        const options = { day: '2-digit', month: 'short', year: 'numeric' };
                                                        const formattedDate = e.$d.toLocaleDateString('en-GB', options);
                                                        handelSubmit('dueDate', formattedDate);
                                                        setErrors(prevErrors => ({ ...prevErrors, dueDateDate: false }));
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Due Time</div>
                                    <div className={classes.twoelements}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['TimePicker']}>
                                                <TimePicker className="custom-datepicker"
                                                    required="Enter Due time"
                                                    renderInput={(params) => <TextField {...params} />}
                                                    onChange={(e) => {
                                                        const date = e.$d;
                                                        const hours = date.getHours().toString().padStart(2, '0');
                                                        const minutes = date.getMinutes().toString().padStart(2, '0');
                                                        const formattedTime = `${hours}:${minutes}`;
                                                        handelSubmit('dueTime', formattedTime);
                                                        setErrors(prevErrors => ({ ...prevErrors, dueTime: false }));
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.columnelements}>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Auto Publish</div>
                                    <div className={classes.twoelements}>
                                        <div className={classes.timmings}>
                                            <input
                                                type="checkbox"
                                                value={submission}
                                                onChange={(e) => { handelSubmit('autoPublish', e.target.checked) }}
                                                style={{ marginTop: '0rem' }}
                                            />
                                            <label>Auto Publish</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.innerColumn}>
                                    <div className={classes.inputTitel}>Timmings</div>
                                    <div className={classes.twoelements}>
                                        <div className={classes.timmings} style={{ height: '60px' }} >
                                            <div>
                                                <div style={{ display: 'flex' }} >
                                                    <input
                                                        type="radio"
                                                        name="typeOfTest"
                                                        id="Timed"
                                                        value="yes"
                                                        className={classes.time}
                                                        style={{ marginTop: '0rem', marginRight: '0.3rem' }}
                                                        onChange={(e) => { handelSubmit('timmings', e.target.value) }}
                                                    />
                                                    <label For="Timed">Timed Test</label>
                                                    {formData.timmings === 'yes' && <div> <input
                                                        type="text"
                                                        value={hours}
                                                        onChange={(e) => onlyNumber(e, 'hours')}

                                                    />
                                                        <label >Hrs</label>
                                                        <input
                                                            type="text"
                                                            value={minutes}
                                                            onChange={(e) => onlyNumber(e, 'mins')}
                                                        />
                                                        <label style={{ marginRight: '0.2rem' }} >Mins</label> </div>}
                                                </div>
                                                <input
                                                    type="radio"
                                                    name="typeOfTest"
                                                    id="Timed"
                                                    value="no"
                                                    className={classes.time}
                                                    style={{ marginTop: '0rem', marginRight: '0.3rem' }}
                                                    onChange={(e) => { handelSubmit('timmings', e.target.value) }}
                                                />
                                                <label For="Timed">Untimed Test</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.inputTitel} ${classes.inst}`}>Instructions</div>
                    <div className={classes.editorText}>
                        <Editor
                            key={1}
                            height={"8rem"}
                            width={"100%"}
                            value={editorContent}
                            placeholder="Provide instructions for the test"
                            parentFunction={(content) => handleEditorChange(content)}
                        />
                    </div>
                    <div className={classes.btn}>
                        <div className={classes.yellow}>Cancel</div>
                        <button className={classes.cancel} type='submit'>Create</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default AddTest;