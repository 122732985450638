import React, { useState } from "react";
import classes from "./TestInstructions1.module.css";
import { Button, Form } from "react-bootstrap";
import useNetwork from "../../CustomHooks/useNetwork";
// import { useSelector } from "react-redux";

const TestInstructions = ({ onEnterFullscreen, testDetails, userState }) => {
  const params = window.location.search;
  const isDeveloper = new URLSearchParams(params).get("developer");
  console.log("isDeveloper", isDeveloper);
  // State to track whether the checkbox is checked or not
  // console.log(testDetails);
  const [isChecked, setIsChecked] = useState(false);

  // console.log("testDetails", testDetails);

  // using Network
  const networkState = useNetwork();
  const { online } = networkState;

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleStartTest = () => {
    if (isChecked) {
      onEnterFullscreen();
    } else {
      alert("Please read the instructions carefully before starting the test.");
    }
  };

  return (
    <div className={classes.outerContainer}>
      <div className={classes.horizontalLine}>
        <h3 style={{ color: "#fff", marginBottom: "0px" }}>
          {testDetails?.title}
        </h3>
        <p style={{ color: "#fff", marginBottom: "0px" }}>
          {userState.firstName + " " + userState.lastName}
        </p>
      </div>
      <div className={classes.innerContainer}>
        {/* <h3>{testDetails?.title}</h3> */}
        <h4>Test Instructions :</h4>
        <div>
          {testDetails?.description ? (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  "<p>" +
                  testDetails?.description?.replace(/\r\n/g, "<br>") +
                  "</p>",
              }}
            />
          ) : (
            <p>Loading details...</p>
          )}
        </div>
        <div className={classes.info}>
          <h5>Test Duration: {testDetails?.timelimit}</h5>
          <h5>Total No. of Questions : {testDetails?.all_questions} </h5>
        </div>
        <div
          style={{
            marginLeft: "50px",
            marginTop: "30px",
          }}
        >
          <h5>Total Marks : {testDetails?.total_points}</h5>
        </div>
        <Form className={classes.checkBoxForm}>
          <Form.Check
            type="checkbox"
            label="I have read the instructions carefully."
            checked={isChecked} // Set the checked prop based on state
            onChange={handleCheckboxChange} // Handle checkbox change
            style={{
              marginLeft: "20px",
              marginTop: "20px",
              fontSize: "16px",
            }}
          />
        </Form>
        {testDetails?.take_test === "1" ? (
          !online ? (
            <div className={classes.alertBox}>
              <p className={classes.alertText}>Connection Lost!</p>
              <p className={classes.alertSubText}>
                Please reconnect to start the test
              </p>
            </div>
          ) : (
            <Button
              variant="primary"
              className={classes.startTestButton}
              onClick={handleStartTest}
            >
              Start Test
            </Button>
          )
        ) : (
          <Button
            variant="primary"
            disabled={isDeveloper !== "true"}
            className={classes.startTestButton}
            onClick={isDeveloper === "true" ? handleStartTest : undefined}
          >
            Already Attempted
          </Button>
        )}
      </div>
      {/* </div> */}
    </div>
  );
};

export default TestInstructions;
