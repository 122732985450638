import React from "react";
import { createRoot } from "react-dom/client"; // Updated import
// import App from './App';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// import "bootstrap/dist/css/bootstrap.min.css";
import RootPage from "./RootPage";
import { Provider } from "react-redux";
import reduxStore from "./Store/Data/Store/ReduxStore";
import { AuthProvider } from './Context/AuthContext';
import { MathJaxContext } from "better-react-mathjax";

const container = document.getElementById("root");
const root = createRoot(container); // Updated code

const mathJaxConfig = {
  tex: {
    inlineMath: [['[math]', '[/math]']],
    displayMath: [],
  },
  options: {
    skipHtmlTags: ['script', 'noscript', 'style', 'textarea', 'pre'],
  },
};

root.render(
  <Provider store={reduxStore}>
    <MathJaxContext config={mathJaxConfig}>
      <AuthProvider>
        <RootPage />
      </AuthProvider>
    </MathJaxContext>
  </Provider>
);

reportWebVitals();
