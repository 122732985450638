// import React, { useState } from "react";
// import "./ChangePassword.css";
// import { changePassword } from "../../../Store/API/API";
// import { useSelector } from "react-redux";

// const ChangePassword = () => {
//   const userState = useSelector((state) => state.userData.userData);
//   const [error, setError] = useState("");

//   const [passwordData, setPasswordData] = useState({
//     current_password: "",
//     new_password: "",
//     confirm_password: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setPasswordData({
//       ...passwordData,
//       [name]: value,
//     });
//   };

//   const handleBlur = (e) => {
//     if (passwordData.new_password !== passwordData.confirm_password) {
//       setError("Passwords do not match");
//     } else {
//       setError("");
//     }
//   };
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // console.log(passwordData);
//     changePassword(userState.token, passwordData);
//   };

//   return (
//     <div className="flex h-[95vh] w-[100%] mt-5">
//       <div className="flex md:w-[100%] w-full flex-col px-10 py-10 h-full bg-gray-100 mb-10 ">
//         <div className="edit-profile">
//           <div className="form-row">
//             <div className="form-group">
//               <h2>Change Password</h2>
//             </div>
//           </div>
//           <form onSubmit={handleSubmit}>
//             <div className="form-row">
//               <div className="form-group">
//                 <label>Current Password</label>
//                 <input
//                   type="password"
//                   name="current_password"
//                   onChange={handleChange}
//                 />
//               </div>
//             </div>
//             <div className="form-row">
//               <div className="form-group">
//                 <label>New Password</label>
//                 <input
//                   type="password"
//                   name="new_password"
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                 />
//               </div>
//             </div>
//             <div className="form-row">
//               <div className="form-group">
//                 <label>Confirm New Password</label>
//                 <input
//                   type="password"
//                   name="confirm_password"
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                 />
//               </div>
//             </div>
//             {error && <p style={{ color: "red" }}>{error}</p>}
//             <div className="form-buttons">
//               <button type="button">Cancel</button>
//               <button type="submit">Change</button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChangePassword;





import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../../Store/API/API';
import { applicationActions } from '../../../Store/Data/Slices/ApplicationSlice';

const ChangePassword = () => {
  const userState = useSelector((state) => state.userData.userData);
    const dispatch = useDispatch();
    const [error, setError] = useState("");
  
    const [passwordData, setPasswordData] = useState({
      current_password: "",
      new_password: "",
      confirm_password: "",
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setPasswordData({
        ...passwordData,
        [name]: value,
      });
    };
  
    const handleBlur = (e) => {
      if (passwordData.new_password !== passwordData.confirm_password) {
        setError("Passwords do not match");
      } else {
        setError("");
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
    
      try {
        const data = await changePassword(userState.token, passwordData);
    
        if (data?.statuscode === "200") {
          dispatch(
            applicationActions.setAlertData({
              isAlert: true,
              alertMessage: "Password changed successfully!",
              alertType: "success",
            })
          );
        } else {
          dispatch(
            applicationActions.setAlertData({
              isAlert: true,
              alertMessage: data?.error || "Password change failed.",
              alertType: "error",
            })
          );
        }
      } catch (error) {
        dispatch(
          applicationActions.setAlertData({
            isAlert: true,
            alertMessage:
              error.message || "An error occurred while changing the password.",
            alertType: "error",
          })
        );
      } finally {
        setTimeout(() => {
          dispatch(
            applicationActions.setAlertData({
              isAlert: false,
              alertMessage: "",
              alertType: "",
            })
          );
        }, 3000);
      }
    };
    
  
  return (
    <div className="w-full min-h-screen mx-auto p-4 bg-textWhite shadow-lg rounded-lg">
    <h2 className="text-[20px] md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl font-bold mt-14">
      Change Password
    </h2>
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1  gap-3 md:gap-4 ">
        <div>
          <label className="block font-semibold">Current Password</label>
          <input
            type="password"
            name="current_password"
            onChange={handleChange}
            className="w-full md:w-[50%] p-2 border border-textGray rounded"
            placeholder="Current Password"
          />
        </div>
        <div>
          <label className="block font-semibold">New Password</label>
          <input
            type="password"
            name="new_password"
            onChange={handleChange}
            className="w-full  md:w-[50%]  p-2 border border-textGray rounded"
            placeholder="New Password"
            onBlur={handleBlur}
          />
        </div>
        <div>
          <label className="block font-semibold">Confirm New Password</label>
          <input
            type="password"
            name="confirm_password"
            onChange={handleChange}
           onBlur={handleBlur}
            className="w-full md:w-[50%] p-2 border border-textGray rounded"
            placeholder="Confirm New Password"
          />
        </div>
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div className="w-full flex justify-center items-center md:justify-start md:items-start text-center gap-4 py-3">
        {/* <button className="bg-primaryBlue text-textWhite px-6 py-2 rounded-lg hover:bg-primaryBlueDisabled">
          Cancel
        </button> */}
        <button type="submit" className="bg-primaryBlue text-textWhite px-6 py-2 rounded-lg hover:bg-primaryBlueDisabled">
          Save Changes
        </button>
      </div>
    </form>
  </div>
  )
}

export default ChangePassword