import React, { useEffect, useState } from "react";
import { Tooltip } from "react-bootstrap";
import classes from "./DPPSubmissionView.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import TableCardButtons from "./TableCardButtons";
import SelectInput from "../../UI/SelectInput/SelectInput";
import HeaderComponent from "../Navbar/Header/HeaderComponent";
import { useDispatch, useSelector } from "react-redux";
import { getAllInstitute, getDPPSubmission } from "../../../Store/API/API";
import Spinner from "../../UserInterface/Spinner/Spinner";

const SelectInputField = ({
  placeholder,
  handleInputChange,
  value,
  options,
}) => {
  return (
    <div>
      <SelectInput
        width={"100%"}
        handleInputChange={handleInputChange}
        value={value}
        placeHolder={placeholder}
        backgroundColor={"#1b3156"}
        border={"none"}
        dropdownTextColor={"#fff"}
        options={options}
      />
    </div>
  );
};

const ActiveTab = ({ heading, active, setActive, totalLength, newLength }) => {
  return (
    <div className="cursor-pointer" onClick={() => setActive(heading)}>
      <p className="text-white font-medium">{`Total ${heading} : ${
        totalLength || "0"
      }`}</p>
      <div
        className={`border rounded-md h-9 ${
          active ? "border-backgroundGrey" : "border-gray-600 "
        } w-full flex items-center justify-center`}
      >
        <p
          className={`${active ? "text-white" : "text-gray-600 "} font-medium`}
        >
          {heading}
        </p>
        {heading === "Submitted" && active && (
          <p className="bg-primaryOrange text-white ml-2 text-sm rounded-sm px-2 ">{`New: ${
            newLength || "0"
          }`}</p>
        )}
      </div>
    </div>
  );
};

const DPPSubmissionView = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const location = useLocation();
  const { quiz_id, course_id } = location?.state || "";
  const [institutesOption, setInstitutesOption] = useState([]);
  const [institute, setInstitute] = useState({
    value: "4",
    label: "Innovartan",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("Submitted");
  const [apiData, setApiData] = useState([]);
  const [viewSubmitted, setViewSubmitted] = useState([]);
  const [viewNotSubmitted, setViewNotSubmitted] = useState([]);
  const [newCounts, setNewCounts] = useState(0);

  const fetchInstitutes = async () => {
    const data = await getAllInstitute(dispatch);
    // console.log(data);

    if (data) {
      setInstitutesOption(data);
    }
  };

  const newCount = (data) => {
    let count = 0;
    let currentDate = new Date();

    let formattedDate = currentDate
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(",", "")
      .trim();

    // console.log(formattedDate, "Formatted Date");
    // console.log(data, "Data");

    data?.forEach((item) => {
      let submittedDate = item?.submitted_date?.split("|")[0]?.trim(); // Handle null values safely
      //   console.log(submittedDate, "Split Date");

      if (submittedDate === formattedDate) {
        count++;
      }
    });

    setNewCounts(count);
  };

  const filterData = (data, selectedInstitute) => {
    // console.log(data?.data);

    let submittedDetails = data?.data?.submitted_details.filter(
      (item) => item.institute_id === selectedInstitute.value
    );

    let notSubmittedDetails = data?.data?.not_submitted.filter(
      (item) => item.institute_id === selectedInstitute.value
    );

    // console.log(submittedDetails);

    // console.log(notSubmittedDetails);

    setViewSubmitted(submittedDetails);
    newCount(submittedDetails);
    setViewNotSubmitted(notSubmittedDetails);
  };

  const fetchDPPSubmission = async () => {
    const apiData = { quiz_id, course_id };

    const data = await getDPPSubmission(apiData, userState?.token, dispatch);
    // console.log(data.data);
    if (data?.data) {
      setApiData(data?.data);
      filterData(data, institute);
    }

    setIsLoading(false);
  };

  const handleInputChange = async (field, selectedValue) => {
    // console.log(selectedValue);
    if (field === "institute") {
      setInstitute(selectedValue);
      setIsLoading(true);
      filterData(apiData, selectedValue);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!quiz_id && !course_id) {
      navigate("/");
      return;
    }

    if (userState.userType === "institute") {
      fetchInstitutes();
    }

    fetchDPPSubmission();
    // eslint-disable-next-line
  }, [institute]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="p-6 mt-16 mb-3">
      <p className="text-[20px] md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl font-bold text-left m-0 mb-2">
        DPP Submission's
      </p>
      <HeaderComponent>
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 items-end ">
          <div className="lg:col-start-1 xl:col-start-2">
            <p className="text-white font-medium">Institute</p>
            <SelectInputField
              value={institute}
              placeholder={"Select Institute"}
              handleInputChange={(field, value) =>
                handleInputChange("institute", value)
              }
              options={institutesOption}
            ></SelectInputField>
          </div>
          <div>
            <ActiveTab
              heading={"Submitted"}
              active={activeTab === "Submitted"}
              setActive={setActiveTab}
              totalLength={viewSubmitted?.length}
              newLength={newCounts}
            />
          </div>
          <div>
            <ActiveTab
              heading={"Not Submitted"}
              active={activeTab === "Not Submitted"}
              setActive={setActiveTab}
              totalLength={viewNotSubmitted?.length}
            />
          </div>
        </div>
      </HeaderComponent>

      <div
        className="overflow-y-auto max-h-[65vh] hidden sm:block"
        style={{
          paddingBottom: "20px",
          marginTop: "15px",
        }}
      >
        <table className="w-full border-separate border-spacing-y-3">
          <thead className="sticky top-0 bg-backgroundBlue">
            <tr>
              <th className="py-3 text-center rounded-l-xl w-[10%]">Name</th>
              <th className="py-3 text-center  w-[20%]">
                Submission Date - Time
              </th>
              <th className="py-3 text-center w-[10%]">Marks</th>
              <th className="py-3 text-center w-[10%]">Percentage</th>
              <th className="py-3 text-center w-[10%]">Rank</th>
              {activeTab === "Submitted" && (
                <th className="py-3 text-center rounded-r-xl  w-[10%]">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody className="overflow-y-auto">
            {(activeTab === "Submitted" ? viewSubmitted : viewNotSubmitted)
              ?.length === 0 && (
              <tr>
                <td
                  colSpan={activeTab === "Submitted" ? 6 : 5}   
                  className="py-2 px-6 text-center text-primaryOrange font-bold"
                >
                  No Submission Found
                </td>
              </tr>
            )}

            {(activeTab === "Submitted"
              ? viewSubmitted
              : viewNotSubmitted
            )?.map((test, index) => (
              <tr
                className="border-b border-gray-200 hover:bg-gray-100"
                key={index}
              >
                <td className="p-3 text-center border-t border-b border-backgroundBlue">
                  {test.student_name || "-"}
                </td>
                <td className="p-3 text-center border-t border-b border-backgroundBlue">
                  <div>{test?.submitted_date || "-"}</div>
                </td>

                <td className="p-3 text-center border-t border-b border-backgroundBlue">
                  <p
                    className={classes.para}
                    style={{ margin: 0, cursor: "pointer" }}
                  >
                    {`${test?.awarded_marks || "-"}/${
                      test?.total_marks || "-"
                    }`}
                  </p>
                </td>
                <td className="p-3 text-center border-t border-b border-backgroundBlue">
                  <p
                    className={classes.para}
                    style={{ margin: 0, cursor: "pointer" }}
                  >
                    {`${test?.percentage || "-"}%`}
                  </p>
                </td>
                <td className="p-3 text-center border-t border-b border-backgroundBlue">
                  <p
                    className={classes.para}
                    style={{ margin: 0, cursor: "pointer" }}
                  >
                    {test?.rank || "NA"}
                  </p>
                </td>
                {activeTab === "Submitted" && (
                  <td className="p-3 text-center border-t border-b border-backgroundBlue">
                    <TableCardButtons
                      quizId={test.quiz_id}
                      groupId={test.group_id}
                      attemptId={test?.attempt_id}
                      studentId={test.student_id}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.cardview}>
        {/* <CardView
          data={testList}
          renderContent={testData}
          noDataMessage={"No Tests Available"}
        /> */}
      </div>
    </div>
  );
};

export default DPPSubmissionView;
