import React, { useState } from "react";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString();

const ReadPDF = () => {
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  // Define the extractText and onFileChange functions here
  const extractText = async (pdf) => {
    let extractedText = "";
    const numPages = pdf.numPages;

    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      textContent.items.forEach((textItem) => {
        extractedText += textItem.str + " ";
      });
    }

    return extractedText;
  };

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("No file uploaded");
      return;
    }

    if (file.type !== "application/pdf") {
      setError("Not a PDF file");
      return;
    }

    setError("");

    const reader = new FileReader();
    reader.onload = async (event) => {
      const typedArray = new Uint8Array(event.target.result);
      const pdf = await pdfjs.getDocument({ data: typedArray }).promise;
      const extractedText = await extractText(pdf);
      setText(extractedText);

      console.log("extractedText", extractedText);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <input type="file" accept=".pdf" onChange={onFileChange} />
      {error && <div styles={{ color: "red" }}>{error}</div>}
      {text && (
        <div>
          <h2>Text extracted from PDF:</h2>
          <p>{text}</p>
        </div>
      )}
    </div>
  );
};
export default ReadPDF;
