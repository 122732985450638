import React from "react";
import styles from "./QuestionTopTag.module.css";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const QuestionTopTag = ({
  questionId,
  questionMarks,
  location,
  questionBankId,
  dropDownValues,
  currentPage,
  handleButtonClick,
}) => {
  // console.log("question edit screen ", questionId, questionMarks, location);

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.LeftContainer}>
        <div className={styles.leftText}>ID: {questionId}</div>{" "}
        {/* Use curly braces */}
        <div className={styles.separator}></div>
        <div className={styles.leftText}>Marks: {questionMarks}</div>
      </div>
      <div className={styles.rightContainer}>
        
        {/* {location !== "QB" && location !== "QL" && location !== "TAG" && (
          <Tooltip title="Edit Marks" arrow>
            <div className={styles.editMarksContainer}>
              <p className={styles.leftText}>Marks :</p>
              <input
                type="text"
                className={styles.marksInputField}
                defaultValue={"4"}
              />
            </div>
          </Tooltip>
        )} */}
        {/* {location !== "QB" && location !== "QL" && location !== "TAG" && (
          <Tooltip title="Report" arrow>
            <div className={styles.reportButton}>
              <ReportGmailerrorredIcon />
            </div>
          </Tooltip>
        )} */}
        {(location === "QB" || location === "QL") && (
          <Tooltip title="Edit" arrow>
            <div
              className={styles.editButton}
              onClick={() => {
                navigate("/question/edit-tag", { state: { questionId, questionBankId , location,dropDownValues,
                  currentPage,} });
              }}
            >
              <EditIcon />
            </div>
          </Tooltip>
        )}
        {/* {location !== "QB" && location !== "TAG" && (
          <Tooltip title="Delete" arrow>
            <div
              className={styles.deleteButton}
              onClick={() => {
                handleButtonClick("delete", questionId);
              }}
            >
              <DeleteOutlineIcon />
            </div>
          </Tooltip>
        )} */}
        {/* {location === "QB" && <div className={styles.saveButton}>Save</div>} */}
      </div>
    </div>
  );
};

export default QuestionTopTag;
