
import React, { useEffect, useState } from "react";
import Navbar2 from "./Navbar2";
import Sidebar2 from "./Sidebar2";
import { Footer2 } from "../Footer/Footer2";
import CustomModal from "../../UI/CustomModal/CustomModal";
import UpdateSessionStatusModal from "./UpdateSessionStatusModal";
import { useDispatch, useSelector } from "react-redux";
import { getPendingSessions, getSessionDetails, updatePendingCurricular } from "../../../Store/API/API";

const MiniDrawerNew = ({ children }) => {

  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const [updateSessionStatusModal, setUpdateSessionStatusModal] = useState(false);
  const [currentSessionIndex, setCurrentSessionIndex] = useState(0);
  const [sessionData, setSessionData] = useState([]);
  const [sessionDetail, setSessionDetail] = useState([]);


  const fetchSessionDetails = async (data, index) => {
    // console.log("Fetching session details...");

    // console.log(data[index]?.value, "sessionData[currentSessionIndex]?.sessionId");


    const tempData = await getSessionDetails(data[index]?.value, dispatch);

    if (tempData?.data?.session_id) {
      setSessionDetail(tempData?.data);
      setTimeout(() => setUpdateSessionStatusModal(true), 100);
    } else {
      const sessionData = {
        user_id: userState?.userId,
        session_id: data[index]?.value,
        curricular_percent: "0",
        live_video: "0",
        live_dpp: "0"
      };
      const apidata = await updatePendingCurricular(sessionData);

      if (apidata?.message === "Curricular Updated") {
        console.log("Curricular Updated");
        handleModalSubmit();
      }
    }
    // console.log(tempData);

  }

  const startSessionProcess = (data) => {
    setCurrentSessionIndex(0);
    fetchSessionDetails(data, 0);
  };

  const handleModalSubmit = () => {
    setUpdateSessionStatusModal(false);

    // Wait 2 seconds before opening next modal
    setTimeout(() => {
      if (currentSessionIndex + 1 < sessionData.length) {
        setCurrentSessionIndex((prev) => prev + 1);
        fetchSessionDetails(sessionData, currentSessionIndex + 1);
      }
    }, 2000);
  };


  const fetchPendingSessions = async () => {
    // console.log("Fetching pending sessions...");
    const data = await getPendingSessions(userState?.userId, userState?.token, dispatch);
    if (data?.data?.length > 0) {
      // console.log(data?.data);
      // console.log(data?.data?.length);
      setSessionData(data?.data);
      startSessionProcess(data?.data)
    }
  }

  useEffect(() => {
    if (userState.userType === "teacher") {
      fetchPendingSessions();
    }

    // eslint-disable-next-line
  }, [userState])

  return (
    <div className="flex relative">
      <Sidebar2 isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="transition-all duration-300 w-full">
        <Navbar2 isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className={`${isSidebarOpen ? "ml-64" : "md:ml-24"}`}>{children}</div>
        <Footer2 isSidebarOpen={isSidebarOpen} />
      </div>
      <CustomModal
        isOpen={updateSessionStatusModal}
      // onClose={() => setOpenAddTopicModal(!openAddTopicModal)}
      >
        <UpdateSessionStatusModal
          currentSessionIndex={currentSessionIndex}
          handleModalSubmit={handleModalSubmit}
          sessionDetail={sessionDetail}
          showEditButton={true}
        // onClose={() => setOpenAddTopicModal(!openAddTopicModal)}
        />
      </CustomModal>
    </div>
  );
};

export default MiniDrawerNew;