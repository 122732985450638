import React, { useState } from "react";
import styles from "./CommonSession.module.css";
import CommonSessionCard from "./CommonSessionCard";
import CreateModal from "./CreateModal";
import Filter from "../../UI/Filter/Filter";
import { type } from "@testing-library/user-event/dist/cjs/utility/type.js";
import CustomModal from "../../UI/CustomModal/CustomModal";

const Upcomming2 = () => {
  const SessionButton = ({ name, isActive }) => {
    return (
      <div
        className={
          isActive ? styles.upcommingButtonActive : styles.upcommingButton
        }
      >
        <p
          className={
            isActive ? styles.sessionbuttonTextActive : styles.sessionbuttonText
          }
        >
          {name}
        </p>
      </div>
    );
  };

  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(true);

  const filterOptions = [
    {
      type: "select",
      placeholder: "Select Subject",
      options: [
        { value: "Physics", label: "Physics" },
        { value: "Chemistry", label: "Chemistry" },
        { value: "Maths", label: "Maths" },
        { value: "Biology", label: "Biology" },
      ],
      onChange: setSelectedSubject,
      selectedValue: selectedSubject,
    },
    {
      type: "select",
      placeholder: "Select Class",
      options: [
        { value: "Class 1", label: "Class 1" },
        { value: "Class 2", label: "Class 2" },
        { value: "Class 3", label: "Class 3" },
        { value: "Class 4", label: "Class 4" },
      ],
      onChange: setSelectedClass,
      selectedValue: selectedClass,
    },
  ];

  const handleApplyFilter = () => {
    console.log("Selected Subject: ", selectedSubject);
    console.log("Selected Class: ", selectedClass);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <div className={styles.headingButtonContainer}>
          <p className={styles.heading}>Live Sessions</p>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <SessionButton name="Upcoming" isActive={false} />
            <SessionButton name="History" isActive={true} />
            <SessionButton name="Cancelled" isActive={false} />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <div
            className={styles.createSession}
            onClick={() => setOpenCreateModal(true)}
          >
            <p className={styles.createSessionText}>Create Session</p>
          </div>
          <div className={styles.webFilter}>
            <Filter
              options={filterOptions}
              parentFunction={handleApplyFilter}
              filterPosition={"left"}
            />
          </div>
          <div className={styles.mobileFilter}>
            <Filter
              options={filterOptions}
              parentFunction={handleApplyFilter}
              filterPosition={"center"}
            />
          </div>
        </div>
      </div>
      <div className={styles.cardsContainer}>
        <CommonSessionCard />
        <CommonSessionCard />
        <CommonSessionCard />
        <CommonSessionCard />
        <CommonSessionCard />
      </div>
      <CustomModal
        isOpen={openCreateModal}
        onClose={() => setOpenCreateModal(!openCreateModal)}
      >
        <CreateModal />
      </CustomModal>
    </div>
  );
};

export default Upcomming2;
