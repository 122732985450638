import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import TabButtons from "./TabButtons";
import { useNavigate } from "react-router-dom";

// Data for Pie Chart
const pieData = [
  { name: "All", value: 31, corrected: 20, incorrect: 40 },
  { name: "Easy", value: 30.7, corrected: 8, incorrect: 12 },
  { name: "Medium", value: 20, corrected: 5, incorrect: 10 },
  { name: "Hard", value: 15, corrected: 6, incorrect: 15 },
  { name: "Pro", value: 10, corrected: 2, incorrect: 16 },
  { name: "Extreme", value: 5, corrected: 1, incorrect: 20 },
];

// Pie Chart Colors
const COLORS = [
  "#8979FF",
  "#FF928A",
  "#3CC3DF",
  "#FFAE4C",
  "#537FF1",
  "#6FD195",
];

// Data for Bar Chart
const barData = [
  { question: 1, time: 0, question_status: "correct" },
  { question: 2, time: 3, question_status: "correct" },
  { question: 3, time: 1.5, question_status: "correct" },
  { question: 5, time: 1.5, question_status: "incorrect" },
  { question: 6, time: 0.5, question_status: "correct" },
  { question: 7, time: 2.5, question_status: "incorrect" },
  { question: 8, time: 2, question_status: "correct" },
  { question: 10, time: 1.8, question_status: "incorrect" },
  { question: 12, time: 4, question_status: "correct" },
  { question: 16, time: 0.8, question_status: "correct" },
  { question: 17, time: 2.5, question_status: "incorrect" },
  { question: 18, time: 1.2, question_status: "correct" },
  { question: 19, time: 0, question_status: "correct" },
  { question: 20, time: 0, question_status: "correct" },
];

const AttemptAnalysis = ({
  pieChartActiveSession,
  setPieChartActiveSession,
  analyticsData,
  quizId,
  attemptStudentId,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const navigate = useNavigate();

  // console.log("analyticsData", analyticsData);

  const CustomTooltip = ({ active, payload, parentSource }) => {
    // console.log(pieChartActiveSession, "pieChartActiveSession");

    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 rounded shadow-md border border-gray-300 text-black">
          {parentSource === "pieChart" ? (
            <div>
              <p className="text-black text-xs">{`Percentage Accuracy: ${payload[0].payload?.accuracy}%`}</p>
              {pieChartActiveSession !== "Chart" && (
                <p className="text-black text-xs">{`Correct: ${payload[0].payload?.correctAnswers}`}</p>
              )}
              {pieChartActiveSession !== "Chart" && (
                <p className="text-black text-xs">{`Average Time: ${payload[0].payload?.averageTime}`}</p>
              )}
            </div>
          ) : (
            <div>
              <p className="text-black">{`Question: ${payload[0].payload?.question_no}`}</p>
              <p className="text-black">{`Time: ${payload[0].value}`}</p>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="text-white p-6 rounded-xl flex flex-col lg:flex-row gap-10 lg:gap-0">
      {/* Pie Chart Section */}
      <div className="lg:w-1/3 w-full self-center">
        <h2 className="text-3xl font-bold text-white">Attempt Analysis</h2>
        {/* Tabs Section */}
        <div className="flex gap-4 mt-4">
          <TabButtons
            title="Difficulty"
            activeTab={pieChartActiveSession}
            onClick={setPieChartActiveSession}
          />
          <TabButtons
            title="Type"
            activeTab={pieChartActiveSession}
            onClick={setPieChartActiveSession}
          />
          <TabButtons
            title="Chart"
            activeTab={pieChartActiveSession}
            onClick={setPieChartActiveSession}
          />
        </div>

        <div className="flex mt-6 gap-0 justify-center lg:justify-normal">
          {/* Pie Chart Section */}
          <div className="flex flex-col items-center relative">
            <PieChart
              width={215}
              height={215}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <Pie
                data={
                  pieChartActiveSession === "Difficulty"
                    ? analyticsData?.dificulty_analysis
                    : pieChartActiveSession === "Type"
                    ? analyticsData?.question_type_analysis
                    : analyticsData?.typeWiseAttempt
                }
                cx="50%"
                cy="50%"
                endAngle={450}
                startAngle={90}
                innerRadius={80}
                outerRadius={105}
                dataKey="accuracy"
              >
                {pieData.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip parentSource={"pieChart"} />} />
            </PieChart>
            {!isHovering && (
              <div className="flex flex-col items-center absolute top-[71px] w-36 -z-1">
                <p className="text-lg font-bold">
                  {(pieChartActiveSession === "Difficulty"
                    ? analyticsData?.dificulty_analysis
                    : pieChartActiveSession === "Type"
                    ? analyticsData?.question_type_analysis
                    : analyticsData?.typeWiseAttempt)?.[0]?.accuracy ?? "N/A"}
                  %
                </p>

                <p className="text-sm text-center">
                  {" "}
                  {pieChartActiveSession === "Chart"
                    ? "Correct Answers"
                    : "All Questions Attempted"}{" "}
                </p>
              </div>
            )}
          </div>

          {/* Legend Section */}
          <div className="flex flex-col gap-2 mt-2 ml-10">
            {(pieChartActiveSession === "Difficulty"
              ? analyticsData?.dificulty_analysis
              : pieChartActiveSession === "Type"
              ? analyticsData?.question_type_analysis
              : analyticsData?.typeWiseAttempt
            )?.map((item, index) => (
              <div key={index} className="flex items-center gap-2">
                <div
                  className="w-3 h-3 rounded-full border-2 border-white "
                  style={{ backgroundColor: COLORS[index] }}
                ></div>
                <p className="text-base">{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Bar Chart Section */}
      <div className="lg:w-2/3 w-full">
        <div className="flex justify-between items-center mb-10 lg:ml-10">
          <h2 className="text-3xl font-bold text-white">Time Per Question</h2>
          <button
            className="text-primaryOrange px-4 py-2 rounded-lg bg-[#FEF0E9] border-1 border-primaryOrange font-semibold hover:bg-gray-200 transition"
            onClick={() => {
              navigate("/ViewTestSubmission", {
                state: { quizId, attemptUserId: attemptStudentId },
              });
            }}
          >
            View my Answers
          </button>
        </div>
        <div className="-ml-10 lg:ml-0 ">
          <ResponsiveContainer width="100%" height={250}>
            <BarChart data={analyticsData?.barData}>
              <XAxis dataKey="question_no" />
              <YAxis />
              <Tooltip
                content={<CustomTooltip parentSource={"barChart"} />}
                cursor={{ fill: "transparent" }}
              />
              <Bar dataKey="attempt_time">
                {analyticsData?.barData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      entry?.question_status === "correct"
                        ? "#6FD195"
                        : "#FF928A"
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default AttemptAnalysis;
