import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PreviewModal from "../Modals/PreviewModal";

const modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    ["link"],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
        ],
      },
    ],
  ],
};

const formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

const Editor = ({ height, width, placeholder, parentFunction, value }) => {
  // const [value, setValue] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  // const handleEditorChange = (content) => {
  //   setValue(content);
  // };

  const handleCloseModal = () => {
    setIsPreview(false);
  };

  return (
    <div>
      <ReactQuill
        style={{ height, width, marginBottom: "2.66rem" }}
        theme="snow"
        value={value}
        onChange={(content) => {
          parentFunction(content);
        }}
        className="code-content"
        modules={modules}
        formats={formats}
        placeholder={placeholder}
      />
      <button
        onClick={() => setIsPreview(!isPreview)}
        style={{
          padding: "5.9px 5px",
          backgroundColor: "#000F8F",
          color: "#fff",
          border: "none",
          borderRadius: "2px",
          fontSize: "16px",
          // cursor: "pointer",
          transition: "background-color 0.3s ease",
          width: "100%",
          cursor: value ? "pointer" : "not-allowed",
          maxWidth: "150px",
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#003F5F")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#000F8F")}
        disabled={!value}
      >
        Preview Text
      </button>

      {isPreview && <PreviewModal value={value} onClose={handleCloseModal} />}
    </div>
  );
};

export default Editor;
