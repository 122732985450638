import React from "react";
import styles from "./Integer.module.css";
import Tag from "../Tag/Tag";

const Integer = ({ correctOption, question, questionOrder }) => {
  return (
    <div className={styles.mcqContainer}>
      <div className={styles.headerSectionContainer}>
        <div className={styles.mcqQuestion}>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              className={styles.questionInputBox}
              value={questionOrder}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: question || " ",
              }}
            ></div>
          </div>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.infoSubContainer}>
            <Tag
              tagName="ID"
              tagValue="90"
              tagColor="#FF933063"
              tagButtonColor={"#FFE4CC"}
            />
            <Tag
              tagName="Marks"
              tagValue="5"
              tagColor="#ffcccc"
              tagButtonColor={"#ff9191"}
            />
          </div>
          <div className={styles.infoSubContainer}>
            <Tag
              tagName="Actions"
              tagValue="Easy"
              tagColor="#cce5ff"
              tagButtonColor={"#91caff"}
            />
          </div>
        </div>
      </div>
      <div className={styles.mcqOptions}>
        <div
          className={styles.mcqOptionContainer}
          style={{ marginLeft: "30px" }}
        >
          <div
            className={
              correctOption === "True"
                ? styles.mcqOptionCorrect
                : styles.mcqOption
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `Answer: ${correctOption?.split(",")[1] || ""}`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integer;
