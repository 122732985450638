// import React, { useCallback, useState, useEffect } from "react";
import React, { useEffect, useState } from "react";
// import classes from "./Components/TestScreen.module.css";
// import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import TestScreen from "./Components/TestScreen" ;  removed
import TestScreen1 from "./Components/Pages/Test/TestScreen1";
// import TestInstructions from "./Components/TestInstructions";  removed
import TestInstructions from "./Components/Pages/Test/TestInstructions1";
// import { getQuizDetails, getQuizQuestion } from "./Store/API/API";    removed
import { getQuizAttempt, getQuizDetails, submitQuiz } from "./Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "./Components/UserInterface/Spinner/Spinner";
import { updateMendatory } from "./Store/Data/Actions";
import { answerActions } from "./Store/Data/Slices/AnswerSlice";
import { questionActions } from "./Store/Data/Slices/QuestionSlice";
import TestSubmitted from "./Components/Pages/Test/TestSubmitted";
import { useLocation, useNavigate } from "react-router-dom";
import useNetwork from "./Components/CustomHooks/useNetwork";
// import { useLocation } from "react-router-dom";
//Testing//
function App() {
  const location = useLocation();
  const { quizId } = location?.state || {};
  // console.log(quizId, "quizId in app");
  const [startTest, setStartTest] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [questionData, setQuestionData] = useState([]);
  const [testDetails, setTestDetails] = useState({});
  // console.log(testDetails, "testDetails");
  const [isLoading, setIsLoading] = useState(true);
  const [testSubmitted, setTestSubmitted] = useState(false);
  const userState = useSelector((state) => state.userData.userData);
  const [onlineStatus, setOnlineStatus] = useState(false);
  // eslint-disable-next-line
  const handleRefreshCase = async () => {
    const userState = localStorage.getItem("userState");
    const userStateData = JSON.parse(userState);
    const userAttemptId = localStorage.getItem("userAttemptId");
    const answerState = localStorage.getItem("answerState");
    const answerStateData = JSON.parse(answerState);
    const questionMendatory = localStorage.getItem("questionMendatory");
    const questionMendatoryData = JSON.parse(questionMendatory);
    const data = await submitQuiz(
      userStateData,
      userAttemptId,
      answerStateData,
      questionMendatoryData,
      quizId,
      dispatch
    );
    console.log(data, "data refreshcase");

    if (data === "200") {
      localStorage.removeItem("userAttemptId");
      localStorage.removeItem("answerState");
      localStorage.removeItem("questionMendatory");
      localStorage.removeItem("userState");
      // setTestSubmitted(true);
      // setIsLoading(false);
      fetchTestDetails();
    }

    if (data === 400) {
      localStorage.removeItem("userAttemptId");
      localStorage.removeItem("answerState");
      localStorage.removeItem("questionMendatory");
      localStorage.removeItem("userState");
      navigate("/");
    }
  };

  // console.log(userState, "userState");
  const handleStartTest = async () => {
    setIsLoading(true);
    const data = await getQuizAttempt(
      userState,
      quizId || userState.testId,
      dispatch
    );
    // if (localStorage.getItem("userAttemptId")) {
    //   // eslint-disable-next-line
    //   if (data?.quiz_attempt_id == localStorage.getItem("userAttemptId")) {
    //     handleRefreshCase();
    //   }
    // } else {
    // console.log(data?.quiz_attempt_id, "attempt_id");
    dispatch(answerActions.setAttemptId(data?.quiz_attempt_id));
    setStartTest(true);
    setIsLoading(false);
    // }
  };

  const handleTestSubmit = () => {
    setTestSubmitted(true);
  };

  // const fetchTestQuestions = async () => {
  //   const data = await getQuizQuestion(userState.token, testDetails.quiz_id);
  //   setQuestionData(data);
  //   if (data.length > 0) {
  //     setIsLoading(false);
  //   }
  // };

  const fetchTestDetails = async () => {
    const data = await getQuizDetails(
      userState.token,
      // userState.testId,
      quizId || userState.testId,
      userState.userId,
      dispatch
    );
    setTestDetails(data);
    dispatch(questionActions.setTestInfo(data));
    if (data?.mendatory_details) {
      dispatch(updateMendatory(data.mendatory_details));
      // console.log(data.mendatory_details, "data");
    }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   if (testDetails) {
  //     // setIsLoading(true);
  //     fetchTestQuestions();
  //   }
  //   // eslint-disable-next-line
  // }, [testDetails]);

  useEffect(() => {
    if (userState.token) {
      // setIsLoading(true);
      // fetchTestDetails();
      console.log(localStorage.getItem("userAttemptId"), "userAttemptId");
      console.log(
        typeof localStorage.getItem("userAttemptId"),
        "typeof userAttemptId"
      );

      if (localStorage.getItem("userAttemptId")) {
        console.log("Pending test to Submite");
        handleRefreshCase();
      } else {
        // console.log("No pending test to submite");
        fetchTestDetails();
      }
    }

    // eslint-disable-next-line
  }, [userState.token]);

  // const [isLaptop, setIsLaptop] = useState(false);
  // const [isTabletOrMobile, setIsTabletOrMobile] = useState(false);

  // useEffect(() => {
  //   const userAgent = navigator.userAgent.toLowerCase();
  //   setIsLaptop(
  //     userAgent.indexOf("macintosh") !== -1 ||
  //       userAgent.indexOf("windows") !== -1
  //   );
  //   setIsTabletOrMobile(
  //     /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
  //       navigator.userAgent
  //     )
  //   );
  // }, []);

  // const handle = useFullScreenHandle();
  // const [isFullscreen, setIsFullscreen] = useState(false);

  // const onExitFullscreen = () => {
  //   setIsFullscreen(false);
  // };

  // const onEnterFullscreen = () => {
  //   handle.enter();
  // };

  // const reportChange = useCallback((state, handle) => {
  //   if (state === true) {
  //     setIsFullscreen(true);
  //     console.log("Entered fullscreen");
  //   }
  //   if (state === false) {
  //     onExitFullscreen();
  //   }
  // }, []);

  const networkState = useNetwork();
  const { online } = networkState;

  useEffect(() => {
    console.log("Use effect running for online status");
    console.log("Onlincnjfnje", online);
    

    if (online) {
      setOnlineStatus(true);
    }
  }, [online]);

  return (
    <div>
      {isLoading && <Spinner />}
      {/* {!isTabletOrMobile && isLaptop && (
        <TestInstructions onEnterFullscreen={onEnterFullscreen} />
      )}
      <FullScreen handle={handle} onChange={reportChange}>
        <div
          style={{
            padding: 0,
            height: "100%",
            display: isTabletOrMobile
              ? "block"
              : isFullscreen
              ? "block"
              : "none",
            backgroundColor: "#fff",
            flex: 1,
            overflowY: "scroll",
          }}
        > */}
      {!testSubmitted && !isLoading && !startTest && (
        <TestInstructions
          onEnterFullscreen={handleStartTest}
          testDetails={testDetails}
          userState={userState}
        />
      )}
      {!testSubmitted && !isLoading && startTest && (
        // <TestScreen data={questionData} testDetails={testDetails} />
        <TestScreen1
          testId={testDetails?.quiz_id}
          testSubjects={testDetails?.test_subjects}
          handleTestSubmit={handleTestSubmit}
          quizId={quizId || userState?.testId}
          testDetails={testDetails}
          onlineStatus={onlineStatus}
        />
      )}
      {testSubmitted && (
        <TestSubmitted
          testDetails={testDetails}
          quizId={quizId || userState?.testId}
        />
      )}
      {/* </div>
      </FullScreen> */}
    </div>
  );
}
export default App;
