import React, { useEffect, useRef, useState } from "react";
import styles from "./QuestionPreview.module.css";
import { getTestQuestions } from "../../../Store/API/API";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";

const isBase64 = (str) => {
  return str.startsWith("data:image/");
};

// Function to convert image URL to Base64
const urlToBase64 = async (url) => {
  if (isBase64(url)) {
    // If the URL is already Base64, return it as is
    console.log("Already Base64:", url);
    return url;
  }

  const res = await fetch(url);
  const blob = await res.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

// Function to process input and convert image URLs to Base64
const convertImageUrlsToBase64 = async (data) => {
  const newData = JSON.parse(JSON.stringify(data)); // Create a deep copy of the data

  // Helper function to process question and answers for image URLs
  const processItem = async (item) => {
    if (item.question) {
      // Convert the image URLs in the question
      const regex = /<img.*?src="(.*?)".*?>/g;
      const matches = [...item.question.matchAll(regex)];
      for (let match of matches) {
        const imageUrl = match[1];
        const base64Image = await urlToBase64(imageUrl);
        item.question = item.question.replace(imageUrl, base64Image);
      }
    }

    if (item.answer && Array.isArray(item.answer)) {
      for (const answer of item.answer) {
        const regex = /<img.*?src="(.*?)".*?>/g;
        const matches = [...answer.name.matchAll(regex)];
        for (let match of matches) {
          const imageUrl = match[1];
          const base64Image = await urlToBase64(imageUrl);
          answer.name = answer.name.replace(imageUrl, base64Image);
        }
      }
    }
  };

  // Process each question
  for (const item of newData) {
    await processItem(item);
  }

  return newData;
};

const QuestionPreview = () => {
  const [questionSet, setQuestionSet] = useState([]);
  const [common, setCommon] = useState([]);
  const [biology1, setBiology1] = useState([]);
  const [biology2, setBiology2] = useState([]);
  const [chemistry1, setChemistry1] = useState([]);
  const [chemistry2, setChemistry2] = useState([]);
  const [maths1, setMaths1] = useState([]);
  const [maths2, setMaths2] = useState([]);
  const [physics1, setPhysics1] = useState([]);
  const [physics2, setPhysics2] = useState([]);

  const componentRef = useRef();

  const { id, grid } = useParams();

  const handleConvert = async (data, type) => {
    const newConvertedData = await convertImageUrlsToBase64(data);
    // setConvertedData(newConvertedData);
    if (type === "b1") {
      setBiology1(newConvertedData);
    }
    if (type === "b2") {
      setBiology2(newConvertedData);
    }
    if (type === "c1") {
      setChemistry1(newConvertedData);
    }
    if (type === "c2") {
      setChemistry2(newConvertedData);
    }
    if (type === "m1") {
      setMaths1(newConvertedData);
    }
    if (type === "m2") {
      setMaths2(newConvertedData);
    }
    if (type === "p1") {
      setPhysics1(newConvertedData);
    }
    if (type === "p2") {
      setPhysics2(newConvertedData);
    }
    if (type === "common") {
      setCommon(newConvertedData);
    }
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      const data = await getTestQuestions("", id); // Fetch data
      console.log(data);
      if (data.Biology_Section_One) {
        handleConvert(data.Biology_Section_One, "b1");
      }
      if (data.Biology_Section_Two) {
        handleConvert(data.Biology_Section_Two, "b2");
      }
      if (data.Chemistry_Section_One) {
        handleConvert(data.Chemistry_Section_One, "c1");
      }
      if (data.Chemistry_Section_Two) {
        handleConvert(data.Chemistry_Section_Two, "c2");
      }
      if (data.Maths_Section_One) {
        handleConvert(data.Maths_Section_One, "m1");
      }
      if (data.Maths_Section_Two) {
        handleConvert(data.Maths_Section_Two, "m2");
      }
      if (data.Physics_Section_One) {
        handleConvert(data.Physics_Section_One, "p1");
      }
      if (data.Physics_Section_Two) {
        handleConvert(data.Physics_Section_Two, "p2");
      }
      if (data.common) {
        handleConvert(data.common, "common");
      }
      setQuestionSet(data);
    };
    fetchQuestions();
  }, []);

  // Helper function to ensure images are loaded before generating the PDF
  const ensureImagesLoaded = async (element) => {
    const images = element.querySelectorAll("img");
    await Promise.all(
      Array.from(images).map((img) => {
        return new Promise((resolve) => {
          if (img.complete) {
            resolve(); // Image is already loaded
          } else {
            img.onload = resolve; // Wait for the image to load
            img.onerror = resolve; // Handle any image load errors
          }
        });
      })
    );
  };

  const handleDownloadPDF = async () => {
    const element = componentRef.current; // Get the HTML element to convert

    await ensureImagesLoaded(element); // Ensure all images are loaded

    const opt = {
      margin: 0.5,
      filename: "test-questions.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1.5, timeout: 20000 }, // Adjusted scale and timeout
      jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
    };

    // Convert the HTML content to PDF
    html2pdf().from(element).set(opt).save();
  };

  const SubjectSection = ({ sectionName, sectionQuestions }) => (
    <div>
      {sectionQuestions?.length > 0 && (
        <div className={styles.SubjectSectionContainer}>
          <div className={styles.titleContainer}>
            <h1 className={styles.title}>{sectionName}</h1>
          </div>
          <div
            className={
              grid === "1" ? styles.gridContainer : styles.gridContainer2
            }
          >
            {sectionQuestions?.map((q, qIndex) => (
              <div key={qIndex} className={styles.questionBlock}>
                <div className={styles.questionColumn}>
                  <div className={styles.question}>
                    <span className={styles.questionNumber}>
                      {qIndex + 1}.{" "}
                    </span>
                    <span
                      dangerouslySetInnerHTML={{ __html: q.question }}
                    ></span>
                  </div>
                  <ul className={styles.options}>
                    {q.answer.map((option, oIndex) => (
                      <li
                        key={oIndex}
                        className={
                          q.currect_answer === option ? styles.correct : ""
                        }
                      >
                        <span className={styles.optionNumber}>
                          {String.fromCharCode(97 + oIndex)}.{" "}
                        </span>
                        <span
                          dangerouslySetInnerHTML={{ __html: option.name }}
                        ></span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={styles.line}></div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  const TitlePage = ({ titleData }) => (
    <div className={`${styles.containerInner} ${styles.titlePage}`}>
      <div className={styles.header}>
        <h1>{titleData?.title}</h1>
        <div className={styles.details}>
          <div style={{ fontWeight: "bold" }}>
            Date: {titleData?.assign_date}
          </div>
          <div style={{ fontWeight: "bold" }}>
            Max. Marks: {titleData?.max_marks}
          </div>
        </div>
      </div>

      <div className={styles.syllabus}>
        <h3>SYLLABUS</h3>
        <div className={styles.subjects}>
          {titleData?.chapters?.Physics && (
            <p>
              <strong>Physics:</strong>{" "}
              {titleData?.chapters?.Physics.map((name, index) => (
                <span key={index}>{name}, </span>
              ))}
            </p>
          )}
          {titleData?.chapters?.Chemistry && (
            <p>
              <strong>Chemistry:</strong>{" "}
              {titleData?.chapters?.Chemistry.map((name, index) => (
                <span key={index}>{name}, </span>
              ))}
            </p>
          )}
          {titleData?.chapters?.Maths && (
            <p>
              <strong>Maths:</strong>{" "}
              {titleData?.chapters?.Maths.map((name, index) => (
                <span key={index}>{name}, </span>
              ))}
            </p>
          )}
          {titleData?.chapters?.Biology && (
            <p>
              <strong>Biology:</strong>{" "}
              {titleData?.chapters?.Biology.map((name, index) => (
                <span key={index}>{name}, </span>
              ))}
            </p>
          )}
        </div>
      </div>

      <div className={styles.instructions}>
        <h3>INSTRUCTIONS FOR STUDENTS</h3>
        <ol>
          <li>All questions are compulsory.</li>
          <li>
            The test is of {titleData?.timelimit} duration and the Test Booklet
            contains {titleData?.all_questions} questions.
          </li>
          <li>
            Each question carries 4 marks. For each correct response, 4 marks
            are awarded. For incorrect responses, 1 mark is deducted.
          </li>
        </ol>
      </div>
    </div>
  );

  return (
    <div>
      <div className={styles.container} ref={componentRef}>
        <TitlePage titleData={questionSet.quiz_details} />
        <SubjectSection
          sectionName="Biology Section One"
          sectionQuestions={biology1}
        />
        <SubjectSection
          sectionName="Biology Section Two"
          sectionQuestions={biology2}
        />
        <SubjectSection
          sectionName="Chemistry Section One"
          sectionQuestions={chemistry1}
        />
        <SubjectSection
          sectionName="Chemistry Section Two"
          sectionQuestions={chemistry2}
        />
        <SubjectSection
          sectionName="Maths Section One"
          sectionQuestions={maths1}
        />
        <SubjectSection
          sectionName="Maths Section Two"
          sectionQuestions={maths2}
        />
        <SubjectSection
          sectionName="Physics Section One"
          sectionQuestions={physics1}
        />
        <SubjectSection
          sectionName="Physics Section Two"
          sectionQuestions={physics2}
        />
        <SubjectSection sectionName={""} sectionQuestions={common} />
      </div>
      <div className={styles.printButtonContainer}>
        <button onClick={handleDownloadPDF} className={styles.printButton}>
          Download PDF
        </button>
      </div>
    </div>
  );
};

export default QuestionPreview;
