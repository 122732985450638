import React, { useEffect, useRef, useState } from "react";
import classes from "./VideoPlayer.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  saveRecordingTime,
  updateVideoViewTime,
} from "../../../../Store/API/API";

const VideoPlayer = ({ fileData, currentViewTimeParent, type }) => {
  const [lastTimeChecked, setLastTimeChecked] = useState(0);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const videoRef = useRef(null);
  const userState = useSelector((state) => state.userData.userData);
  const groupState = useSelector((state) => state.applicationData.batchData);
  const endTimeRef = useRef(currentViewTimeParent > 0 ? currentViewTimeParent : 1);
  const dispatch = useDispatch();
  // console.log("currentViewTimeParent", currentViewTimeParent);
  
  // console.log(
  //   fileData,
  //   "file data",
  //   currentViewTimeParent,
  //   "current view time"
  // );

  const startTime = 0;
  // const endTime = 120;

  const handleTimeUpdate = () => {
    const currentTime = videoRef?.current?.currentTime;
    // console.log(currentTime, lastTimeChecked, currentTime - lastTimeChecked);
    if (currentTime - lastTimeChecked >= 10) {
      // console.log("30-second interval reached");
      updateViewTime(currentTime);
      setLastTimeChecked(currentTime);
    }
  };

  const handleSeeking = () => {
    // console.log("Seeking");

    const videoElement = videoRef.current;
    if (!videoElement) return;

    const currentTime = videoElement.currentTime;
    const endTime = endTimeRef.current;

    // If the user seeks out of bounds, bring them back
    if (currentTime < startTime) {
      videoElement.currentTime = startTime;
    } else if (currentTime > endTime) {
      videoElement.currentTime = endTime;
    }
  };

  const updateViewTime = async (currentTime) => {
    // console.log("Updating view time", currentTime);
    // console.log("Video duration", videoDuration);
    endTimeRef.current = currentTime;

    if (type === "resource") {
      // console.log("Resource type");
      const shopTempData = {
        user_id: userState?.userId,
        content_type: "resource",
        session_id: fileData?.content_id,
        currentviewtime: currentTime,
        allDuration: videoDuration,
        source_id: fileData?.course_id,
        groupid: groupState?.groupId,
        videoName: fileData?.name,
      };

      const data = await saveRecordingTime(
        shopTempData,
        userState.token,
        dispatch
      );
      // console.log(data);
    } else if (type === "session") {
      // console.log("Session type");
      const shopTempData = {
        user_id: userState.userId,
        content_type: "session",
        session_id: fileData?.session_id,
        currentviewtime: currentTime,
        allDuration: videoDuration,
        source_id: fileData?.course_id,
        groupid: groupState?.groupId,
        videoName: fileData?.name,
      };
      // console.log("shop temp data",shopTempData);

      const data = await saveRecordingTime(
        shopTempData,
        userState.token,
        dispatch
      );
      // console.log(data);
    } else {
      // console.log("Shop type");
      const shopTempData = {
        student_id: userState?.userId,
        content_type: "shop",
        content_id: fileData?.content_id,
        current_view_time: currentTime,
        total_video_time: videoDuration,
        source_id: fileData?.course_id,
      };
      const data = await updateVideoViewTime(
        shopTempData,
        userState.token,
        dispatch
      );
      // console.log(data);
    }
    // const shopTempData = {
    //   student_id: userState.userId,
    //   content_type: "shop",
    //   content_id: fileData.content_id,
    //   current_view_time: currentTime,
    //   total_video_time: videoDuration,
    //   source_id: fileData.course_id,
    // };
    // const data = await updateVideoViewTime(shopTempData, userState.token);
    // console.log( "video player api data=",data);
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleLoadedMetadata = () => {
      videoElement.currentTime = currentViewTimeParent; // Seek to 10 seconds
      setVideoDuration(videoElement?.duration);
    };
    const handleCanPlay = () => {
      setIsVideoLoaded(true);
      if (document.visibilityState === "visible") {
        videoElement
          .play()
          .catch((error) => console.warn("Autoplay blocked:", error));
      }
      // videoElement.play(); // Automatically play the video
    };

    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
      videoElement.addEventListener("canplay", handleCanPlay);
      videoElement.addEventListener("seeking", handleSeeking);

      return () => {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
        videoElement.removeEventListener("canplay", handleCanPlay);
        videoElement.removeEventListener("seeking", handleSeeking);
      };
    }
  }, [currentViewTimeParent]);

  // useEffect(() => {
  //   const getViewTime = async () => {
  //     const shopTempData = {
  //       student_id: userState.userId,
  //       content_type: "shop",
  //       content_id: fileData.content_id,
  //     };
  //     const data = await getVedioViewTime(shopTempData, userState.token);
  //     console.log(data.data.current_view_time, "view time data");
  //     setCurrentViewTime(data.data.current_view_time);
  //   };
  //   getViewTime();
  // }, [fileData]);
  return (
    <div className={classes.videoPlayerWrapper}>
      <video
        ref={videoRef}
        controls
        width="100%"
        height="100%"
        onTimeUpdate={handleTimeUpdate}
        controlsList="nodownload"
        // className={classes.videoPlayer}
      >
        <source src={fileData?.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!isVideoLoaded && <div className={classes.loader}>Loading...</div>}
    </div>
  );
};
export default VideoPlayer;
