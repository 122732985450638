// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Resources_batchAssignments__ObLT5 {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 40px 40px; /* Default padding for mobile */
  background-color: #f3f4f6; /* Tailwind's bg-gray-100 */
  overflow: auto; /* Default overflow for mobile */
}

@media (max-width: 768px) {
  .Resources_batchAssignments__ObLT5 {
    padding: 30px 20px; /* Default padding for desktop */
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Resources/Resources.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,kBAAkB,EAAE,+BAA+B;EACnD,yBAAyB,EAAE,2BAA2B;EACtD,cAAc,EAAE,gCAAgC;AAClD;;AAEA;EACE;IACE,kBAAkB,EAAE,gCAAgC;EACtD;AACF","sourcesContent":[".batchAssignments {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  padding: 40px 40px; /* Default padding for mobile */\n  background-color: #f3f4f6; /* Tailwind's bg-gray-100 */\n  overflow: auto; /* Default overflow for mobile */\n}\n\n@media (max-width: 768px) {\n  .batchAssignments {\n    padding: 30px 20px; /* Default padding for desktop */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"batchAssignments": `Resources_batchAssignments__ObLT5`
};
export default ___CSS_LOADER_EXPORT___;
