import React from "react";
import Select from "react-select";

const SelectInput = ({
  options,
  value,
  handleInputChange,
  placeHolder,
  width,
  border,
  backgroundColor,
  field,
  height,
  isMulti,
  textColor, // Added textColor prop
  dropdownTextColor, // Added dropdown text color prop
}) => {
  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      defaultValue={field ? value[field] : value}
      value={field ? value[field] : value}
      name="color"
      isMulti={isMulti ? isMulti : false}
      options={field ? options[field] : options}
      onChange={(selectedOption) => handleInputChange(field, selectedOption)}
      placeholder={placeHolder}
      styles={{
        control: (base) => ({
          ...base,
          border: border ? border : base.border,
          boxShadow: border ? "none" : base.boxShadow,
          backgroundColor: backgroundColor
            ? backgroundColor
            : base.backgroundColor,
          width: width,
          height: height,
          color: textColor ? textColor : "white",
        }),
        container: (base) => ({
          ...base,
          width: width,
          height: height,
        }),
        singleValue: (base) => ({
          ...base,
          color: dropdownTextColor || "black", // Customize selected value text color
        }),
        menu: (base) => ({
          ...base,
          maxHeight: "150px", // Controls dropdown height
          overflowY: "auto", // Enables scrolling when options exceed maxHeight
        }),
        menuList: (base) => ({
          ...base,
          maxHeight: "150px", // Limits the height of the option list
          overflowY: "auto", // Enables scrolling
        }),
      }}
    />
  );
};

export default SelectInput;
