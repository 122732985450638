import React, { useState } from "react";
import { FiDownload } from "react-icons/fi";
import * as XLSX from "xlsx";
import { Pagination } from "@mui/material";

const ReportData = ({ activities = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentActivities = activities.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(activities.length / itemsPerPage);

  const handleDownload = () => {
    if (activities.length === 0) {
      return;
    }

    const dataToExport = activities.map((activity) => ({
            "Date & Time": activity.time || "N/A",
            "User Name": activity.user_name || "N/A",
            "User Type": activity.user_type || "N/A",
            "Action": activity.entity_action || "N/A",
             "Source": activity.entity_source || "N/A",
          }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    worksheet["!cols"] = [
            { wch: 30 },
            { wch: 20 },
            { wch: 30 },
            { wch: 25 },
            { wch: 15 },
          ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Activity Logs");
    XLSX.writeFile(workbook, "ActivityLogs.xlsx");
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="w-full mx-auto">
      {activities.length > 0 && (
        <div className="flex justify-between items-center mb-6 px-6">
          <h2 className="text-base leading-[20px] lg:text-[24px] lg:leading-[28px] font-bold">
            Activity Logs
          </h2>
          <button
            onClick={handleDownload}
            className="hidden lg:flex items-center gap-2 bg-primaryOrange text-white px-4 py-2 rounded-full hover:bg-primaryOrangeDisabled transition duration-200"
          >
            <FiDownload size={20} />
            Download Logs
          </button>
        </div>
      )}

      {activities.length === 0 ? (
        <div className="px-6 text-center text-base font-semibold text-primaryOrange">No Data to Show</div>
      ) : (
        <div className="space-y-4 px-6 -mt-5 md:mt-0 pb-0 md:pb-10">
          {currentActivities.map((activity, index) => (
            <div key={index} className="flex items-start gap-4 p-4">
              <div className="p-3 bg-textBlue text-primaryBlue bg-backgroundBlue text-center rounded-full">
                {activity.icon  || activity.user_name[0]}
              </div>
              <div>
                <p className="text-sm leading-[18px] text-textBlack font-normal">
                  {activity.time}
                </p>
                <p className="text-base leading-[20px] lg:text-[20px] lg:leading-[24px] font-medium">
                  {activity.entity_action}  {activity.user_name} ({activity.user_type})
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      {activities.length > 0 && (
        <div className="px-16 lg:hidden pb-5">
          <button
            onClick={handleDownload}
            className="w-full flex justify-center items-center gap-2 bg-primaryOrange text-white px-4 py-2 rounded-full hover:bg-primaryOrangeDisabled transition duration-200">
            <FiDownload size={20} />
            Download Logs
          </button>
        </div>
      )}

    {activities.length > itemsPerPage && (
        <div className="flex justify-center mt-4">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            shape="rounded"
            size="medium"
          />
        </div>
      )}
    </div>
  );
};

export default ReportData;