import React, { useState } from "react";
import classes from "./BatchCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { applicationActions } from "../../../../Store/Data/Slices/ApplicationSlice";
import CustomModal from "../../../UI/CustomModal/CustomModal";
import CreateBatchModal from "../CreateBatchModal";
import EditIcon from "@mui/icons-material/Edit";

const BatchCrad = ({ batch, instituteId, updateBatchDetails }) => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const [createBatchModal, setCreateBatchModal] = useState(false);

  // console.log("userState batch", userState);

  const handleBatchClick = () => {
    let userType = "";

    if (userState?.userType === "teacher") {
      if (batch?.teacher_as_student === true) {
        userType = "student";
      } else {
        userType = "teacher";
      }
    } else {
      userType = userState?.userType;
    }

    const batchData = {
      groupId: batch.group_id,
      batchName: batch.group_name,
      batchColor: batch.color_code,
      batchUserType: userType,
    };
    console.log(batchData);
    dispatch(applicationActions.setBatchData(batchData));
    localStorage.setItem("batchData", JSON.stringify(batchData));

    localStorage.setItem("curr_directory", JSON.stringify([]));
  };

  return (
    <div
      className={classes.batchCardOuter}
      style={{ backgroundColor: batch?.color_code }}
    >
      <div
        className="absolute top-2 right-2 border rounded-full w-[26px] h-[26px] cursor-pointer bg-white flex items-center justify-center"
        onClick={() => setCreateBatchModal(true)}
      >
        <EditIcon
          className="text-gray-600"
          style={{
            fontSize: "16px",
            margin: "0px",
          }}
        />
      </div>
      <div onClick={handleBatchClick}>
        <h4 className={classes.cardText}>{batch.group_name}</h4>
        {/* <div className={classes.badge}>
        <p className={classes.badgeText}>
        {batch?.batch_type.charAt(0).toUpperCase() +
        batch?.batch_type.slice(1)}
        </p>
        </div> */}
        <p className={classes.tutorName}>
          By: {batch?.school_name || "Innovartan"}
        </p>
      </div>

      <CustomModal
        isOpen={createBatchModal}
        onClose={() => setCreateBatchModal(false)}
      >
        <CreateBatchModal
          handleModalSubmit={() => {
            setCreateBatchModal(!createBatchModal);
            updateBatchDetails();
          }}
          onClose={() => {
            setCreateBatchModal(!createBatchModal);
          }}
          modalHeading={"Edit Batch"}
          batchData={batch}
          instituteId={instituteId}
        />
      </CustomModal>
    </div>
  );
};
export default BatchCrad;
