import React, { useEffect, useState } from "react";
import styles from "./TopicAdd.module.css";
import { getDppContentTopic, updateContentTopic, updateDppContentTopic } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../UI/SelectInput/SelectInput";

const width = window.innerWidth;

const SelectInputField = ({
  heading,
  placeholder,
  field,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
  isMulti,
}) => {
  return (
    <div className={styles.selectWrapper}>
      <label className={styles.label}>
        {heading} <span className={styles.required}>*</span>
      </label>
      <div className={styles.dropDownWrapper}>
        <SelectInput
          width={width > 500 ? "350px" : "100%"}
          placeHolder={placeholder}
          options={dropDownoptions}
          field={field}
          handleInputChange={handleInputChange}
          value={dropDownValues}
          isMulti={isMulti}
        />
      </div>
    </div>
  );
};

const TopicAddModalNew = ({
  onClose,
  courseId,
  contentId,
  type,
  chapterId,
  fetchContent,
}) => {
  const [error, setError] = useState(null);
  const [dropDownValues, setDropDownValues] = useState([]);
  console.log(dropDownValues);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const data = await getDppContentTopic(
          courseId,
          contentId,
          type,
          chapterId,
          userState.token,
          dispatch
        );
        if (data?.topics) {
          setDropDownOptions(
            data?.topics.map((topic) => ({
              value: topic.value,
              label: topic.label,
            }))
          );
          setDropDownValues(
            data?.content_topics?.map((topic) => ({
              value: topic.value,
              label: topic.label,
            }))
          );
        } else {
          setDropDownOptions([]);
          setDropDownValues([]);
        }
      } catch (error) {
        console.error("Error fetching topics:", error);
        setError("Failed to load topics.");
      }
    };
    fetchTopics();
  }, [courseId, contentId, type, chapterId, dispatch]);

  const handleInputChange = (field, value) => {
    setDropDownValues(value);
  };

  const handleSubmit = async () => {
    if (dropDownValues.length === 0) {
      setError("Please select at least one topic.");
      return;
    }

    const payload = {
      course_id: courseId,
      content_id: contentId,
      type: type,
      topics: dropDownValues.map((topic) => topic.value),
    };

    try {
      const response = await updateDppContentTopic(
        payload,
        userState.token,
        dispatch
      );
      if (response.statuscode === "200") {
        onClose();
        fetchContent();
      } else {
        setError("Failed to update topics.");
      }
    } catch (error) {
      console.error("Error updating topics:", error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.crossIcon} onClick={onClose}></div>
      <header className={styles.header}>
        <div className={styles.headerContainer}>
          <p className={styles.title}>Update Topics</p>
        </div>
      </header>
      <div className={styles.form}>
        <SelectInputField
          // field="Topics"
          heading="DPP Topics"
          placeholder="Select Topic"
          dropDownValues={dropDownValues}
          dropDownoptions={dropDownOptions}
          handleInputChange={handleInputChange}
          isMulti={true}
        />
        {error && <div className={styles.errorText}>{error}</div>}
        <div className={styles.actions} onClick={handleSubmit}>
          <button className="bg-primaryBlue text-textWhite px-4 py-2 rounded-lg ">Submit</button>
        </div>
      </div>
    </div>
  );
};

export default TopicAddModalNew;
