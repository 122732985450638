import React from "react";

const AboutCourseSection = ({ courseDetails }) => {
  return (
    <div className="w-full lg:w-[70%] md:mb-10 mb-3 md:mt-10 mt-5">
      {/* Description Section */}
      <div className="mb-6">
        <h2 className="text-xl font-metropolis font-semibold m-0">
          Description
        </h2>
        <div className="text-gray-700 font-metropolis mt-2">
          <div
            dangerouslySetInnerHTML={{ __html: courseDetails?.description }}
          ></div>
        </div>
      </div>

      {/* What You'll Learn Section */}
      <div className="border-t pt-6 mb-7">
        <h2 className="text-xl font-semibold">What You'll Learn</h2>
        <div className="mt-3 w-full lg:w-[70%]">
          <div dangerouslySetInnerHTML={{ __html: courseDetails.topic }}></div>
        </div>
      </div>

      {/* Who Can Enroll Section */}
      <div className="border-t pt-6 mt-4">
        <h2 className="text-xl font-semibold">Who Can Enroll?</h2>
        <div className="mt-3 text-gray-700">
          <div
            dangerouslySetInnerHTML={{ __html: courseDetails.requirement }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AboutCourseSection;
