// import React, { useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import styles from "./TeacherTestView.module.css";
// import MCQ from "../../../UI/OuestionTypes/MCQ/MCQ";
// import MCQMA from "../../../UI/OuestionTypes/MCQMA/MCQMA";
// import TF from "../../../UI/OuestionTypes/TF/TF";
// import Integer from "../../../UI/OuestionTypes/Integer/Integer";
// import FIB from "../../../UI/OuestionTypes/FIB/FIB";
// import Button from "../../../UI/Button/Button";
// import { useDispatch, useSelector } from "react-redux";
// import { getQuizQuestion } from "../../../../Store/API/API";

// const TeacherTestView = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const userState = useSelector((state) => state.userData.userData);
//   const [loadingQuestions, setLoadingQuestions] = useState(false);

//   const { testId, testDetails } = location?.state || {};
//   const [questionData, setQuestionData] = useState({});

//   const handleViewQuestions = async () => {
//     setLoadingQuestions(true);
//     const data = await getQuizQuestion(userState.token, testId, dispatch);
//     if (data) {
//       const updatedQuestionData = {};
//       data.forEach((item) => {
//         const key = `${item.section_subject}_${item.section}`;
//         if (!updatedQuestionData[key]) {
//           updatedQuestionData[key] = [];
//         }
//         updatedQuestionData[key].push(item);
//       });
//       setQuestionData(updatedQuestionData);
//       setLoadingQuestions(false);
//     }
//   };

//   return (
//     <div className={styles.outerContainer}>
//       <div className={styles.headerSection}>
//         <div className={styles.headingContainer}>
//           <div style={{ display: "flex" }}>
//             <p className={styles.heading}>{`Test > ${
//               testDetails?.title || ""
//             }`}</p>
//           </div>
//           <div>
//             <p className={styles.heading}>
//               {testDetails?.status === "Published" ? (
//                 <span style={{ color: "green" }}>{testDetails?.status}</span>
//               ) : (
//                 <span style={{ color: "red" }}>{testDetails?.status}</span>
//               )}
//             </p>
//           </div>
//           <div className={styles.buttonContainer1}>
//             <button className={styles.activeButton} disabled>
//               View Test
//             </button>
//             <button
//               className={styles.inactiveButton}
//               onClick={() =>
//                 navigate("/teacher-submission-view", {
//                   state: { testId, testDetails },
//                 })
//               }
//             >
//               View Submissions
//             </button>
//           </div>
//         </div>
//         <div className={styles.buttonContainer2}>
//           <button className={styles.activeButton} disabled>
//             View Test
//           </button>
//           <button
//             className={styles.inactiveButton}
//             onClick={() => {
//               // console.log("View Submissions");
//               navigate("/teacher-submission-view", {
//                 state: { testId, testDetails },
//               });
//             }}
//           >
//             View Submissions
//           </button>
//         </div>
//       </div>

//       <div
//         className={
//           Object.keys(questionData).length > 0
//             ? styles.contentSection
//             : styles.contentSectionNoQuestion
//         }
//       >
//         {Object.keys(questionData).length > 0 ? (
//           Object.entries(questionData).map(([key, values]) => (
//             <div key={key}>
//               <p className={styles.questionTypeHeading}>{key}</p>
//               {values.map((value, index) => {
//                 // console.log("value", value);
//                 switch (value.type) {
//                   case "MCQ":
//                     return (
//                       <MCQ
//                         key={index}
//                         correctOption={value?.answer}
//                         answer={value?.currect_answer}
//                         question={value?.question}
//                         questionOrder={value?.question_order}
//                         questionId={value?.question_id}
//                         questionMarks={value?.marks}
//                       />
//                     );
//                   case "MCQMA":
//                     return (
//                       <MCQMA
//                         key={index}
//                         correctOption={value.answer}
//                         answer={value.solution_array}
//                         question={value.question}
//                         questionOrder={value.question_order}
//                         questionId={value?.question_id}
//                         questionMarks={value?.marks}
//                       />
//                     );
//                   case "TF":
//                     return (
//                       <TF
//                         key={index}
//                         correctOption={value.answer}
//                         answer={value.currect_answer}
//                         question={value.question}
//                         questionOrder={value.question_order}
//                         questionId={value?.question_id}
//                         questionMarks={value?.marks}
//                       />
//                     );
//                   case "INTT":
//                     return (
//                       <Integer
//                         key={index}
//                         correctOption={value.currect_answer}
//                         question={value.question}
//                         questionOrder={value.question_order}
//                         questionId={value?.question_id}
//                         questionMarks={value?.marks}
//                       />
//                     );
//                   case "FIB":
//                     return (
//                       <FIB
//                         key={index}
//                         correctOption={value.solution_array}
//                         fibCount={value.fib_count}
//                         question={value.question}
//                         questionOrder={value.question_order}
//                         questionId={value?.question_id}
//                         questionMarks={value?.marks}
//                       />
//                     );
//                   default:
//                     return null;
//                 }
//               })}
//             </div>
//           ))
//         ) 
        
//         : (
//           <Button
//             custom_class={styles.ViewButton}
//             onClick={handleViewQuestions}
//             text={loadingQuestions ? "Loading..." : "View Questions"}
//             disabled={loadingQuestions}
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default TeacherTestView;



import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./TeacherTestView.module.css";
import MCQ from "../../../UI/OuestionTypes/MCQ/MCQ";
import MCQMA from "../../../UI/OuestionTypes/MCQMA/MCQMA";
import TF from "../../../UI/OuestionTypes/TF/TF";
import Integer from "../../../UI/OuestionTypes/Integer/Integer";
import FIB from "../../../UI/OuestionTypes/FIB/FIB";
import { useDispatch, useSelector } from "react-redux";
import { getQuizQuestion } from "../../../../Store/API/API";

const TeacherTestView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const { testId, testDetails } = location?.state || {};
  const [questionData, setQuestionData] = useState({});

  useEffect(() => {
    const fetchQuestions = async () => {
      const data = await getQuizQuestion(userState.token, testId, dispatch);
      if (data) {
        const updatedQuestionData = {};
        data.forEach((item) => {
          const key = `${item.section_subject}_${item.section}`;
          if (!updatedQuestionData[key]) {
            updatedQuestionData[key] = [];
          }
          updatedQuestionData[key].push(item);
        });
        setQuestionData(updatedQuestionData);
      }
      setLoadingQuestions(false);
    };

    fetchQuestions();
  }, [userState.token, testId, dispatch]);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.headerSection}>
        <div className={styles.headingContainer}>
          <div style={{ display: "flex" }}>
            <p className={styles.heading}>{`Test > ${testDetails?.title || ""}`}</p>
          </div>
          <div>
            <p className={styles.heading}>
              {testDetails?.status === "Published" ? (
                <span style={{ color: "green" }}>{testDetails?.status}</span>
              ) : (
                <span style={{ color: "red" }}>{testDetails?.status}</span>
              )}
            </p>
          </div>
          <div className={styles.buttonContainer1}>
            <button className={styles.activeButton} disabled>
              View Test
            </button>
            <button
              className={styles.inactiveButton}
              onClick={() =>
                navigate("/teacher-submission-view", {
                  state: { testId, testDetails },
                })
              }
            >
              View Submissions
            </button>
          </div>
        </div>
        <div className={styles.buttonContainer2}>
          <button className={styles.activeButton} disabled>
            View Test
          </button>
          <button
            className={styles.inactiveButton}
            onClick={() => {
              navigate("/teacher-submission-view", {
                state: { testId, testDetails },
              });
            }}
          >
            View Submissions
          </button>
        </div>
      </div>

      <div
        className={
          Object.keys(questionData).length > 0
            ? styles.contentSection
            : styles.contentSectionNoQuestion
        }
      >
        {loadingQuestions ? (
          <p>Loading questions...</p>
        ) : Object.keys(questionData).length > 0 ? (
          Object.entries(questionData).map(([key, values]) => (
            <div key={key}>
              <p className={styles.questionTypeHeading}>{key}</p>
              {values.map((value, index) => {
                switch (value.type) {
                  case "MCQ":
                    return (
                      <MCQ
                        key={index}
                        correctOption={value?.answer}
                        answer={value?.currect_answer}
                        question={value?.question}
                        questionOrder={value?.question_order}
                        questionId={value?.question_id}
                        questionMarks={value?.marks}
                      />
                    );
                  case "MCQMA":
                    return (
                      <MCQMA
                        key={index}
                        correctOption={value.answer}
                        answer={value.solution_array}
                        question={value.question}
                        questionOrder={value.question_order}
                        questionId={value?.question_id}
                        questionMarks={value?.marks}
                      />
                    );
                  case "TF":
                    return (
                      <TF
                        key={index}
                        correctOption={value.answer}
                        answer={value.currect_answer}
                        question={value.question}
                        questionOrder={value.question_order}
                        questionId={value?.question_id}
                        questionMarks={value?.marks}
                      />
                    );
                  case "INTT":
                    return (
                      <Integer
                        key={index}
                        correctOption={value.currect_answer}
                        question={value.question}
                        questionOrder={value.question_order}
                        questionId={value?.question_id}
                        questionMarks={value?.marks}
                      />
                    );
                  case "FIB":
                    return (
                      <FIB
                        key={index}
                        correctOption={value.solution_array}
                        fibCount={value.fib_count}
                        question={value.question}
                        questionOrder={value.question_order}
                        questionId={value?.question_id}
                        questionMarks={value?.marks}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          ))
        ) : (
          <p>No questions available</p>
        )}
      </div>
    </div>
  );
};

export default TeacherTestView;

