import React, { useEffect, useState } from "react";
import styles from "./QuestionEditBar.module.css";
import SelectInput from "../../../UI/SelectInput/SelectInput";
import {
  getChapters,
  getClasses,
  getSubjects,
  getTopics,
  updateTagQuestion,
} from "../../../../Store/API/API";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { applicationActions } from "../../../../Store/Data/Slices/ApplicationSlice";

const SelectInputField = ({
  heading,
  placeholder,
  field,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
  width,
  isMulti,
}) => {
  return (
    <div className={styles.selectWrapper}>
      <label className={styles.label}>
        {heading} <span className={styles.required}>*</span>
      </label>
      <div className={styles.dropDownWrapper}>
        <SelectInput
          width={"100%"}
          placeHolder={placeholder}
          options={dropDownoptions}
          field={field}
          handleInputChange={handleInputChange}
          value={dropDownValues}
          backgroundColor={"#FFFFFF"}
          dropdownTextColor={"#000000"}
          isMulti={isMulti}
        />
      </div>
    </div>
  );
};

const QuestionEditBar = ({
  questionData,
  userState,
  questionBankId,
  location,
}) => {
  // console.log("questionBankIdnew", questionBankId);
  const currentYear = new Date().getFullYear();
  const startYear = 2010; // Define the starting year

  const pastYears = Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => ({
      value: (currentYear - i).toString(),
      label: (currentYear - i).toString(),
    })
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dropDownValues, setDropDownValues] = useState({
    difficultyLevel: "",
    questionType: "",
    isVerified: "",
    class: "",
    subject: "",
    chapter: "",
    topics: "",
    PYQ: "",
    PYQDate: "",
    PYQMonth: "",
    PYQShift: "",
    pastConnectionClass: "",
    pastConnectionSubject: "",
    pastConnectionChapter: "",
  });
  const [dropDownoptions, setDropDownOptions] = useState({
    difficultyLevel: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
    ],
    questionType: [
      { value: "Information Based", label: "Information Based" },
      { value: "Understanding Based", label: "Understanding Based" },
      { value: "Application Based", label: "Application Based" },
    ],
    isVerified: [
      { value: "yes", label: "yes" },
      { value: "no", label: "no" },
    ],
    class: [],
    subject: [],
    chapter: [],
    topics: [],
    PYQ: pastYears,
    PYQDate: Array.from({ length: 31 }, (_, i) => ({
      value: (i + 1).toString(),
      label: (i + 1).toString(),
    })),
    PYQMonth: [
      { value: "January", label: "January" },
      { value: "February", label: "February" },
      { value: "March", label: "March" },
      { value: "April", label: "April" },
      { value: "May", label: "May" },
      { value: "June", label: "June" },
      { value: "July", label: "July" },
      { value: "August", label: "August" },
      { value: "September", label: "September" },
      { value: "October", label: "October" },
      { value: "November", label: "November" },
      { value: "December", label: "December" },
    ],
    PYQShift: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
    ],
    pastConnectionClass: [],
    pastConnectionSubject: [],
    pastConnectionChapter: [],
  });

  const fetchTopics = async ({ value }) => {
    const data = await getTopics(
      dropDownValues?.class?.value,
      dropDownValues?.subject?.value,
      value,
      dispatch
    );

    // console.log(data);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        topics: data,
      }));
    }
  };

  const fetchChapters = async ({ value }) => {
    const data = await getChapters(
      dropDownValues?.class?.value,
      value,
      dispatch
    );
    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        chapter: data,
      }));
    }
  };

  const handleCancel = () => {
    if (location.state.location === "QL") {
      navigate("/question-bank/view", {
        state: { questionBankId: questionBankId },
      });
    }
    if (location.state.location === "QB") {
      navigate("/question-library", {
        state: {
          dropDownValues: location.state.dropDownValues,
          currentPage: location.state.currentPage,
        },
      });
    }
  };

  const PastConnectionChapter = async ({ value }) => {
    const data = await getChapters(
      dropDownValues.pastConnectionClass.value,
      value,
      dispatch
    );
    // console.log("getChapters", data);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        pastConnectionChapter: data,
      }));
    }
  };

  const fetchSubjects = async ({ value }) => {
    const data = await getSubjects(value, dispatch);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        subject: data,
      }));
    }
  };

  const PastConnectionSubject = async ({ value }) => {
    const data = await getSubjects(value, dispatch);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        pastConnectionSubject: data,
      }));
    }
  };

  const fetchClasses = async () => {
    const data = await getClasses(dispatch);
    // console.log(data);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        class: data,
        pastConnectionClass: data,
      }));
    }
  };

  const handleInputChange = (field, value) => {
    // console.log(field, value);

    setDropDownValues((prev) => ({ ...prev, [field]: value }));

    if (field === "class") {
      setDropDownValues((prev) => ({
        ...prev,
        subject: null,
        chapter: null,
        topics: "",
      }));
      fetchSubjects(value);
    }

    if (field === "subject") {
      setDropDownValues((prev) => ({
        ...prev,
        chapter: null,
        topics: "",
      }));
      fetchChapters(value);
    }

    if (field === "chapter") {
      setDropDownValues((prev) => ({
        ...prev,
        topics: "",
      }));
      fetchTopics(value);
    }

    if (field === "pastConnectionClass") {
      setDropDownValues((prev) => ({
        ...prev,
        pastConnectionSubject: null,
        pastConnectionChapter: null,
      }));
      PastConnectionSubject(value);
    }

    if (field === "pastConnectionSubject") {
      setDropDownValues((prev) => ({
        ...prev,
        pastConnectionChapter: null,
      }));
      PastConnectionChapter(value);
    }
  };

  const handleUpdate = async () => {
    // console.log(dropDownValues);

    const tagValue = {
      user_id: userState?.userId,
      question_id: questionData?.question?.question_id,
      difficulty_level: dropDownValues?.difficultyLevel?.value,
      question_chapter: dropDownValues?.chapter?.value,
      question_topics: dropDownValues?.topics?.map((topic) => topic.value),
      question_type_code:
        dropDownValues?.questionType?.value === "Information Based"
          ? "I"
          : dropDownValues?.questionType?.value === "Understanding Based"
          ? "U"
          : "A",
      is_verified: dropDownValues?.isVerified?.value,
      pyq: dropDownValues?.PYQ?.value,
      pyq_date: dropDownValues?.PYQDate?.value,
      pyq_month: dropDownValues?.PYQMonth?.value,
      pyq_shift: dropDownValues?.PYQShift?.value,
    };

    // console.log("tagValue", tagValue);

    try {
      const data = await updateTagQuestion(
        tagValue,
        userState?.token,
        dispatch
      );
      console.log("updateTagQuestion", data);

      if (data?.statuscode === "200") {
        dispatch(
          applicationActions.setAlertData({
            isAlert: true,
            alertMessage: "Question updated successfully!",
            alertType: "success",
          })
        );
        if (location.state.location === "QL") {
          navigate("/question-bank/view", {
            state: { questionBankId: questionBankId },
          });
        }
        if (location.state.location === "QB") {
          navigate("/question-library", {
            state: {
              dropDownValues: location.state.dropDownValues,
              currentPage: location.state.currentPage,
            },
          });
        }
      } else {
        dispatch(
          applicationActions.setAlertData({
            isAlert: true,
            alertMessage:
              data?.error || "Failed to update question. Please try again.",
            alertType: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: error.message || "An error occurred. Please try again.",
          alertType: "error",
        })
      );
    } finally {
      setTimeout(() => {
        dispatch(
          applicationActions.setAlertData({
            isAlert: false,
            alertMessage: "",
            alertType: "",
          })
        );
      }, 3000);
    }
  };

  const processData = () => {
    console.log("processData", questionData);

    if (!questionData?.question) return;

    const tempDropDownValues = {
      difficultyLevel: {
        value: questionData?.question?.difficulty_level,
        label: questionData?.question?.difficulty_level,
      },
      questionType: {
        value:
          questionData?.question?.question_type_code === "I"
            ? "Information Based"
            : questionData?.question?.question_type_code === "U"
            ? "Understanding Based"
            : "Application Based",
        label:
          questionData?.question?.question_type_code === "I"
            ? "Information Based"
            : questionData?.question?.question_type_code === "U"
            ? "Understanding Based"
            : "Application Based",
      },
      isVerified: {
        value: questionData?.question?.is_verified,
        label: questionData?.question?.is_verified,
      },
      chapter: questionData?.chapters?.find(
        (chapter) => chapter.label === questionData?.question?.question_chapter
      ),

      topics: questionData?.question?.question_topics?.map((topic) => ({
        value: topic.value,
        label: topic.label,
      })),
      PYQ: {
        value: questionData?.question?.pyq,
        label: questionData?.question?.pyq,
      },
      PYQDate: {
        value: questionData?.question?.pyq_date,
        label: questionData?.question?.pyq_date,
      },
      PYQMonth: {
        value: questionData?.question?.pyq_month,
        label: questionData?.question?.pyq_month,
      },
      PYQShift: {
        value: questionData?.question?.pyq_shift,
        label: questionData?.question?.pyq_shift,
      },
      // pastConnectionClass: {
      //   value: questionData.question.question_class,
      //   label: questionData.question.question_class,
      // },
      // pastConnectionSubject: {
      //   value: questionData.question.question_subject,
      //   label: questionData.question.question_subject,
      // },
      // pastConnectionChapter: {
      //   value: questionData.question.question_chapter,
      //   label: questionData.question.question_chapter,
      // },
    };

    setDropDownValues(tempDropDownValues);

    setDropDownOptions((prev) => {
      return {
        ...prev,
        topics: questionData?.topics,
        chapter: questionData?.chapters,
      };
    });
  };

  useEffect(() => {
    // fetching classes
    // fetchClasses();
    if (questionData) {
      processData();
    }

    // eslint-disable-next-line
  }, [questionData]);

  return (
    <div className={styles.container}>
      <div className="row m-0 p-0 my-1 gap-1 gap-md-0">
        <div className="col-lg-3 col-12 col-md-6">
          <SelectInputField
            field={"chapter"}
            heading={"Chapter"}
            placeholder={"Select Chapter"}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
          />
        </div>
        <div className="col-lg-3 col-12 col-md-6">
          <SelectInputField
            field={"topics"}
            heading={"Topics"}
            placeholder={"Select Topics"}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
            isMulti={true}
          />
        </div>
        <div className="col-lg-2 col-12 col-md-6">
          <SelectInputField
            field={"questionType"}
            heading={"Question Type"}
            placeholder={"Select Type"}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
          />
        </div>
        <div className="col-lg-2 col-12 col-md-6">
          <SelectInputField
            field={"difficultyLevel"}
            heading={"Difficulty Level"}
            placeholder={"Select ..."}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
          />
        </div>
        <div className="col-lg-2 col-12 col-md-6">
          <SelectInputField
            field={"isVerified"}
            heading={"Is Verified"}
            placeholder={"Is Verified ?"}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
          />
        </div>
      </div>
      <div className="row m-0 p-0 my-1 gap-1 gap-md-0">
        <div className="col-lg-3 col-12 col-md-6">
          <SelectInputField
            field={"PYQ"}
            heading={"PYQ"}
            placeholder={"Select PYQ"}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
          />
        </div>
        <div className="col-lg-3 col-12 col-md-6">
          <SelectInputField
            field={"PYQDate"}
            heading={"PYQ Date"}
            placeholder={"Select PYQ Date"}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
          />
        </div>
        <div className="col-lg-3 col-12 col-md-6">
          <SelectInputField
            field={"PYQMonth"}
            heading={"PYQ Month"}
            placeholder={"Select PYQ Month"}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
          />
        </div>
        <div className="col-lg-3 col-12 col-md-6">
          <SelectInputField
            field={"PYQShift"}
            heading={"PYQ Shift"}
            placeholder={"Select PYQ Shift"}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
          />
        </div>
      </div>
      <div className="row m-0 p-0 my-1 gap-1 gap-md-0">
        {/* <div className="col-lg-3 col-12 col-md-6">
          <SelectInputField
            field={"pastConnectionClass"}
            heading={"Past Connection Class"}
            placeholder={"Select Connection Class"}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
          />
        </div>
        <div className="col-lg-3 col-12 col-md-6">
          <SelectInputField
            field={"pastConnectionSubject"}
            heading={"Past Connection Subject"}
            placeholder={"Select Connection Subject"}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
          />
        </div>
        <div className="col-lg-3 col-12 col-md-6">
          <SelectInputField
            field={"pastConnectionChapter"}
            heading={"Past Connection Chapter"}
            placeholder={"Select Connection Chapter"}
            dropDownValues={dropDownValues}
            dropDownoptions={dropDownoptions}
            handleInputChange={handleInputChange}
          />
        </div> */}
        <div className="col-lg-3 col-12 col-md-6 align-self-end mt-2">
          <div className={styles.buttonContainer}>
            {/* <div
              className={styles.cancelButton}
              onClick={() => {
                setShowEditBar(false);
              }}
            >
              Cancel
            </div> */}
            <button
              className={styles.cancelButton}
              onClick={handleCancel}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </button>
            <button
              className={styles.updateQuestionButton}
              onClick={handleUpdate}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionEditBar;
