import React from "react";
import { useSelector } from "react-redux";

const StudentCard = ({ analyticsData }) => {
  const userState = useSelector((state) => state.userData.userData);

  const convertTime = (timeString) => {
    if (!timeString) return "0 hr 0 min 0 sec";

    const [hours, minutes, seconds] = timeString?.split(":")?.map(Number);
    return `${hours} hr ${minutes} min ${seconds} sec`;
  };

  return (
    <div className="bg-white rounded-xl mx-auto p-5 border border-gray-200 w-full max-w-5xl">
      {/* Top Section: Profile & Badge */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          {/* Profile Image */}
          <img
            src="./images/profile-img.png" // Replace with actual image
            alt="Student"
            className="w-14 h-14 rounded-full"
          />

          {/* Name & ID */}
          <div>
            <div className="flex items-center justify-center gap-2">
              <h2 className="text-lg font-semibold m-0 font-metropolis">
                {analyticsData?.student_name}
              </h2>
              <span className="bg-primaryOrange text-white text-xs px-2 py-[2px] rounded-md font-semibold font-metropolis">
                Rank {analyticsData?.student_rank}
              </span>
            </div>
            <p className="text-gray-500 text-sm font-metropolis">
              Student ID: {userState?.userId}
            </p>
          </div>
        </div>
      </div>

      {/* Bottom Section: Subject & Arrow */}
      <div className="bg-gray-100 rounded-lg mt-4 p-4 flex justify-between items-center">
        <div>
          <h3 className="font-bold text-sm font-metropolis">
            {analyticsData?.test_title}
          </h3>
          <p className="text-gray-500 text-xs font-metropolis">
            Class {analyticsData?.test_class || "-"} | Subject:{" "}
            {analyticsData?.test_subjects?.join(", ") || "-"}| Time:{" "}
            {analyticsData?.test_duration}
          </p>
        </div>

        {/* Arrow Button */}
        {/* <div className="bg-orange-500 w-10 h-10 flex items-center justify-center rounded-full">
          <span className="text-white text-lg">➜</span>
        </div> */}
      </div>
    </div>
  );
};

export default StudentCard;
