import * as React from "react";
import { useNavigate } from "react-router-dom";
import { teacherTestDetail } from "../../../Store/API/API";
import { useDispatch } from "react-redux";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import { FaChartBar, FaClipboardList } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { LiaPasteSolid } from "react-icons/lia";
import { BiDetail } from "react-icons/bi";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
export default function MenuBar({
  takeTest,
  viewEvaluation,
  viewAnalysis,
  viewDetails,
  awaitingEvaluation,
  navigateParam,
  passedData,
  analysisURL,
  attemptId,
  studentId,
  groupId,
}) {
  const hostname = window.location.hostname;
  const school = hostname.split(".")[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = async (navParam, passedData) => {
    if (navParam === "startTest") {
      navigate("/startTest", { state: { quizId: passedData } });
    } else if (navParam === "ViewTestSubmission") {
      navigate("/ViewTestSubmission", {
        state: { quizId: passedData, attemptUserId: studentId || "" },
      });
    } else if (navParam === "teacher-test-view") {
      const data = await teacherTestDetail(passedData, dispatch);
      if (data) {
        navigate("/teacher-test-view", {
          state: { testId: passedData, testDetails: data },
        });
      } else {
        dispatch(
          applicationActions.setAlertData({
            isAlert: true,
            alertMessage: "Something went wrong",
            alertType: "error",
          })
        );
        setTimeout(() => {
          dispatch(
            applicationActions.setAlertData({
              isAlert: false,
              alertMessage: "",
              alertType: "",
            })
          );
        }, 3000);
      }
    } else if (navParam === "ViewAnalysis") {
      // navigate("/view-analysis", { state: { framUrl: passedData } });
      navigate("/analysis", {
        state: {
          quizId: passedData,
          student_id: studentId || "",
          attemptId,
          groupId: groupId || "",
        },
      });
    }
  };

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "#f36421",
            color: "white",
            fontSize: "12px",
            padding: "8px 10px",
          },
          arrow: {
            color: "#f36421",
          },
        },
      },
    },
  });

  return (
    <div className="flex justify-center items-center gap-4">
      <ThemeProvider theme={theme}>
        {takeTest && (
          <Tooltip title={"Attempt Test"} placement="bottom" arrow>
            <div
              onClick={() => handleNavigate("startTest", passedData)}
              className=" p-3 text-primaryOrange border-2 border-[#E7EDF6] hover:border-primaryOrange rounded-full cursor-pointer"
            >
              {/* <IoEyeOutline className="w-6 h-6"/> */}
              <LiaPasteSolid className="w-6 h-6" />
            </div>
          </Tooltip>
        )}
        {school !== "dpsg" && viewEvaluation && (
          <Tooltip title={"View Evaluation"} placement="bottom" arrow>
            <div
              onClick={() => handleNavigate("ViewTestSubmission", passedData)}
              className=" p-3 text-primaryOrange border-2 border-[#E7EDF6] hover:border-primaryOrange rounded-full cursor-pointer"
            >
              <IoEyeOutline className="w-6 h-6" />
            </div>
          </Tooltip>
        )}
        {school !== "dpsg" && viewAnalysis && (
          <Tooltip title={"View Analysis"} placement="bottom" arrow>
            <div
              onClick={() => handleNavigate("ViewAnalysis", passedData)}
              className=" p-3 text-primaryOrange border-2 border-[#E7EDF6] hover:border-primaryOrange rounded-full cursor-pointer"
            >
              <FaChartBar className="w-6 h-6" />
            </div>
          </Tooltip>
        )}
        {viewDetails && (
          <Tooltip title={"View Details"} placement="bottom" arrow>
            <div
              onClick={() => handleNavigate("teacher-test-view", passedData)}
              className=" p-3 text-primaryOrange border-2 border-[#E7EDF6] hover:border-primaryOrange rounded-full cursor-pointer"
            >
              <BiDetail className="w-6 h-6" />
            </div>
          </Tooltip>
        )}
        {awaitingEvaluation && (
          <Tooltip title={"Awaiting Evaluation"} placement="bottom" arrow>
            <div className=" p-3 text-primaryOrange border-2 border-[#E7EDF6] hover:border-primaryOrange rounded-full cursor-pointer">
              <FaClipboardList className="w-6 h-6" />
            </div>
          </Tooltip>
        )}
      </ThemeProvider>
    </div>
  );
}
