import React, { useEffect, useState } from "react";
import classes from "./TestList.module.css";
import TestCards from "../../UserInterface/TestList/TestCards";
import { getTestList } from "../../../Store/API/API";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import CardView from "../Session/CardView";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Tooltip } from "@mui/material";
import HeaderComponent from "../Navbar/Header/HeaderComponent";

const TestList = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const navigate = useNavigate();
  const [testList, setTestList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState(1);

  const path = window.location.search;
  const isDpp = new URLSearchParams(path).get("dpp");

  const fetchData = async () => {
    if (userState.token) {
      const data = await getTestList(
        userState.userId,
        batchState.groupId,
        userState.token,
        dispatch,
        batchState.batchUserType,
        isDpp,
        tab
      );
      setTestList(data);
      setIsLoading(false);
      // console.log(data, "data");
      
      // console.log([0].quiz_id, "quix id");
    }
  };

  useEffect(() => {
    fetchData();
  }, [userState.token, isDpp, tab]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const testData = (item) => (
    <>
      <div>
        <strong>Titel:</strong>
        <span>{item.title}</span>
      </div>
      <div>
        <strong>Publish Date:</strong>
        <span>To {item.due_date}</span>
      </div>
      <div>
        <strong>Subject:</strong>
        <span>{item.test_subjects}</span>
      </div>
      <div>
        <strong>Chapter:</strong>
        <span>
          {item.test_chapters?.map((chapter, index) => {
            return (
              <span key={index} className={classes.para}>
                {chapter},
              </span>
            );
          })}
        </span>
      </div>
      <div>
        <strong>Test Mode:</strong>
        <span>{item.test_mode}</span>
      </div>
      {/* <div>
        <strong>Rank:</strong> <span>{item.rank}</span>
      </div> */}
      <div className=" flex h-10">
        <strong style={{ marginRight: "10px" }}>Action: </strong>
        <span>
          {item?.take_test === "1" && (
            <div className="mt-2">
              <span
                onClick={() => {
                  navigate("/startTest", { state: { quizId: item.quiz_id } });
                }}
                className="text-sm text-primaryOrange p-2 text-center font-semibold border border-primaryOrange rounded-xl"
              >
                Take Test
              </span>
            </div>
          )}
          {item?.take_test === "3" && (
            <div className="mt-2">
              <span
                className="text-sm text-primaryOrange p-2 text-center font-semibold border border-primaryOrange rounded-xl"
                onClick={() => {
                  navigate("/ViewTestSubmission", {
                    state: { quizId: item.quiz_id },
                  });
                }}
              >
                Evaluation Analytics
              </span>
            </div>
          )}
          {item?.take_test === "4" && (
            <div className="mt-2">
              <span className="text-sm text-primaryOrange p-2 text-center font-semibold border-2 border-primaryOrange rounded-xl">
                Awaiting Evaluation
              </span>
            </div>
          )}
        </span>
      </div>
    </>
  );

  return (
    <div className="p-6 mt-16 mb-3">
      <p className="text-[20px] md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl font-bold text-left m-0 mb-2">
        Test List
      </p>
      {userState?.userType === "student" && (
        <HeaderComponent>
          <div className={`row align-items-end gap-y-2 justify-end `}>
            <div className={"col-xl-2 col-lg-3 col-md-6 mt-md-0 mt-2"}>
              <div
                onClick={() => setTab(1)}
                className={`rounded-xl py-2 text-base font-medium cursor-pointer justify-center flex items-center self-end truncate ${
                  tab === 1
                    ? "bg-primaryBlue text-textWhite"
                    : "bg-primaryBlueDisabled text-white "
                }`}
              >
                <p>Upcoming</p>
              </div>
            </div>
            <div className={"col-xl-2 col-lg-3 col-md-6 mt-md-0 mt-2"}>
              <div
                onClick={() => setTab(2)}
                className={`rounded-xl py-2 text-base font-medium cursor-pointer justify-center flex items-center self-end truncate ${
                  tab === 2
                    ? "bg-secondarySkyblue text-textWhite"
                    : "bg-secondarySkyblueDisabled text-white "
                }`}
              >
                <p>Attempted</p>
              </div>
            </div>
            <div className={"col-xl-2 col-lg-3 col-md-6 mt-md-0 mt-2"}>
              <div
                onClick={() => setTab(3)}
                className={`rounded-xl py-2 text-base font-medium cursor-pointer justify-center flex items-center self-end truncate ${
                  tab === 3
                    ? "bg-secondaryRed text-textWhite"
                    : "bg-secondaryRedDisabled text-white "
                }`}
              >
                <p> Not Attempted</p>
              </div>
            </div>
          </div>
        </HeaderComponent>
      )}
      <div
        className="overflow-y-auto max-h-[80vh] hidden sm:block"
        style={{
          paddingBottom: "20px",
          marginTop: "15px",
        }}
      >
        <table className="w-full border-separate border-spacing-y-3">
          <thead className="sticky top-0 bg-backgroundBlue z-10">
            <tr>
              <th className="py-3 text-center rounded-l-xl w-[10%]">Title</th>
              <th className="py-3 text-center  w-[20%]">Publish Date</th>
              <th className="py-3 text-center w-[10%]">Subject</th>
              <th className="py-3 text-center  w-[10%]">Chapter</th>
              <th className="py-3 text-center  w-[8%]">Test Mode</th>
              {/* <th className="p-3 text-center w-[18%]">Rank</th> */}
              <th className="py-3 text-center rounded-r-xl w-[8%]">Actions</th>
            </tr>
          </thead>
          <tbody className="overflow-y-auto">
            {testList?.length === 0 && (
              <tr>
                <td
                  colSpan="6"
                  className="py-2 px-6 text-center text-primaryOrange font-bold"
                >
                  No Test Found
                </td>
              </tr>
            )}

            {testList?.map((test, index) => (
              <tr
                className="border-b border-gray-200 hover:bg-gray-100"
                key={index}
              >
                <td className="p-3 text-center border-t border-b border-backgroundBlue">
                  {test.title || "-"}
                </td>
                <td className="p-3 text-center border-t border-b border-backgroundBlue">
                  <div>{test?.assign_date || "-"}</div>
                  <span>-</span>
                  <div>{test?.due_date || "-"}</div>
                </td>
                <td className="p-3 text-center border-t border-b border-backgroundBlue">
                  {test.test_subjects?.map((sub, index) => {
                    return (
                      <p
                        key={index}
                        className={classes.para}
                        style={{ margin: 0 }}
                      >
                        {sub}
                      </p>
                    );
                  })}
                </td>
                <td className="p-3 text-center border-t border-b border-backgroundBlue">
                  <Tooltip
                    title={
                      test?.test_chapters
                        ? test?.test_chapters?.join(", ")
                        : " "
                    }
                    placement="right"
                    arrow
                  >
                    <p
                      className={classes.para}
                      style={{ margin: 0, cursor: "pointer" }}
                    >
                      {test?.test_chapters
                        ? test?.test_chapters?.join(", ").length > 50
                          ? test.test_chapters?.join(", ").slice(0, 50) + "..."
                          : test.test_chapters?.join(", ")
                        : "-"}
                    </p>
                  </Tooltip>
                </td>
                <td className="p-3 text-center border-t border-b border-backgroundBlue">
                  {test.test_mode || "-"}
                </td>
                {/* <td className="p-3 text-center border-t border-b border-backgroundBlue">{test.rank || "-"}</td> */}
                <td className="p-3 text-center border-t border-b border-backgroundBlue">
                  <TestCards
                    key={index}
                    type={test.type}
                    title={test.title}
                    Actions={test.Actions}
                    // Rank={test.rank}
                    TestMode={test.test_mode}
                    chapters={test.test_chapters}
                    publishFrom={test.assign_date}
                    publishTo={test.due_date}
                    subject={test.test_subjects}
                    quizId={test.quiz_id}
                    token={test.token}
                    takeTest={test?.take_test}
                    analyticURL={test.analytic_url}
                    attemptId={test?.attempt_id}
                    
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.cardview}>
        <CardView
          data={testList}
          renderContent={testData}
          noDataMessage={"No Tests Available"}
        />
      </div>
    </div>
  );
};
export default TestList;
