// import React, { useEffect, useState } from "react";  removed
import React from "react";
import classes from "./InfoArea1.module.css";
import CustomTimer from "../../UserInterface/CustomTimer/CustomTimer";
// import { useDispatch, useSelector } from "react-redux";  removed
import { useSelector } from "react-redux";
// import { answerActions } from "../../../Store/Data/Slices/AnswerSlice";   removed

const InfoArea = ({
  questionSet,
  currentIndex,
  updateCurrentIndex,
  unAnswered,
  answered,
  markedForReview,
  notVisited,
  parentFunction,
  time,
  quizId,
  testDetails,
  setAutoTestSubmitting,
  onlineStatus,
}) => {
  const answerState = useSelector((state) => state.answerData.answer);
  const userState = useSelector((state) => state.userData.userData);
  // console.log(userState, "userState");
  // const dispatch = useDispatch();    removed
  // const [buttonState, setButtonState] = useState("Not Visited");

  // console.log("====================================");
  // console.log(testDetails, "testDetails");
  // console.log("====================================");

  // useEffect(() => {
  //   let currentQuestion = questionSet[currentIndex];
  //   let tempAnswerObject = answerState.find(
  //     (item) => item.questionId === currentQuestion?.question_id
  //   );
  //   if (tempAnswerObject) {
  //     setButtonState(tempAnswerObject.question_state);
  //   }
  // }, [answerState, currentIndex, questionSet]);
  const QuestionButton = ({
    questionNumber,
    questionState,
    questionPosition,
    questionId,
    currentIndex,
    presentIndex,
    updateCurrentIndex, // Add updateCurrentIndex prop
  }) => {
    let backgroundColor;

    // Find the answer object corresponding to the current questionId
    const currentAnswer = answerState.find(
      (item) => item.questionId === questionId
    );

    // Determine background color based on question state
    switch (currentAnswer?.question_state) {
      case "Not Visited":
        backgroundColor = "#D9D9D9";
        break;
      case "Answered":
        backgroundColor = "#6eea91";
        break;
      case "Marked for Review":
        backgroundColor = "#eab14f";
        break;
      case "Not Answered":
        backgroundColor = "#f25c5c";
        break;
      default:
        backgroundColor = "#D9D9D9"; // Default color for unknown state or when answer not found
    }

    return (
      <div
        className={classes.questionButton}
        style={{
          backgroundColor: backgroundColor,
          ...(currentIndex + 1 === presentIndex
            ? { border: "2px solid black", scale: "1.2" }
            : {}),
        }}
        onClick={() => {
          // console.log(questionPosition - 1, "questionPosition");
          // dispatch(
          //   answerActions.updateQuestionStateByPosition({
          //     position: questionPosition - 1,
          //   })
          // );
          updateCurrentIndex(presentIndex - 1, questionId);
        }} // Update current index onClick
      >
        <h6>{questionNumber}</h6>
      </div>
    );
  };
  const QuestionInfoContainer = ({ backgroundColor, questionText, number }) => {
    return (
      <div className={classes.questionInfo}>
        <div
          className={classes.questionInfoButton}
          style={{ backgroundColor: backgroundColor }}
        >
          <h5>{number}</h5>
        </div>
        <h6>{questionText}</h6>
      </div>
    );
  };

  return (
    <div>
      <div className={classes.userInfo}>
        <i className="fa-solid fa-circle-user fa-lg"></i>
        <h6>
          {userState.firstName + " "}
          {userState.lastName}
        </h6>
      </div>
      <div className={classes.instructionContainer}>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <h6>Start Time:</h6>
          <h6>{testDetails?.assign_date}</h6>
        </div>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <h6>End Time:</h6>
          <h6>{testDetails?.due_date}</h6>
        </div>
        <div className={classes.questionInfoContainer}>
          <QuestionInfoContainer
            backgroundColor={"#6eea91"}
            questionText={"Answered"}
            number={answered}
          />
          <QuestionInfoContainer
            backgroundColor={"#f25c5c"}
            questionText={"Not Answered"}
            number={unAnswered}
          />
          <QuestionInfoContainer
            backgroundColor={"#eab14f"}
            questionText={"Marked for Review"}
            number={markedForReview}
          />
          <QuestionInfoContainer
            backgroundColor={"#D9D9D9"}
            questionText={"Not Visited"}
            number={notVisited}
          />
        </div>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h5>Question Overview</h5>
          <CustomTimer
            expiryTimestamp={time}
            callFunction={true}
            parentFunction={parentFunction}
            quizId={quizId || userState.testId}
            setAutoTestSubmitting={setAutoTestSubmitting}
            onlineStatus={onlineStatus}
          />
        </div>

        <div className={classes.SectionDetail}>
          <h6>Subject : { questionSet && questionSet[currentIndex]?.section_subject}</h6>
        </div>
        <div className={classes.questionsTile}>
          {questionSet?.length > 0 &&
            questionSet?.map((question, index) => (
              <QuestionButton
                key={index}
                presentIndex={index + 1}
                questionNumber={question.question_order}
                questionState={question.questionState}
                currentIndex={currentIndex}
                questionPosition={question.question_order}
                questionId={question.question_id}
                updateCurrentIndex={updateCurrentIndex}
                // buttonState={buttonState}
                // answerState={answerState}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default InfoArea;
