import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import classes from "./Library.module.css";
import { getLibrary } from "../../../Store/API/API";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { useNavigate } from "react-router-dom";

const SectionCard = ({ data, isCourse }) => {
  const navigate = useNavigate();
  return (
    <div
      className={classes.sectionCard}
      onClick={() => {
        if (isCourse) {
          navigate("/course-details", {
            state: { course_id: data.course_id },
          });
        }
      }}
      style={{ cursor: isCourse ? "pointer" : "default" }}
    >
      <img
        alt="thumbnail"
        src={data?.cover_photo_url || "/images/sessionLogo.png"}
        className={classes.cardImage}
      />
      <div className={classes.contentContainer}>
        <p className={classes.cardHeading}>{data?.name}</p>
        <p>{data?.subject}</p>
        {/* <p>{data?.course_format}</p> */}
        <p className={classes.cardSubHeading}>By: Innovartan</p>
      </div>
    </div>
  );
};

const ModuleLibrary = () => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const [dashboardCourses, setDashboardCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await getLibrary(userState, dispatch);
      if (response && response.status) {
        setDashboardCourses(response.data);
      }
      setIsLoading(false);
    };

    fetchCourses();
  }, [userState, dispatch]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={classes.courseDashboard}>
      <div className={classes.section}>
        <div>
          <h2 className="text-[20px] md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl font-bold m-3 mb-0">
            Module Library
          </h2>
        </div>

        {dashboardCourses.length > 0 ? (
          <div className={classes.sectionCardContainer}>
            {dashboardCourses.map((course, index) => (
              <SectionCard key={index} data={course} isCourse={true} />
            ))}
          </div>
        ) : (
          <p>No courses available.</p>
        )}
      </div>
    </div>
  );
};

export default ModuleLibrary;
