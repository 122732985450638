import React, { useEffect, useState } from "react";
import styles from "./CommonSession.module.css";
import CommonSessionCard from "./CommonSessionCard";
import CreateModal from "./CreateModal";
// import Filter from "../../UI/Filter/Filter";
import CustomModal from "../../UI/CustomModal/CustomModal";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { getGroupSession } from "../../../Store/API/API";
// import Form from "react-bootstrap/Form";
import SelectInput from "../../UI/SelectInput/SelectInput";

const SelectInputField = ({
  heading,
  placeholder,
  field,
  type,
  dropDownoptions,
  handleInputChange,
  dropDownValues,
}) => {
  return (
    <div className={styles.selectWrapper}>
      <label className={styles.label}>
        {heading} <span className={styles.required}>*</span>
      </label>
      <div className={styles.dropDownWrapper}>
        <SelectInput
          width={"100%"}
          placeHolder={placeholder}
          options={dropDownoptions}
          field={field}
          handleInputChange={handleInputChange}
          value={dropDownValues}
          backgroundColor={"#1b3156"}
          border={"none"}
          dropdownTextColor={"#FFFFFF"}
        />
      </div>
    </div>
  );
};

const Upcomming2 = () => {
  // const [filterClassOptions, setFilterClassOptions] = useState(null);
  // const [filterChapterOptions, setFilterChapterOptions] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [creatingSession, setCreatingSession] = useState(false);
  //eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [historySessions, setHistorySessions] = useState([]);
  const [cancelledSessions, setCancelledSessions] = useState([]);
  const [displaySessionsData, setDisplaySessionsData] = useState([]);
  const [activeSession, setActiveSession] = useState("Upcoming");
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const dispatch = useDispatch();

  const [dropDownValues, setDropDownValues] = useState({
    sessionType: { value: activeSession, label: activeSession },
    filterClass: "",
    filterSubject: "",
    filterChapter: "",
  });
  const [dropDownoptions, setDropDownOptions] = useState({
    sessionType: [
      { value: "Upcoming", label: "Upcoming" },
      { value: "History", label: "History" },
      { value: "Cancelled", label: "Cancelled" },
    ],
    filterClass: [],
    filterSubject: [],
    filterChapter: [],
  });

  // const SessionButton = ({ name, isActive, parentFunction }) => {
  //   return (
  //     <div
  //       className={
  //         isActive ? styles.upcommingButtonActive : styles.upcommingButton
  //       }
  //       onClick={parentFunction}
  //     >
  //       <p
  //         className={
  //           isActive ? styles.sessionbuttonTextActive : styles.sessionbuttonText
  //         }
  //       >
  //         {name}
  //       </p>
  //     </div>
  //   );
  // };

  // const filterOptions = [
  //   {
  //     type: "select",
  //     placeholder: "Select Subject",
  //     options: [
  //       { value: "Physics", label: "Physics" },
  //       { value: "Chemistry", label: "Chemistry" },
  //       { value: "Maths", label: "Maths" },
  //       { value: "Biology", label: "Biology" },
  //     ],
  //     onChange: setSelectedSubject,
  //     selectedValue: selectedSubject,
  //   },
  //   {
  //     type: "select",
  //     placeholder: "Select Class",
  //     options: [
  //       { value: "CLASS IX", label: "Class IX" },
  //       { value: "CLASS X", label: "Class X" },
  //       { value: "CLASS XI", label: "Class XI" },
  //       { value: "CLASS XII", label: "Class XII" },
  //     ],
  //     onChange: setSelectedClass,
  //     selectedValue: selectedClass,
  //   },
  // ];

  const fetchSessions = async ({ sessionType }) => {
    // console.log("Session Type: ", sessionType);

    // setSelectedClass("");
    // setSelectedSubject("");

    if (sessionType === "upcomingButton") {
      setActiveSession("Upcoming");
      setDisplaySessionsData(upcomingSessions);
      return;
    } else if (sessionType === "past") {
      setActiveSession("History");
      if (historySessions.length > 0) {
        setDisplaySessionsData(historySessions);
        return;
      }
    } else if (sessionType === "cancel") {
      setActiveSession("Cancelled");
      if (cancelledSessions.length > 0) {
        setDisplaySessionsData(cancelledSessions);
        return;
      }
    }

    setIsLoading(true);

    const data = await getGroupSession(
      userState,
      batchState?.groupId,
      sessionType,
      true,
      dispatch
    );

    console.log("Data: ", data);

    if (data) {
      // console.log(data);
      if (sessionType === "upcoming") {
        setUpcomingSessions(data);
        setDisplaySessionsData(data);
      }
      if (sessionType === "past") {
        setHistorySessions(data);
        setDisplaySessionsData(data);

        const classOption = [
          ...new Map(
            data?.map((session) => [
              session?.session_class || "NA",
              {
                value: session?.session_class || "NA",
                label: session?.session_class || "NA",
              },
            ])
          ).values(),
        ];

        const chapterOptions = [
          ...new Map(
            data?.map((session) => [
              session?.session_chapter || "NA",
              {
                value: session?.session_chapter || "NA",
                label: session?.session_chapter || "NA",
              },
            ])
          ).values(),
        ];

        const subjectOptions = [
          ...new Map(
            data?.map((session) => [
              session?.session_subject || "NA",
              {
                value: session?.session_subject || "NA",
                label: session?.session_subject || "NA",
              },
            ])
          ).values(),
        ];

        setDropDownOptions((prev) => ({
          ...prev,
          filterClass: classOption,
          filterSubject: subjectOptions,
          filterChapter: chapterOptions,
        }));

        // console.log("Class Option: ", classOption);
      }
      if (sessionType === "cancel") {
        setCancelledSessions(data);
        setDisplaySessionsData(data);
      }
      setIsLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    // console.log(field, value);

    setDropDownValues((prev) => {
      const updatedValues = { ...prev, [field]: value };

      if (field === "sessionType") {
        fetchSessions({
          sessionType:
            value.value === "Upcoming"
              ? "upcomingButton"
              : value.value === "History"
              ? "past"
              : "cancel",
        });
        return updatedValues;
      }

      if (
        field === "filterClass" ||
        field === "filterChapter" ||
        field === "filterSubject"
      ) {
        const filterData = historySessions.filter((session) => {
          const matchsClass = updatedValues.filterClass?.value
            ? session.session_class === updatedValues.filterClass.value
            : true;

          const matchsChapter = updatedValues.filterChapter?.value
            ? session.session_chapter === updatedValues.filterChapter.value
            : true;

          const matchsSubject = updatedValues.filterSubject?.value
            ? session.session_subject === updatedValues.filterSubject.value
            : true;

          return matchsClass && matchsChapter && matchsSubject;
        });

        setDisplaySessionsData(filterData);
      }

      return updatedValues;
    });
  };

  const handleUpdateSession = async (
    type,
    sessionId,
    url,
    uploadType,
    data
  ) => {
    console.log(type, sessionId, url, uploadType, data);

    if (type === "cancelled") {
      // console.log("Cancelled Session ID: ", sessionId);

      const tempData = displaySessionsData.filter(
        (session) => session.sessionId !== sessionId
      );
      const tempData2 = upcomingSessions.filter(
        (session) => session.sessionId !== sessionId
      );
      setDisplaySessionsData(tempData);
      setUpcomingSessions(tempData2);
      setCancelledSessions([]);
      return;
    }

    if (type === "upcoming") {
      fetchSessions({ sessionType: "upcoming" });
      return;
    }

    if (type === "history") {
      console.log("History Session ID: ", sessionId, url, uploadType);

      const updatedHistory = historySessions.map((session) => {
        if (session.sessionId === sessionId) {
          return {
            ...session,
            ...(uploadType === "notes"
              ? { notes: url }
              : { recordingUrl: url, recordingUrlmp4: url }),
          };
        }
        return session;
      });
      const updatedDisplayData = displaySessionsData.map((session) => {
        if (session.sessionId === sessionId) {
          return {
            ...session,
            ...(uploadType === "notes"
              ? { notes: url }
              : { recordingUrl: url, recordingUrlmp4: url }),
          };
        }
        return session;
      });

      console.log("updatedDisplayData: ", updatedDisplayData);
      setHistorySessions(updatedHistory);
      setDisplaySessionsData(updatedDisplayData);
      return;
    }

    if (type === "topicChanged") {
      // console.log("Topic Changed", data, sessionId);

      const updateHistory = historySessions.map((session) => {
        if (session.sessionId === sessionId) {
          return {
            ...session,
            session_topic: data?.selectedTopicLabel,
            session_chapter: data?.selectedChapterLabel,
          };
        }
        return session;
      });

      const updateDisplayData = displaySessionsData.map((session) => {
        if (session.sessionId === sessionId) {
          return {
            ...session,
            session_topic: data?.selectedTopicLabel,
            session_chapter: data?.selectedChapterLabel,
          };
        }
        return session;
      });

      setHistorySessions(updateHistory);
      setDisplaySessionsData(updateDisplayData);
      return;
    }

    if (type === "curriculumUpdate") {
      const updateHistory = historySessions.map((session) => {
        if (session.sessionId === sessionId) {
          return {
            ...session,
            curricular_percent: data?.progress,
            live_dpp: data?.dppLive,
            live_video: data?.videoLive,
          };
        }
        return session;
      });

      const updateDisplayData = displaySessionsData.map((session) => {
        if (session.sessionId === sessionId) {
          return {
            ...session,
            curricular_percent: data?.progress,
            live_dpp: data?.dppLive === true ? "1" : "0",
            live_video: data?.videoLive === true ? "1" : "0",
          };
        }
        return session;
      });

      // console.log("updateHistory: ", updateHistory);
      // console.log("updateDisplayData: ", updateDisplayData);

      setHistorySessions(updateHistory);
      setDisplaySessionsData(updateDisplayData);
    }

    // fetchSessions({ sessionType: activeSession });
  };

  useEffect(() => {
    // Fetching Upcomming Session
    fetchSessions({ sessionType: "upcoming" });

    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      {creatingSession && <Spinner />}
      <div className={styles.headerWrapper}>
        <div className={styles.headerContainer}>
          <p className={styles.heading}>Live Session</p>
        </div>
        <div className={styles.dropDownContainer}>
          <div
            className={`row align-items-end gap-y-2 ${
              activeSession !== "History" ? "justify-between" : ""
            }`}
          >
            <div className="col-lg-3 col-md-6 ">
              <SelectInputField
                field={"sessionType"}
                heading={"Session Type"}
                placeholder={"Select Date"}
                dropDownValues={dropDownValues}
                dropDownoptions={dropDownoptions}
                handleInputChange={handleInputChange}
              />
            </div>
            {activeSession === "History" && (
              <div className="col-xl-2 col-lg-3 col-md-6 ">
                <SelectInputField
                  field={"filterClass"}
                  heading={"Filter By Class"}
                  placeholder={"Select Session"}
                  dropDownValues={dropDownValues}
                  dropDownoptions={dropDownoptions}
                  handleInputChange={handleInputChange}
                />
              </div>
            )}
            {activeSession === "History" && (
              <div className="col-xl-2 col-lg-3 col-md-6 ">
                <SelectInputField
                  field={"filterSubject"}
                  heading={"Filter By Subject"}
                  placeholder={"Select Session"}
                  dropDownValues={dropDownValues}
                  dropDownoptions={dropDownoptions}
                  handleInputChange={handleInputChange}
                />
              </div>
            )}
            {activeSession === "History" && (
              <div className="col-xl-2 col-lg-3 col-md-6 ">
                <SelectInputField
                  field={"filterChapter"}
                  heading={"Filter By Chapter"}
                  placeholder={"Select status"}
                  dropDownValues={dropDownValues}
                  dropDownoptions={dropDownoptions}
                  handleInputChange={handleInputChange}
                />
              </div>
            )}
            {batchState?.batchUserType !== "student" &&
              activeSession !== "Cancelled" && (
                <div
                  className={
                    activeSession === "Upcoming"
                      ? " col-lg-3 col-md-6 mt-md-0 mt-2"
                      : "col-xl-3 col-lg-12 col-md-0 col-12 mt-2 mt-xl-0"
                  }
                >
                  <div style={{ alignSelf: "flex-end" }}>
                    <div
                      className={styles.markButton}
                      onClick={() => {
                        if (activeSession === "History") {
                          setDropDownValues((prev) => ({
                            ...prev,
                            filterClass: "",
                            filterChapter: "",
                            filterSubject: "",
                          }));
                          setDisplaySessionsData(historySessions);
                          return;
                        }
                        setOpenCreateModal(true);
                      }}
                    >
                      <p>
                        {activeSession === "History"
                          ? "Clear Filter"
                          : "Create Session"}
                      </p>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      <div className={styles.bodyWrapper}>
        <div className={styles.cardsContainer}>
          {displaySessionsData.length > 0 ? (
            <div className={styles.gridContainer}>
              {displaySessionsData?.map((session, index) => {
                return (
                  <CommonSessionCard
                    key={index}
                    session={session}
                    sessionType={activeSession}
                    handleUpdateSession={handleUpdateSession}
                    setCreatingSession={setCreatingSession}
                  />
                );
              })}
            </div>
          ) : (
            <div className={styles.noSessionContainer}>
              <p className={styles.noSessionText}>No Sessions Found</p>
            </div>
          )}
        </div>
      </div>

      <CustomModal
        isOpen={openCreateModal}
        onClose={() => setOpenCreateModal(!openCreateModal)}
      >
        <CreateModal
          onClose={() => setOpenCreateModal(!openCreateModal)}
          setCreatingSession={setCreatingSession}
          handleUpdateSession={handleUpdateSession}
          // creatingSession={setCreatingSession}
        />
      </CustomModal>
    </div>
  );
};

export default Upcomming2;
