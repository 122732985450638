import React, { useEffect, useState } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { getQuestionBank } from "../../../../Store/API/API";
import { useDispatch } from "react-redux";
import Spinner from "../../../UserInterface/Spinner/Spinner";
import ImportQuestionModal from "../../../UI/QuestionBank/ImportQuestionModal";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../UI/CustomModal/CustomModal";
import CreateQuestionBankModal from "../../../UI/QuestionBank/CreateQuestionBankModal";

const Questionbank = () => {
  const dispatch = useDispatch();
  const [questionBank, setQuestionBank] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isCreateQuestionBankModalOpen, setIsCreateQuestionBankModalOpen] =
    useState(false);
  const [calculateImportModalvalues, setCalculateImportModalValues] =
    useState(false);

  const [selectedQuestionBankId, setSelectedQuestionBankId] = useState();

  const navigate = useNavigate();

  const handleImportModalClose = (callingSource) => {
    setIsImportModalOpen(false);
    if (callingSource === "fileUploaded") {
      fetchQuestionBank();
    }
  };

  const fetchQuestionBank = async () => {
    setIsLoading(true);
    const data = await getQuestionBank(dispatch);
    if (data) {
      setQuestionBank(data.data);
    }
    setIsLoading(false);
  };

  const handleEditQuestionBank = (questionBankId) => {
    setSelectedQuestionBankId(questionBankId);
    setTimeout(() => setIsCreateQuestionBankModalOpen(true), 100);
  };

  const handleCreateQuestionBankModal = (type) => {
    setIsCreateQuestionBankModalOpen(false);

    if (type === "create") {
      fetchQuestionBank();
    }
  };

  useEffect(() => {
    fetchQuestionBank();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isCreateQuestionBankModalOpen) {
      setSelectedQuestionBankId(null);
    }
  },[isCreateQuestionBankModalOpen]);

  return (
    <div className="p-6 mt-[50px] mb-3 ">
      <p className={"text-2xl font-bold leading-7 text-left m-0 mb-2"}>
        Question Bank
      </p>
      <div className={"bg-[#021a43] mb-1 p-4 rounded-xl"}>
        <div className={`row align-items-end gap-y-2 justify-end `}>
          <div className={"col-xl-2 col-lg-3 col-md-6 mt-md-0 mt-2"}>
            <div
              className={
                "bg-[#f36421] hover:bg-primaryOrangeDisabled  text-white rounded-xl py-2 text-base font-medium cursor-pointer justify-center flex items-center self-end truncate "
              }
              onClick={() => {
                setSelectedQuestionBankId(null);
                setTimeout(() => setIsCreateQuestionBankModalOpen(true), 100);
              }}
            >
              <p>Create Question Bank</p>
            </div>
          </div>
          <div className={"col-xl-2 col-lg-3 col-md-6 mt-md-0 mt-2"}>
            <div
              className={
                "bg-[#f36421] hover:bg-primaryOrangeDisabled text-white rounded-xl py-2 text-base font-medium cursor-pointer justify-center flex items-center self-end"
              }
              onClick={() => {
                setCalculateImportModalValues(true);
                setIsImportModalOpen(true);
              }}
            >
              <p>Import Questions</p>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Spinner spinnerHeight={"50%"} />
      ) : (
        <div>
          <div className="hidden lg:block overflow-x-auto">
            <div className="relative w-full">
              <table className="w-full border-separate border-spacing-y-3">
                <thead className="sticky top-0 bg-gray-100 z-10">
                  <tr className="">
                    <th className="py-3 text-center rounded-l-lg w-[8%]">
                      Subject
                    </th>
                    <th className="py-3 text-center w-[8%]">Class</th>
                    {/* <th className="py-3 text-center w-[10%]">Chapter Name</th> */}
                    <th className="p-3 text-center w-[20%]">Title</th>
                    <th className="p-3 text-center w-[12%]">Created On</th>
                    <th className="p-3 text-center w-[12%]">Total Question</th>
                    {/* <th className="p-3 text-center w-[8%]">Status</th> */}
                    <th className="p-3 text-center rounded-r-lg w-[12%]">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="overflow-y-auto">
                  {questionBank?.map((session, index) => (
                    <tr key={index} className={`${session.bgColor}`}>
                      <td className="py-3 text-center rounded-l-lg border-l border-t border-b border-backgroundBlue">
                        {session.subject}
                      </td>
                      <td className="p-3 text-center border-t border-b border-backgroundBlue">
                        {session.class}
                      </td>
                      {/* <td className="p-3 text-center border-t border-b border-backgroundBlue">
                    {session.chapter}
                  </td> */}
                      <td className="p-3 text-center border-t border-b border-backgroundBlue">
                        {session.title}
                      </td>
                      <td className="p-3 text-center border-t border-b whitespace-nowrap  border-backgroundBlue">
                        {session.created_date}
                      </td>
                      <td className="p-3 text-center border-t border-b border-backgroundBlue">
                        {session.question_count}
                      </td>
                      {/* <td className="p-3 text-center border-t border-b  border-backgroundBlue">
                    <span
                      className={`inline-block w-[100px] uppercase text-xs px-2 py-1 rounded-full text-white ${session.statusColor}`}
                    >
                      {session.status}
                    </span>
                  </td> */}
                      <td className="p-3 text-center overflow-hidden rounded-r-lg border-t border-b border-r border-backgroundBlue">
                        <div className="flex justify-center gap-2">
                          <div
                            className={`px-2 py-1 border rounded-lg text-white bg-primaryOrange hover:bg-primaryOrangeDisabled cursor-pointer `}
                          >
                            <VisibilityOutlinedIcon
                              fontSize="small"
                              onClick={() => {
                                console.log(session.question_bank_id);
                                navigate("/question-bank/view", {
                                  state: {
                                    questionBankId: session.question_bank_id,
                                  },
                                });
                              }}
                            />
                          </div>
                          <div
                            className={`px-2 py-1 border rounded-lg text-white bg-primaryOrange hover:bg-primaryOrangeDisabled cursor-pointer`}
                          >
                            <EditNoteOutlinedIcon
                              fontSize="small"
                              onClick={() => {
                                handleEditQuestionBank(
                                  session?.question_bank_id
                                );
                              }}
                            />
                          </div>
                          <div
                            className={`px-2 py-1 border rounded-lg text-white bg-primaryOrange hover:bg-primaryOrangeDisabled cursor-pointer`}
                          >
                            <DeleteForeverOutlinedIcon fontSize="small" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {questionBank?.map((session, index) => (
            <div
              key={index}
              className="block lg:hidden bg-white p-4 rounded-lg border-l-4 md:mb-5 shadow-md relative mt-3"
            >
              <p className="flex items-center gap-2">
                <strong>Subject:</strong> {session.subject}
              </p>
              <p className="flex items-center gap-2">
                <strong>Class:</strong> {session.class}
              </p>
              <p className="flex items-center gap-2">
                <strong>Title:</strong> {session.title}
              </p>
              <p className="flex items-center gap-2">
                <strong>Created On:</strong> {session.created_date}
              </p>
              <p className="flex items-center gap-2">
                <strong>Total Questions:</strong> {session.question_count}
              </p>
              <p className="flex items-center gap-2 absolute top-4 right-3">
                <div className="flex flex-col justify-center gap-2">
                  <div
                    className={`px-2 py-1 border rounded-lg text-white bg-primaryOrange hover:bg-primaryOrangeDisabled cursor-pointer `}
                  >
                    <VisibilityOutlinedIcon
                      fontSize="small"
                      onClick={() => {
                        console.log(session.question_bank_id);
                        navigate("/question-bank/view", {
                          state: {
                            questionBankId: session.question_bank_id,
                          },
                        });
                      }}
                    />
                  </div>
                  <div
                    className={`px-2 py-1 border rounded-lg text-white bg-primaryOrange hover:bg-primaryOrangeDisabled cursor-pointer`}
                  >
                    <EditNoteOutlinedIcon
                      fontSize="small"
                      onClick={() => {
                        handleEditQuestionBank(session?.question_bank_id);
                      }}
                    />
                  </div>
                  <div
                    className={`px-2 py-1 border rounded-lg text-white bg-primaryOrange hover:bg-primaryOrangeDisabled cursor-pointer`}
                  >
                    <DeleteForeverOutlinedIcon fontSize="small" />
                  </div>
                </div>
              </p>
            </div>
          ))}
        </div>
      )}

      {/* Import Question Modal */}
      <ImportQuestionModal
        handleImportModalClose={handleImportModalClose}
        isImportModalOpen={isImportModalOpen}
        questionBank={questionBank}
        calculateImportModalvalues={calculateImportModalvalues}
      />

      {/* Create Question Bank Modal */}
      <CustomModal
        isOpen={isCreateQuestionBankModalOpen}
        onClose={() => {
          setIsCreateQuestionBankModalOpen(!isCreateQuestionBankModalOpen);
          setSelectedQuestionBankId(null);
        }}
      >
        <CreateQuestionBankModal
          onClose={handleCreateQuestionBankModal}
          setIsCreateQuestionBankModalOpen={setIsCreateQuestionBankModalOpen}
          selectedQuestionBankId={selectedQuestionBankId}
        />
      </CustomModal>
    </div>
  );
};

export default Questionbank;
