import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        {...props}
        size={100} // Increase size here
        sx={{
          color: props.barColor, // Set the bar color from props
        }}
      />
      <Box
        sx={{
          top: 5,
          left: 15,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: props.textColor, fontSize: '1.5rem', alignItems: 'center', display: 'flex', justifyContent: 'center' }} // Set the text color from props
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

// Prop types for CircularProgressWithLabel
CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  barColor: PropTypes.string.isRequired, // Added barColor prop
  textColor: PropTypes.string.isRequired, // Added textColor prop
};

// Main component to manage progress
export default function CircularWithValueLabel({ targetValue, barColor, textColor }) {
  const [progress, setProgress] = React.useState(10);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= targetValue ? targetValue : prevProgress + 1));
  //   }, 50);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [targetValue]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const difference = targetValue - prevProgress;
        const step = Math.max(1, difference / 4); // Adjusts dynamically based on remaining distance
        return prevProgress >= targetValue ? targetValue : prevProgress + step;
      });
    }, 90); // Use a consistent interval

    return () => {
      clearInterval(timer);
    };
  }, [targetValue]);

  return (
    <CircularProgressWithLabel
      value={progress}
      targetValue={targetValue}
      barColor={barColor} // Pass bar color to child
      textColor={textColor} // Pass text color to child
    />
  );
}

// Prop types for CircularWithValueLabel
CircularWithValueLabel.propTypes = {
  targetValue: PropTypes.number.isRequired,
  barColor: PropTypes.string, // Allow optional barColor prop
  textColor: PropTypes.string, // Allow optional textColor prop
};

// Default values for optional props
CircularWithValueLabel.defaultProps = {
  barColor: '#76c7c0', // Default bar color
  textColor: 'black', // Default text color
};
