// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Overlay to dim the background */
/* .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
} */

/* Modal container */
/* .modal {
  background: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: fadeIn 0.3s ease-out;
  overflow-y: auto;
}

@media (max-width: 1024px) {
  .modal {
    height: 80%;
  }
}

@media (max-width: 768px) {
  .modal {
    margin-left: 60px;
    width: 80%;
    height: 80%;
  }
} */

/* Close button */
/* .closeButton {
  position: absolute;
  top: 25px;
  right: 25px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
} */

/* Animation for modal appearance */
/* @keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
} */

.CustomModal_container__zml0b {
  width: -moz-fit-content;
  width: fit-content;
  overflow-y: auto;
}

@media (max-width: 500px) {
  .CustomModal_container__zml0b {
    width: 90%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/CustomModal/CustomModal.module.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;;;;;;;;;;;GAWG;;AAEH,oBAAoB;AACpB;;;;;;;;;;;;;;;;;;;;;;GAsBG;;AAEH,iBAAiB;AACjB;;;;;;;;;GASG;;AAEH,mCAAmC;AACnC;;;;;;;;;GASG;;AAEH;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":["/* Overlay to dim the background */\n/* .overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.6);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n} */\n\n/* Modal container */\n/* .modal {\n  background: white;\n  border-radius: 20px;\n  padding: 20px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);\n  position: relative;\n  animation: fadeIn 0.3s ease-out;\n  overflow-y: auto;\n}\n\n@media (max-width: 1024px) {\n  .modal {\n    height: 80%;\n  }\n}\n\n@media (max-width: 768px) {\n  .modal {\n    margin-left: 60px;\n    width: 80%;\n    height: 80%;\n  }\n} */\n\n/* Close button */\n/* .closeButton {\n  position: absolute;\n  top: 25px;\n  right: 25px;\n  background: none;\n  border: none;\n  font-size: 20px;\n  cursor: pointer;\n  z-index: 1;\n} */\n\n/* Animation for modal appearance */\n/* @keyframes fadeIn {\n  from {\n    opacity: 0;\n    transform: scale(0.95);\n  }\n  to {\n    opacity: 1;\n    transform: scale(1);\n  }\n} */\n\n.container {\n  width: fit-content;\n  overflow-y: auto;\n}\n\n@media (max-width: 500px) {\n  .container {\n    width: 90%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CustomModal_container__zml0b`
};
export default ___CSS_LOADER_EXPORT___;
