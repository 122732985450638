import React from "react";
import styles from "./MCQMA.module.css";
import QuestionMenuBar from "../QuestionMenuBar/QuestionMenuBar";
import Tag from "../Tag/Tag";

const MCQMA = ({ correctOption, answer, question, questionOrder }) => {
  return (
    <div className={styles.mcqContainer}>
      <div className={styles.headerSectionContainer}>
        <div className={styles.mcqQuestion}>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              className={styles.questionInputBox}
              value={questionOrder}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: question || " ",
              }}
            ></div>
          </div>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.infoSubContainer}>
            <Tag
              tagName="ID"
              tagValue="90"
              tagColor="#FF933063"
              tagButtonColor={"#FFE4CC"}
            />
            <Tag
              tagName="Marks"
              tagValue="5"
              tagColor="#ffcccc"
              tagButtonColor={"#ff9191"}
            />
          </div>
          <div className={styles.infoSubContainer}>
            <Tag
              tagName="Actions"
              tagValue="Easy"
              tagColor="#cce5ff"
              tagButtonColor={"#91caff"}
            />
          </div>
        </div>
      </div>
      <div className={styles.mcqOptions}>
        <div className={styles.mcqOptionContainer}>
          <p className={styles.mcqOptiontag}>A.</p>
          <div
            className={
              answer?.includes("A") ? styles.mcqOptionCorrect : styles.mcqOption
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: correctOption[0]?.name || " ",
              }}
            ></div>
          </div>
        </div>
        <div className={styles.mcqOptionContainer}>
          <p className={styles.mcqOptiontag}>B.</p>
          <div
            className={
              answer?.includes("B") ? styles.mcqOptionCorrect : styles.mcqOption
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: correctOption[1]?.name || " ",
              }}
            ></div>
          </div>
        </div>
        <div className={styles.mcqOptionContainer}>
          <p className={styles.mcqOptiontag}>C.</p>
          <div
            className={
              answer?.includes("C") ? styles.mcqOptionCorrect : styles.mcqOption
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: correctOption[2]?.name || " ",
              }}
            ></div>
          </div>
        </div>
        <div className={styles.mcqOptionContainer}>
          <p className={styles.mcqOptiontag}>D.</p>
          <div
            className={
              answer?.includes("D") ? styles.mcqOptionCorrect : styles.mcqOption
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: correctOption[3]?.name || " ",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MCQMA;
