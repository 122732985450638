import React, { useEffect } from "react";
import styles from "./Members.module.css";
import { FaTrash } from "react-icons/fa"; // Using react-icons for the trash icon
import { useDispatch, useSelector } from "react-redux";
import { getBatchMembers } from "../../../Store/API/API";
import HeaderComponent from "../Navbar/Header/HeaderComponent";
import CustomModal from "../../UI/CustomModal/CustomModal";
import MemberOnBoardModal from "./MemberOnBoardModal";

const Members = () => {
  const [batchMembers, setBatchMembers] = React.useState([]);
  const [checkedMembers, setCheckedMembers] = React.useState([]);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const [showMemberModal, setShowMemberModal] = React.useState(false);
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  const handleChecked = (id, type) => {
    const isChecked = checkedMembers.some((member) => member.user_id === id);

    if (isChecked) {
      const newCheckedMembers = checkedMembers.filter(
        (member) => member.user_id !== id
      );
      setCheckedMembers(newCheckedMembers);
      return;
    }

    if (type === "single") {
      const checkedMember = batchMembers.find(
        (member) => member.user_id === id
      );
      if (checkedMember) {
        setCheckedMembers([...checkedMembers, checkedMember]);
      }
    } else {
      // console.log("All members checked");

      setCheckedMembers(batchMembers);
    }
  };

  // const handleOnBoard = () => {
  //   console.log(checkedMembers);
  // };

  const fetchmembers = async () => {
    const data = await getBatchMembers(
      batchState.groupId,
      userState.token,
      dispatch
    );

    // console.log(data);

    if (data.statuscode === "200") {
      setBatchMembers(data.data);
    }
  };

  useEffect(() => {
    fetchmembers();

    // eslint-disable-next-line
  }, [batchState.batchId]);

  return (
    <div
      className={
        "w-full mx-auto font-sans mt-[70px] relative h-[88vh] overflow-y-auto"
      }
    >
      <div className="mb-0 sticky p-5 top-0 bg-white z-50">
        <HeaderComponent>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
            <div className="bg-[#1b3156] rounded-lg flex justify-center items-center gap-3 py-2 md:py-0 ">
              <span className="bg-white px-2 rounded-md text-xl font-bold ">
                {batchMembers.length}
              </span>
              <p className="text-white">Number of members</p>
            </div>

            <div className="xl:col-start-4" style={{ alignSelf: "flex-end" }}>
              <div
                className={`bg-[#f36421] hover:bg-orange-700 text-white border-none rounded-[12px] px-5 py-2 text-[16px] font-medium ${
                  checkedMembers.length === batchMembers.length
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                } h-[40px] self-end flex justify-center items-center`}
                onClick={() => {
                  if (checkedMembers.length === batchMembers.length) {
                    return;
                  }

                  handleChecked("", "all");
                }}
              >
                <p>Select All</p>
              </div>
            </div>

            <div
              className="md:col-span-full lg:col-span-1"
              style={{ alignSelf: "flex-end" }}
            >
              <div
                className={`bg-[#f36421] hover:bg-orange-700 text-white border-none rounded-[12px] px-5 py-2 text-[16px] font-medium ${
                  checkedMembers.length === 0
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                } h-[40px] self-end flex justify-center items-center`}
                onClick={() => {
                  if (checkedMembers.length === 0) {
                    return;
                  }
                  setShowMemberModal(true);
                }}
              >
                <p>On Board</p>
              </div>
            </div>
          </div>
        </HeaderComponent>
      </div>

      <div className={styles.memberGrid}>
        {batchMembers.map((member) => (
          <div key={member?.user_id} className={styles.memberCard}>
            <div className={styles.memberIcon}>
              <img src={member?.profile_image} alt="black-icon" />
            </div>
            <div className={styles.memberDetails}>
              <p className={styles.memberName}>{member?.name}</p>
              <div className={styles.studentInfo}>
                <p className={styles.memberMobile}>
                  Mobile: {member?.mobile_number}
                </p>
                <p className={styles.memberMobile}>Role: {member?.user_type}</p>
                <p
                  className={styles.memberMobile}
                  style={{ fontWeight: "bold" }}
                >
                  User Id: {member?.user_id}
                </p>
              </div>
            </div>
            <div className={styles.memberCheckbox}>
              <input
                type="checkbox"
                onChange={() => handleChecked(member?.user_id, "single")}
                checked={
                  Array.isArray(checkedMembers) &&
                  checkedMembers.some(
                    (checkedMember) => checkedMember.user_id === member?.user_id
                  )
                }
              />
            </div>
            {/* <div className={styles.trashIcon}>
              <FaTrash />
            </div> */}
          </div>
        ))}
      </div>
      <CustomModal
        isOpen={showMemberModal}
        onClose={() => setShowMemberModal(false)}
      >
        <MemberOnBoardModal
          // handleModalSubmit={() => {}}
          onClose={() => setShowMemberModal(!showMemberModal)}
          modalHeading={"Student On Board"}
          checkedMembers={checkedMembers}
        />
      </CustomModal>
    </div>
  );
};

export default Members;
