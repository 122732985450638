import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import ReportFilter from "./ReportFilter";
import { fetchLoginUser, getAllInstitute } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../UI/SelectInput/SelectInput";
const Reports = () => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const [selectedRange, setSelectedRange] = useState("Today");
  const [chartData, setChartData] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [institutesOption, setInstitutesOption] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState(null);


  const fetchInstitutes = async () => {
    const data = await getAllInstitute();
    if (data) {
      setInstitutesOption(data);
      // if (data.length > 0) {
      //   setSelectedInstitute(data[0]);
      // }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // if (!selectedInstitute) return;

      const data = await fetchLoginUser(
        userState,
        selectedRange.toLowerCase(),
        dispatch,
        selectedInstitute?.value 
      );
      console.log("Fetching data",data)

      if (data?.status && data?.data) {
        setReportsData([
          { label: "Active Users", value: data.data.active_login_users },
          { label: "Not Active Users", value: data.data.never_login_users },
          { label: "All Users", value: data.data.all_users },
          {
            label: getDynamicLabel(selectedRange),
            value: data.data.filter_login_users,
          },
        ]);

        setChartData(data.data.graph_users_details[0] || []);
      }
    };

    fetchData();
  }, [selectedRange, selectedInstitute, userState, dispatch]);

  useEffect(() => {
    fetchInstitutes();
  }, []);

  const getDynamicLabel = (range) => {
    switch (range.toLowerCase()) {
      case "today":
        return "Today Login Users";
      case "weekly":
        return "Weekly Login Users";
      case "monthly":
        return "Monthly Login Users";
      case "yearly":
        return "Yearly Login Users";
      default:
        return "Filtered Login Users";
    }
  };

  const handleInstituteChange = (field, selectedValue) => {
    setSelectedInstitute(selectedValue);
  };

  const formatTime = (hour) => {
    const intHour = parseInt(hour, 10);
    const period = intHour >= 12 ? "PM" : "AM";
    const formattedHour = intHour > 12 ? intHour - 12 : intHour === 0 ? 12 : intHour;
    return `${formattedHour} ${period}`;
  };

  const SelectInputField = ({
    placeholder,
    handleInputChange,
    value,
    options,
  }) => {
    return (
      <div>
        <SelectInput
          width={"100%"}
          handleInputChange={handleInputChange}
          value={value}
          placeHolder={placeholder}
          backgroundColor={"#ffffff"}
          borderColor={"#E7EDF6"}
          borderRadius={"12px"}
          dropdownTextColor={"#000000"}
          options={options}
        />
      </div>
    );
  };

  const rangeOptions = [
    { label: "Today", value: "Today" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Yearly", value: "Yearly" },
  ];

  return (
    <>
      <div className="w-full flex flex-col lg:flex-row">
        <div className="w-full lg:w-[40%] mt-20">
          <h2 className="text-base lg:text-[24px] font-bold mb-4 lg:mb-10 px-6">
          User Activities
          </h2>
          <div className="grid grid-cols-2 gap-4 px-6 ">
            {reportsData.map((report, index) => (
              <div
                key={index}
                className="bg-backgroundBlue rounded-lg p-8 text-center shadow"
              >
                <p className="text-base lg:text-[24px] font-bold">
                  {report.value}
                </p>
                <p className="text-sm lg:text-base text-textBlack">
                  {report.label}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full lg:w-[60%] mt-5 lg:mt-20 px-2 lg:px-6">
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center mb-4 lg:mb-5 px-6">
            <h2 className="text-base lg:text-[24px] font-bold">
              Login Activity
            </h2>
           <div className="flex flex-col md:flex-row gap-5">
           {userState.userType === "institute" && (
              <div className="min-w-[200px] -mt-3">
                <SelectInputField
                  placeholder={"Select Institute"}
                  handleInputChange={(field, value) =>
                    handleInstituteChange("institute", value)
                  }
                  value={selectedInstitute}
                  options={institutesOption}
                />
              </div>
            )}

            <div className="min-w-[200px] -mt-3">
              <SelectInputField
                placeholder={"Select Range"}
                handleInputChange={(field, selectedOption) => {
                  setSelectedRange(selectedOption.value);
                }}
                value={rangeOptions.find(
                  (option) => option.value === selectedRange
                )}
                options={rangeOptions}
              />
            </div>
           </div>
          </div>

          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData} >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="key" tickFormatter={formatTime} />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#0743A3"
                strokeWidth={3}
                dot={{ fill: "#0743A3", r: 4 }}
                activeDot={{ r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <ReportFilter />
    </>
  );
};

export default Reports;
