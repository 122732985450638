import React, { useEffect, useState } from "react";
import styles from "./StudentAttendance.module.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttendanceMonth,
  getStudentAttendance,
} from "../../../../Store/API/API";

const StudentAttendance = () => {
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [months, setMonths] = useState([]);
  const [attandanceData, setAttandanceData] = useState({});
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedMode, setSelectedMode] = useState("");
  const [openMenu, setOpenMenu] = useState(false);

  console.log(attandanceData, "attandanceData");

  const handleClick = (event) => {
    setOpenMenu(!openMenu);
  };

  const AttendanceTag = ({ status }) => {
    const color =
      status === "P"
        ? attandanceData?.color_list?.present
        : status === "A"
        ? attandanceData?.color_list?.absent
        : status === "H"
        ? attandanceData?.color_list?.holiday
        : "#494949";
    return (
      <div className={styles.tagContainer} style={{ backgroundColor: color }}>
        <p className={styles.tagText}>{status}</p>
      </div>
    );
  };

  const AttendanceTagMobile = ({ subject, status }) => {
    console.log(subject, status, "subject, status");

    const color =
      status === "P"
        ? attandanceData?.color_list?.present
        : status === "A"
        ? attandanceData?.color_list?.absent
        : status === "H"
        ? attandanceData?.color_list?.holiday
        : "#494949";
    return (
      //   <MenuItem>
      <div className={styles.munuListTag} style={{ backgroundColor: color }}>
        {status}
        {subject}
      </div>
      //   </MenuItem>
    );
  };

  const ModeCard = ({ type }) => {
    return (
      <div
        className={styles.modeCard}
        style={{ backgroundColor: type === "online" ? "#fef0e9" : "#e7edf6" }}
      >
        <i
          className={
            type === "online" ? "fa-solid fa-play" : "fa-solid fa-suitcase"
          }
          style={{ color: type === "online" ? "#F36421" : "#0743A3" }}
        ></i>
        <p className={styles.cardHeading}>
          {type === "online" ? "Online" : "Offline"}
        </p>
      </div>
    );
  };

  const handleChange = (event) => {
    if (event.target.name === "month") {
      setSelectedMode("");
      setAttandanceData({});
      setSelectedMonth(event.target.value);
    }
    if (event.target.name === "mode") {
      setSelectedMode(event.target.value);
      setAttandanceData({});
    }
  };

  const handleButton = async () => {
    console.log(selectedMonth, selectedMode, "selected");
    if (selectedMonth && selectedMode) {
      const data = await getStudentAttendance(
        userState.userId,
        batchState.groupId,
        selectedMonth,
        //   23,
        //   3,
        //   "Nov 2024",
        selectedMode,
        userState.token,
        dispatch
      );

      console.log(data, "data");

      setAttandanceData(data);
    }
  };

  const getMonths = async () => {
    const data = await getAttendanceMonth(
      batchState.groupId,
      //   3,
      userState.token,
      dispatch
    );

    console.log(data, "data");
    if (data) {
      setMonths(data);
    }
  };

  useEffect(() => {
    getMonths();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.webView}>
        <div className={styles.headerContainer}>
          <p className={styles.heading}>My Attendance</p>
          <div className={styles.dropDownContainer}>
            <FormControl variant="outlined" className={styles.formControl}>
              <InputLabel>Select Month</InputLabel>
              <Select
                label="Select Month"
                sx={{
                  height: "50px", // Adjust the dropdown height
                  ".MuiSelect-select": {
                    padding: "10px", // Adjust padding for content
                  },
                }}
                name="month"
                value={selectedMonth}
                onChange={handleChange}
              >
                {months?.map((months, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={months.value}
                      sx={{ height: "50px" }}
                    >
                      {months.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={styles.formControl}>
              <InputLabel>Select Mode</InputLabel>
              <Select
                label="Select Mode"
                sx={{
                  height: "50px", // Adjust the dropdown height
                  ".MuiSelect-select": {
                    padding: "10px", // Adjust padding for content
                  },
                }}
                value={selectedMode}
                name="mode"
                onChange={handleChange}
              >
                <MenuItem value={"online"} sx={{ height: "50px" }}>
                  Online
                </MenuItem>
                <MenuItem value={"offline"} sx={{ height: "50px" }}>
                  Offline
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {attandanceData?.subjects ? (
          <div>
            <div className={styles.tableHeader}>
              <div className={styles.dateContainer}>
                <p className={styles.tableHeading}>Date</p>
              </div>
              <div className={styles.classContainer}>
                <p className={styles.tableHeading}>Class Mode</p>
              </div>
              <div className={styles.subjectsContainer}>
                {attandanceData?.subjects?.map((subject, index) => {
                  return (
                    <p key={index} className={styles.tableHeading}>
                      {subject?.slice(0, 3)}
                    </p>
                  );
                })}
              </div>
            </div>

            <div className={styles.tableBody}>
              {attandanceData?.batch_attendance?.map((attendance, index) => {
                const currentIndex = index;
                return (
                  <div className={styles.tableRow} key={index}>
                    <div className={styles.dateContainer}>
                      <p className={styles.tableData}>{attendance?.date}</p>
                    </div>
                    <div className={styles.classContainer}>
                      {selectedMode === "online" ? (
                        <ModeCard type={"online"} />
                      ) : (
                        <ModeCard type={"offline"} />
                      )}
                    </div>
                    <div className={styles.subjectsContainer}>
                      {attandanceData?.subjects?.map((subject, index) => {
                        return (
                          <div className={styles.tableData} key={index * 20}>
                            <AttendanceTag
                              color={"#74AE42"}
                              status={
                                attandanceData?.batch_attendance?.[currentIndex]
                                  ?.subject?.[subject]?.attendance === "present"
                                  ? "P"
                                  : attandanceData?.batch_attendance?.[
                                      currentIndex
                                    ]?.subject?.[subject]?.attendance ===
                                    "absent"
                                  ? "A"
                                  : attandanceData?.batch_attendance?.[
                                      currentIndex
                                    ]?.subject?.[subject]?.attendance ===
                                    "holiday"
                                  ? "H"
                                  : "U"
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className={styles.searchButtonContainer} onClick={handleButton}>
            <div className={styles.searchButton}>Search</div>
          </div>
        )}
      </div>
      <div className={styles.mobileView}>
        <div
          className={styles.headerContainer}
          style={{ flexDirection: "column" }}
        >
          <p className={styles.heading}>Attendance</p>
          <div
            className={styles.dropDownContainer}
            style={{ flexDirection: "column" }}
          >
            <FormControl variant="outlined" className={styles.formControl}>
              <InputLabel>Select Month</InputLabel>
              <Select
                label="Select Month"
                sx={{
                  height: "50px", // Adjust the dropdown height
                  ".MuiSelect-select": {
                    padding: "10px", // Adjust padding for content
                  },
                }}
                value={selectedMonth}
                name="month"
                onChange={handleChange}
              >
                {months?.map((months, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={months.value}
                      sx={{ height: "50px" }}
                    >
                      {months.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={styles.formControl}>
              <InputLabel>Select Mode</InputLabel>
              <Select
                label="Select Mode"
                sx={{
                  height: "50px", // Adjust the dropdown height
                  ".MuiSelect-select": {
                    padding: "10px", // Adjust padding for content
                  },
                }}
                value={selectedMode}
                name="mode"
                onChange={handleChange}
              >
                <MenuItem value={"online"} sx={{ height: "50px" }}>
                  Online
                </MenuItem>
                <MenuItem value={"offline"} sx={{ height: "50px" }}>
                  Offline
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {attandanceData.subjects ? (
          <div className={styles.tableBody}>
            {attandanceData?.batch_attendance?.map((attendance, index) => {
              const currentIndex2 = index;
              return (
                <div
                  className={styles.tableRow}
                  style={{ marginTop: "10px" }}
                  key={index}
                >
                  <div className={styles.dateContainer}>
                    <p className={styles.tableData}>{attendance?.date}</p>
                  </div>
                  <Button onClick={handleClick}>
                    <div
                      style={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "20px",
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MoreVertIcon style={{ color: "#000" }} />
                    </div>
                  </Button>
                  <div
                    className={styles.menuContainer}
                    style={{ display: openMenu ? "block" : "none" }}
                  >
                    {attandanceData?.subjects?.map((subject, index) => {
                      return (
                        <div key={index * 20}>
                          <AttendanceTagMobile
                            subject={subject}
                            status={
                              attandanceData?.batch_attendance?.[currentIndex2]
                                ?.subject?.[subject]?.attendance === "present"
                                ? "P"
                                : attandanceData?.batch_attendance?.[
                                    currentIndex2
                                  ]?.subject?.[subject]?.attendance === "absent"
                                ? "A"
                                : attandanceData?.batch_attendance?.[
                                    currentIndex2
                                  ]?.subject?.[subject]?.attendance ===
                                  "holiday"
                                ? "H"
                                : "U"
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          //   <div className={styles.searchButtonContainer} onClick={handleButton}>
          <div className={styles.searchButtonContainer}>
            <div className={styles.searchButton}>Search</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentAttendance;
