import React, { useState, useEffect } from "react";
import "./Session.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGroupSession, getSessionJoinningUrl } from "../../../Store/API/API";
import Spinner from "../../UserInterface/Spinner/Spinner";
import CardView from "./CardView";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
const UpcomingSessions = () => {
  const userState = useSelector((state) => state.userData.userData);
  const [upcommingSessions, setSessions] = useState([]);
  console.log(upcommingSessions);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const fetchUpcomingSession = async () => {
    const data = await getGroupSession(
      userState,
      batchState.groupId,
      "upcoming",
      dispatch
    );
    if (data) {
      console.log(data);
      setSessions(data);
      setIsLoading(false);
    }
  };
  const handleJoinSession = async (event, session, batchId) => {
    event.preventDefault();
    const data = await getSessionJoinningUrl(
      userState.token,
      userState.userId,
      session,
      batchId,
      dispatch
    );
    if (data) {
      window.location.href = data.meetingUrl;
    }
  };

  useEffect(() => {
    fetchUpcomingSession();

    // eslint-disable-next-line
  }, [userState]);
  const upData = (item) => (
    <div>
      <div>
        <strong>Start Date:</strong> <span>{item.date}</span>
      </div>
      <div>
        <strong>Start Time:</strong> <span>{item.fromTime}</span>
      </div>
      <div>
        <strong>Subject:</strong> <span>{item.session_subject}</span>
      </div>
      <div>
        <strong>Chapter:</strong> <span>{item.session_chapter}</span>
      </div>
      <div>
        <strong>Teacher:</strong> <span>{item.session_tutor_name}</span>
      </div>
      <div>
        <strong>Action:</strong>
        <span>
          <button
            className="invoice-button"
            onClick={(e) => {
              handleJoinSession(e, item.sessionId, batchState.groupId);
            }}
          >
            {item.startingIn === "Started"
              ? "Join now"
              : "Start in " + item.startingIn}
          </button>
        </span>
      </div>
    </div>
  );
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="flex h-[95vh] w-[100%]">
      <div className="overflow-auto flex md:w-[100%] w-full flex-col px-3 py-6 h-full bg-gray-100 mt-10 ">
        <div className="form-row">
          <div className="form-buttons">
            <Link
              className="mx-1 decoration-transparent sm:w-[7rem] text-center rounded-lg p-2 text-white activecolor Buttons"
              to="/upcomming-session"
            >
              Upcoming
            </Link>
            <Link
              className="mx-1 decoration-transparent sm:w-[7rem] text-center rounded-lg p-2 text-white hrcolor Buttons"
              to="/past-session"
            >
              Past
            </Link>
            <Link
              className="mx-1 decoration-transparent sm:w-[7rem] text-center rounded-lg p-2 text-white hrcolor Buttons"
              to="/cancel-session"
            >
              Canceled
            </Link>
          </div>
        </div>
        <div className=" bg-white rounded-lg py-1 md:p-3 w-full border-t-2 border-t-orange-500 main-container">
          <Table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <Thead>
              <Tr className="bg-[#0A3C87] text-white uppercase text-sm leading-normal">
                <Th className="py-2 px-0 text-center">Start Date</Th>
                <Th className="py-2 px-0 text-center">Start Time</Th>
                <Th className="py-2 px-0 text-center">Subject</Th>
                <Th className="py-2 px-0 text-center">Chapter</Th>
                <Th className="py-2 px-0 text-center">Teacher</Th>
                <Th className="py-2 px-0 text-center">Actions</Th>
              </Tr>
            </Thead>
            <Tbody className="text-gray-600 text-sm font-light">
              {upcommingSessions?.length === 0 && (
                <Tr>
                  <Td
                    colSpan="6"
                    className="py-2 px-6 text-center text-orange-500 font-bold"
                  >
                    No Upcomming Sessions Found
                  </Td>
                </Tr>
              )}

              {upcommingSessions?.map((item, index) => (
                <Tr
                  className="border-b border-gray-200 hover:bg-gray-100"
                  key={index}
                >
                  <Td className="py-2 px-0 text-center">{item.date}</Td>
                  <Td className="py-2 px-0 text-center">{item.fromTime}</Td>
                  <Td className="py-2 px-0 text-center">
                    {item.session_subject}
                  </Td>
                  <Td className="py-2 px-0 text-center">
                    {item.session_chapter}
                  </Td>
                  <Td className="py-2 px-0 text-center">
                    {item.session_tutor_name}
                  </Td>

                  <Td className="py-3 px-6 text-center">
                    <button
                      onClick={(e) => {
                        handleJoinSession(
                          e,
                          item.sessionId,
                          batchState.groupId
                        );
                      }}
                      className="bg-blue-500 text-white rounded px-3 py-1 transition duration-200 ease-in-out hover:bg-blue-600"
                      disabled={item.startingIn !== "Started"}
                      style={{
                        cursor:
                          item.startingIn !== "Started"
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      {item.startingIn === "Started"
                        ? "Join now"
                        : "Start in " + item.startingIn}{" "}
                    </button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        <CardView
          data={upcommingSessions}
          renderContent={upData}
          noDataMessage=" No Upcoming Sessions"
        />
      </div>
    </div>
  );
};
export default UpcomingSessions;
