// import React, { useState, useEffect, useRef } from "react";   removed
import React, { useState, useEffect } from "react";
import classes from "./TestScreen1.module.css";
import { Button } from "react-bootstrap";
import CustomQuestion1 from "./CustomQuestion1";
import InfoArea from "./InfoArea1";
// import { totalQuestion, getQuestion } from "../../../Store/Actions/Actions"; removed
import SubmiteModal from "../../Modals/SubmiteModal/SubmiteModal";
import useNetwork from "../../CustomHooks/useNetwork";
import CustomTimer from "../../UserInterface/CustomTimer/CustomTimer";
import { useSelector, useDispatch } from "react-redux";
import { initializeAnswerArray } from "../../../Store/Data/Actions";
// import { getQuizQuestion } from "../../../Store/API/API";  removed
// import { useParams } from "react-router-dom";  removed
import { answerActions } from "../../../Store/Data/Slices/AnswerSlice";
import TimeUpModal from "../../Modals/TimeUpModal";
import TestInfoModal from "../../Modals/TestInfoModal/TestInfoModal";
import Spinner from "../../UserInterface/Spinner/Spinner";

const TestScreen = ({
  testId,
  testSubjects,
  handleTestSubmit,
  quizId,
  testDetails,
  onlineStatus,
}) => {
  const url = new URLSearchParams(window.location.search);
  const mobiletoken = url.get("token");
  const mobiletestId = url.get("testid");

  // console.log(mobiletoken, "token");
  // console.log(mobiletestId, "testId");
  // console.log(testDetails, "testDetails");

  const userState = useSelector((state) => state.userData.userData);
  const answerState = useSelector((state) => state.answerData.answer);
  // const answerSlice = useSelector((state) => state.answerData)
  // const userData = useSelector((state) => state.answerData);
  const questionState = useSelector((state) => state.questionData.questions);
  const questionDetail = useSelector((state) => state.questionData.testInfo);

  // console.log(userData, "userData");
  // console.log(answerSlice, "answerSlice");
  // console.log(userState, "userState");
  // console.log(answerState, "answerState");
  // console.log(mendatoryCount, "mendatoryCount");
  // console.log(questionState, "questionState");

  // console.log(testSubjects, "testSubjects");

  const [selectedSubjectQuestions, setSelectedSubjectQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedSubject, setSelectedSubject] = useState("All");
  const [currentQuestionTime, setCurrentQuestionTime] = useState(0);
  const [unAnswered, setUnAnswered] = useState(0);
  const [answered, setAnswered] = useState(0);
  const [markedForReview, setMarkedForReview] = useState(0);
  const [notVisited, setNotVisited] = useState(0);
  const [show, setShow] = useState(false);
  const [showTimeUp, setShowTimeUp] = useState(false);
  const [showTestInfo, setShowTestInfo] = useState(false);
  const [autoTestSubmitting, setAutoTestSubmitting] = useState(true);

  const time = new Date();
  time.setSeconds(time.getSeconds() + questionDetail?.time * 60);

  const dispatch = useDispatch();

  const handleSelectedSubject = (subject) => {
    setSelectedSubject(subject);
  };

  const handleModal = () => {
    setShow(!show);
  };

  const handleTimeUpModal = () => {
    setShowTimeUp(true);
  };

  const handleTestInfoModal = () => {
    setShowTestInfo(!showTestInfo);
  };

  const handleButtonClick = (type, question_id) => {
    if (type === "next") {
      const tempAnswer = answerState.find(
        (answer) =>
          answer.questionId ===
          selectedSubjectQuestions[currentIndex].question_id
      );
      if (
        tempAnswer?.answer === "" &&
        tempAnswer?.question_state !== "Marked for Review"
      ) {
        dispatch(
          answerActions.updateQuestionState({
            questionId: tempAnswer.questionId,
            value: "Not Answered",
          })
        );
      }
      dispatch(
        answerActions.updateAnswerTime({
          questionId: tempAnswer.questionId,
          startTime: currentQuestionTime.getTime(),
          endTime: new Date().getTime(),
        })
      );
      setCurrentIndex(currentIndex + 1);
    }
    if (type === "submit") {
      handleModal();
    }
    if (type === "Mark for Review") {
      const tempAnswer = answerState.find(
        (answer) =>
          answer.questionId ===
          selectedSubjectQuestions[currentIndex].question_id
      );
      dispatch(
        answerActions.updateQuestionState({
          questionId: tempAnswer.questionId,
          value: "Marked for Review",
        })
      );
    }
  };

  const updateCurrentIndex = (indexValue, questionId) => {
    const tempAnswer = answerState.find(
      (answer) =>
        answer.questionId === selectedSubjectQuestions[currentIndex].question_id
    );
    if (
      tempAnswer?.answer === "" &&
      tempAnswer?.question_state !== "Marked for Review"
    ) {
      dispatch(
        answerActions.updateQuestionState({
          questionId: tempAnswer.questionId,
          value: "Not Answered",
        })
      );
    }
    dispatch(
      answerActions.updateAnswerTime({
        questionId: tempAnswer.questionId,
        startTime: currentQuestionTime.getTime(),
        endTime: new Date().getTime(),
      })
    );
    setCurrentIndex(indexValue);
  };

  useEffect(() => {
    setCurrentQuestionTime(new Date());
  }, [currentIndex]);

  useEffect(() => {
    let unAnsweredCount = 0;
    let answeredCount = 0;
    let markedForReviewCount = 0;
    let notVisitedCount = 0;

    answerState.forEach((answer) => {
      switch (answer.question_state) {
        case "Not Answered":
          unAnsweredCount++;
          break;
        case "Answered":
          answeredCount++;
          break;
        case "Marked for Review":
          markedForReviewCount++;
          break;
        case "Not Visited":
          notVisitedCount++;
          break;
        default:
          break;
      }
    });

    setUnAnswered(unAnsweredCount);
    setAnswered(answeredCount);
    setMarkedForReview(markedForReviewCount);
    setNotVisited(notVisitedCount);
  }, [answerState]);

  useEffect(() => {
    if (selectedSubject === "All") {
      setSelectedSubjectQuestions(questionState);
    } else {
      setSelectedSubjectQuestions(
        questionState.filter(
          (question) => question.section_subject === selectedSubject
        )
      );
    }
    setCurrentIndex(0);
  }, [questionState, selectedSubject]);

  useEffect(() => {
    // dispatch(initializeAnswerArray(userState.token, userState.testId));
    dispatch(
      initializeAnswerArray(
        userState.token || mobiletoken,
        testId || mobiletestId
      )
    );

    //eslint-disable-next-line
  }, [userState.token, userState.testId]);

  const networkState = useNetwork();
  const { online } = networkState;

  console.log(questionState?.length);

  if (questionState?.length === 0) {
    return <Spinner />;
  }

  return (
    <div className={classes.outerContainer}>
      <div className={classes.topBar}>
        <h6>{questionDetail?.title}</h6>
        <div
          style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
        >
          {online ? (
            <div className={classes.networkBar}>
              <div>
                <i
                  className="fa-solid fa-rotate fa-spin"
                  style={{ color: "green" }}
                ></i>
              </div>
              <p
                style={{
                  marginBottom: "0",
                  marginLeft: "10px",
                  color: "green",
                }}
              >
                Connected, syncing
              </p>
            </div>
          ) : (
            <div className={classes.networkBar}>
              <div className={classes.alerBox}>
                <p className={classes.alertText}>Connection Lost!</p>
                <p className={classes.alertSubText}>
                  Please reconnect to avoid losing progress and ensure your test
                  submission.
                </p>
              </div>
              <div>
                <i
                  className="fa-solid fa-wifi fa-beat-fade"
                  style={{ color: "red" }}
                ></i>
              </div>
              <p
                style={{ marginBottom: "0", marginLeft: "10px", color: "red" }}
              >
                Disconnected
              </p>
            </div>
          )}
          <Button
            variant="primary"
            className={classes.barButton}
            onClick={() => {
              handleButtonClick("submit");
            }}
          >
            Finish Test
          </Button>
        </div>
      </div>
      <div className={classes.questionArea}>
        <div className={classes.sections}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h6>Subject</h6>
            <i
              className="fa-solid fa-circle-info  fa-xl"
              style={{ color: "#d8786f", marginTop: "10px" }}
              onClick={handleTestInfoModal}
            ></i>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant="outline-secondary"
              className={
                selectedSubject === "All"
                  ? classes.sectionButtonActive
                  : classes.sectionButton
              }
              onClick={() => handleSelectedSubject("All")}
            >
              All
            </Button>
            {testSubjects?.includes("Physics") && (
              <Button
                variant="outline-secondary"
                className={
                  selectedSubject === "Physics"
                    ? classes.sectionButtonActive
                    : classes.sectionButton
                }
                onClick={() => handleSelectedSubject("Physics")}
              >
                Physics
              </Button>
            )}
            {testSubjects?.includes("Chemistry") && (
              <Button
                variant="outline-secondary"
                className={
                  selectedSubject === "Chemistry"
                    ? classes.sectionButtonActive
                    : classes.sectionButton
                }
                onClick={() => handleSelectedSubject("Chemistry")}
              >
                Chemistry
              </Button>
            )}
            {testSubjects?.includes("Maths") && (
              <Button
                variant="outline-secondary"
                className={
                  selectedSubject === "Maths"
                    ? classes.sectionButtonActive
                    : classes.sectionButton
                }
                onClick={() => handleSelectedSubject("Maths")}
              >
                Maths
              </Button>
            )}
            {testSubjects?.includes("Biology") && (
              <Button
                variant="outline-secondary"
                className={
                  selectedSubject === "Biology"
                    ? classes.sectionButtonActive
                    : classes.sectionButton
                }
                onClick={() => handleSelectedSubject("Biology")}
              >
                Biology
              </Button>
            )}
          </div>
        </div>
        <div className={classes.questionInfo}>
          <h5>
            Question {currentIndex + 1} of {selectedSubjectQuestions?.length}
          </h5>
          <div className={classes.markingContainer}>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <h6 style={{ marginRight: "10px" }}>Marking</h6>
              <div
                style={{
                  padding: "2px 15px",
                  backgroundColor: "#6BD276",
                  borderRadius: "5px",
                  color: "#fff",
                  marginRight: "10px",
                }}
              >
                {questionState?.length > 0 && (
                  <h6>{`+ ${questionState[currentIndex].marks}`}</h6>
                )}
              </div>
              <div
                style={{
                  padding: "2px 10px",
                  backgroundColor: "#FB2121",
                  borderRadius: "5px",
                  color: "#fff",
                  marginRight: "30px",
                }}
              >
                {questionState?.length > 0 && (
                  <h6>{`- ${questionState[currentIndex].negative_marks}`}</h6>
                )}
              </div>
            </div>
            {/* <div style={{ flexDirection: "row", display: "flex" }}>
              <h6 style={{ marginRight: "5px" }}>Report</h6>
              <i
                className="fa-solid fa-triangle-exclamation"
                style={{ color: "orange", fontSize: "20px", cursor: "pointer" }}
              ></i>
            </div> */}
          </div>
        </div>
        <div className={classes.question}>
          {selectedSubjectQuestions && (
            <CustomQuestion1
              currentQuestion={selectedSubjectQuestions[currentIndex]}
            />
          )}
        </div>
        <div className={classes.questionOptions}>
          <div className={classes.Time}>
            <CustomTimer expiryTimestamp={time} callFunction={false} onlineStatus={onlineStatus} />
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <Button
              className={classes.Review}
              onClick={() => {
                handleButtonClick("Mark for Review");
              }}
            >
              Mark for Review
            </Button>
          </div>
          {currentIndex < selectedSubjectQuestions?.length - 1 && (
            <Button
              className={classes.SaveEnabled}
              onClick={() => {
                handleButtonClick("next");
              }}
            >
              Next
            </Button>
          )}
          {currentIndex === selectedSubjectQuestions?.length - 1 &&
            selectedSubject === "All" && (
              <Button
                className={classes.SaveEnabled}
                onClick={() => {
                  handleButtonClick("submit");
                }}
              >
                Submit
              </Button>
            )}
        </div>
      </div>
      <div className={classes.infoArea}>
        <InfoArea
          questionSet={selectedSubjectQuestions}
          currentIndex={currentIndex}
          updateCurrentIndex={updateCurrentIndex}
          unAnswered={unAnswered}
          answered={answered}
          markedForReview={markedForReview}
          notVisited={notVisited}
          time={time}
          parentFunction={handleTimeUpModal}
          quizId={quizId || userState?.testId}
          testDetails={testDetails}
          setAutoTestSubmitting={setAutoTestSubmitting}
          onlineStatus={onlineStatus}
        />
      </div>
      <SubmiteModal
        handleClose={handleModal}
        show={show}
        unAnswered={unAnswered}
        answered={answered}
        markedForReview={markedForReview}
        notVisited={notVisited}
        online={online}
        time={time}
        handleTestSubmit={handleTestSubmit}
        quizId={quizId || userState?.testId}
      />
      <TimeUpModal
        show={showTimeUp}
        handleTestSubmit={handleTestSubmit}
        autoTestSubmitting={autoTestSubmitting}
      />
      <TestInfoModal handleClose={handleTestInfoModal} show={showTestInfo} />
    </div>
  );
};

export default TestScreen;
