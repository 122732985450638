import React, { useEffect, useState } from "react";
import AttachmentBox from "../../UI/Box/AttachmentBox";
import classes from "./Assighnment.module.css";
import {
  fetchAssignmentDetails,
  handleSubmitPost,
} from "../../../Store/API/User";
import PreviewModal from "../../Modals/PreviewModal";
import { Input, TextField } from "@mui/material";
import { handleFileChange } from "../../../Store/API/File";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import Spinner from "../../UserInterface/Spinner/Spinner";
const AssignmentDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentAssignmentId = location.state.assignmentId;
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isFetching, setIsFetching] = useState(true);

  const token = "98c1ec61f46858ef5bcce7f85f5286cbtutMjk0Mg==";
  // const token = userState?.token;
  const assignmentId = currentAssignmentId;
  const student_id = userState?.userId;
  const type = "batch";
  const group_id = batchState?.groupId;

  const handleOnChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    setIsLoading(true);

    const filesData = await Promise.all(
      selectedFiles.map(async (file) => {
        const { data } = await handleFileChange(file, dispatch);
        let fileSizeInKB = data.file_size / 1024;
        fileSizeInKB = fileSizeInKB.toFixed(2);

        return {
          name: data.file_name,
          owner_id: student_id,
          url: data.file_url,
          mime: data.file_type,
          size: fileSizeInKB + " KB",
          size_bytes: data.file_size,
        };
      })
    );

    setIsLoading(false);
    setFiles([...files, ...filesData]);
  };

  const removeFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchAssignmentDetails(
          token,
          assignmentId,
          student_id,
          type,
          dispatch
        );
        // console.log(result);
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
        setIsFetching(false);
      }
    };

    getData();
  }, [currentAssignmentId, userState, batchState]);

  const handleOpenModal = () => {
    if (isOpenModal) {
      setIsOpenModal(false);
    } else {
      setIsOpenModal(true);
    }
  };

  const handleSubmitRemarks = async () => {
    if (remarks?.length === 0) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Error while submitting assignment",
          alertType: "danger",
        })
      );
      setTimeout(() => {
        dispatch(
          applicationActions.setAlertData({
            isAlert: false,
            alertMessage: "",
            alertType: "",
          })
        );
      }, 3000);
      return;
    }
    const strAttachments = JSON.stringify(files);
    const data = await handleSubmitPost(
      token,
      assignmentId,
      group_id,
      student_id,
      remarks,
      strAttachments,
      dispatch
    );
    if (data.status) {
      handleOpenModal();
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Assignment submitted successfully",
          alertType: "success",
        })
      );
    }
    setFiles([]);
    setRemarks("");
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  };

  const content = (
    <>
      <div className="content m-0 text-center">
        <span className="text-3xl font-bold">Submit Assignment</span>
        <TextField
          id="outlined-multiline-static"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          multiline
          style={{ marginTop: "2rem" }}
          color="warning"
          focused
          fullWidth
          rows={3}
          label="REMARKS"
          variant="outlined"
        />
        <div className="flex">
          <input
            type="file"
            multiple
            onChange={(e) => handleOnChange(e)}
            style={{ display: "none" }}
            id="fileInput"
          />
        </div>
        {files?.length > 0 && (
          <div className="flex flex-1 gap-2 pt-3 items-center flex-wrap w-full">
            {files.map((file, index) => (
              <div
                key={index}
                className="flex w-[8rem] px-3 py-2 truncate items-center p-1 bg-gray-200 rounded-md"
              >
                <p className="truncate text-sm text-slate-700 p-0 m-0 ">
                  {file.name} ({file.size})
                </p>
                <button
                  onClick={() => removeFile(index)}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="float-right mt-4 flex gap-2">
          <label className="" htmlFor="fileInput">
            <span
              className={`bg-orange-${
                isLoading ? "400" : "500"
              } flex cursor-pointer items-center gap-2 text-white rounded-lg p-2`}
            >
              {isLoading ? "Uploading.." : "Upload Attachments"}
            </span>
          </label>
          <span
            onClick={handleSubmitRemarks}
            className="bg-orange-500 px-3 flex cursor-pointer items-center gap-2 text-white rounded-lg p-2"
          >
            Submit
          </span>
        </div>
      </div>
    </>
  );

  if (isFetching) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={classes.MainContainer}>
      <div className={classes.container}>
        <div className={classes.formRow}>
          <div className="form-buttons">
            <button
              style={{
                backgroundColor: "#FFA63D",
                borderColor: "#FFF",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#fff",
                margin: "0 5px",
                width: "160px",
                height: "36px",
                textAlign: "center",
                padding: "5px",
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "5px",
              }}
              type="submit"
              onClick={(event) => {
                event.preventDefault();
                navigate("/assignment");
              }}
            >
              Assignments
            </button>
            <button
              type="submit"
              style={{
                borderRadius: "5px",
                backgroundColor: "#FFA63D",
                borderColor: "#FFF",
                borderWidth: "1px",
                borderStyle: "solid",
                color: "#fff",
                margin: "0 5px",
                width: "160px",
                height: "36px",
                textAlign: "center",
                padding: "5px",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
              onClick={(event) => {
                event.preventDefault();
                navigate("/assignment/submittions", {
                  state: { assignmentId },
                });
              }}
            >
              Submissions
            </button>
          </div>
        </div>
        {data && (
          <div className={classes.BatchAssighnmetDetails}>
            <div className={classes.titelbox}>
              <div className="title bg-white py-2 px-3 border-l-[#FFA63D] border-l-4 rounded-md shadow-md w-[25rem] ">
                <h5
                  style={{ margin: "0px", fontSize: "20px", fontWeight: "500" }}
                >
                  Title
                </h5>
                <span
                  style={{
                    margin: "0px",
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#000E8F",
                  }}
                >
                  {data.title}
                </span>
              </div>
              <div className="data-time bg-white py-2 px-3 border-l-[#FFA63D] border-l-4 rounded-md shadow-md w-[25rem] ">
                <h5
                  style={{ margin: "0px", fontSize: "20px", fontWeight: "500" }}
                >
                  Due Date-Time
                </h5>
                <span
                  style={{
                    margin: "0px",
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#000E8F",
                  }}
                >
                  {data.due_date}
                </span>
              </div>
            </div>
            <div className="pt-3 w-[100%]  md:w-[85%]">
              <h4
                style={{
                  fontSize: "22px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  margin: "10px 0px",
                }}
              >
                Description
              </h4>
              <div
                className="text-gray-500"
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></div>
            </div>
            <div className="pt-2 pb-1">
              {/* <div className="attachments-card w-[100%] md:w-[85%] bg-white py-3 border-l-orange-500 border-l-4 rounded-md shadow-md"> */}
              <div className={classes.attachementscard}>
                <h5
                  className="pl-2 mb-3"
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#4C4C4C",
                    margin: "10px 0px",
                  }}
                >
                  Attachments
                </h5>
                <div className="attachments flex gap-2 justify-start flex-wrap">
                  {data?.attachments?.map((attachment, index) => (
                    <AttachmentBox key={index} attachment={attachment} />
                  ))}
                </div>
              </div>
            </div>
            {data?.can_submit !== "1" && data.solution && (
              <div className="pt-2 pb-1">
                <div className={classes.attachementscard}>
                  <h5 className="pl-8">Solutions</h5>
                  <div className="attachments flex gap-1 justify-start px-2 py-2 flex-wrap">
                    {data?.solution?.map((attachment, index) => (
                      <AttachmentBox key={index} attachment={attachment} />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {data?.can_submit === "1" && (
              <div>
                <button
                  className="px-4 py-2 bg-blue-800 text-white rounded-lg mt-4 w-[50%] mx-auto block"
                  onClick={handleOpenModal}
                >
                  {data?.button_title}
                </button>
              </div>
            )}
            {isOpenModal && (
              <PreviewModal content={content} onClose={handleOpenModal} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignmentDetails;
