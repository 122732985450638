import React from "react";
import styles from "./CustomModal.module.css";

const CustomModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null; // Don't render anything if modal is closed

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          <i className="fa-solid fa-xmark fa-xl"></i>
        </button>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default CustomModal;
