import React from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { IoEyeOffOutline } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { IoEyeOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../Context/AuthContext";
import {
   loginEmail,
} from "../../../../Store/API/API";
import { useDispatch } from "react-redux";
import { userDataActions } from "../../../../Store/Data/Slices/UserDataSlice";
import Cookies from "universal-cookie";
import { applicationActions } from "../../../../Store/Data/Slices/ApplicationSlice";

function Login2({schoolData}) {
  const cookies = new Cookies();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [dynamic, setDynamic] = useState({
    button_color: schoolData. button_color|| "0743A3",
    tag_line:schoolData.tag_line || "Unlock your endless learning ",
    description:schoolData.description || "",
    web_link: schoolData.web_link|| "https://innovartan.com/",
    page_image:schoolData. page_image || "/images/inno-img.png",
    program_image: schoolData.program_image || "/images/inno-img",
    page_color:schoolData.page_color || "0743A3",
    page_color_2:schoolData. page_color_2 || "F36421",
    text_color:schoolData.text_color || "ffffff",
    fb_link: schoolData.fb_link || "",
    twitter_link:schoolData.twitter_link || "",
    instagram_link: schoolData.instagram_link || "",
    youtube_link:schoolData.youtube_link || "",
    linkedin_link:schoolData.linkedin_link || "",
  });
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { login } = useAuth();
  const dispatch = useDispatch();
 
 
  const changeFavicon = (iconUrl, title, schoolCode, imageURL) => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = iconUrl;
    document.getElementsByTagName("head")[0].appendChild(link);

    if (title) {
      document.title = title;
    }

    const tempData = {
      schoolName: title,
      schoolCode: schoolCode,
      favicon: iconUrl,
      schoolImage: imageURL,
    };

    localStorage.setItem("schoolData", JSON.stringify(tempData));
    dispatch(applicationActions.setSchoolData(tempData));
  };

  useEffect(() => {
      if (schoolData) {
        setDynamic({
          button_color: schoolData.button_color,
          tag_line: schoolData.tag_line,
          description: schoolData.description,
          web_link: schoolData.web_link,
          page_image: schoolData.page_image,
          program_image: schoolData.program_image,
          page_color:schoolData.page_color,
          page_color_2: schoolData.page_color_2,
          text_color: schoolData.text_color,
          fb_link: schoolData.fb_link,
          twitter_link: schoolData.twitter_link,
          linkedin_link: schoolData.linkedin_link,
          instagram_link: schoolData.instagram_link,
          youtube_link: schoolData.youtube_link,
        });
        changeFavicon(
          schoolData.fevicon,
          schoolData.school_name,
          schoolData.school_code,
          schoolData.school_image
        );
      }
    }, [schoolData]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!username || !password) {
      setError("Please enter both username and password.");
      return;
    }
    const credentials = btoa(`${username}:${password}`);
    const data = await loginEmail(credentials, dispatch);
    // console.log(data, "userData");
    let tempData = {
      token: data?.token,
      userId: data?.user_id,
      instituteId: data?.institute_id,
      firstName: data?.first_name,
      lastName: data?.last_name,
      userType: data?.user_type,
      hideDashboard: data?.hide_dashboard,
      inst: data?.inst,
      remark: data?.remark,
      pdfScore: data?.getScore,
      leftBar: data?.left_config,
    };

    // console.log(tempData);
    if (data?.default_batch) {
      let SelectedBatchData = {};
      if (data?.default_batch?.group_id) {
        SelectedBatchData = {
          groupId: data?.default_batch?.group_id,
          batchName: data?.default_batch.batch_name,
          batchColor: data?.default_batch.batch_color,
          batchUserType:
            tempData?.userType === "teacher" ? "student" : tempData?.userType,
        };
      }

      // console.log(SelectedBatchData, "SelectedBatchData");

      dispatch(userDataActions.setUserData(tempData));
      dispatch(applicationActions.setBatchData(SelectedBatchData));
      // navigate('/dashboard');
      cookies.set("userData", JSON.stringify(tempData), {
        sameSite: "None",
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
      });
      localStorage.setItem("userData", JSON.stringify(tempData));
      localStorage.setItem("batchData", JSON.stringify(SelectedBatchData));
    } else {
      setError("Invalid username or password.");
    }
  };
  const handleNavigate = () => {
    window.open(`${dynamic.web_link}`, "_blank");
  };
  return (
    <div className="flex flex-col lg:flex-row relative h-screen lg:min-h-screen  w-full font-metropolis">
      <div className="w-full lg:w-[50%] bg-textWhite flex flex-col justify-center items-center relative">
        <div className="w-full flex flex-col items-start  mt-0 lg:mt-64">
          <div
            className="flex-1 w-full lg:max-w-[85%]  p-5 lg:min-h-[65vh]"
            style={{ backgroundColor:` #${dynamic.page_color}` }}
          >
            <div className="flex flex-col pt-[280px] md:pt-80 lg:pt-52 px-4 lg:px-0 lg:pl-52">
              <h2
                className=" text-base leading-5 text-center lg:text-left md:text-[24px] md:leading-7 mt-4 font-bold "
                style={{ color: `#${dynamic.text_color}` }}
              >
                {dynamic.tag_line}
              </h2>
              {dynamic.description && (
                <p
                  className="text-sm md:text-[18px] -mt-4 text-center lg:text-left md:leading-6 "
                  style={{ color: `#${dynamic.text_color}` }}
                >
                  {dynamic.description}
                </p>
              )}
            </div>
            <div className="px-4 lg:px-0 lg:pl-52 mt-5 hidden lg:block">
              {dynamic.web_link && (
                <button
                  onClick={handleNavigate}
                  className=" rounded-lg px-4 py-2 text-lg text-center flex justify-center text-textWhite items-center mt-4"
                  style={{ backgroundColor: `#${dynamic.page_color_2}` }}
                >
                  Explore Now
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="absolute top-0 lg:top-8 left-0 lg:left-[30%] xl:left-[30%] w-full flex flex-col items-center lg:items-start  gap-5 z-10">
          {dynamic.program_image ? (
            <img
              src={dynamic.program_image}
              alt="school logo"
              className="w-36 h-20 object-contain"
            />
          ):<div  className="w-36 h-20 object-contain">
            </div>}
          <img
            src={dynamic.page_image}
            alt="school-img"
            className=" w-72 h-52 lg:w-[550px] lg:h-[350px] object-contain"
          />
        </div>
      </div>
      <div className="w-full px-4 sm:px-8 bg-textWhite   lg:w-[50%]   flex flex-col justify-center items-center ">
        <div className="py-5 ">


          <div className="text-center pt-2 lg:pt-32 pb-4">
            {schoolData.school_image && (
              <img
                src={schoolData.school_image}
                alt={"School Logo"}
                className="mx-auto w-24 h-24 lg:w-32 lg:h-32 xl:w-40 xl:h-40 object-contain"
              />
            )}
          </div>
          <div className="text-center mb-6">
            <h2 className="text-lg lg:text-2xl xl:text-3xl font-bold mb-2 ">
              Login to your account
            </h2>
            <p className="hidden lg:block lg:text-base text-textGray">
              Begin your journey with us Today!!
            </p>
          </div>
          <form className="mt-4" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-sm lg:text-base font-medium text-textBlack"
              >
                Email <span className="text-primaryOrange">*</span>/ Mobile No.{" "}
                <span className="text-primaryOrange">*</span>
              </label>
              <div className="relative">
                <MdOutlineEmail className="absolute w-5 h-5 lg:w-6 lg:h-6 m-2 mx-3 text-textGray" />
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Enter Your Email /Mobile No."
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mt-1 block w-full px-12 py-2 bg-backgroundBlue text-textGray rounded-lg focus:outline-none"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm lg:text-base font-medium text-black"
              >
                Password <span className="text-primaryOrange">*</span>
              </label>
              <div className="relative">
                <RiLockPasswordLine className="absolute w-5 h-5 lg:w-6 lg:h-6 m-2 mx-3 text-textGray" />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full px-12 py-2 bg-backgroundBlue text-textGray rounded-lg focus:outline-none"
                />
                {showPassword ? (
                  <IoEyeOutline
                    className="absolute w-5 h-5 lg:w-6 lg:h-6 text-textGray right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <IoEyeOffOutline
                    className="absolute w-5 h-5 lg:w-6 lg:h-6 text-textGray right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
            </div>
            {error && <p className="text-textRed text-sm">{error}</p>}
            <button
              type="submit"
              className="w-full text-textWhite py-2 px-4 rounded-lg "
              style={{ backgroundColor: `#${dynamic.button_color}` }}
            >
              Login
            </button>
          </form>
          <div className="flex  pr-6 justify-center items-center mt-8">
            <ul className="flex gap-2">
              {[
                { icon: FaFacebookF, link: dynamic?.fb_link },
                { icon: FaXTwitter, link: dynamic?.twitter_link },
                { icon: FaLinkedinIn, link: dynamic?.linkedin_link },
                { icon: FaInstagram, link: dynamic?.instagram_link },
                { icon: FaYoutube, link: dynamic?.youtube_link },
              ].map(({ icon: Icon, link }, idx) =>
                link ? (
                  <li
                    key={idx}
                    className=" rounded-lg h-4 w-4 p-4 text-center flex justify-center items-center"
                    style={{ backgroundColor: `#${dynamic.page_color}` }}
                  >
                    <button
                      onClick={() =>
                        window.open(link, "_blank", "noopener,noreferrer")
                      }
                      style={{ color: `#${dynamic.text_color}` }}
                    >
                      <Icon />
                    </button>
                  </li>
                ) : null
              )}
            </ul>
          </div>
          <footer className="text-textLightgrey text-center text-sm mt-8">
            Copyright © {new Date().getFullYear()} Innovartan | All Rights
            Reserved
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Login2;