import React from "react";
import classes from "./Spinner.module.css";

const Spinner = ({ spinnerHeight }) => {
  return (
    <div
      className={classes.spinner}
      style={{ height: spinnerHeight ? spinnerHeight : "100%" }}
    ></div>
  );
};

export default Spinner;
