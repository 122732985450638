import React from "react";

const TabButtons = ({ title, activeTab, onClick, number }) => {
  return (
    <button
      className={`${
        activeTab === title
          ? "bg-[#0743A3] border-[#0743A3]"
          : "border-[#073278]"
      } text-white px-4 py-2 rounded-xl border text-sm hover:bg-[#0743a3b7] hover:border-[#0743A3] transition`}
      onClick={() => onClick(title)}
    >
      {title} {number ? `(${number})` : ""}
    </button>
  );
};

export default TabButtons;
