import React, { useState, useEffect } from "react";
import { getCourseContent, getVedioViewTime } from "../../../Store/API/API";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./CourseContent.module.css";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiMovieLine,
  RiTimeLine,
  RiCheckboxLine,
  RiCheckboxBlankLine,
  RiCloseLine,
} from "@remixicon/react";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckIcon from "@mui/icons-material/Check";
import QuizIcon from "@mui/icons-material/Quiz";
import VideoPlayer from "../Dashboard/Videoplayer/VideoPlayer";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
const CourseContent = () => {
  const userState = useSelector((state) => state.userData.userData);
  const [isLoading, setIsLoading] = useState(true);
  const [chapter, setCourseContent] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [activeTopic, setActiveTopic] = useState("");
  const [watchedVideos, setWatchedVideos] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [showSideContent, setShowSideContent] = useState(false);
  const location = useLocation();
  const [iframeSrc, setIframeSrc] = useState("");
  const { course_name } = location?.state || "";
  const toggleSideContent = () => setShowSideContent((prev) => !prev);
  const closeSideContent = () => setShowSideContent(false);
  const [selectedMP4, setSelectedMP4] = useState();
  const [currentViewTime, setCurrentViewTime] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContent = async () => {
      const data = await getCourseContent(
        course_name,
        userState.userId,
        dispatch
      );
      if (data) {
        // console.log("data", data);

        setCourseContent(data.chapter_content);
      }
      setIsLoading(false);
    };
    fetchContent();

    //eslint-disable-next-line
  }, [userState, course_name]);

  const totalVideos = chapter.reduce((total, currentChapter) => {
    const chapterVideos = currentChapter.content.filter(
      (item) => item.type === "video"
    ).length;
    return total + chapterVideos;
  }, 0);

  const toggleDropdown = (topic) => {
    if (!activeTopic) {
      setSelectedVideo();
      setSelectedMP4();
    }
    setActiveTopic(activeTopic === topic ? null : topic);
  };

  const formatVideoData = (apiData, course_id, course_name) => {
    return {
      video_id: apiData.content_id || "",
      url: apiData.value,
      title: apiData.title,
      course_id: course_id,
      course_name: course_name,
      content_id: apiData.content_id,
      duration: apiData.duration || "0",
      thumb_nail: apiData.thumb_nail || "",
    };
  };

  const playVideo = async (videoSrc, data) => {
    setSelectedMP4();
    setSelectedVideo(null);
    if (!selectedVideo) {
      const formattedData = formatVideoData(data, data.content_id, course_name);
      setSelectedVideo(videoSrc);
      setSelectedMP4(formattedData);
      setShowVideo(true);
      setIframeSrc("");
      const tempData = {
        student_id: userState.userId,
        content_type: "shop",
        content_id: data.content_id,
      };
      const apiData = await getVedioViewTime(
        tempData,
        userState.token,
        dispatch
      );
      if (apiData) {
        setCurrentViewTime(apiData.data.current_view_time);
        // console.log( "current view time ",currentViewTime);
      }
    }
    // setWatchedVideos(prev => new Set(prev.add(data.content_id)));
  };

  const handleTestClick = (url, e) => {
    e.preventDefault();
    e.stopPropagation();
    setIframeSrc(url);
    setShowVideo(false);
  };

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className={classes["contentner"]}>
        <div className={`${classes["button"]}`} onClick={toggleSideContent}>
          Content
        </div>
        <div className={classes["mainContentner"]}>
          <div className={classes["main-content"]}>
            <div className={`${classes["videoplayer"]}`}>
              {iframeSrc && (
                <iframe src={iframeSrc} allowFullScreen title="test-content" />
              )}
              {showVideo && selectedVideo && (
                <VideoPlayer
                  videoUrl={selectedVideo}
                  fileData={selectedMP4}
                  currentViewTimeParent={currentViewTime}
                />
              )}
              {!iframeSrc && !showVideo && (
                <h1>Select Course video or test from the list to play</h1>
              )}
              {!selectedVideo && showVideo && (
                <h1>Select Course video or test from the list to play</h1>
              )}
            </div>
          </div>
          <div
            className={`${classes["side-content"]} ${
              showSideContent ? classes["visible"] : ""
            }`}
          >
            <div>
              <RiCloseLine
                className={classes["closebtn"]}
                onClick={closeSideContent}
              />
            </div>
            <div className={classes["course-content"]}>
              <p>Course Content</p>
              <span className={classes["totalVideos"]}>
                <RiMovieLine height="18" color="#00233796" />
                <p>{totalVideos} Videos,</p>
                <RiTimeLine height="18" color="#00233796" />
              </span>
            </div>
            <div className={classes["course-contentList"]}>
              <div className={classes["topics-wrapper"]}>
                {chapter.map((topicData, index) => (
                  <div
                    key={index}
                    onClick={() => toggleDropdown(topicData.chapterTitle)}
                  >
                    <div className={classes["course-content1"]}>
                      {topicData.chapterTitle}:
                      {activeTopic === topicData.chapterTitle ? (
                        <RiArrowUpSLine className={classes["down-arrow"]} />
                      ) : (
                        <RiArrowDownSLine className={classes["down-arrow"]} />
                      )}
                    </div>
                    <span className={classes["totalVideos"]}>
                      <RiMovieLine height="18" color="#00233796" />
                      <p>
                        {
                          topicData.content.filter(
                            (video) => video.type === "video"
                          ).length
                        }{" "}
                        video
                        {topicData.content.filter(
                          (video) => video.type === "video"
                        ).length > 1
                          ? "s"
                          : ""}
                        ,
                      </p>
                      <RiTimeLine height="18" color="#00233796" />
                    </span>
                    {activeTopic === topicData.chapterTitle && (
                      <ul
                        className={`${classes["video-list"]} ${classes["transition"]}`}
                      >
                        {topicData.content
                          .filter((video) => video.type === "video")
                          .map((video, idx) => (
                            <li key={idx}>
                              <div
                                className={classes["video-title-container"]}
                                onClick={() => playVideo(video.value, video)}
                              >
                                <PlayCircleOutlinedIcon
                                  style={{ color: "orangered" }}
                                />
                                <span>{video.title}</span>
                                <div className={classes["checkbox-container"]}>
                                  {/* {watchedVideos.has(video.content_id) ? (
                                    <RiCheckboxLine
                                      className={classes["icon-margin"]}
                                    />
                                  ) : (
                                    <RiCheckboxBlankLine
                                      className={classes["icon-margin"]}
                                    />
                                  )} */}
                                </div>
                              </div>
                              {video.linked_test &&
                                video.linked_test.length > 0 && (
                                  <div className={classes["test-info"]}>
                                    {video.linked_test.map((test, testIdx) => (
                                      <div
                                        key={testIdx}
                                        className={classes["test-item"]}
                                      >
                                        <p
                                          onClick={(e) => {
                                            console.log(
                                              "test token",
                                              test.token
                                            );
                                            test.lastAttemptStatus
                                              ? navigate("/ViewTestSubmission", {
                                                  state: { quizId: test.test_id },
                                                })
                                              : handleTestClick(test.token, e);
                                          }}
                                        >
                                          {/* <a
                                            href={test.token}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={(e) =>
                                              handleTestClick(test.token, e)
                                              console.log("test token", test.token)
                                              
                                            }
                                          > */}
                                          <QuizIcon
                                            style={{ color: "orangered" }}
                                          />{" "}
                                          {test.name}
                                          {/* </a> */}
                                        </p>
                                        <div className={classes["DoneMargin"]}>
                                          {test.lastAttemptStatus ? (
                                            <DoneAllIcon
                                              style={{ color: "green" }}
                                              className={classes["icon-margin"]}
                                            />
                                          ) : (
                                            <CheckIcon
                                              className={classes["icon-margin"]}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CourseContent;
