import React, { useState, useEffect } from "react";
import { FaFolder } from "react-icons/fa6";
import { FaFile } from "react-icons/fa";
import Zoom from "react-medium-image-zoom";
import classes from "./ResourceFolders.module.css";
import VideoPlayer from "./../Pages/Dashboard/Videoplayer/VideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import { getRecordingTime } from "../../Store/API/API";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Spinner from "../UserInterface/Spinner/Spinner";
import PDFViewer from "./PDFView";
const ResourceFolders = ({
  isGrid,
  folders,
  files,
  onFolderClick,
  directory,
  handleDirectoryClick,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedMP4, setSelectedMP4] = useState("");
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [currentViewTime, setCurrentViewTime] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const userState = useSelector((state) => state.userData.userData);
  useEffect(() => {
    if (folders && files) {
      setLoading(false);
    }
  }, [folders, files]);
  const closeVideoModal = () => {
    setSelectedMP4("");
    setCurrentViewTime(0);
    setShowVideoModal(false);
  };

  const closePDFModal = () => {
    setSelectedPDF(null);
  };

  const handlePdfClick = (file) => {
    // console.log("PDF clicked:", file);

    const btobUrl = btoa(file.url);
    setSelectedPDF(btobUrl);
  };
  
  const handleImageClick = (file) => {
    setSelectedFile(file);
  };
  const handleMp4Click = async (file) => {
    setSelectedMP4(file);
    const tempData = {
      user_id: userState.userId,
      content_type: "resource",
      content_id: file.content_id,
    };

    // console.log("Video Data", tempData);

    const data = await getRecordingTime(tempData, userState.token, dispatch);
    // console.log("Recording Time", data);
    if (data) {
      setCurrentViewTime(data.current_view_time);
      setShowVideoModal(true);
    }
  };

  // console.log(`https://app.innovartan.com/flipBook/flip.php?link=${selectedPDF}`);
  // https://app.innovartan.com/flipBook/flip.php?link=aHR0cHM6Ly9pbm5vdmFydGFuLnMzLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb20vcmVzb3VyY2UvNC9maWxlLTE3MjI4MzQyODEtODE4MjgxOTk3LnBkZg==

  const handleFileClick = (file) => {
    switch (file.mime) {
      case "application/pdf":
        handlePdfClick(file);
        break;
      case "video/mp4":
        handleMp4Click(file);
        break;
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        handleImageClick(file);
        break;
      default:
      // console.log("Unknown file type:", file);
    }
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <div className="directory mt-16 overflow-auto ">
        <span
          onClick={() => handleDirectoryClick(0)}
          className="cursor-pointer text-sm"
        >
          / {" >> "}
        </span>
        {directory &&
          directory.map((folder, idx) => (
            <span
              onClick={() => handleDirectoryClick(folder.folder_id)}
              className="cursor-pointer text-sm"
              key={idx}
            >
              {folder.folder_name} {" >> "}
            </span>
          ))}
      </div>
      {!isGrid && (
        <div className={classes.resourceheading}>
          <div
            className={
              files && files.length > 0
                ? `${classes.titleheading}`
                : `${classes.heading}`
            }
          >
            {files && files.length > 0 ? "Title" : "Subject"}
          </div>
          <div className={classes.heading}>Size</div>
          <div className=""></div>
        </div>
      )}
      <div
        className={`flex relative overflow-auto ${
          !isGrid ? "flex-wrap" : "flex-wrap"
        } ${classes.foldercontainer}`}
      >
        {folders?.map((folder) => (
          <div
            className={
              isGrid
                ? `  mt-2 rounded-lg `
                : `w-[100%] bg-white flex p-1 rounded-lg items-center my-2 cursor-pointer justify-between h-[3rem]`
            }
            key={folder.folder_id}
            onClick={() => {
              onFolderClick(folder.folder_id, folder.folder_name);
            }}
          >
            <div
              className={
                isGrid
                  ? `text-black truncate hover:text-blue-600 flex flex-col justify-center items-center mt-2 w-full px-2`
                  : `mx-5 my-auto text-lg flex pl-6`
              }
            >
              <FaFolder color="orange" size={isGrid ? `100` : 25} />
              <div className={isGrid ? `` : `${classes.folderName} `}>
                {" "}
                {folder.folder_name}
              </div>
            </div>
            <div className={classes.foldersize}>
              {!isGrid ? (folder.size ? folder.size : "-") : ""}
            </div>
            <div className={""}></div>
          </div>
        ))}
        {files?.map((file) => (
          <div
            className={
              isGrid
                ? `p-4 m-1 flex flex-col justify-start items-center`
                : `w-[100%] bg-white flex px-10 py-4 rounded-lg items-center my-2 cursor-pointer`
            }
            key={file.document_id}
            onClick={() => handleFileClick(file)}
          >
            <div
              className={`${
                isGrid
                  ? "bg-transparent"
                  : "bg-[#000E8F] rounded-md border border-black"
              } ${classes.fileinfo} ${
                file.mime === "video/mp4"
                  ? classes.videoinfo
                  : file.mime === "application/pdf"
                  ? classes.pdfinfo
                  : ""
              }`}
              style={
                file.mime === "image/jpeg" ||
                file.mime === "image/png" ||
                file.mime === "image/gif"
                  ? {
                      backgroundImage: `url(${file.url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }
                  : {}
              }
            >
              {file.mime === "video/mp4" && (
                <span className={classes.videoIcon}>
                  <PlayArrowIcon style={{ fontSize: 50 }} />
                </span>
              )}
            </div>

            <span
              className={
                isGrid
                  ? `text-black truncate hover:text-blue-600 mt-2 text-center w-full px-2`
                  : `mx-3 truncate w-[80%] my-auto text-lg`
              }
            >
              {file.name}
            </span>
          </div>
        ))}
        {selectedFile && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ml-[50px]">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg w-[80%] h-[70%] flex flex-col overflow-auto ">
              <Zoom>
                <img
                  src={selectedFile.url}
                  alt={selectedFile.name}
                  className="w-[100%] h-auto cursor-pointer object-contain"
                />
              </Zoom>
              <button
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 w-[100px] self-center"
                onClick={() => setSelectedFile(null)}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {selectedPDF && (
          <div className="fixed  w-full inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50  ">
            <div className="bg-white p-4 rounded-lg shadow-lg w-[95%] flex flex-col justify-center items-center ml-20 mr-5">
              <PDFViewer selectedPDF={selectedPDF} />
              <button
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 w-[100px] self-center"
                onClick={closePDFModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {showVideoModal && (
          <div className={classes.videoModal}>
            <div className={classes.videoModalContent}>
              <span className={classes.close} onClick={closeVideoModal}>
                &times;
              </span>
              <VideoPlayer
                fileData={selectedMP4}
                currentViewTimeParent={currentViewTime}
                type={"resource"}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ResourceFolders;
